import { RefCallback } from 'react';
import { ChangeHandler, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import { PasswordInput } from '@components/atoms/PasswordInput';

type InputElementProps = {
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  ref: RefCallback<HTMLInputElement>;
};

type WifiSectionProps = {
  enabled: boolean;
  enabledProps: InputElementProps;
  ssidProps: InputElementProps;
  passwordProps: InputElementProps;
  errors: FieldErrors;
};

export const WifiSection = ({ enabled, enabledProps, ssidProps, passwordProps, errors }: WifiSectionProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Checkbox label={t('wifiEnabled', 'WiFi enabled')} className="!text-base" {...enabledProps} />
      {enabled && (
        <>
          <Input
            label={t('wifiSsid', 'WiFi SSID')}
            placeholder={t('wifiSsidPlaceholder', 'Please enter SSID to use WiFi')}
            type="text"
            error={Boolean(errors[ssidProps.name])}
            helpText={errors[ssidProps.name]?.message?.toString()}
            {...ssidProps}
          />
          <PasswordInput
            autoComplete="new-password"
            label={t('password', 'Password')}
            type="text"
            error={Boolean(errors[passwordProps.name])}
            helpText={errors[passwordProps.name]?.message?.toString()}
            {...passwordProps}
          />
        </>
      )}
    </>
  );
};
