import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import nothingIcon from '@images/icons/Nothing.svg';

type NoOcppVariablesToShowBoxProps = {
  withSearchQuery: boolean;
};

export const NoOcppVariablesToShowBox = ({ withSearchQuery }: NoOcppVariablesToShowBoxProps) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ textAlign: 'center', paddingTop: '40px' }}>
      <Box
        sx={{
          borderRadius: '50%',
          borderColor: '#999898',
          borderWidth: '5px',
          height: '80px',
          width: '80px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginBottom: '20px',
          position: 'relative',
        }}
      >
        <img className="absolute inset-0 m-auto" src={nothingIcon} alt="Nothing" />
      </Box>
      <Typography variant="h2">{t('nothingToShow', 'Nothing to show')}</Typography>
      <Typography variant="p14">
        {withSearchQuery
          ? t('noVariablesMatchSearchQueryText', 'It seems there are no results that match your search criteria.')
          : t('noVariablesResponseText', 'It seems like the device responded with no variables.')}
      </Typography>
    </Box>
  );
};
