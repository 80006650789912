import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';

import { Tooltip } from '@mui/material';

import chipIcon from '@images/icons/Chip.svg';
import { isChargerInLmcGroup } from '@views/Devices/DeviceDetailsSidebar/deviceConfigurationUtils';

export const ChargerInLmcGroupIndicator = ({ device }: { device: Device }) => {
  const { t } = useTranslation();

  return (
    isChargerInLmcGroup(device) && (
      <Tooltip title={t('inLmcGroup', 'In LMC group')} placement="top">
        <img src={chipIcon} alt="In LMC group" />
      </Tooltip>
    )
  );
};
