import { selectActiveCompanyUuid, selectPartnerCompanies } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetCompanyPartnershipQuery } from '@services/partners/endpoints';

export const useCompanyPartnership = () => {
  const partnerCompanies = useAppSelector(selectPartnerCompanies);
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);

  const { data: companyPartnership, isLoading: isCompanyPartnershipsLoading } = useGetCompanyPartnershipQuery();

  return {
    partnerCompanies: partnerCompanies.filter((company) => company.uuid !== activeCompanyUuid),
    partnerCompany: partnerCompanies.find((company) => company.uuid === companyPartnership?.partnerCompanyUuid),
    isCompanyPartnershipsLoading,
  };
};
