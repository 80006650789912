import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { t } from 'i18next';
import { User } from 'models/user';

import { Autocomplete, Box, createFilterOptions } from '@mui/material';

import { Avatar } from '@components/avatar/Avatar';
import { useAppSelector } from '@services/hooks';
import { selectUsers } from '@services/users/selectors';
import { MemberInfo } from '@views/Workspace/MemberInfo';
import { GroupMemberChip } from '@views/Workspace/sidebars/groups/GroupMemberChip';

import { StyledTextField } from './StyledTextField';

export type UserEmailOption = {
  label: string;
  email: string;
  name?: string;
  uuid?: string;
  inviteFlag?: 0 | 1;
  inviteTime?: Date;
};

type FormUserEmailAutocompleteProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
};

type UserOption<OptionType> = OptionType & UserEmailOption;

type UserEmailAutocompleteProps<OptionType> = {
  label?: string;
  options?: UserOption<OptionType>[];
  value?: UserOption<OptionType>[];
  error?: { message?: string };
  ref?: React.Ref<HTMLInputElement>;
  enterOnSpaceOrComma?: boolean;
  onChange?: (value: UserOption<OptionType>[]) => void;
};

export const makeUserEmailOption = (user: User): UserEmailOption => {
  return {
    label: user.name ?? user.email,
    email: user.email,
    name: user.name,
    uuid: user.uuid,
    inviteFlag: user.inviteFlag,
    inviteTime: user.inviteTime,
  };
};

export const UserEmailAutocomplete = <OptionType,>({
  label,
  options,
  value,
  error,
  enterOnSpaceOrComma,
  onChange,
}: UserEmailAutocompleteProps<OptionType>) => {
  const isValidEmail = (options?: UserOption<OptionType>[]) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (option: UserOption<OptionType> | string) => {
      if (typeof option === 'string') {
        return !options?.some((user) => user.email.toLowerCase() === option.toLowerCase()) && regex.test(option);
      }

      return regex.test(option.email);
    };
  };
  return (
    <Autocomplete
      multiple
      freeSolo
      options={options ?? []}
      getOptionKey={(option) => (typeof option === 'string' ? option : option.email)}
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => (
          <GroupMemberChip label={option.label} {...getTagProps({ index })} key={index} />
        ))
      }
      renderInput={(params) => (
        <StyledTextField
          {...params}
          label={label}
          error={Boolean(error)}
          helperText={error?.message}
          InputLabelProps={{}}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            style: { padding: '5px 8px' },
          }}
          sx={{
            '& .MuiInputLabel-root': {
              transform: 'translate(14px, 12px)',
            },
            '& .MuiOutlinedInput-root': {
              height: 'auto',
            },
          }}
        />
      )}
      renderOption={(props, user) => (
        <Box {...props} key={crypto.randomUUID()} component="li" gap={2}>
          <Avatar name={user.name} email={user.email} bigger />
          <MemberInfo member={user} />
        </Box>
      )}
      isOptionEqualToValue={(option, value) => option.email === value.email}
      value={value ?? []}
      disableClearable
      forcePopupIcon={false}
      filterOptions={createFilterOptions({
        stringify: (option: UserOption<OptionType>) => `${option.name || ''} ${option.email}`,
      })}
      autoSelect
      filterSelectedOptions
      onKeyDown={(event) => {
        if (enterOnSpaceOrComma && (event.key === ' ' || event.key === ',')) {
          event.preventDefault();
          const enterEvent = new KeyboardEvent('keydown', { key: 'Enter', bubbles: true });
          const target = event.target as HTMLInputElement;
          target.dispatchEvent(enterEvent);
        }
      }}
      onChange={(event, values, reason) => {
        if (reason === 'blur' && typeof values[values.length - 1] === 'object') return;
        const newUsers = values
          .flatMap((value) =>
            typeof value === 'string' && value.split(',').length ? value.split(',').map((v) => v.trim()) : value,
          )
          .filter(isValidEmail(value))
          .map((value) => {
            if (typeof value === 'string') {
              const lowerCaseValue = value.toLowerCase();
              return (
                options?.find((user) => user.email.toLowerCase() === lowerCaseValue) ??
                ({
                  label: lowerCaseValue,
                  email: lowerCaseValue,
                } as UserOption<OptionType>)
              );
            }
            return value;
          });
        onChange?.(newUsers);
      }}
    />
  );
};

export const FormUserEmailAutocomplete = <T extends FieldValues>({
  control,
  name,
}: FormUserEmailAutocompleteProps<T>) => {
  const companyUsers = useAppSelector(selectUsers);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UserEmailAutocomplete
          label={t('emailAddresses', 'E-mail addresses')}
          options={companyUsers.map(makeUserEmailOption)}
          value={field.value.map(makeUserEmailOption)}
          error={error}
          onChange={field.onChange}
        />
      )}
    />
  );
};
