import { ControlChannel } from 'models/device.enums';

type DeviceConfigurationParameter = {
  key: string;
  readonly: boolean;
  letter?: string;
};

const createParam = (key: string, readonly: boolean, letter?: string): DeviceConfigurationParameter => ({
  key,
  readonly,
  letter,
});

export const DEVICE_CONFIGURATION_PARAMETERS = {
  FIRMWARE_UPDATE: createParam('firmware_update', true),
  FIRMWARE_VERSION: createParam('firmware_version', true),
  FIRMWARE_VERSION_FRONT_PANEL: createParam('firmware_version_front_panel', true, 'P'),
  FIRMWARE_VERSION_GATEWAY: createParam('firmware_version_gateway', true, 'G'),
  FIRMWARE_VERSION_MCU: createParam('firmware_version_mcu', true, 'M'),
  ENABLED_NETWORKS: createParam('enabled_networks', true),
  AVAILABLE_NETWORKS: createParam('available_networks', true),
  SUPPORTED_NETWORKS: createParam('supported_networks', true),
  AUTOMATICALLY_START_CHARGING: createParam('automatically_start_charging', false),
  CHARGER_ENABLED: createParam('charger_enabled', false),
  CURRENT_LIMIT: createParam('current_limit', false),
  MAXIMUM_CHARGING_CURRENT: createParam('maximum_charging_current', false),
  WIFI_SSID: createParam('wifi_ssid', false),
  WIFI_PASSWORD: createParam('wifi_password', false),
  WIFI_SIGNAL_STRENGTH: createParam('wifi_signal_strength', true),
  GSM_SIGNAL_STRENGTH: createParam('gsm_signal_strength', true),
  ENABLED_CONTROL_CHANNELS: createParam('enabled_control_channels', false),
  OCPP_SERVER_ADDRESS: createParam('ocpp_server_address', false),
  OCPP_SERVER_RECONNECT_TIME_S: createParam('ocpp_server_reconnect_time_s', false),
  PHASE_LOAD_MANAGEMENT: createParam('phase_load_management', false),
  POWER_METER_TYPE: createParam('power_meter_type', false),
  POWER_METER_IP: createParam('power_meter_modbus_ip', false),
  SOLAR_INVERTER_TYPE: createParam('solar_inverter_type', false),
  SOLAR_INVERTER_IP: createParam('solar_inverter_modbus_ip', false),
  LOAD_MANAGEMENT_PROTOCOL: createParam('load_management_protocol', false),
  POWER_SHARING_METHOD: createParam('power_sharing_method', false),
  MAINS_METERING_TYPE: createParam('mains_metering_type', false),
  POWER_METER_TYPE_OPTIONS: createParam('power_meter_type_values', true),
  SOLAR_INVERTER_TYPE_OPTIONS: createParam('solar_inverter_type_values', true),
  EXTERNAL_ANTENNA_ENABLED: createParam('external_antenna_enabled', false),
};

export const { APP_CMD, LAN_CMD, LAN_LM, CAN_CMD, CAN_LM } = ControlChannel;

export const CONTROL_CHANNEL_COMBINATIONS = Object.freeze({
  standalone: [APP_CMD],
  lmcLanWithoutOcpp: [LAN_LM, LAN_CMD],
  lmcCanWithoutOcpp: [CAN_LM, CAN_CMD],
  lmcLanWithOcpp: [LAN_LM, APP_CMD],
  lmcCanWithOcpp: [CAN_LM, APP_CMD],
});
