import DataHandler from '../data';

export const ACTIONS_TYPES = (objectType) => ({
  created: `create${DataHandler.getCapitalizedString(objectType)}`,
  updated: `update${DataHandler.getCapitalizedString(objectType)}`,
  deleted: `delete${DataHandler.getCapitalizedString(objectType)}`,
});

export const EVENTS_TYPES = {
  public: 'public-event',
  private: 'private-event',
};
