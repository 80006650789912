import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

import { selectUser } from '../auth/selectors';

const selectSlice = (state: RootState) => state.companies;

export const selectCompanies = createAppSelector(selectSlice, (slice) => slice.list);
export const selectPartnerCompanies = createAppSelector(selectCompanies, (companies) =>
  companies.filter((c) => c.partnerFlag),
);
export const selectActiveCompany = createAppSelector(selectCompanies, selectUser, (companies, user) =>
  companies.find((c) => c.uuid === user?.activeCompanyUuid),
);
export const selectActiveCompanyUuid = createAppSelector(selectActiveCompany, (activeCompany) => activeCompany?.uuid);
export const selectActiveCompanyGeneralPusherChannel = createAppSelector(
  selectActiveCompany,
  (activeCompany) => activeCompany?.generalPusherChannel,
);
