import { useState } from 'react';
import { useForm as useReactHookForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

export const useForm = ({ schema, ...rest }) => {
  const [formError, setFormError] = useState('');
  const { handleSubmit, ...useFormRest } = useReactHookForm({ resolver: yupResolver(schema), ...rest });

  const handleSubmitAndResolve = (submitFn) => () =>
    new Promise((resolve) =>
      handleSubmit((attrs) => Promise.resolve(submitFn(attrs)).then((success) => resolve(success)))(),
    );

  return {
    formError,
    setFormError,
    handleSubmitAndResolve,
    handleSubmit,
    ...useFormRest,
  };
};
