import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import { selectUser } from '@services/auth/selectors';

const BackofficeView = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const isAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;

  useEffect(() => {
    if (!isAdmin && !isInstaller) {
      navigate('/dashboard');
    }
  }, [isAdmin, isInstaller]);

  return <Outlet />;
};

export default BackofficeView;
