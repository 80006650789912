import { Dispatch, SetStateAction, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceModalOption } from 'models/device.enums';

import { Box, TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { UsageLocation } from '@views/Devices/useGetDeviceMenuItems';
import { useGetLmcMultipointMenuItems } from '@views/LMC/useGetLmcMultipointMenuItems';
import { DeviceTypeOption } from '@views/reporting/hooks/useDeviceTypeMenu';
import { Menu } from '@views/reporting/table/Menu';

import { DevicesAndGroupsContext } from '../DevicesAndGroupsProvider';
import { LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { ChargerConfiguredOutsideSiteWarning } from '../siteDevicesAndGroupsHelpers/ChargerConfiguredOutsideWarning';
import { DeviceIcon } from '../siteDevicesAndGroupsHelpers/DeviceIcon';
import { DeviceSiteContent } from '../siteDevicesAndGroupsHelpers/DeviceSiteContent';
import { DeviceVendorModel } from '../siteDevicesAndGroupsHelpers/DeviceVendorModel';
import { LmcStatusContent } from '../siteDevicesAndGroupsHelpers/LmcStatusContent';
import { UserFavoriteDeviceStar } from '../siteDevicesAndGroupsHelpers/UserFavoriteDeviceStar';
import {
  getLinkToAddGroup,
  getLinkToDevice,
  getLmcMultipointSumBy,
  isMultipoint,
} from '../siteDevicesAndGroupsHelpers/siteDevicesUtils';
import { SiteGroupsTableRowSkeleton, SiteGroupsTableSkeleton } from './SiteGroupsTableSkeleton';

type SiteGroupsColumnData = ColumnData<LmcMultipointWithStats>;

const useColumns = (hiddenColumns?: (keyof LmcMultipointWithStats | 'menu')[]): SiteGroupsColumnData[] => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const columns: SiteGroupsColumnData[] = [
    {
      width: 380,
      label: t('name', 'Name'),
      dataKey: 'displayName',
      sortable: true,
    },
    {
      width: 380,
      label: t('site', 'Site'),
      dataKey: 'siteUuid',
      sortable: true,
    },
    {
      width: 160,
      label: t('firmware', 'Firmware'),
      dataKey: 'firmwareVersion',
      sortable: true,
    },
    {
      width: 160,
      label: t('status', 'Status'),
      dataKey: 'status',
      sortable: true,
    },
    {
      width: 160,
      label: t('gridConnection', 'Grid connection'),
      dataKey: 'gridConnection',
      sortable: true,
      numeric: true,
    },
    {
      width: 60,
      label: t('sessions', 'Sessions'),
      dataKey: 'numSessions',
      sortable: true,
      numeric: true,
    },
    {
      width: 180,
      label: isMobile ? `${t('consumptionAbbreviation', 'Cons.')} kWh` : `${t('consumption', 'Consumption')} kWh`,
      dataKey: 'consumption',
      sortable: true,
      numeric: true,
    },
    {
      width: 20,
      label: '',
      dataKey: 'menu',
      sortable: false,
    },
  ];

  return hiddenColumns ? columns.filter((column) => !hiddenColumns.includes(column.dataKey)) : columns;
};

type RenderRowsProps = {
  rows: LmcMultipointWithStats[];
  columns: SiteGroupsColumnData[];
  selectedDevice?: LmcMultipointWithStats;
  onModalOptionSelected: Dispatch<SetStateAction<DeviceModalOption | undefined>>;
  onDeviceSelected: Dispatch<SetStateAction<Device | undefined>>;
};

const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.lmcMultipointUuid} row={row} {...rest} />);

type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: LmcMultipointWithStats;
};

const TableRow = ({ row, columns, selectedDevice, onModalOptionSelected, onDeviceSelected }: TableRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getLmcMultipointMenuOptions = useGetLmcMultipointMenuItems({
    onModalOptionSelected,
    onDeviceSelected,
    navigateToAddGroup: (lmcMultipoint) => navigate(getLinkToAddGroup(lmcMultipoint)),
    usageLocation: UsageLocation.SITE_DEVICES,
  });

  return (
    <StyledTableRow
      key={row.lmcMultipointUuid}
      sx={{
        bgcolor: selectedDevice?.lmcMultipointUuid === row.lmcMultipointUuid ? '#F4F4F4' : 'background.paper',
      }}
    >
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'displayName':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Link
                  to={row.multipointId ? getLinkToAddGroup(row) : getLinkToDevice(row)}
                  className="flex w-full text-left lg:min-w-[200px]"
                >
                  <DeviceIcon device={row} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    pl={{
                      lg: 2,
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={0.5}>
                      <Typography variant="p16b">{row.displayName}</Typography>
                      <ChargerConfiguredOutsideSiteWarning lmcMultipoint={row} />
                    </Box>
                    <Typography variant="p14">
                      {isMultipoint(row) ? (
                        <>
                          {t('group', 'Group')} - <span className="font-semibold"> {row.lmcName}</span>
                        </>
                      ) : (
                        <DeviceVendorModel device={row} />
                      )}
                    </Typography>
                  </Box>
                </Link>
              </TableCell>
            );
          case 'siteUuid':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <DeviceSiteContent device={row} />
              </TableCell>
            );
          case 'status':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {!isMultipoint(row) && <LmcStatusContent status={row.status} />}
              </TableCell>
            );
          case 'gridConnection':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.gridConnection || '-'}
              </TableCell>
            );
          case 'numSessions':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.numSessions || '-'}
              </TableCell>
            );
          case 'consumption':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                {row.consumption ? row.consumption.toFixed(2) : '-'}
              </TableCell>
            );
          case 'menu':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Box display="flex" alignItems="center" justifyContent="end" gap={0.5}>
                  {isSubdomainPartner() && !isMultipoint(row) && <UserFavoriteDeviceStar device={row} />}
                  <Menu menuOptions={getLmcMultipointMenuOptions(row)} />
                </Box>
              </TableCell>
            );
          default:
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Typography variant="p14">{String(row[column.dataKey] ?? '-')}</Typography>
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};

type SiteGroupsMaterialTableProps = {
  selectedDevice?: LmcMultipointWithStats;
  onDeviceSelected: Dispatch<SetStateAction<Device | undefined>>;
  onModalOptionSelected: Dispatch<SetStateAction<DeviceModalOption | undefined>>;
};

export const SiteGroupsMaterialTable = ({
  selectedDevice,
  onDeviceSelected,
  onModalOptionSelected,
}: SiteGroupsMaterialTableProps) => {
  const { siteUuid, lmcMultipointsWithStats, chargersWithStats, dataLoading, order, orderBy } =
    useContext(DevicesAndGroupsContext);

  const hiddenColumns: (keyof LmcMultipointWithStats | 'menu')[] = siteUuid
    ? ['siteUuid', 'firmwareVersion']
    : ['gridConnection', 'numSessions', 'consumption'];

  const columns = useColumns(hiddenColumns);

  const lmcMultipointsWithSessions = useMemo(
    () =>
      lmcMultipointsWithStats
        .filter((lmcMultipoint) => !!siteUuid || lmcMultipoint.deviceType !== DeviceTypeOption.MULTIPOINT)
        .map((lmcMultipoint) => ({
          ...lmcMultipoint,
          numSessions: getLmcMultipointSumBy({ lmcMultipoint, chargers: chargersWithStats, key: 'numSessions' }),
          consumption: getLmcMultipointSumBy({ lmcMultipoint, chargers: chargersWithStats, key: 'consumption' }),
        })),
    [lmcMultipointsWithStats, chargersWithStats],
  );

  return (
    <MaterialTable
      sortingStorageKey="siteGroupsTable"
      data={lmcMultipointsWithSessions}
      isLoading={dataLoading}
      headerProps={{
        columns,
        order,
        orderBy,
      }}
      renderRows={(rows, columns) =>
        renderRows({ rows, columns, selectedDevice, onModalOptionSelected, onDeviceSelected })
      }
      loaders={{ TableLoader: SiteGroupsTableSkeleton, RowLoader: SiteGroupsTableRowSkeleton }}
    />
  );
};
