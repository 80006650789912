import { PaymentMethod } from 'models/paymentMethod';

import apiSlice from '@services/api';

export interface CreateStripeSessionResponse {
  success: boolean;
  clientSecret: string;
}

export interface DeletePaymentMethodResponse {
  success: boolean;
  paymentMethod: PaymentMethod;
}

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createStripeSession: builder.mutation<CreateStripeSessionResponse, void>({
      query: () => ({
        url: '/billing/paymentMethods',
        method: 'POST',
      }),
    }),
    getCompanyPaymentMethods: builder.query({
      query: () => ({
        url: '/billing/paymentMethods/company',
        method: 'GET',
      }),
      providesTags: ['PaymentMethods'],
    }),
    deletePaymentMethod: builder.mutation<DeletePaymentMethodResponse, string>({
      query: (paymentMethodId) => ({
        url: '/billing/paymentMethods',
        method: 'DELETE',
        body: { paymentMethodId },
      }),
      invalidatesTags: ['PaymentMethods'],
    }),
  }),
});

export const { useCreateStripeSessionMutation, useGetCompanyPaymentMethodsQuery, useDeletePaymentMethodMutation } =
  extendedApi;
export const billingApi = extendedApi;
