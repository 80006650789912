import { Box } from '@mui/material';

import { ReactComponent as ChargerIcon } from '@images/icons/reporting/charger.svg';

export const NonVoolChargerIcon = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 16, height: 16, borderRadius: '4px', backgroundColor: '#D6D6D6' }}
  >
    <ChargerIcon width={10} />
  </Box>
);
