import { Site } from 'models/site';
import { User } from 'models/user';

import { UnifiedConnector } from '@views/Devices/connectorsUtil';

export enum AmplitudeEvent {
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  SWITCH_WORKSPACE = 'SwitchWorkspace',
  START_CHARGING = 'StartCharging',
  STOP_CHARGING = 'StoptCharging',
  OPEN_BACKOFFICE_DEVICES_VIEW = 'OpenBackofficeDevicesView',
  OPEN_BACKOFFICE_USERS_VIEW = 'OpenBackofficeUsersView',
  OPEN_DASHBOARD_VIEW = 'OpenDashboardView',
  OPEN_MAP_VIEW = 'OpenMapView',
  OPEN_SITES_VIEW = 'OpenSitesView',
  OPEN_VEHICLES_VIEW = 'OpenVehiclesView',
  OPEN_REPORTING_VIEW = 'OpenReportingView',
  OPEN_WORKSPACE_VIEW = 'OpenWorkspaceView',
  OPEN_LOGS_VIEW = 'OpenLogsView',
}

export enum AmplitudeLoginMethod {
  EMAIL = 'Email',
  GOOGLE = 'Google',
  APPLE = 'Apple',
}

export const getAmplitudeEventPropertiesForUser = (user: User) => ({
  userId: user.uuid,
  companyId: user.activeCompanyUuid,
});

export const getAmplitudeEventPropertiesForSite = ({ site, user }: { site: Site; user: User }) => ({
  ...getAmplitudeEventPropertiesForUser(user),
  siteId: site.uuid,
  siteName: site.name,
  companyId: site.companyUuid,
});

export const getAmplitudeEventPropertiesForConnector = ({
  connector,
  site,
  user,
}: {
  connector: UnifiedConnector;
  site: Site;
  user: User;
}) => ({
  ...getAmplitudeEventPropertiesForSite({ site, user }),
  voolDeviceId: connector.deviceUuid,
  deviceName: connector.deviceName,
  connectorId: connector.connectorId,
  companyId: connector.companyUuid,
});
