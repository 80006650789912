import { useTranslation } from 'react-i18next';

import { ReactComponent as ccsIcon } from '@images/icons/DevicePlugType/CCS.svg';
import { ReactComponent as chademoIcon } from '@images/icons/DevicePlugType/CHAdeMO.svg';
import { ReactComponent as cableIcon } from '@images/icons/DevicePlugType/Cable.svg';
import { ReactComponent as socketIcon } from '@images/icons/DevicePlugType/Socket.svg';
import { ReactComponent as type1Icon } from '@images/icons/DevicePlugType/Type1.svg';
import { ReactComponent as type2Icon } from '@images/icons/DevicePlugType/Type2.svg';
import { ReactComponent as unknownIcon } from '@images/icons/DevicePlugType/Unknown.svg';

export const useConnectorTypes = () => {
  const { t } = useTranslation();
  return {
    CCS: {
      label: 'CCS',
      icon: ccsIcon,
      powerType: 'DC',
      connectorFormat: 'CABLE',
    },
    CHADEMO: {
      label: 'CHAdeMO',
      icon: chademoIcon,
      powerType: 'DC',
      connectorFormat: 'CABLE',
    },
    TYPE_1: {
      label: t('typeNumber', 'Type {{type}}', { type: 1 }),
      icon: type1Icon,
      powerType: 'AC',
      options: [
        {
          label: t('cable', 'Cable'),
          icon: cableIcon,
          connectorFormat: 'CABLE',
        },
        {
          label: t('socket', 'Socket'),
          icon: socketIcon,
          connectorFormat: 'SOCKET',
        },
      ],
    },
    TYPE_2: {
      label: t('typeNumber', 'Type {{type}}', { type: 2 }),
      icon: type2Icon,
      powerType: 'AC',
      options: [
        {
          label: t('cable', 'Cable'),
          icon: cableIcon,
          connectorFormat: 'CABLE',
        },
        {
          label: t('socket', 'Socket'),
          icon: socketIcon,
          connectorFormat: 'SOCKET',
        },
      ],
    },
    UNKNOWN: {
      label: t('unknown', 'Unknown'),
      icon: unknownIcon,
    },
  };
};
