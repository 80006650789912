import { createSlice } from '@reduxjs/toolkit';
import { Transaction } from 'models/transaction';
import { TransactionStatus } from 'models/transaction.enums';

import DataHandler from '@handlers/data';
import api from '@services/api';
import { devicesApi } from '@services/devices/endpoints';

import { setTransactionsPeriod, updateTransactionFromPusher } from './actions';

type TransactionsState = {
  transactions: {
    period?: string;
    list: Transaction[];
  };
};

const initialState: TransactionsState = {
  transactions: {
    list: [],
  },
};

const setTransactionsToState = (state: TransactionsState, { payload }: any) => {
  const { transactions } = payload;
  state.transactions.list = transactions;
};

const updateTransactionByTransactionId = (state: TransactionsState, { payload }: any) => {
  const { transaction } = payload;
  const transactionIndex = state.transactions.list.findIndex(({ transactionId }) => {
    return DataHandler.isEqual(transactionId, transaction?.transactionId);
  });
  const transactionUpdated = {
    ...state.transactions.list[transactionIndex],
    ...transaction,
  };
  state.transactions.list[transactionIndex] = transactionUpdated;
};

const handleStopTransaction = (state: TransactionsState, { payload }: any) => {
  const transactionIndex = state.transactions.list.findIndex(({ transactionId }) => {
    return DataHandler.isEqual(transactionId, payload.transactionId);
  });
  state.transactions.list[transactionIndex] = {
    ...state.transactions.list[transactionIndex],
    status: TransactionStatus.ENDED,
  };
};

const deleteTransaction = (state: TransactionsState, { payload }: any) => {
  const transactionIndex = state.transactions.list.findIndex(({ transactionId }) => {
    return DataHandler.isEqual(transactionId, payload.transactionId);
  });
  if (transactionIndex !== -1) {
    state.transactions.list.splice(transactionIndex, 1);
  }
};

export const dataSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTransactionsPeriod, (state, { payload }) => {
      state.transactions.period = payload;
    });
    builder.addCase(updateTransactionFromPusher, updateTransactionByTransactionId);
    builder.addMatcher(api.endpoints.transactions.matchFulfilled, setTransactionsToState);
    builder.addMatcher(devicesApi.endpoints.stopCharging.matchFulfilled, handleStopTransaction);
    builder.addMatcher(api.endpoints.deleteTransaction.matchFulfilled, deleteTransaction);
  },
});

export { setTransactionsPeriod };

export default dataSlice.reducer;
