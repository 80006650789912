import { Box, Skeleton, Table, TableBody, TableCell, TableContainer } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';

export const SiteGroupsTableRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell width={380}>
      <Box display="flex" flexDirection="column">
        <Skeleton variant="text" animation="wave" width="50%" />
        <Skeleton variant="text" animation="wave" width="60%" />
      </Box>
    </TableCell>
    <TableCell width={160}>
      <Skeleton variant="text" animation="wave" width="80%" />
    </TableCell>
    <TableCell width={160}>
      <Box display="flex" justifyContent="right">
        <Skeleton variant="text" animation="wave" width="30%" />
      </Box>
    </TableCell>
    <TableCell width={60}>
      <Box display="flex" justifyContent="right">
        <Skeleton variant="text" animation="wave" width="30%" />
      </Box>
    </TableCell>
    <TableCell width={180}>
      <Box display="flex" justifyContent="right">
        <Skeleton variant="text" animation="wave" width="30%" />
      </Box>
    </TableCell>
    <TableCell width={20} />
  </StyledSkeletonTableRow>
);

export const SiteGroupsTableSkeleton = () => {
  return (
    <TableContainer component={CardContainer} sx={{ p: 0 }}>
      <Table>
        <TableBody>
          {Array.from(Array(3).keys()).map(() => (
            <SiteGroupsTableRowSkeleton key={self.crypto.randomUUID()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
