import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceStatus } from 'models/device.enums';

import { Box } from '@mui/material';

import { SummaryCard } from '@components/cards/SummaryCard';
import apiSlice from '@services/api';
import { selectActiveCompanyUuid, selectCompanies } from '@services/companies/selectors';
import { useGetDevicesQuery } from '@services/devices/endpoints';
import { selectDevicesBy } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { selectVehicles } from '@services/vehicles/selectors';

export const DashboardSummary = () => {
  const { t } = useTranslation();
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const devices = useAppSelector((state) => selectDevicesBy(state, undefined, undefined, activeCompanyUuid));
  const vehicles = useAppSelector(selectVehicles);
  const companies = useAppSelector(selectCompanies);

  const onlineDevices = useMemo(() => devices.filter((device) => device.status === DeviceStatus.ONLINE), [devices]);

  const data = [
    {
      label: t('totalNrOfDevices', 'Total nr of devices'),
      value: devices.length,
    },
    {
      label: t('currentlyOnlineDevices', 'Currently online devices'),
      value: onlineDevices.length,
    },
    {
      label: t('vehicles', 'Vehicles'),
      value: vehicles.length,
    },
    {
      label: t('activeWorkspaces', 'Active workspaces'),
      value: companies.length,
    },
  ];

  apiSlice.useVehiclesQuery({});
  useGetDevicesQuery({}, { refetchOnFocus: true });

  return (
    <Box display="flex" flexWrap="wrap" alignItems="stretch" mb={5} gap={2}>
      {data.map(({ label, value }) => (
        <SummaryCard key={label} label={label} value={value} />
      ))}
    </Box>
  );
};
