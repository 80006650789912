import { Box, Tooltip, Typography } from '@mui/material';

import { isLmcSupportingChargerCommStatusesRequest } from '@views/Devices/DeviceDetailsSidebar/deviceConfigurationUtils';

import { ChargerWithStats, LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { useLmcChargerCommunications } from './useLmcChargerCommunications';

type LmcChargerCommunicationProps = {
  charger: ChargerWithStats;
  lmcMultipoints: LmcMultipointWithStats[];
  type?: 'dot' | 'text';
};

export const LmcChargerCommunicationStatus = ({
  charger,
  lmcMultipoints,
  type = 'dot',
}: LmcChargerCommunicationProps) => {
  const chargerMultipoint = lmcMultipoints.find(
    ({ lmcMultipointUuid }) => lmcMultipointUuid === charger.lmcMultipointUuid,
  );
  const { isLoadingGetLmcCommunications, text, bgColor } = useLmcChargerCommunications({
    charger,
    lmcMultipoint: chargerMultipoint,
  });

  if (type === 'text') {
    return chargerMultipoint && isLmcSupportingChargerCommStatusesRequest(chargerMultipoint) ? (
      <Box>
        <Typography variant="p14">{text}</Typography>
      </Box>
    ) : (
      '-'
    );
  }

  return (
    chargerMultipoint &&
    isLmcSupportingChargerCommStatusesRequest(chargerMultipoint) &&
    !isLoadingGetLmcCommunications && (
      <Tooltip title={text}>
        <Box width={8} height={8} borderRadius="50%" bgcolor={bgColor} />
      </Tooltip>
    )
  );
};
