import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentSessionSummary } from 'models/payment-session-summary';
import { TransactionType } from 'models/reporting-transaction';
import { TransactionStatus } from 'models/transaction.enums';

import { TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { InvoiceDownloadCell } from '@views/reporting/table/InvoiceDownloadCell';
import { LocationCell } from '@views/reporting/table/LocationCell';
import { StartTimeCell } from '@views/reporting/table/StartTimeCell';

import { InvoicesContext } from '../context/InvoicesProvider';
import { InvoicesTableRowSkeleton, InvoicesTableSkeleton } from './InvoicesTableSkeleton';

type PaymentSessionSummaryColumnData = ColumnData<PaymentSessionSummary>;

const useColumns = (): PaymentSessionSummaryColumnData[] => {
  const { t } = useTranslation();

  const columns: PaymentSessionSummaryColumnData[] = [
    {
      width: 180,
      label: t('startTime', 'Start time'),
      dataKey: 'startTime',
      sortable: true,
    },
    {
      width: 160,
      label: t('site', 'Site'),
      dataKey: 'siteName',
      sortable: true,
    },
    {
      width: 260,
      label: t('customerEmail', 'Customer email'),
      dataKey: 'customerEmail',
      sortable: true,
    },
    {
      width: 100,
      label: t('total', 'Total'),
      dataKey: 'monetaryRevenueWithVat',
      numeric: true,
      sortable: true,
      currency: true,
    },
    {
      width: 30,
      label: '',
      dataKey: 'menu',
    },
  ];

  return columns;
};

const renderRows = (rows: PaymentSessionSummary[], columns: PaymentSessionSummaryColumnData[]) => {
  return rows.map((row) => (
    <StyledTableRow key={row.id}>
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'startTime':
            return (
              <StartTimeCell
                key={column.dataKey}
                startTime={row.startTime}
                transactionType={TransactionType.PUBLIC}
                status={TransactionStatus.ENDED}
                width={column.width}
              />
            );
          case 'siteName':
            return <LocationCell key={column.dataKey} siteName={row.siteName ?? ''} width={column.width} />;
          case 'customerEmail':
            return (
              <TableCell key={column.dataKey} width={column.width}>
                <Typography variant="p14">{row.customerEmail}</Typography>
              </TableCell>
            );
          case 'monetaryRevenueWithVat':
            return (
              <TableCell key={column.dataKey} align="right" width={column.width}>
                <Typography variant="p14">{(Math.ceil(row.monetaryRevenueWithVat * 100) / 100).toFixed(2)}</Typography>
              </TableCell>
            );
          case 'menu':
            return (
              <InvoiceDownloadCell key={column.dataKey} invoiceId={row.invoiceId} align="right" width={column.width} />
            );
          default:
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Typography variant="p14">{String(row[column.dataKey])}</Typography>
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  ));
};

export const InvoicesMaterialTable = () => {
  const { data, dataLoading, loadMore, order, orderBy, handleRequestSort } = useContext(InvoicesContext);

  return (
    <MaterialTable
      context={InvoicesContext}
      data={data}
      isLoading={dataLoading}
      headerProps={{
        columns: useColumns(),
        onRequestSort: handleRequestSort,
        order,
        orderBy,
      }}
      renderRows={renderRows}
      loadMore={loadMore}
      loaders={{ TableLoader: InvoicesTableSkeleton, RowLoader: InvoicesTableRowSkeleton }}
    />
  );
};
