import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AddSiteDto, Site } from 'models/site';

import { useAppDispatch } from '@services/hooks';
import { useUpdateSiteMutation } from '@services/sites/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { SiteDetailsSidebarBase } from './SiteDetailsSidebarBase';

export const SiteDetailsSidebar = () => {
  const { t } = useTranslation();
  const { site } = useOutletContext<{ site?: Site }>();
  const dispatch = useAppDispatch();

  const [updateSite, { isLoading }] = useUpdateSiteMutation();

  const saveValues = async (attributes: AddSiteDto) => {
    try {
      await updateSite({
        uuid: site!.uuid,
        ...attributes,
      }).unwrap();

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemUpdated', '{{item}} updated', { item: t('site', 'Site') }),
        }),
      );
      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheItemFailed', 'Updating the {{item}} failed', {
            item: t('site', 'Site').toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return (
    <SiteDetailsSidebarBase
      site={site}
      title={t('siteDetails', 'Site details')}
      isSaveLoading={isLoading}
      onSaveValues={saveValues}
    />
  );
};
