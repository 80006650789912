import { Box } from '@mui/material';

import { Card, CardProps } from '@components/atoms/Card';
import ComponentError from '@components/atoms/ComponentError';

import { OverflowSpinner } from '../spinner/OverflowSpinner';

type CardItemProps = CardProps & {
  tools?: React.FC;
  loading?: boolean;
  error?: boolean;
};

export const CardItem = ({ children, tools: Tools, loading, error, ...props }: CardItemProps) => {
  if (loading) {
    return (
      <Card {...props}>
        <OverflowSpinner size={14} className="p-5" />
      </Card>
    );
  }

  if (error) {
    return (
      <Card {...props}>
        <ComponentError />
      </Card>
    );
  }

  return (
    <Card {...props}>
      {Tools && (
        <Box right={0} top={0} zIndex={10} pr={4} pt={1} position="absolute">
          <Tools />
        </Box>
      )}
      {children}
    </Card>
  );
};
