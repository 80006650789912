import apiSlice from '../api';

type TransactionSummaryRequest = {
  siteUuid: string;
  selectedDuration: string;
};

const transactionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionSummary: builder.query({
      query: ({ siteUuid, selectedDuration }: TransactionSummaryRequest) =>
        `transactions/site/${siteUuid}/summary?duration=${selectedDuration}`,
    }),
  }),
});

export const { useGetTransactionSummaryQuery } = transactionsApi;
