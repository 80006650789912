import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Tooltip } from '@mui/material';

import { ReactComponent as WarningIcon } from '@images/icons/Warning.svg';
import { selectChargersBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { selectSiteByUuid } from '@services/sites/selectors';

import { LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { useLmcChargerCommunications } from './useLmcChargerCommunications';

type ChargerConfiguredOutsideSiteWarningProps = {
  lmcMultipoint: LmcMultipointWithStats;
};

export const ChargerConfiguredOutsideSiteWarning = ({ lmcMultipoint }: ChargerConfiguredOutsideSiteWarningProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const lmcSite = useAppSelector((state) => selectSiteByUuid(state, lmcMultipoint?.siteUuid));
  const quotedSiteName = location.pathname.includes('devices') && lmcSite ? `"${lmcSite?.name}"` : null;

  const { lmcCommunications, isErrorGetLmcCommunications } = useLmcChargerCommunications({ lmcMultipoint });

  const rawChargerDevices = useAppSelector((state) => selectChargersBySiteUuid(state, lmcMultipoint?.siteUuid));

  const chargersConfiguredOutsideSite = lmcCommunications?.filter(
    (lmcCommunications) =>
      !rawChargerDevices.some(
        (charger) =>
          charger.serialNumber.toLocaleUpperCase() === lmcCommunications.deviceSerialNumber.toLocaleUpperCase(),
      ),
  );

  return (
    (!!chargersConfiguredOutsideSite?.length || isErrorGetLmcCommunications) && (
      <Tooltip
        title={
          isErrorGetLmcCommunications
            ? t('errorFetchingLmcCommunications', 'Error fetching LMC communications')
            : t(
                'thereAreChargersConfiguredWhichAreNotInTheSite',
                'There are chargers configured which are not in the site {{siteName}} with serial numbers: {{deviceSerialNumbers}}.',
                {
                  siteName: quotedSiteName,
                  deviceSerialNumbers: chargersConfiguredOutsideSite
                    ?.map((charger) => charger.deviceSerialNumber)
                    .join(', '),
                },
              )
        }
      >
        <WarningIcon height={15} />
      </Tooltip>
    )
  );
};
