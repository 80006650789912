import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';

const MetricsCard = ({ title, metrics, className, onClick }) => (
  <div className={`flex w-full flex-col gap-y-4 rounded-2xl bg-white p-4 ${className}`}>
    <div className="flex w-full justify-between">
      <span className="font-poppins text-lg font-semibold leading-8 text-gray-600">{title}</span>
      <button type="button" onClick={onClick}>
        <ArrowLeftIcon className="rotate-180" />
      </button>
    </div>
    <div className="flex w-full flex-col gap-y-2">
      {metrics.map(({ label, value }) => (
        <div key={label} className="flex w-full flex-row justify-between font-poppins text-sm leading-6 text-gray-600">
          <span>{label}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  </div>
);

MetricsCard.propTypes = {
  title: PropTypes.string.isRequired,
  metrics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

MetricsCard.defaultProps = {
  className: '',
  onClick: () => {},
};

export default MetricsCard;
