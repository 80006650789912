import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { CHARGE_STATUSES } from '@handlers/vehicle/constVehicle';
import battery25Icon from '@images/icons/BatteryStatus/Battery_25.svg';
import battery50Icon from '@images/icons/BatteryStatus/Battery_50.svg';
import battery75Icon from '@images/icons/BatteryStatus/Battery_75.svg';
import battery100Icon from '@images/icons/BatteryStatus/Battery_100.svg';
import chargingIcon from '@images/icons/VehicleStatus/Charging.svg';
import onTheRoadIcon from '@images/icons/VehicleStatus/On_The_Road.svg';
import pluggedInIcon from '@images/icons/VehicleStatus/Plugged_In.svg';
import unavailableIcon from '@images/icons/VehicleStatus/Unavailable.svg';

export const getBatteryLevelIcon = (batteryLevel) => {
  if (batteryLevel <= 25) {
    return battery25Icon;
  }
  if (batteryLevel <= 50) {
    return battery50Icon;
  }
  if (batteryLevel <= 75) {
    return battery75Icon;
  }
  return battery100Icon;
};

export const BatteryLevelIcon = ({ batteryLevel }) => (
  <div className={`flex items-center space-x-1 ${batteryLevel <= 25 ? 'text-vermillion' : 'text-gray-600'}`}>
    <img src={getBatteryLevelIcon(batteryLevel)} alt="battery level" />
    <span>{batteryLevel ?? '-'}</span>
    <span className="text-sm">%</span>
  </div>
);

BatteryLevelIcon.propTypes = {
  batteryLevel: PropTypes.number,
};

BatteryLevelIcon.defaultProps = {
  batteryLevel: null,
};

export const VehicleBatteryInfo = ({ vehicle, className }) => (
  <div className={`flex flex-col font-poppins text-base font-normal ${className}`}>
    <div>
      {vehicle.data?.format?.distanceToBatteryEmpty ?? '-'} <span className="text-sm text-gray-400">km</span>
    </div>
    <BatteryLevelIcon batteryLevel={vehicle?.batteryLevel} />
  </div>
);

export const VehicleStatusInfo = ({ vehicle, className }) => {
  const { t } = useTranslation();
  const getVehicleStatusElementData = (status) => {
    if (status === CHARGE_STATUSES.CHARGING) {
      return { icon: chargingIcon, className: 'text-vermillion' };
    }
    if (status === CHARGE_STATUSES.CONNECTED) {
      return { icon: pluggedInIcon, className: 'text-queen' };
    }
    if (status === CHARGE_STATUSES.NOT_CONNECTED) {
      return { icon: onTheRoadIcon, className: 'text-forest' };
    }

    return { icon: unavailableIcon, className: 'text-gray-600' };
  };

  return (
    <div className={`flex gap-2 font-poppins text-sm font-normal ${className}`}>
      <img src={getVehicleStatusElementData(vehicle.status).icon} alt="vehicle status" />
      <span className={getVehicleStatusElementData(vehicle.status).className}>
        {t(vehicle.status.toLowerCase(), vehicle.status)}
      </span>
    </div>
  );
};

const vehiclePropsType = {
  vehicle: PropTypes.object.isRequired,
  className: PropTypes.string,
};

const vehicleDefaultProps = {
  className: '',
};

VehicleBatteryInfo.propTypes = vehiclePropsType;
VehicleStatusInfo.propTypes = vehiclePropsType;
VehicleBatteryInfo.defaultProps = vehicleDefaultProps;
VehicleStatusInfo.defaultProps = vehicleDefaultProps;
