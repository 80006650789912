import { Outlet } from 'react-router-dom';

import { DevicesAndGroups } from '@views/Sites/SiteDevicesAndGroups/DevicesAndGroups';

export const PartnerWorkspaceDevicesView = () => (
  <>
    <DevicesAndGroups />
    <Outlet />
  </>
);
