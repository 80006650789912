import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Input } from '@components/atoms/Input';
import QuickFilters from '@components/filterComponents/QuickFilters';
import { ReactComponent as SearchIcon } from '@images/icons/Search.svg';

const UserFilters = ({ users, onFilteredUsersChange, onActiveFilterChange, onSearchQuery }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilteredUsers, setCategoryFilteredUsers] = useState([]);

  const filters = [
    {
      key: 'installers',
      label: t('installers', 'Installers'),
      filterFunction: (user) => user.features?.isInstaller,
    },
  ];

  useEffect(() => {
    if (!searchQuery || !categoryFilteredUsers.length) {
      onFilteredUsersChange(categoryFilteredUsers);
    } else {
      const newFilteredData = categoryFilteredUsers.filter((user) => {
        const { email, name } = user;
        const optionsToSearch = [email, name];
        return optionsToSearch.some((option) => option?.toLowerCase().includes(searchQuery.toLowerCase()));
      });
      onFilteredUsersChange(newFilteredData);
    }
  }, [categoryFilteredUsers, searchQuery]);

  return (
    <div className="flex items-center justify-between">
      <QuickFilters
        data={users}
        filters={filters}
        onSelectedData={setCategoryFilteredUsers}
        onActiveFilterChange={onActiveFilterChange}
      />
      <Input
        name="search"
        className="!h-10 w-64 rounded-lg bg-gray-100"
        backgroundColor="bg-gray-100"
        leadingIcon={SearchIcon}
        leadingIconClassName="!h-4 !w-4"
        placeholder={t('search', 'Search')}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          onSearchQuery(e.target.value);
        }}
      />
    </div>
  );
};

UserFilters.propTypes = {
  users: PropTypes.array.isRequired,
  onFilteredUsersChange: PropTypes.func,
  onActiveFilterChange: PropTypes.func,
  onSearchQuery: PropTypes.func,
};

UserFilters.defaultProps = {
  onFilteredUsersChange: () => {},
  onActiveFilterChange: () => {},
  onSearchQuery: () => {},
};

export default UserFilters;
