import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { StyledTextField } from '@components/atoms/StyledTextField';
import { TimePicker } from '@components/atoms/TimePicker';
import { useActiveCurrency } from '@hooks/useActiveCurrency';

import { formatTime, hhMmToMoment } from './functions';

export type PeriodicalCostInputsProps = {
  periodLabel: string;
  costLabel: string;
  startTime?: string;
  endTime?: string;
  cost?: number;
  periodInputsDisabled?: boolean;
  onStartTimeChange?: (val: string) => void;
  onEndTimeChange?: (val: string) => void;
  onCostChange?: (val: string) => void;
  errors?: any;
};

export const PeriodicalCostInputs = ({
  periodLabel,
  periodInputsDisabled,
  costLabel,
  startTime,
  endTime,
  cost,
  onStartTimeChange,
  onEndTimeChange,
  onCostChange,
  errors,
}: PeriodicalCostInputsProps) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const getPeriodError = () => {
    const periodError = errors?.dayHours;
    return !periodInputsDisabled ? periodError : null;
  };
  const getPriceError = () => {
    const priceError = errors?.price?.[periodLabel.toLowerCase()];
    return priceError || null;
  };
  return (
    <>
      <Typography variant="p16b">{periodLabel}</Typography>
      <Box display="flex" alignItems="center" gap={1}>
        <TimePicker
          name={`${periodLabel}_startTime`}
          label={t('start', 'Start')}
          value={startTime ? hhMmToMoment(startTime) : null}
          disabled={periodInputsDisabled}
          noMinutes
          onChange={(val) => onStartTimeChange?.(formatTime(val))}
          error={Boolean(getPeriodError()?.start)}
          helpText={getPeriodError()?.start?.message}
        />
        <TimePicker
          name={`${periodLabel}_endTime`}
          label={t('end', 'End')}
          value={endTime ? hhMmToMoment(endTime) : null}
          disabled={periodInputsDisabled}
          noMinutes
          onChange={(val) => onEndTimeChange?.(formatTime(val))}
          error={Boolean(getPeriodError()?.end)}
          helpText={getPeriodError()?.end?.message}
          {...(!periodInputsDisabled && {
            shouldDisableTime: (time) =>
              !time.isSame(hhMmToMoment('0000')) && time.isBefore(hhMmToMoment(startTime).add(1, 'hour')),
          })}
        />
      </Box>
      <StyledTextField
        name={`${periodLabel}_${costLabel}`}
        label={costLabel}
        value={cost ?? ''}
        type="number"
        InputProps={{
          endAdornment: (
            <Typography variant="p14" color="darkGray">
              {currency.centsName?.toLowerCase()}
              /kWh
            </Typography>
          ),
        }}
        onChange={(e) => onCostChange?.(e.target.value)}
        error={Boolean(getPriceError())}
        helperText={getPriceError()?.message}
      />
    </>
  );
};
