import { useCallback, useMemo } from 'react';

import { ConnectorComputedStatus } from 'models/device.enums';

import { useAppSelector } from '@services/hooks';
import { useGetAllInvertersQuery, useGetInvertersQuery } from '@services/inverters/endpoints';
import { selectInvertersBy } from '@services/inverters/selectors';
import { useGetSiteInvertersQuery, useGetSiteInvertersSummaryQuery } from '@services/sites/endpoints';
import { DeviceTypeOption } from '@views/reporting/hooks/useDeviceTypeMenu';

import { DevicesAndGroupsCommonProps, InverterWithStats } from '../devicesAndGroupsTypes';

export const useInvertersWithStats = ({ siteUuid, companyUuid, selectedDuration }: DevicesAndGroupsCommonProps) => {
  const inverters = useAppSelector((state) => selectInvertersBy(state, siteUuid, companyUuid));

  const { refetch: refetchSiteInverters, isLoading: isLoadingGetSiteInverters } = useGetSiteInvertersQuery(siteUuid!, {
    refetchOnMountOrArgChange: true,
    skip: !siteUuid,
  });

  const { refetch: refetchCompanyInverters, isLoading: isLoadingGetCompanyInverters } = useGetInvertersQuery(
    { companyUuid },
    {
      refetchOnMountOrArgChange: true,
      skip: !companyUuid,
    },
  );

  const { refetch: refetchAllInverters, isLoading: isLoadingAllInverters } = useGetAllInvertersQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !!siteUuid || !!companyUuid,
  });

  const refetchInverters = useCallback(() => {
    if (siteUuid) {
      refetchSiteInverters();
    }
    if (companyUuid) {
      refetchCompanyInverters();
    }
    if (!siteUuid && !companyUuid) {
      refetchAllInverters();
    }
  }, [siteUuid, companyUuid]);

  const { data: invertersSummary, isFetching: isFetchingGetSiteInvertersSummary } = useGetSiteInvertersSummaryQuery(
    {
      uuid: siteUuid!,
      duration: selectedDuration,
    },
    {
      skip: !selectedDuration || !siteUuid,
    },
  );
  const invertersWithStats = useMemo<InverterWithStats[]>(
    () =>
      inverters.map((inverter) => {
        const inverterSummary = invertersSummary?.data?.find((summary) => summary.inverterUuid === inverter.uuid);
        return {
          ...inverter,
          isProducing: inverter.data?.productionState?.isProducing,
          currentRateKw: inverter.data?.productionState?.productionRate,
          energyProducedKwh: inverterSummary?.summary.totalProductionKwh,
          displayName: inverter.name ?? inverter.model,
          deviceType: DeviceTypeOption.INVERTER,
          computedStatus: inverter.data?.productionState?.isProducing
            ? ConnectorComputedStatus.AVAILABLE
            : ConnectorComputedStatus.UNKNOWN,
        };
      }),
    [inverters, invertersSummary],
  );

  return {
    invertersWithStats,
    isLoading: isLoadingGetSiteInverters || isLoadingGetCompanyInverters || isLoadingAllInverters,
    isFetchingGetSiteInvertersSummary: isFetchingGetSiteInvertersSummary,
    refetchInverters,
  };
};
