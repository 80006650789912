import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ResetPasswordBox } from './ResetPasswordBox';
import { useInviteTokenFromQueryParam } from './inviteTokenHook';

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { inviteData } = useInviteTokenFromQueryParam();
  return (
    <div className="w-11/12 max-w-md space-y-6 rounded-lg bg-white p-9 md:w-full">
      <div className="w-full">
        <div className="font-everett text-3xl sm:pt-1">{t('forgotYourPassword', 'Forgot your password?')}</div>
        <span className="font-poppins">{t('forgotYourPassword', 'Forgot your password?')}</span>
      </div>
      <ResetPasswordBox inviteData={inviteData} showLoginBox={() => navigate('/login')} />
    </div>
  );
};
