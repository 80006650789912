import { Box } from '@mui/material';

import { getUserProfileColorByString } from './userProfileColorData';

type AvatarProps = {
  uuid?: string;
  email?: string;
  name?: string;
  bigger?: boolean;
  hidden?: boolean;
  backgroundColor?: string;
};

export const Avatar = ({ uuid, name, email, bigger, hidden, backgroundColor }: AvatarProps) => {
  const avatarIconLetter = (name || email)?.charAt(0).toUpperCase();
  const avatarIconBackgroundColor = backgroundColor ?? getUserProfileColorByString(uuid || email || name || '');

  return (
    <Box
      display={hidden ? 'none' : 'flex'}
      alignItems="center"
      justifyContent="center"
      bgcolor={avatarIconBackgroundColor}
      color="white"
      borderRadius={50}
      sx={
        bigger
          ? {
              width: 40,
              height: 40,
              minWidth: 40,
            }
          : {
              width: 32,
              height: 32,
              minWidth: 32,
            }
      }
    >
      {avatarIconLetter}
    </Box>
  );
};
