import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty, isNil } from 'lodash';
import { Device, DeviceConfigItem, UpdateDeviceDataValues } from 'models/device';
import { DeviceStatus } from 'models/device.enums';

import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useForm } from '@hooks/useTypedForm';
import { selectUser } from '@services/auth/selectors';
import { selectLmcMultipointsBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

import {
  ChargerSettingsForm,
  chargerSettingsFormDefaultValues,
  useChargerSettingsSchema,
} from './useChargerSettingsSchema';

type UseChargerSettingsFormProps = {
  device: Device;
  onSaveValues: (props: UpdateDeviceDataValues) => Promise<boolean>;
};

export const useChargerSettingsForm = ({ device, onSaveValues }: UseChargerSettingsFormProps) => {
  const { t } = useTranslation();

  const lmcMultipoints = useAppSelector((state) => selectLmcMultipointsBySiteUuid(state, device.siteUuid));

  const schema = useChargerSettingsSchema({ lmcMultipoints });
  const form = useForm<ChargerSettingsForm>({
    schema,
    defaultValues: chargerSettingsFormDefaultValues,
  });
  const { handleSubmitAndResolve, reset } = form;

  const deviceConfiguration = device.configuration ?? {};
  const user = useAppSelector(selectUser);
  const isAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const canChangeCommunicatesViaLmc = isAdmin || isInstaller;
  const isOffline = device.status === DeviceStatus.OFFLINE;

  const submit = async (data: ChargerSettingsForm) => {
    const {
      name,
      gridConnection,
      lmcMultipoint,
      communicatesViaLmc,
      chargeEnabled,
      autoStart,
      defaultIdTag,
      defaultIdTagEnabled,
    } = data;
    const values: UpdateDeviceDataValues = {
      deviceUpdateData: {
        uuid: device.uuid,
        name: name?.trim() || null,
        ...(isVoolDevice(device) && { lmcMultipointUuid: lmcMultipoint?.value !== '' ? lmcMultipoint?.value : null }),
        ...(!!gridConnection && { gridConnection }), // FIXME? BE also changes this with MAXIMUM_CHARGING_CURRENT
        ...(canChangeCommunicatesViaLmc && !isNil(communicatesViaLmc) && { communicatesViaLmc }),
        defaultIdTag: defaultIdTagEnabled ? defaultIdTag : null,
      },
    };

    if (isVoolDevice(device) && !isOffline) {
      const parameters: DeviceConfigItem[] = [
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.CHARGER_ENABLED.key,
          value: chargeEnabled,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.AUTOMATICALLY_START_CHARGING.key,
          value: autoStart,
        },
      ];
      if (gridConnection) {
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.MAXIMUM_CHARGING_CURRENT.key,
          value: gridConnection,
        });
      }
      values.deviceConfig = parameters;
    }

    return onSaveValues(values);
  };

  useEffect(() => {
    if (isEmpty(device)) {
      reset();
    } else {
      const lmcMultipoint = lmcMultipoints.find((lmc) => lmc.uuid === device.lmcMultipointUuid);
      reset({
        name: device.name || device.serialNumber,
        gridConnection: device.gridConnection ?? null,
        lmcMultipoint: device.lmcMultipointUuid
          ? { label: lmcMultipoint?.name || '', value: device.lmcMultipointUuid }
          : { label: t('noGroup', 'No group'), value: null },
        communicatesViaLmc: device.communicatesViaLmc ?? 1,
        chargeEnabled: !!deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.CHARGER_ENABLED.key]?.value,
        autoStart: !!deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.AUTOMATICALLY_START_CHARGING.key]?.value,
        defaultIdTagEnabled: !!device.defaultIdTag,
        defaultIdTag: device.defaultIdTag || null,
      });
    }
  }, [device.configuration, device, lmcMultipoints]);

  const submitChargerSettings = () => handleSubmitAndResolve(submit)();
  return {
    form,
    submitChargerSettings,
  };
};
