import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Slide, Typography, useTheme } from '@mui/material';

import { CountryAutocomplete } from '@components/atoms/CountryAutocomplete';
import { FormTextField } from '@components/atoms/FormTextField';

import { WorkspaceSetupFooter } from '../setup/WorkspaceSetupFooter';
import { WorkspaceSetupSidebarContext } from '../setup/WorkspaceSetupSidebarProvider';
import { usePayoutDetailsForm } from './usePayoutDetailsForm';

type PayoutDetailsProps = {
  isSetup?: boolean;
};

export const PayoutDetails = ({ isSetup }: PayoutDetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setAnyChanges, setCloseSidebar } = useContext(WorkspaceSetupSidebarContext);

  const { form, isLoading, isSaving, submitPayoutDetails } = usePayoutDetailsForm();
  const { watch, control, formState } = form;
  const [country] = watch(['bankAccountDetails.addressJson.country']);

  useEffect(() => {
    setAnyChanges(formState.isDirty);
    return () => setAnyChanges(false);
  }, [formState.isDirty]);

  const handleSave = async () => {
    const success = await submitPayoutDetails();
    if (!success) return;
    setAnyChanges(false);

    if (isSetup) {
      navigate('../../subscribe/complete');
    } else {
      setCloseSidebar(true);
    }
  };

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box display="flex" flexDirection="column" flex={1} overflow="auto" gap={4} p="0 24px">
          <Box display="flex" flexDirection="column" gap={6}>
            <Typography variant="p16" color={theme.palette.darkGray}>
              {t('companyPayoutDetailsDescription', 'Please provide your bank account details here for payout.')}
            </Typography>
            <CountryAutocomplete
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="bankAccountDetails.addressJson.country"
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p16b">{t('accountDetails', 'Account details')}</Typography>
            <Box display="flex" flexDirection="column" gap={4}>
              <FormTextField
                control={control}
                isLoading={isLoading}
                disabled={isSaving}
                name="bankAccountDetails.accountName"
                label={t('accountName', 'Account name')}
              />
              <FormTextField
                control={control}
                isLoading={isLoading}
                disabled={isSaving}
                name="bankAccountDetails.iban"
                label={t('iban', 'IBAN')}
              />
              {!!country && country !== 'Estonia' && (
                <FormTextField
                  control={control}
                  isLoading={isLoading}
                  disabled={isSaving}
                  name="bankAccountDetails.swift"
                  label={t('swiftBic', 'SWIFT/BIC code')}
                />
              )}
            </Box>
          </Box>
          {!!country && country !== 'Estonia' && (
            <Box display="flex" flexDirection="column" gap={3}>
              <Typography variant="p16b">{t('bankAddress', 'Bank address')}</Typography>
              <Box display="flex" flexDirection="column" gap={4}>
                <FormTextField
                  control={control}
                  isLoading={isLoading}
                  disabled={isSaving}
                  name="bankAccountDetails.addressJson.streetAddress"
                  label={t('streetAddress', 'Street address')}
                />
                <FormTextField
                  control={control}
                  isLoading={isLoading}
                  disabled={isSaving}
                  name="bankAccountDetails.addressJson.apt"
                  label={`${t('aptSuiteEtc', 'Apt, suite, etc.')} (${t('optional', 'Optional').toLowerCase()})`}
                />
                <FormTextField
                  control={control}
                  isLoading={isLoading}
                  disabled={isSaving}
                  name="bankAccountDetails.addressJson.city"
                  label={t('city', 'City')}
                />
                <Box display="flex" gap={4}>
                  <FormTextField
                    control={control}
                    isLoading={isLoading}
                    disabled={isSaving}
                    name="bankAccountDetails.addressJson.state"
                    label={t('county', 'County/state')}
                    sx={{ flex: 1 }}
                  />
                  <FormTextField
                    control={control}
                    isLoading={isLoading}
                    disabled={isSaving}
                    name="bankAccountDetails.addressJson.zipCode"
                    label={t('zipPostalCode', 'ZIP/postal code')}
                    sx={{ flex: 1 }}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Slide>
      <WorkspaceSetupFooter
        onNext={handleSave}
        onCancel={() => (isSetup ? navigate(-1) : setCloseSidebar(true))}
        saveDisabled={isLoading || isSaving}
        isSetup={isSetup}
        isFinalStep
      />
    </>
  );
};
