import { useTranslation } from 'react-i18next';

import { ConnectorComputedStatus, DeviceStatus } from 'models/device.enums';

import { useComputedStatusDefs } from '@views/Chargers/useComputedStatusDefs';

export const LmcStatusContent = ({ status }: { status: DeviceStatus }) => {
  const { t } = useTranslation();
  const computedStatusDefs = useComputedStatusDefs();

  // TODO: refactor: create a separate mapping for LMC statuses
  // TOOO: handle NEVER_CONNECTED status
  const statusDefMapping: Record<DeviceStatus, typeof computedStatusDefs[ConnectorComputedStatus]> = {
    [DeviceStatus.ONLINE]: computedStatusDefs[ConnectorComputedStatus.AVAILABLE],
    [DeviceStatus.FAULTED]: computedStatusDefs[ConnectorComputedStatus.FAULTED],
    [DeviceStatus.OFFLINE]: computedStatusDefs[ConnectorComputedStatus.OFFLINE],
  };
  const statusDef = statusDefMapping[status] ?? computedStatusDefs[ConnectorComputedStatus.UNKNOWN];

  const labelOverride = status === DeviceStatus.ONLINE ? t('online', 'Online') : null;

  return (
    <div className="flex items-center">
      <img
        src={statusDef.icon}
        alt={labelOverride ?? statusDef.statusLabel}
        className={`mr-2 inline-block rounded-full ${statusDef.iconClassName}`}
      />
      <span className={statusDef.textClassName}>{labelOverride ?? statusDef.statusLabel}</span>
    </div>
  );
};
