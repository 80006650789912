import PropTypes from 'prop-types';

/* eslint-disable react/button-has-type */

const buttonVariants = {
  default:
    'bg-vermillion text-white shadow-sm font-semibold hover:bg-vermillion-600 active:bg-vermillion-700 disabled:bg-gray-200 disabled:text-gray-400 disabled:font-normal',
  primary:
    'bg-forest text-white shadow-sm font-semibold hover:bg-forest-600 active:bg-forest-700 disabled:bg-gray-200 disabled:text-gray-400 disabled:font-normal',
  secondary:
    'bg-gray-200 text-black shadow-sm font-semibold hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-200 disabled:text-gray-400 disabled:font-normal',
  link: 'bg-transparent text-vermillion underline underline-offset-[0.5em] hover:text-vermillion-600 active:text-vermillion-700 disabled:text-gray-200',
};

const Button = ({ id, type, variant, bigger, className, disabled, onClick, children }) => (
  <button
    id={id}
    disabled={disabled}
    onClick={onClick}
    type={type}
    className={`
      ${className}
      ${buttonVariants[variant]}
      ${bigger ? 'py-4 text-base' : 'py-2 text-sm'}
      rounded-full px-6 font-poppins
    `}
  >
    {children}
  </button>
);

Button.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'primary', 'secondary', 'link']),
  bigger: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  id: null,
  type: 'button',
  variant: 'default',
  bigger: false,
  className: '',
  disabled: false,
  onClick: () => {},
};

export default Button;
