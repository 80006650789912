import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import * as yup from 'yup';

import Button from '@components/atoms/Button';
import { PasswordInput } from '@components/atoms/PasswordInput';
import CardSpinner from '@components/spinner/CardSpinner';
import { useForm } from '@hooks/useTypedForm';
import checkImage from '@images/icons/check.svg';
import { getErrorMessage } from '@services/api/api-errors';
import { useChangeResetPasswordMutation, useCheckPasswordHashQuery } from '@services/auth/endpoints';
import { ResetPasswordBox } from '@views/Welcome/ResetPasswordBox';
import { useInviteToken } from '@views/Welcome/inviteTokenHook';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      password: yup.string().required(t('required*', 'Required*')),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], t('passwordsMustMatch', 'Passwords must match')),
    })
    .required();
};

type ResetPasswordValues = {
  password: string;
  passwordConfirmation: string;
};

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = useSchema();
  const { token } = useParams();
  const {
    data: passwordCheckData,
    isLoading: isLoadingCheck,
    error: checkingError,
  } = useCheckPasswordHashQuery(token ? { token } : skipToken);
  const [
    changeResetPassword,
    { isLoading: isLoadingPasswordChange, isSuccess: passwordChangeSuccess, error: passwordChangeError },
  ] = useChangeResetPasswordMutation();
  const isLoading = isLoadingCheck || isLoadingPasswordChange;

  const { inviteData, outcomeComponent: OutcomeComponent } = useInviteToken(passwordCheckData?.inviteToken);
  const { register, handleSubmit, formState } = useForm<ResetPasswordValues>({ schema });

  const onSubmit = async ({ password }: ResetPasswordValues) => {
    if (token) {
      await changeResetPassword({ password, token });
    }
  };

  const error = passwordChangeError || checkingError;
  if (error) {
    return (
      <div className="w-11/12 max-w-md rounded-lg bg-white p-9 md:w-full">
        <div className="w-full">
          <div className="font-everett text-3xl">{t('forgotYourPassword', 'Forgot your password?')}</div>
          <span className="font-poppins">{t('forgotYourPassword', 'Forgot your password?')}</span>
        </div>
        <ResetPasswordBox inviteData={inviteData} showLoginBox={() => navigate('/login')}>
          {getErrorMessage(error) === 'Token expired'
            ? t('passwordResetLinkExpiredErrorText', 'Password reset link is expired. Send a new one.')
            : t('somethingWentWrong', 'Something went wrong')}
        </ResetPasswordBox>
      </div>
    );
  }

  if (passwordChangeSuccess) {
    if (OutcomeComponent) {
      return <OutcomeComponent />;
    }
    return (
      <div className="w-11/12 max-w-md rounded-lg bg-white p-9 md:w-full">
        <img src={checkImage} alt="success" className="h-20 w-20 rounded-full border-2 border-forest" />
        <div className="mt-4 w-full">
          <div className="font-everett text-3xl">{t('passwordChanged', 'Password changed')}</div>
          <span className="font-poppins">{t('continueToThePortal', 'Continue to the portal.')}</span>
        </div>
        <Button variant="primary" bigger className="mt-5 w-full" onClick={() => navigate('/')}>
          {t('continue', 'Continue')}
        </Button>
      </div>
    );
  }

  return (
    <>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div className="w-11/12 max-w-md space-y-6 rounded-lg bg-white p-9 md:w-full">
          <div className="mt-4 w-full">
            <div className="font-everett text-3xl">{t('createPassword', 'Create password')}</div>
            <span className="font-poppins">{t('createYourNewPassword', 'Please create your new password.')}</span>
          </div>
          <form className="w-full space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <PasswordInput
              label={t('newPassword', 'New password')}
              error={Boolean(formState.errors?.password)}
              helpText={formState.errors?.password?.message}
              autoComplete="new-password"
              {...register('password')}
            />
            <PasswordInput
              label={t('confirmPassword', 'Confirm password')}
              error={Boolean(formState.errors?.passwordConfirmation)}
              helpText={formState.errors?.passwordConfirmation?.message}
              autoComplete="new-password"
              {...register('passwordConfirmation')}
            />
            <Button type="submit" variant="primary" bigger className="mt-5 w-full">
              {t('savePassword', 'Save password')}
            </Button>
          </form>
        </div>
      )}
    </>
  );
};
