import { Trans, useTranslation } from 'react-i18next';

import { VoolDeviceAvailabilityResponse } from 'models/device';
import { VoolDeviceAvailabilityStatus } from 'models/device.enums';

import { Link, Typography, useTheme } from '@mui/material';

export type VoolDeviceAvailabilityData = Partial<Omit<VoolDeviceAvailabilityResponse, 'success' | 'type'>> & {
  isPinIncorrect?: boolean;
};

export const VoolDeviceAvailabilityMessage = ({ status, site, isPinIncorrect }: VoolDeviceAvailabilityData) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (status === VoolDeviceAvailabilityStatus.ERROR) {
    return (
      <Typography mt={2} color={theme.palette.mediumVermilion}>
        {t('somethingWentWrongPleaseTryAgain', 'Something went wrong. Please try again.')}
      </Typography>
    );
  }

  if (status === VoolDeviceAvailabilityStatus.IN_USE && site) {
    const { uuid: siteUuid, name: siteName } = site;
    return (
      <Typography mt={2} color={theme.palette.mediumVermilion}>
        <Trans
          i18nKey="thisDeviceHasAlreadyBeenAdded.toTheSystemUnderSite"
          defaults="This device has already been added to the system under site <1>{{siteName}}</1>."
          values={{
            siteName,
          }}
          components={{
            1: <Link href={`/sites/${siteUuid}`} color={theme.palette.mediumVermilion} target="_blank" />,
          }}
        />
      </Typography>
    );
  }

  if (status === VoolDeviceAvailabilityStatus.IN_USE) {
    return (
      <Typography mt={2} color={theme.palette.mediumVermilion}>
        {t('thisDeviceHasAlreadyBeenAdded.toTheSystem', 'This device has already been added to the system.')}
      </Typography>
    );
  }

  if (status === VoolDeviceAvailabilityStatus.NO_MATCH) {
    return (
      <Typography mt={2} color={theme.palette.mediumVermilion}>
        {t(
          'voolDeviceNoMatchText',
          'The entered serial number doesn’t match with a VOOL device. Please check for any typos.',
        )}
      </Typography>
    );
  }

  if (isPinIncorrect) {
    return (
      <Typography mt={2} color={theme.palette.mediumVermilion}>
        {t('voolDevicePinIncorrectText', 'The entered PIN is incorrect.')}
      </Typography>
    );
  }

  return null;
};
