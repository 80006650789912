import { ReactComponent as SpinnerIcon } from '@images/icons/Spinner.svg';

const sizeClasses = {
  4: 'h-4 w-4',
  6: 'h-6 w-6',
  8: 'h-8 w-8',
  10: 'h-10 w-10',
  14: 'h-14 w-14',
  16: 'h-16 w-16',
  18: 'h-18 w-18',
  20: 'h-20 w-20',
  full: 'h-full w-full',
};

type SpinnerProps = {
  size?: keyof typeof sizeClasses;
  className?: string;
};

export const Spinner = ({ size = 16, className }: SpinnerProps) => (
  <div className="flex items-center justify-center">
    <SpinnerIcon className={`animate-spin ${sizeClasses[size]} ${className}`} />
  </div>
);
