import { useEffect } from 'react';

import { isEmpty } from 'lodash';
import { Device, DeviceConfigItem, UpdateDeviceDataValues } from 'models/device';
import { DeviceStatus } from 'models/device.enums';

import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useForm } from '@hooks/useTypedForm';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import { LmcSettingsForm, lmcSettingsFormDefaultValues, useLmcSettingsSchema } from './useLmcSettingsSchema';

type UseLmcSettingsFormProps = {
  device: Device;
  onSaveValues: (props: UpdateDeviceDataValues) => Promise<boolean>;
};

export const useLmcSettingsForm = ({ device, onSaveValues }: UseLmcSettingsFormProps) => {
  const user = useAppSelector(selectUser);

  const isAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const deviceConfiguration = device.configuration ?? {};
  const isOffline = device.status === DeviceStatus.OFFLINE;

  const schema = useLmcSettingsSchema({ device, isAdmin, isInstaller });
  const form = useForm<LmcSettingsForm>({
    schema,
    defaultValues: lmcSettingsFormDefaultValues,
  });
  const { handleSubmitAndResolve, reset } = form;

  const submit = async ({
    deviceName,
    gridConnection,
    powerSharingMethod,
    powerMeterType,
    powerMeterIpAddress,
    solarInverterType,
    solarInverterIpAddress,
    ocppHostAddress,
    ocppServerReconnectTime,
    mainsMeteringMode,
    externalAntennaEnabled,
  }: LmcSettingsForm) => {
    const values: UpdateDeviceDataValues = {
      deviceUpdateData: {
        uuid: device.uuid,
        name: deviceName?.trim() || null,
        ...(!!Number(gridConnection) && { gridConnection }),
      },
    };

    if (!isOffline) {
      const parameters: DeviceConfigItem[] = [
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.POWER_SHARING_METHOD.key,
          value: powerSharingMethod,
        },
        {
          key: DEVICE_CONFIGURATION_PARAMETERS.EXTERNAL_ANTENNA_ENABLED.key,
          value: externalAntennaEnabled,
        },
      ];

      if (powerMeterType && powerMeterIpAddress) {
        parameters.push(
          {
            key: DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE.key,
            value: powerMeterType.value,
          },
          {
            key: DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_IP.key,
            value: powerMeterIpAddress,
          },
        );
      }

      if (solarInverterType && solarInverterIpAddress) {
        parameters.push(
          {
            key: DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE.key,
            value: solarInverterType?.value,
          },
          {
            key: DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_IP.key,
            value: solarInverterIpAddress,
          },
        );
      }

      if (isAdmin) {
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_ADDRESS.key,
          value: ocppHostAddress,
        });
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_RECONNECT_TIME_S.key,
          value: ocppServerReconnectTime,
        });
      }
      if (isAdmin || isInstaller) {
        parameters.push({
          key: DEVICE_CONFIGURATION_PARAMETERS.MAINS_METERING_TYPE.key,
          value: mainsMeteringMode,
        });
      }

      values.deviceConfig = parameters;
    }

    return onSaveValues(values);
  };

  useEffect(() => {
    if (isEmpty(device)) {
      reset();
    } else {
      const powerMeterTypeValue = deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE.key]?.value;
      const solarInverterTypeValue =
        deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE.key]?.value;
      reset({
        deviceName: device.name || device.serialNumber,
        gridConnection: device.gridConnection ?? null,
        powerSharingMethod: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_SHARING_METHOD.key]?.value,
        powerMeterType: powerMeterTypeValue
          ? {
              label: powerMeterTypeValue,
              value: powerMeterTypeValue,
            }
          : null,
        powerMeterIpAddress: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_IP.key]?.value,
        solarInverterType: solarInverterTypeValue
          ? {
              label: solarInverterTypeValue,
              value: solarInverterTypeValue,
            }
          : null,
        solarInverterIpAddress: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_IP.key]?.value,
        ...(isAdmin && {
          ocppHostAddress: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_ADDRESS.key]?.value,
          ocppServerReconnectTime:
            deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.OCPP_SERVER_RECONNECT_TIME_S.key]?.value,
        }),
        ...((isAdmin || isInstaller) && {
          mainsMeteringMode: deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.MAINS_METERING_TYPE.key]?.value,
        }),
        externalAntennaEnabled:
          deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.EXTERNAL_ANTENNA_ENABLED.key]?.value,
      });
    }
  }, [device.configuration]);

  const submitLmcSettings = () => handleSubmitAndResolve(submit)();
  return {
    form,
    submitLmcSettings,
  };
};
