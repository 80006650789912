import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Group } from 'models/group.dto';

import NoItemsCard from '@components/cards/NoItemsCard';
import SortableTable from '@components/tables/SortableTable';
import { ReactComponent as DeleteIcon } from '@images/icons/Trash.svg';

import { DeleteWorkspaceGroupModal } from './DeleteWorkspaceGroupModal';
import { useWorkspaceGroupsAndRoles } from './workspaceHooks';

export const WorkspaceGroupsView = () => {
  const { t } = useTranslation();
  const { companyUuid } = useParams();
  const navigate = useNavigate();

  const [groupToDelete, setGroupToDelete] = useState<Group>();

  const { isWorkspaceAdmin, groups } = useWorkspaceGroupsAndRoles({
    refetchOnMountOrArgChange: true,
    withUsers: true,
    companyUuid,
  });

  const tableColumns = [
    {
      key: 'name',
      label: t('name', 'Name'),
      tdClassName: 'py-5 px-4',
      value: (group: Group) => group.name,
    },
    {
      key: 'users',
      label: t('members', 'Members'),
      tdClassName: 'py-5 px-4',
      value: (group: Group) => group.users?.length ?? 0,
    },
    {
      key: 'menu',
      label: '',
      sortingDisabled: true,
      tdClassName: 'w-10 pr-1.5',
      // FIXME: Move this component definition out of the parent component and pass data as props. sonarlint(javascript:S6478)
      value: (group: Group) =>
        isWorkspaceAdmin && (
          <button onClick={() => setGroupToDelete(group)}>
            <DeleteIcon />
          </button>
        ),
    },
  ];

  return (
    <>
      {!groups?.length ? (
        <NoItemsCard
          message={t('noGroupsText', 'There are currently no groups in your workspace.')}
          buttonLabel={t('createAGroup', 'Create a group')}
          onButtonClick={() => navigate('create')}
        />
      ) : (
        <SortableTable
          columns={tableColumns}
          data={groups}
          keyAttr="uuid"
          sortingStorageKey="workspaceGroups"
          onRowClick={(group) => navigate(group.uuid)}
        />
      )}
      <Outlet />
      {groupToDelete && (
        <DeleteWorkspaceGroupModal
          isOpen={!!groupToDelete}
          group={groupToDelete}
          closeModal={() => setGroupToDelete(undefined)}
        />
      )}
    </>
  );
};
