/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { get } from 'lodash';
import PropTypes from 'prop-types';

import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { Tooltip } from '@mui/material';

import copyContent from '@helpers/copyContent';
import clipboardIcon from '@images/icons/Clipboard.svg';
import { ReactComponent as ChartIcon } from '@images/icons/nav/Chart.svg';
import apiSlice from '@services/api';

import SmartChargingGraph from '../smartChargingGraph';
import { Spinner } from './Spinner';
import TableRowGraph from './TableRowGraph';

const TableRow = ({ columns, background, data, lastRow, onTableEnd }) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const [graphOpen, setGraphOpen] = useState(false);
  const [transactionData, setTransactionData] = useState(null);
  const [skipTransactionRequest, setSkipTransactionRequest] = useState(true);

  const defaultCopyState = t('copyTransactionId', 'Copy transaction ID');
  const [copyState, setCopyState] = useState(defaultCopyState);
  const { data: transactionGraphData, isLoading } = apiSlice.useTransactionDataQuery(data?.transactionId, {
    skip: skipTransactionRequest,
  });
  const consumption = transactionGraphData?.consumption;
  const prices = transactionGraphData?.prices;
  const updateGraphOpen = () => (data?.transactionId || data?.carUuid) && setGraphOpen((open) => !open);

  useEffect(() => {
    if (graphOpen) {
      setSkipTransactionRequest(!!transactionData || !data?.transactionId);
    }
  }, [graphOpen]);

  useEffect(() => {
    if (consumption?.length) {
      setTransactionData(consumption);
    }
  }, [consumption]);

  useEffect(() => {
    if (lastRow && inView) {
      onTableEnd();
    }
  }, [inView]);

  return (
    <>
      <tr
        ref={ref}
        className={`
          ${!graphOpen ? 'border-b border-gray-100' : 'bg-gray-100/50'}
          ${(data?.transactionId || data?.carUuid) && 'cursor-pointer hover:bg-gray-100/50'}
          ${background}
        `}
        onClick={updateGraphOpen}
        style={{ height: 60 }}
      >
        {inView ? (
          columns.map((c) => (
            <td
              key={c.key}
              className={`
                overflow-hidden truncate text-ellipsis whitespace-nowrap font-poppins
                text-base text-black ${c.textAlign ?? ''} ${c.tdClassName ?? ''}
              `}
            >
              <div className="p-4">
                <span>
                  {get(c, 'value', () => t('dataMissing', 'Data missing').toLowerCase())(data, updateGraphOpen)}
                </span>
              </div>
            </td>
          ))
        ) : (
          <td colSpan={columns.length}></td>
        )}
      </tr>
      {graphOpen && (
        <tr className="border-b border-gray-100 bg-gray-100/50">
          <td colSpan={columns.length}>
            <div className="flex items-center px-4 font-poppins text-sm leading-6">
              <span className="pr-1">{t('id', 'ID')}:</span>
              <span>{data.transactionId}</span>
              <Tooltip
                placement="top"
                title={copyState}
                onClose={() => setTimeout(() => setCopyState(defaultCopyState), 300)}
              >
                <button
                  type="button"
                  className="p-1"
                  tabIndex={0}
                  onClick={() =>
                    copyContent({
                      text: data.transactionId,
                      afterCopy: () => setCopyState(t('copied', 'Copied')),
                    })
                  }
                >
                  <img src={clipboardIcon} alt="Copy" className="w-4" />
                </button>
              </Tooltip>
            </div>
            <div className="relative mt-2 p-2">
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {!transactionData?.length && !data?.slots && (
                    <div className="mt-4 flex items-center justify-center space-x-3">
                      <ChartIcon className="h-5 w-5 [&_path]:fill-gray-400" />
                      <span className="text-sm text-gray-400">
                        {t('notEnoughDataAvailable', 'Not enough data available')}
                      </span>
                    </div>
                  )}
                  {transactionData?.length && <TableRowGraph options={transactionData} prices={prices} />}
                  {data?.slots && <SmartChargingGraph slots={data.slots} />}
                  <button type="button" onClick={() => setGraphOpen(false)}>
                    <Icon path={mdiClose} size="14px" className="absolute right-2 top-0" />
                  </button>
                </>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

TableRow.propTypes = {
  columns: PropTypes.array,
  background: PropTypes.string,
  data: PropTypes.object,
  onTableEnd: PropTypes.func,
  lastRow: PropTypes.bool,
};

TableRow.defaultProps = {
  columns: [],
  background: '',
  data: {},
  onTableEnd: () => {},
  lastRow: false,
};

export default TableRow;
