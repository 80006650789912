import { useTranslation } from 'react-i18next';

import { WorkspaceSetupSidebarBase } from '../setup/WorkspaceSetupSidebarBase';
import { CompanyPaymentMethod } from './CompanyPaymentMethod';

export const PaymentMethodSidebar = () => {
  const { t } = useTranslation();

  return (
    <WorkspaceSetupSidebarBase title={t('paymentMethod', 'Payment method')}>
      <CompanyPaymentMethod />
    </WorkspaceSetupSidebarBase>
  );
};
