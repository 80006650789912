import { useMemo } from 'react';

import { Device } from 'models/device';

import { Box, Typography } from '@mui/material';

import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { useGetPartnerCompaniesQuery } from '@services/partners/endpoints';
import { useGetAllSitesQuery } from '@services/sites/endpoints';

export const DeviceSiteContent = ({ device }: { device: Device }) => {
  const { data: partnerCompanies } = useGetPartnerCompaniesQuery(undefined, { skip: !isSubdomainPartner() });
  const { data: sites } = useGetAllSitesQuery(undefined, { skip: !isSubdomainPartner() });

  const company = useMemo(
    () => partnerCompanies?.find((company) => company.uuid === device?.companyUuid),
    [device?.companyUuid, partnerCompanies],
  );
  const site = useMemo(() => sites?.find((site) => site.uuid === device?.siteUuid), [device?.siteUuid, sites]);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
      <Typography variant="p14">{company?.name}</Typography>
      <Typography variant="p14">{site?.name}</Typography>
    </Box>
  );
};
