import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from 'components/atoms/StyledButton';

import { Box, Typography } from '@mui/material';

import { useBreakpoints } from '@hooks/useBreakpoints';
import { useCreateStripeSessionMutation } from '@services/billing/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';

export type StripePaymentLinkProps = {
  onCancel: () => void;
  onSuccess: (setupIntent: SetupIntent) => void;
};

export const PaymentLink = ({ onCancel, onSuccess }: StripePaymentLinkProps) => {
  const { isMobile } = useBreakpoints();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [startStripeSession] = useCreateStripeSessionMutation();
  const [loading, setLoading] = useState(false);

  const getReturnUrl = () => {
    return `${location.href}/${location.pathname}`;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();

    if (submitError) {
      setLoading(false);
      dispatch(addToastMessage({ type: 'error', title: t('somethingWentWrong', 'Something went wrong') }));
      return;
    }

    try {
      const { clientSecret } = await startStripeSession().unwrap();
      const { error: confirmError, setupIntent } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: { return_url: getReturnUrl() },
        redirect: 'if_required',
      });

      if (confirmError) {
        setLoading(false);
        dispatch(
          addToastMessage({
            type: 'error',
            title: confirmError.message ?? t('somethingWentWrong', 'Something went wrong'),
          }),
        );
      } else {
        onSuccess(setupIntent);
      }
    } catch (error) {
      setLoading(false);
      dispatch(addToastMessage({ type: 'error', title: t('somethingWentWrong', 'Something went wrong') }));
    }
  };

  return stripe && elements ? (
    <Box component="form" display="flex" flexDirection="column" flex={1} mt={1.5} onSubmit={handleSubmit}>
      <PaymentElement
        onLoadError={() =>
          dispatch(
            addToastMessage({
              type: 'error',
              title: t('errorLoadingPayment', 'Something went wrong while loading payment'),
            }),
          )
        }
      />
      <Box mt={3} display="flex" alignItems="flex-end" justifyContent="flex-end" flex={1} columnGap={1.5}>
        <StyledButton color="secondary" fullWidth={isMobile} onClick={onCancel} disabled={loading}>
          {t('cancel', 'Cancel')}
        </StyledButton>
        <StyledButton color="success" fullWidth={isMobile} type="submit" disabled={loading}>
          {t('save', 'Save')}
        </StyledButton>
      </Box>
    </Box>
  ) : (
    <Box>
      <Typography variant="p16">
        {t('somethingWentWrong', 'Something went wrong')}. {t('pleaseTryAgain', 'Please try again.')}
      </Typography>
    </Box>
  );
};
