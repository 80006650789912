import { useContext } from 'react';
import { useFieldArray } from 'react-hook-form';

import { t } from 'i18next';
import { CreateReportScheduleDto, ReportScheduleForm } from 'models/report-schedule';

import { makeUserEmailOption } from '@components/atoms/UserEmailAutocomplete';
import { useForm } from '@hooks/useTypedForm';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import {
  useLazyGetReportScheduleQuery,
  usePostReportScheduleMutation,
  useUpdateReportScheduleMutation,
} from '@services/reporting/endpoints';
import { addToastMessage } from '@services/toastMessages';
import { selectUsers } from '@services/users/selectors';

import { ReportScheduleContext } from '../context/ReportScheduleProvider';
import { useReportScheduleSchema } from './useReportScheduleSchema';

export const useReportSchedule = (uuid?: string) => {
  const dispatch = useAppDispatch();
  const { buildColumnFilters, loadFilters } = useContext(ReportScheduleContext);
  const companyUsers = useAppSelector(selectUsers);

  const [createReportSchedule, { isLoading: reportScheduleSaving }] = usePostReportScheduleMutation();
  const [updateReportSchedule, { isLoading: reportScheduleUpdating }] = useUpdateReportScheduleMutation();
  const [fetchReportSchedule, { isFetching: fetchingReportSchedule }] = useLazyGetReportScheduleQuery();

  const schema = useReportScheduleSchema();
  const form = useForm<ReportScheduleForm>({
    schema,
    defaultValues: {
      name: '',
      fileTypes: [
        { fileType: 'pdf', checked: false },
        { fileType: 'csv', checked: false },
      ],
      recurrence: 'daily',
      recipients: [],
      filters: [],
    },
  });

  const fileTypes = useFieldArray({
    control: form.control,
    name: 'fileTypes',
  });

  const loadReportSchedule = async () => {
    try {
      if (uuid) {
        const response = await fetchReportSchedule(uuid).unwrap();
        form.reset({
          name: response.name ?? '',
          fileTypes: fileTypes.fields.map((field) => ({
            fileType: field.fileType,
            checked: response.fileTypes.includes(field.fileType),
          })),
          recurrence: response.recurrence,
          recipients: response.recipients.map((recipient) => {
            const existingUser = companyUsers.find((user) => user.email === recipient.email);
            if (existingUser) {
              return makeUserEmailOption(existingUser);
            }

            return {
              email: recipient.email,
              uuid: recipient.userUuid,
            };
          }),
          filters: response.filters ?? [],
        });

        if (response.filters) {
          loadFilters(response.filters);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submit = async (values: ReportScheduleForm) => {
    try {
      const filters = buildColumnFilters();
      const body: CreateReportScheduleDto = {
        name: values.name || undefined,
        fileTypes: values.fileTypes.filter((fileType) => fileType.checked).map((fileType) => fileType.fileType),
        recurrence: values.recurrence,
        recipients: values.recipients.map((recipient) => ({
          email: recipient.email,
          userUuid: recipient.uuid,
        })),
        filters: filters.length ? filters : undefined,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (uuid) {
        await updateReportSchedule({ uuid, body }).unwrap();
      } else {
        await createReportSchedule(body).unwrap();
      }

      dispatch(
        addToastMessage({
          type: 'success',
          title: uuid ? t('scheduleUpdated', 'Schedule updated') : t('scheduleCreated', 'Schedule created'),
        }),
      );

      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('addingItemFailed', 'Adding {{item}} failed', {
            item: t('schedule', 'Schedule').toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }

    return false;
  };

  return {
    form,
    fileTypes,
    loadReportSchedule,
    submit,
    reportScheduleSaving,
    reportScheduleUpdating,
    fetchingReportSchedule,
  };
};
