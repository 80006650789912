import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DeviceType } from 'models/device.enums';
import { ReportingTransaction } from 'models/reporting-transaction';

import { NonVoolChargerIcon } from '@components/atoms/NonVoolChargerIcon';
import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { ReactComponent as VoolChargerIcon } from '@images/icons/reporting/vool-charger.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectUser } from '@services/auth/selectors';
import { useGetAllDevicesQuery, useGetDevicesQuery } from '@services/devices/endpoints';
import { useAppSelector } from '@services/hooks';

export const useChargerMenu = (transactions?: ReportingTransaction[]) => {
  const { t } = useTranslation();
  const { companyUuid } = useParams();
  const self = useAppSelector(selectUser);
  const skipGetAll = isSubdomainPartner() ? !!companyUuid : !self?.admin;
  const { data, isFetching, isError } = useGetDevicesQuery({ companyUuid }, { skip: !skipGetAll });
  const {
    data: dataAdmin,
    isFetching: isFetchingAdmin,
    isError: isErrorAdmin,
  } = useGetAllDevicesQuery({}, { skip: skipGetAll });
  const [chargerMenuOptions, setChargerMenuOptions] = useState<StyledMenuItemProps[]>([]);

  useEffect(() => {
    const devices = data?.devices ?? dataAdmin?.devices;
    if (!isFetching && !isError && !isFetchingAdmin && !isErrorAdmin) {
      setChargerMenuOptions(
        (prevState) =>
          devices
            ?.filter((device) => device.type === DeviceType.CHARGER)
            .map((device) => ({
              uuid: device.uuid,
              checked: prevState?.find((option) => option.uuid === device.uuid)?.checked ?? false,
              Icon: device.voolDeviceFlag ? VoolChargerIcon : NonVoolChargerIcon,
              label: device.name || device.serialNumber || t('unknown', 'Unknown'),
              resultCount: transactions?.filter((transaction) => transaction.deviceUuid === device.uuid).length,
              onClick: () => toggleOptionChecked(device.uuid),
            }))
            .sort(compareMenuOptions) ?? [],
      );
    }
  }, [isFetching, isFetchingAdmin, transactions]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setChargerMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { chargerMenuOptions, setChargerMenuOptions };
};
