import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import charger from '@images/icons/map/charger.svg';

export const SiteMarker = ({ onClick }) => (
  <Box onClick={onClick}>
    <img
      src={charger}
      className="w-12 transform hover:scale-110"
      alt="siteChargers"
      style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
    />
  </Box>
);

SiteMarker.propTypes = {
  onClick: PropTypes.func.isRequired,
};
