import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Group, GroupDto } from 'models/group.dto';

import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { useWorkspaceGroupsAndRoles } from '@views/Workspace/workspaceHooks';

import { GroupAssignment } from './GroupAssignment';
import { GroupDetailsForm } from './GroupDetails';
import { GroupMembers } from './GroupMembers';
import { GroupStepper } from './GroupStepper';
import { useGroupAssignmentState } from './hooks/useGroupAssignmentState';
import { useGroupDetailsState } from './hooks/useGroupDetailsState';
import { useGroupMembersState } from './hooks/useGroupMembersState';

type GroupSidebarBaseProps = {
  mode: 'create' | 'edit';
  isLoading?: boolean;
  isSaving: boolean;
  handleSave: (group: GroupDto) => Promise<boolean>;
  group?: Group;
  companyUuid?: string;
};

export const GroupSidebarBase = ({
  mode,
  isLoading,
  isSaving,
  handleSave,
  group,
  companyUuid,
}: GroupSidebarBaseProps) => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState<{ [key: number]: boolean }>({});

  const { control, name, description, anyDataChanged, trigger } = useGroupDetailsState(group);
  const { groupUsers, setGroupUsers, handleAddUsers } = useGroupMembersState(group);
  const { siteUuids, setSiteUuids, deviceUuids, setDeviceUuids } = useGroupAssignmentState(group, companyUuid);
  const { isWorkspaceAdmin } = useWorkspaceGroupsAndRoles();

  const submitGroup = async () => {
    const processedUsers = groupUsers.map((u) => ({ email: u.email, role: u.role }));
    const group = {
      name: name.trim(),
      description: description?.trim() || undefined,
      users: processedUsers,
      siteUuids,
      deviceUuids,
    };

    return handleSave(group);
  };

  const handleStepChosen = (step: number) => {
    if (activeStep === step) {
      return;
    }
    if ((activeStep === 0 && !!name.trim()) || (activeStep === 1 && activeStep < step) || activeStep === 2) {
      setCompletedSteps((prev) => ({ ...prev, [activeStep]: true }));
    }
    setActiveStep(step);
  };

  const handleSaveValues = async () => {
    if (!isWorkspaceAdmin || activeStep === 2) {
      return submitGroup();
    }

    const isValidForm = await trigger();
    if (!isValidForm) {
      return false;
    }

    handleStepChosen(activeStep + 1);
    return false;
  };

  const handleDiscardValues = () => {
    if (activeStep > 0) {
      handleStepChosen(activeStep - 1);
      setCompletedSteps((prev) => ({ ...prev, [activeStep]: false, [activeStep - 1]: false }));
    }
  };

  const getSaveButtonLabel = () => {
    if (activeStep === 2) return mode === 'create' ? t('create', 'Create') : t('save', 'Save');
    return t('next', 'Next');
  };

  const getTitle = () => {
    if (!isWorkspaceAdmin && group?.name) {
      return group.name;
    }
    return mode === 'create' ? t('createGroup', 'Create group') : t('editGroup', 'Edit group');
  };

  useEffect(() => {
    if (!isWorkspaceAdmin) {
      setActiveStep(1);
    }
  }, [isWorkspaceAdmin]);

  return (
    <EditingSidebarBase
      title={getTitle()}
      subtitle={!isWorkspaceAdmin ? group?.description : ''}
      saveLabel={getSaveButtonLabel()}
      saveDisabled={isLoading}
      updateLoading={isSaving}
      discardLabel={isWorkspaceAdmin && activeStep > 0 ? t('back', 'Back') : t('discard', 'Discard')}
      disableCloseOnDiscard={isWorkspaceAdmin && activeStep > 0}
      anyDataChanged={anyDataChanged}
      onSaveValues={handleSaveValues}
      onDiscardValues={handleDiscardValues}
      skipDisablingSaveIfNoDataChanged
      isWide
    >
      {isWorkspaceAdmin && <GroupStepper activeStep={activeStep} completedSteps={completedSteps} />}
      {activeStep === 0 && <GroupDetailsForm control={control} isLoading={isLoading} />}
      {activeStep === 1 && (
        <GroupMembers
          onAddUsers={handleAddUsers}
          groupUsers={groupUsers}
          onGroupUsersChange={setGroupUsers}
          isLoading={isLoading}
          isSaving={isSaving}
        />
      )}
      {activeStep === 2 && (
        <GroupAssignment
          siteUuids={siteUuids}
          onSiteUuidsChange={setSiteUuids}
          deviceUuids={deviceUuids}
          onDeviceUuidsChange={setDeviceUuids}
          isSaving={isSaving}
          companyUuid={companyUuid}
        />
      )}
    </EditingSidebarBase>
  );
};
