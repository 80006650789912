import PropTypes from 'prop-types';

import Button from '@components/atoms/Button';

const NoItemsCard = ({ message, buttonLabel, onButtonClick }) => (
  <div className="flex w-full flex-col items-center justify-center space-y-4 rounded-2xl border border-gray-200 py-14">
    <span className="font-poppins text-lg font-semibold leading-8 text-gray-600">{message}</span>
    {!!buttonLabel && (
      <Button variant="primary" onClick={onButtonClick}>
        {buttonLabel}
      </Button>
    )}
  </div>
);

NoItemsCard.propTypes = {
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

NoItemsCard.defaultProps = {
  buttonLabel: null,
  onButtonClick: () => {},
};

export default NoItemsCard;
