import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSwitchToCompany } from '@hooks/useSwitchToCompany';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import apiSlice from '@services/api';
import { useGetCompaniesQuery } from '@services/companies/endpoints';
import { selectCompanies } from '@services/companies/selectors';
import { useGetAllDevicesQuery } from '@services/devices/endpoints';
import { selectDevices } from '@services/devices/selectors';
import { useGetPartnerCompaniesQuery } from '@services/partners/endpoints';
import { useGetAllSitesQuery } from '@services/sites/endpoints';
import { selectSites } from '@services/sites/selectors';
import { useGetUsersQuery } from '@services/users/endpoints';
import { selectUsers } from '@services/users/selectors';
import { selectVehicles } from '@services/vehicles/selectors';

import { extractFilterTypeAndQuery } from './searchQueryUtils';

const COUNT_LIMITED_RESULTS = 5;

const useSearchResultsBase = ({ haystack = [], searchQuery, limited, skip, extraSearchKeys = [] }) => {
  const searchQueryLowerCase = searchQuery.trim().toLowerCase();
  const pickResults = (allResults) => {
    if (skip) {
      return [];
    }
    if (limited) {
      return allResults.slice(0, COUNT_LIMITED_RESULTS);
    }
    return allResults;
  };
  const orderedHaystack = useMemo(() => [...haystack].sort((a, b) => a.name?.localeCompare(b.name) ?? 0), [haystack]);
  return useMemo(() => {
    // TODO: implement fancy multipart searching
    const allResults = orderedHaystack.filter((item) => {
      const searchKeys = ['name', ...extraSearchKeys];
      return searchKeys.some((key) => item[key]?.toLowerCase().includes(searchQueryLowerCase));
    });
    return { results: pickResults(allResults), totalCount: allResults.length };
  }, [haystack, searchQuery, skip]);
};

const useCompanyResults = ({ searchQuery, limited, skip }) => {
  useGetCompaniesQuery(undefined, { skip: isSubdomainPartner() });
  const { data: partnerCompanies } = useGetPartnerCompaniesQuery(undefined, { skip: !isSubdomainPartner() });
  const companies = useSelector(selectCompanies);

  return useSearchResultsBase({
    haystack: isSubdomainPartner() ? partnerCompanies : companies,
    searchQuery,
    limited,
    skip,
  });
};

const useDeviceResults = ({ searchQuery, limited, skip }) => {
  useGetAllDevicesQuery({});
  const devices = useSelector(selectDevices);
  const processedDevices = useMemo(
    () =>
      devices.map(({ name, ...device }) => ({
        name: name || device.serialNumber,
        ...device,
      })),
    [devices],
  );
  return useSearchResultsBase({
    haystack: processedDevices,
    searchQuery,
    limited,
    skip,
    extraSearchKeys: ['serialNumber', 'userName', 'userEmail'],
  });
};

const useUserResults = ({ searchQuery, limited, skip }) => {
  useGetUsersQuery(undefined, { skip: isSubdomainPartner() }); // TODO: implement users search for partners
  const users = useSelector(selectUsers);
  const processedUsers = useMemo(
    () =>
      users.map(({ name, ...user }) => ({
        name: name || user.email,
        ...user,
      })),
    [users],
  );
  return useSearchResultsBase({ haystack: processedUsers, searchQuery, limited, skip });
};

const useSiteResults = ({ searchQuery, limited, skip }) => {
  useGetAllSitesQuery();
  const sites = useSelector(selectSites);
  return useSearchResultsBase({
    haystack: sites,
    searchQuery,
    limited,
    skip,
    extraSearchKeys: ['userName', 'userEmail'],
  });
};

const useVehicleResults = ({ searchQuery, limited, skip }) => {
  apiSlice.useVehiclesQuery({});
  const vehicles = useSelector(selectVehicles);
  const processedVehicles = useMemo(
    () => vehicles.map((v) => ({ ...v, name: `${v.brand} ${v?.name || v.model}` })),
    [vehicles],
  );
  return useSearchResultsBase({ haystack: processedVehicles, searchQuery, limited, skip });
};

const useGroupedSearchResults = ({ fullSearchQuery }) => {
  const navigate = useNavigate();
  const switchToCompany = useSwitchToCompany();
  const companies = useSelector(selectCompanies);

  const [filteredType, searchQuery] = extractFilterTypeAndQuery(fullSearchQuery);
  const typeSkipped = (type) => filteredType && filteredType !== type;

  const userResults = {
    type: 'user',
    ...useUserResults({ searchQuery, limited: !filteredType, skip: typeSkipped('user') }),
    onItemClick: (user) => navigate(`/access-management/members?highlighted=${user.uuid}`),
  };

  const groupedResults = [
    {
      type: 'workspace',
      ...useCompanyResults({ searchQuery, limited: !filteredType, skip: typeSkipped('workspace') }),
      onItemClick: (company) => {
        if (isSubdomainPartner()) {
          navigate(`/workspaces/${company.uuid}`);
        } else {
          switchToCompany(company);
        }
      },
    },
    ...(!isSubdomainPartner() ? [userResults] : []),
    {
      type: 'site',
      ...useSiteResults({ searchQuery, limited: !filteredType, skip: typeSkipped('site') }),
      onItemClick: (site) => {
        if (isSubdomainPartner()) {
          navigate(`/workspaces/${site.companyUuid}/sites/${site.uuid}`);
        } else {
          const company = companies.find((c) => c.uuid === site.companyUuid);
          (company ? switchToCompany(company) : Promise.resolve()).then(() => navigate(`/sites/${site.uuid}`));
        }
      },
    },
    {
      type: 'device',
      ...useDeviceResults({ searchQuery, limited: !filteredType, skip: typeSkipped('device') }),
      onItemClick: (device) => {
        if (isSubdomainPartner()) {
          navigate(`/workspaces/${device.companyUuid}/devices/${device.uuid}`);
        } else {
          const company = companies.find((c) => c.uuid === device.companyUuid);
          (company ? switchToCompany(company) : Promise.resolve()).then(() => navigate(`/devices/${device.uuid}`));
        }
      },
    },
    {
      type: 'vehicle',
      ...useVehicleResults({ searchQuery, limited: !filteredType, skip: typeSkipped('vehicle') }),
      onItemClick: (vehicle) => {
        if (isSubdomainPartner()) {
          navigate(`/workspaces/${vehicle.companyUuid}`);
        } else {
          navigate(`/vehicles/${vehicle.uuid}`);
        }
      },
    },
  ];
  return [filteredType, searchQuery, groupedResults];
};

export default useGroupedSearchResults;
