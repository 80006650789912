import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

export const selectSlice = (state: RootState) => state.vehicles;

export const selectVehicles = createAppSelector(selectSlice, (slice) => slice.vehicles);
export const selectPrivateVehiclesOrByCompanyUuid = createAppSelector(
  selectVehicles,
  (state: RootState, companyUuid?: string) => companyUuid,
  (vehicles, companyUuid) =>
    companyUuid ? vehicles.filter((v) => v.privateFlag || v.companyUuid === companyUuid) : [],
);
export const selectLinkedAccounts = createAppSelector(selectSlice, (slice) => slice.linkedAccounts);
export const selectVehiclesNotLinked = createAppSelector(selectSlice, (slice) => slice.vehiclesNotLinked);
export const selectSmartChargingSessions = createAppSelector(selectSlice, (slice) => slice.smartChargingSessions);
export const selectCurrentSmartChargingSession = createAppSelector(
  selectSlice,
  (slice) => slice.currentSmartChargingSession,
);
export const selectSmartChargingSessionsSummary = createAppSelector(
  selectSlice,
  (slice) => slice.smartChargingSessionsSummary,
);
export const selectVehiclesMileage = createAppSelector(selectSlice, (slice) => slice.vehiclesMileage);

export const makeSelectVehicleByUuid = () =>
  createAppSelector(
    selectVehicles,
    (state: RootState, uuid: string) => uuid,
    (vehicles, uuid) => vehicles.find((v) => v.uuid === uuid),
  );

export const selectVehicleByUuid = createAppSelector(
  selectVehicles,
  (state: RootState, uuid: string) => uuid,
  (vehicles, uuid) => vehicles.find((v) => v.uuid === uuid),
);
