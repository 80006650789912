import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { get, isNaN, isNil } from 'lodash';
import PropTypes from 'prop-types';

import { MenuAlt1Icon } from '@heroicons/react/outline';

import TableHeaderWithSorting, { parseSortingParam } from './TableHeaderWithSorting';

const ROWS_AT_A_TIME = 300;

const SortableTable = ({
  data,
  columns,
  keyAttr,
  selectedItem,
  sortingStorageKey,
  defaultSortingParam,
  bottomClassName,
  tableRow: TableRow,
  onRowClick,
}) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [dataUntil, setDataUntil] = useState(ROWS_AT_A_TIME);
  const [sortingParam, setSortingParam] = useState('');

  const { sortingColumn, isSortingDescending } = parseSortingParam(sortingParam);

  const sortedData = useMemo(() => {
    const result = [...data];
    const normalizeValue = (value) => {
      const lowerCase = typeof value === 'string' ? value.toLowerCase() : value;
      const number = Number(lowerCase);
      return isNaN(number) ? lowerCase : number;
    };
    result.sort((a, b) => {
      const aValue = normalizeValue(a[sortingColumn]);
      const bValue = normalizeValue(b[sortingColumn]);
      if ((isNil(aValue) && !isNil(bValue)) || aValue < bValue) {
        return isSortingDescending ? 1 : -1;
      }
      if ((!isNil(aValue) && isNil(bValue)) || aValue > bValue) {
        return isSortingDescending ? -1 : 1;
      }
      return 0;
    });
    return result;
  }, [data, sortingColumn, isSortingDescending]);

  useEffect(
    () =>
      setSortingParam(
        localStorage.getItem(`${sortingStorageKey}SortingParam`) ?? defaultSortingParam ?? columns[0].key,
      ),
    [],
  );
  useEffect(() => {
    if (sortingParam) {
      localStorage.setItem(`${sortingStorageKey}SortingParam`, sortingParam);
    }
  }, [sortingParam]);

  useEffect(() => {
    if (inView) {
      setDataUntil((until) => until + ROWS_AT_A_TIME);
    }
  }, [inView]);

  return (
    <table className="w-full rounded-2xl bg-white font-poppins">
      <TableHeaderWithSorting
        columns={columns}
        sortingParam={sortingParam}
        defaultSortingParam={defaultSortingParam}
        onSortingParamChange={setSortingParam}
      />
      <tbody>
        {!sortedData.length && (
          <tr>
            <td colSpan={columns.length} className="text-center font-light">
              <div className="align-center flex flex-col items-center p-4 text-xs text-gray-400">
                <MenuAlt1Icon className="h-6 w-6" />
                {t('listIsEmpty', 'List is empty')}
              </div>
            </td>
          </tr>
        )}
        {sortedData.slice(0, dataUntil).map((item, index) =>
          TableRow ? (
            <TableRow
              data={item}
              key={item[keyAttr]}
              columns={columns}
              background="bg-white"
              onTableEnd={() => setDataUntil((until) => until + ROWS_AT_A_TIME)}
              lastRow={dataUntil - 5 === index}
            />
          ) : (
            <tr
              className={`border-b border-gray-100 [&:last-child>td]:${bottomClassName} ${
                item[keyAttr] === selectedItem?.[keyAttr] ? 'bg-gray-100/50' : ''
              } ${onRowClick ? 'hover:bg-gray-100/50' : ''}`}
              key={item[keyAttr]}
              {...(index === dataUntil - 5 && { ref })}
            >
              {columns.map((column) => (
                <td
                  key={column.key}
                  className={`${column.getTdClassName?.(item) ?? ''} ${column.tdClassName ?? ''} ${
                    onRowClick && column.key !== 'menu' ? 'cursor-pointer' : ''
                  }`}
                  {...(onRowClick && column.key !== 'menu' && { onClick: () => onRowClick(item) })}
                >
                  {get(column, 'value', () => '')(item)}
                </td>
              ))}
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
};

SortableTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      extraData: PropTypes.string,
      extraDataClassName: PropTypes.string,
      tdClassName: PropTypes.string,
      getTdClassName: PropTypes.func,
      sortingDisabled: PropTypes.bool,
      value: PropTypes.func.isRequired,
      reverseSorting: PropTypes.bool,
    }),
  ).isRequired,
  keyAttr: PropTypes.string.isRequired,
  selectedItem: PropTypes.object,
  sortingStorageKey: PropTypes.string.isRequired,
  defaultSortingParam: PropTypes.string,
  bottomClassName: PropTypes.string,
  tableRow: PropTypes.func,
  onRowClick: PropTypes.func,
};

SortableTable.defaultProps = {
  data: [],
  selectedItem: null,
  defaultSortingParam: null,
  bottomClassName: 'pb-2',
  tableRow: null,
  onRowClick: null,
};

export default SortableTable;
