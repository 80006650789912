import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReportingTransaction } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetAllRfidCardsQuery, useGetRfidCardsQuery } from '@services/rfidCards/endpoints';
import { selectUsers } from '@services/users/selectors';
import { selectVehicles } from '@services/vehicles/selectors';
import { useGetRfidCardAssigneeData } from '@views/Workspace/RfidCardsTable/useGetRfidCardAssigneeData';

export const useRfidMenu = (transactions?: ReportingTransaction[]) => {
  const { companyUuid } = useParams();
  const self = useAppSelector(selectUser);
  const skipGetAll = isSubdomainPartner() ? !!companyUuid : !self?.admin;
  const { data } = useGetRfidCardsQuery({ companyUuid }, { skip: !skipGetAll });
  const { data: dataAdmin } = useGetAllRfidCardsQuery(undefined, {
    skip: skipGetAll,
  });

  const [rfidMenuOptions, setRfidMenuOptions] = useState<StyledMenuItemProps[]>([]);
  const getRfidCardAssigneeData = useGetRfidCardAssigneeData();
  const users = useAppSelector(selectUsers);
  const vehicles = useAppSelector(selectVehicles);
  const rfidCards = data ?? dataAdmin;

  useEffect(() => {
    setRfidMenuOptions(
      (prevState) =>
        rfidCards
          ?.map((card) => {
            const assignedTo = getRfidCardAssigneeData({ rfidCard: card, users, vehicles });
            const label = card.normalizedIdentifier + (assignedTo.name ? ` (${assignedTo.name})` : '');

            return {
              uuid: card.uuid,
              checked: prevState?.find((option) => option.uuid === card.uuid)?.checked ?? false,
              label,
              resultCount: transactions?.filter((transaction) => transaction.rfidCardUuid === card.uuid).length,
              onClick: () => toggleOptionChecked(card.uuid),
            };
          })
          .sort(compareMenuOptions) ?? [],
    );
  }, [rfidCards, transactions, users, vehicles]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setRfidMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { rfidMenuOptions, setRfidMenuOptions };
};
