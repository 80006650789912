import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { createSelector } from '@reduxjs/toolkit';

import { RootState, store } from './store';

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const createAppSelector = createSelector.withTypes<RootState>();
