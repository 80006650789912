import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { Box, MenuItem, Skeleton, Typography } from '@mui/material';

import { Checkbox } from '@components/atoms/Checkbox';
import { FormTextField } from '@components/atoms/FormTextField';
import { StyledFormHelperText } from '@components/atoms/StyledFormHelperText';
import { FormUserEmailAutocomplete } from '@components/atoms/UserEmailAutocomplete';
import { useFilterMenu } from '@components/filters/hooks/useFilterMenu';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { isSubdomainPartner } from '@routesConfig/routesUtil';

import { ReportScheduleFilterOption } from './ReportScheduleFilterOption';
import { ReportScheduleContext } from './context/ReportScheduleProvider';
import { useRecurrence } from './hooks/useRecurrence';
import { useReportSchedule } from './hooks/useReportSchedule';

export const ScheduleReportSidebar = () => {
  const { t } = useTranslation();
  const { scheduleUuid } = useParams();
  const { filterOptionsMap, reset, anyActiveFilters } = useContext(ReportScheduleContext);
  const { filterMenuOptions } = useFilterMenu(filterOptionsMap);
  const { recurrenceMap } = useRecurrence();

  const {
    form: { control, handleSubmitAndResolve, trigger, formState },
    fileTypes: { fields, update },
    loadReportSchedule,
    submit,
    reportScheduleSaving,
    reportScheduleUpdating,
    fetchingReportSchedule,
  } = useReportSchedule(scheduleUuid);

  useEffect(() => {
    loadReportSchedule();
  }, [scheduleUuid]);

  return (
    <EditingSidebarBase
      title={t('schedule', 'Schedule')}
      discardLabel={t('cancel', 'Cancel')}
      saveLabel={t('save', 'Save')}
      saveDisabled={fetchingReportSchedule}
      updateLoading={reportScheduleSaving || reportScheduleUpdating}
      anyDataChanged={formState.isDirty}
      skipDisablingSaveIfNoDataChanged
      onSaveValues={handleSubmitAndResolve(submit)}
      onDiscardValues={reset}
      noActions={isSubdomainPartner()}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        {fetchingReportSchedule ? (
          <Skeleton variant="rounded" animation="wave" height={48} />
        ) : (
          <FormTextField
            control={control}
            name="name"
            label={`${t('reportName', 'Report name')} (${t('optional', 'Optional').toLowerCase()})`}
          />
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="p14b">{t('fileTypes', 'File types')}</Typography>
          <Box display="flex" flexDirection="column">
            <Box display="flex" gap={4}>
              {fields.map((field, index) => {
                return fetchingReportSchedule ? (
                  <Box key={field.id} display="flex" gap={2}>
                    <Skeleton variant="rounded" animation="wave" height={24} width={24} />
                    <Typography variant="p16">{`.${field.fileType}`}</Typography>
                  </Box>
                ) : (
                  <Checkbox
                    key={field.id}
                    name={field.fileType}
                    label={`.${field.fileType}`}
                    checked={field.checked}
                    onChange={() => {
                      update(index, { fileType: field.fileType, checked: !field.checked });
                      trigger('fileTypes');
                    }}
                  />
                );
              })}
            </Box>
            <StyledFormHelperText error={Boolean(formState.errors.fileTypes)}>
              {formState.errors?.fileTypes && t('required*', 'Required*')}
            </StyledFormHelperText>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="p14b">{t('schedule', 'Schedule')}</Typography>
          {fetchingReportSchedule ? (
            <Skeleton variant="rounded" animation="wave" height={48} />
          ) : (
            <FormTextField control={control} name="recurrence" label={t('recurrence', 'Recurrence')} select>
              {Object.keys(recurrenceMap).map((option) => (
                <MenuItem key={option} value={option}>
                  <Typography variant="p16" textTransform="capitalize">
                    {recurrenceMap[option as keyof typeof recurrenceMap]}
                  </Typography>
                </MenuItem>
              ))}
            </FormTextField>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="p14b">{t('sendReportTo', 'Send report to')}</Typography>
          {fetchingReportSchedule ? (
            <Skeleton variant="rounded" animation="wave" height={48} />
          ) : (
            <FormUserEmailAutocomplete control={control} name="recipients" />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="p14b">{t('reportFilters', 'Report filters')}</Typography>
            {anyActiveFilters && (
              <Typography
                variant="p14"
                textAlign="center"
                color="#EB4E20"
                onClick={() => reset()}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                {t('reset', 'Reset')}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={4}
            p="24px 16px"
            borderRadius={4}
            sx={{ backgroundColor: '#F4F4F4' }}
          >
            {filterMenuOptions.map((option) => (
              <ReportScheduleFilterOption key={option.uuid} option={option} />
            ))}
          </Box>
        </Box>
      </Box>
      <Outlet />
    </EditingSidebarBase>
  );
};
