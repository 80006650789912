import { useTranslation } from 'react-i18next';

import { Permission } from 'models/permission.enum';
import { Site } from 'models/site';

import { Typography } from '@mui/material';

import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import { EditableDetailCardBase } from './EditableDetailCardBase';

type SiteDetailsCardProps = {
  site?: Site;
};

export const SiteDetailsCard = ({ site }: SiteDetailsCardProps) => {
  const { t } = useTranslation();
  const canEditSites = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_SITES));
  const pricingLabel = site?.pricingModel?.isAutomatic
    ? t('marketPrice', 'Market price')
    : t('fixedPrice', 'Fixed price');

  return (
    <EditableDetailCardBase title={t('details', 'Details')} editingPath="details" disabled={!canEditSites}>
      <Typography variant="p16">
        {site?.address}
        <br />
        {site?.pricingModel && pricingLabel}
      </Typography>
    </EditableDetailCardBase>
  );
};
