import { Trans, useTranslation } from 'react-i18next';

import { FeatureId } from 'models/subscriptions';
import moment from 'moment';

import { Box, Typography, useTheme } from '@mui/material';

import { Spinner } from '@components/atoms/Spinner';
import { ReactComponent as SuccessIcon } from '@images/icons/Large_success.svg';
import { useGetFeaturesQuery } from '@services/subscriptions/endpoints';

type SubscriptionSidebarWelcomeProps = {
  featureId: FeatureId;
  isLoading: boolean;
};

export const SubscriptionSidebarWelcome = ({ featureId, isLoading }: SubscriptionSidebarWelcomeProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: features } = useGetFeaturesQuery();
  const feature = features?.find((f) => f.featureId === featureId);
  const hasTrial = feature?.subscription?.trialEndsAt && new Date(feature.subscription.trialEndsAt) > new Date();
  const price = feature?.featurePrice.price ? Number(feature.featurePrice.price).toFixed(2) : 0;
  const percentage = feature?.featurePrice.revenueSharePercentage
    ? (Number(feature.featurePrice.revenueSharePercentage) * 100).toFixed(0)
    : 0;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
      {isLoading && <Spinner />}
      {!isLoading && feature?.subscription?.isActive && (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={4}>
          <SuccessIcon />
          <Typography variant="h2" textAlign="center">
            {hasTrial
              ? t('welcomeToYourVoolTrial!', 'Welcome to Your VOOL Trial!')
              : t('welcomeToYourVoolSubscription!', 'Welcome to Your VOOL Subscription!')}
          </Typography>
          <Typography variant="p14" textAlign="center" color={theme.palette.darkGray}>
            {hasTrial ? (
              <Trans
                i18nKey="publicCharging.trialWelcomeMessage"
                defaults="Thank you for signing up for a trial subscription with <1>VOOL</1> on app.vool.com. Your trial period will last until <1>{{date}}</1>. After the trial ends, you will be automatically charged <1>{{price}}€ per connector + {{percentage}}% of revenue/month</1> unless you cancel your subscription before the trial expires."
                components={{
                  1: <Typography variant="p14b" />,
                }}
                values={{
                  date: moment(feature.subscription.trialEndsAt).format('MMM D, YYYY'),
                  price,
                  percentage,
                }}
              />
            ) : (
              <Trans
                i18nKey="publicCharging.subscriptionWelcomeMessage"
                defaults="Thank you for subscribing to <1>VOOL</1> via app.vool.com. Your subscription is now active, and you will be charged <1>{{price}}€ per connector + {{percentage}}% of revenue/month</1>."
                components={{
                  1: <Typography variant="p14b" />,
                }}
                values={{ price, percentage }}
              />
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
