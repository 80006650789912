import { TableCell, TableCellProps } from '@mui/material';

type DurationCellProps = TableCellProps & {
  duration: number;
};

export const DurationCell = ({ duration, ...props }: DurationCellProps) => {
  const seconds = String(duration % 60).padStart(2, '0');
  const minutes = String(Math.floor(duration / 60) % 60).padStart(2, '0');
  const hours = Math.floor(duration / 3600);
  return <TableCell align="right" {...props}>{`${hours}:${minutes}:${seconds}`}</TableCell>;
};
