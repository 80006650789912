import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportingTransaction, TransactionType } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { ReactComponent as ChargerIcon } from '@images/icons/reporting/charger.svg';
import { ReactComponent as PublicChargingIcon } from '@images/icons/reporting/public-charging.svg';
import { ReactComponent as SmartChargingIcon } from '@images/icons/reporting/smart-charging.svg';

export const useTransactionTypeMenu = (transactions?: ReportingTransaction[]) => {
  const { t } = useTranslation();
  const [typeMenuOptions, setTypeMenuOptions] = useState<StyledMenuItemProps[]>([]);

  const optionLabels: Record<TransactionType, string> = {
    [TransactionType.PRIVATE]: t('manualCharging', 'Manual charging'),
    [TransactionType.SMART_CHARGING]: t('smartCharging', 'Smart charging'),
    [TransactionType.PUBLIC]: t('publicCharging', 'Public charging'),
  };

  const options: StyledMenuItemProps[] = [
    {
      uuid: TransactionType.PRIVATE,
      checked: false,
      Icon: ChargerIcon,
      label: optionLabels[TransactionType.PRIVATE],
      onClick: () => toggleOptionChecked(TransactionType.PRIVATE),
    },
    {
      uuid: TransactionType.SMART_CHARGING,
      checked: false,
      Icon: SmartChargingIcon,
      label: optionLabels[TransactionType.SMART_CHARGING],
      onClick: () => toggleOptionChecked(TransactionType.SMART_CHARGING),
    },
    {
      uuid: TransactionType.PUBLIC,
      checked: false,
      Icon: PublicChargingIcon,
      label: optionLabels[TransactionType.PUBLIC],
      onClick: () => toggleOptionChecked(TransactionType.PUBLIC),
    },
  ];

  useEffect(() => {
    setTypeMenuOptions((prevState) =>
      options.map((option) => ({
        ...option,
        checked: prevState?.find((prevOption) => prevOption.uuid === option.uuid)?.checked ?? false,
        resultCount: transactions?.filter((transaction) => transaction.transactionType === option.uuid).length,
      })),
    );
  }, [transactions]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setTypeMenuOptions((prevOptions) =>
      prevOptions?.map((option) => {
        if (option.uuid === selectedOptionUuid) {
          return { ...option, checked: !option.checked };
        }
        return option;
      }),
    );
  };

  return { typeMenuOptions, setTypeMenuOptions };
};
