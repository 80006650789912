import { InviteCompanyUsersDto } from 'models/company';
import { UpdateUserPasswordDto, User, UserFeaturesDto, UserGroupsDto, UserRoleDto } from 'models/user';

import apiSlice from '@services/api';

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<User[], { companyUuid?: string } | void>({
      query: (params) => ({
        url: 'users',
        method: 'GET',
        params: params ?? undefined,
      }),
      transformResponse: (response: { users: User[] }) => response.users,
      providesTags: ['CompanyContext', 'CompanyUsers'],
    }),
    updateUserRole: builder.mutation<User, UserRoleDto>({
      query: ({ uuid, role, companyUuid }) => ({
        url: `users/${uuid}`,
        method: 'PUT',
        body: { role },
        params: { companyUuid },
      }),
      transformResponse: (response: { user: User }) => response.user,
    }),
    updateUserFeatures: builder.mutation<User, UserFeaturesDto>({
      query: ({ uuid, features }) => ({
        url: `users/${uuid}/features`,
        method: 'PUT',
        body: features,
      }),
      transformResponse: (response: { user: User }) => response.user,
    }),
    removeUserFromWorkspace: builder.mutation<
      { success: boolean; userUuid: string },
      { userUuid: string; companyUuid?: string }
    >({
      query: ({ userUuid, companyUuid }) => ({
        url: `users/${userUuid}`,
        method: 'DELETE',
        params: { companyUuid },
      }),
    }),
    inviteUsersToWorkspace: builder.mutation<User[], InviteCompanyUsersDto>({
      query: ({ emails, role, groupUuids, companyUuid }) => ({
        url: 'users/invite',
        method: 'POST',
        body: { emails, role, groupUuids },
        params: { companyUuid },
      }),
      transformResponse: (response: { users: User[] }) => response.users,
    }),
    resendInviteForUser: builder.mutation<{ success: boolean }, { userUuid: string; companyUuid?: string }>({
      query: ({ userUuid, companyUuid }) => ({
        url: 'users/resendInvite',
        method: 'POST',
        body: { userUuid },
        params: { companyUuid },
      }),
    }),
    updateUserPassword: builder.mutation<{ success: boolean; message: string }, UpdateUserPasswordDto>({
      query: (body) => ({
        url: 'users/update-password',
        method: 'PUT',
        body,
      }),
    }),
    getAllUsers: builder.query<User[], void>({
      query: () => ({
        url: 'users/all',
        method: 'GET',
      }),
      transformResponse: (response: { users: User[] }) => response.users,
    }),
    updateUserGroups: builder.mutation<{ success: boolean }, UserGroupsDto>({
      query: ({ uuid, groupUuids, companyUuid }) => ({
        url: `users/${uuid}/groups`,
        method: 'PUT',
        body: { groupUuids },
        params: { companyUuid },
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useUpdateUserRoleMutation,
  useUpdateUserFeaturesMutation,
  useRemoveUserFromWorkspaceMutation,
  useInviteUsersToWorkspaceMutation,
  useResendInviteForUserMutation,
  useUpdateUserPasswordMutation,
  useGetAllUsersQuery,
  useUpdateUserGroupsMutation,
} = usersApi;
