import { RFIDCard, RFIDCardDto } from 'models/rfid-card';

import apiSlice from '@services/api';

export const rfidCardsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRfidCards: builder.query<RFIDCard[], { companyUuid?: string } | void>({
      query: (params) => ({
        url: 'rfidCards',
        method: 'GET',
        params: params ?? undefined,
      }),
      providesTags: ['CompanyContext'],
      transformResponse: (response: { data: RFIDCard[] }) => response.data,
    }),
    getAllRfidCards: builder.query<RFIDCard[], void>({
      query: () => ({
        url: 'rfidCards/all',
        method: 'GET',
      }),
      providesTags: ['PartnerContext'],
      transformResponse: (response: { data: RFIDCard[] }) => response.data,
    }),
    addRfidCard: builder.mutation<RFIDCard, RFIDCardDto & { companyUuid?: string }>({
      query: ({ companyUuid, ...body }) => ({
        url: 'rfidCards',
        method: 'POST',
        body,
        params: { companyUuid },
      }),
    }),
    updateRfidCard: builder.mutation<RFIDCard, RFIDCardDto>({
      query: ({ uuid, ...body }) => ({
        url: `rfidCards/${uuid}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: { data: RFIDCard }) => response.data,
    }),
    deleteRfidCard: builder.mutation<{ success: boolean }, string>({
      query: (uuid) => ({
        url: `rfidCards/${uuid}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetRfidCardsQuery,
  useGetAllRfidCardsQuery,
  useAddRfidCardMutation,
  useUpdateRfidCardMutation,
  useDeleteRfidCardMutation,
} = rfidCardsApi;
