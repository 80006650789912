import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeviceType, VoolDeviceAvailabilityStatus } from 'models/device.enums';

import { useLazyCheckDeviceQuery } from '@services/devices/endpoints';

import { VoolDeviceAvailabilityData } from './VoolDeviceAvailabilityMessage';

enum Manufacturer {
  VOOL = 'vool',
  OTHER = 'other',
  SOLAR = 'solar',
}

type UseVoolDeviceAvailabilityProps = {
  serialNumber: string;
  pin: string;
  manufacturer?: Manufacturer;
};

export const useVoolDeviceAvailability = ({ serialNumber, pin, manufacturer }: UseVoolDeviceAvailabilityProps) => {
  const { t } = useTranslation();

  const [deviceType, setDeviceType] = useState<DeviceType>();
  const [voolDeviceAvailabilityData, setVoolDeviceAvailabilityData] = useState<VoolDeviceAvailabilityData>();
  const isOtherDevice = manufacturer === Manufacturer.OTHER;

  const [checkDevice] = useLazyCheckDeviceQuery();

  useEffect(() => setDeviceType(undefined), [manufacturer, serialNumber]);
  useEffect(() => setVoolDeviceAvailabilityData(undefined), [manufacturer, serialNumber, pin]);

  const handleCheckDevice = async () => {
    if (isOtherDevice || !serialNumber?.trim()) {
      setDeviceType(undefined);
      return;
    }
    try {
      const response = await checkDevice({ serialNumber }).unwrap();
      const { status, type, site } = response;
      if (status === VoolDeviceAvailabilityStatus.AVAILABLE) {
        setDeviceType(type);
      }
      setVoolDeviceAvailabilityData({
        status,
        site,
      });
    } catch (e) {
      setVoolDeviceAvailabilityData({
        status: VoolDeviceAvailabilityStatus.ERROR,
      });
    }
  };

  const getVoolDeviceTypeLabel = () => {
    const deviceTypeToLabel: Partial<Record<DeviceType, string>> = {
      charger: `VOOL ${t('charger', 'Charger')}`,
      lmc: 'VOOL LMC',
    };
    return deviceType ? deviceTypeToLabel[deviceType] : t('unknown', 'Unknown');
  };

  return {
    voolDeviceAvailabilityData,
    deviceType,
    isUnavailable:
      voolDeviceAvailabilityData?.status &&
      [VoolDeviceAvailabilityStatus.IN_USE, VoolDeviceAvailabilityStatus.NO_MATCH].includes(
        voolDeviceAvailabilityData.status,
      ),
    handleCheckDevice,
    getVoolDeviceTypeLabel,
  };
};
