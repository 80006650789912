import { createUseStyles } from 'react-jss';

import classnames from 'classnames';

import { Spinner } from '../atoms/Spinner';

type OverflowSpinnerProps = {
  backgroundColor?: string;
  position?: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  opacity?: number;
  fit?: boolean;
  className?: string;
  size?: React.ComponentProps<typeof Spinner>['size'];
};

type UseStylesProps = Required<Omit<OverflowSpinnerProps, 'className' | 'size'>>;

const DEFAULT: UseStylesProps = {
  backgroundColor: 'white',
  position: 'absolute',
  top: -4,
  bottom: -4,
  left: -4,
  right: -4,
  opacity: 0.8,
  fit: false,
};

const useStyles = ({ backgroundColor, position, top, bottom, left, right, opacity, fit }: UseStylesProps) =>
  createUseStyles({
    background: {
      backgroundColor,
      position,
      top: fit ? 0 : top,
      bottom: fit ? 0 : bottom,
      left: fit ? 0 : left,
      right: fit ? 0 : right,
      opacity,
      marginTop: '0!important',
    },
  })();

export const OverflowSpinner = (props: OverflowSpinnerProps) => {
  const { className = '', size, ...rest } = props;
  const classes = useStyles({ ...DEFAULT, ...rest });

  return (
    <div
      className={classnames(
        'flex',
        'justify-center',
        'items-center',
        'dark:bg-truegray-900',
        'z-30',
        { [classes.background]: true },
        { [className]: true },
      )}
    >
      <Spinner className={className} size={size} />
    </div>
  );
};
