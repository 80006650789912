import { useTranslation } from 'react-i18next';

import { AddSiteDto, Site } from 'models/site';

import { Box, Typography, useTheme } from '@mui/material';

import { FormTextField } from '@components/atoms/FormTextField';
import { StyledAutocomplete } from '@components/atoms/StyledAutocomplete';
import ButtonSwitch from '@components/buttons/ButtonSwitch';
import { GoogleMapsAddressInput } from '@components/google/GoogleMapsAddressInput';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { useActiveCurrency } from '@hooks/useActiveCurrency';

import { FixedPriceInputs } from './sidebarHelpers/FixedPriceInputs';
import { useSiteDetailsForm } from './sidebarHelpers/useSiteDetailsForm';
import '/node_modules/flag-icons/css/flag-icons.min.css';

type SiteDetailsSidebarBaseProps = {
  title: string;
  site?: Site;
  saveLabel?: string;
  discardLabel?: string;
  isSaveLoading: boolean;
  onSaveValues: (values: AddSiteDto) => Promise<boolean>;
  getReturnPath?: () => string | undefined;
};

export const SiteDetailsSidebarBase = ({
  title,
  site,
  saveLabel,
  discardLabel,
  isSaveLoading,
  onSaveValues,
  getReturnPath,
}: SiteDetailsSidebarBaseProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currency = useActiveCurrency();

  const { form, regionOptions, updateFailed, submitSiteDetails, handleSetPricingModelValue } = useSiteDetailsForm({
    site,
    onSaveValues,
  });
  const { formState, control, watch, clearErrors, setValue, setError } = form;

  const pricingModel = watch('pricingModel');
  const address = watch('address');
  const addressJson = watch('addressJson');

  return (
    <EditingSidebarBase
      title={title}
      {...(saveLabel && { saveLabel })}
      {...(discardLabel && { discardLabel })}
      onSaveValues={submitSiteDetails}
      anyDataChanged={formState.isDirty}
      updateLoading={isSaveLoading}
      updateFailed={updateFailed}
      getReturnPath={getReturnPath}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="p16b">{t('details', 'Details')}</Typography>
        <FormTextField control={control} name="name" label={t('name', 'Name')} />
        <GoogleMapsAddressInput
          name="address"
          label={t('address', 'Address')}
          setFormValue={(attr, value) => {
            if (attr === 'address' && typeof value === 'string') {
              setValue('address', value, { shouldDirty: true });
            }
            if (attr === 'addressJson' && typeof value === 'object') {
              setValue('addressJson', value, { shouldDirty: true });
            }
          }}
          required
          address={address}
          addressJson={addressJson}
          isError={Boolean(formState.errors?.address)}
          helpText={formState.errors?.address?.message}
          clearError={(errorToClear) => clearErrors(errorToClear)}
          setError={(errorToSet, newError) => setError(errorToSet, newError)}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="p16b">{t('pricingModel', 'Pricing model')}</Typography>
        <ButtonSwitch
          options={[
            { label: t('market', 'Market'), value: true },
            { label: t('fixed', 'Fixed'), value: false },
          ]}
          value={pricingModel?.isAutomatic}
          onChange={(value) => handleSetPricingModelValue('isAutomatic', value)}
        />
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="p14" color={theme.palette.darkGray}>
            {pricingModel?.isAutomatic
              ? t(
                  'pricingModelMarketText',
                  'Is your electricity charged at market prices? Add your country or region so we can estimate your charge costs accurately.',
                )
              : t(
                  'pricingModelFixedText',
                  'Is your electric contract is based on fixed rates? Add your rate(s) here so we can estimate your charge costs accurately.',
                )}
          </Typography>
          <Typography variant="p14" color={theme.palette.darkGray}>
            {t('allPricesEnteredShouldExcludeVAT', 'All prices entered should exclude VAT.')}
          </Typography>
        </Box>
        {pricingModel?.isAutomatic ? (
          <StyledAutocomplete
            label={t('country', 'Country')}
            options={regionOptions}
            value={regionOptions.find((option) => option.value === pricingModel?.region) ?? null}
            error={{
              message: formState.errors?.pricingModel?.region?.message,
            }}
            onChange={(region) => handleSetPricingModelValue('region', region?.value)}
          />
        ) : (
          <FixedPriceInputs
            form={form}
            name={pricingModel?.isSinglePrice ? 'singlePrice' : 'dualPrice'}
            costItemLabel={t('energy', 'Energy')}
          />
        )}
      </Box>
      <Box display="flex" flexDirection="column" gap={1} mt={5}>
        <FixedPriceInputs
          form={form}
          name={pricingModel?.isSingleTransmission ? 'transmissionSinglePrice' : 'transmissionDualPrice'}
          costItemLabel={t('transmission', 'Transmission')}
        />
        <FormTextField
          control={control}
          name="pricingModel.extraFees"
          type="number"
          label={`${t('otherFeesAndTaxes', 'Other fees and taxes')} (${t('optional', 'Optional').toLowerCase()})`}
          InputProps={{
            endAdornment: (
              <Typography variant="p14" color={theme.palette.darkGray}>
                {currency.centsName?.toLowerCase()}
                /kWh
              </Typography>
            ),
          }}
          sx={{ marginTop: '20px !important' }}
        />
      </Box>
    </EditingSidebarBase>
  );
};
