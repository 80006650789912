import { useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useTranslation } from 'react-i18next';

import { ErrorCode } from 'models/errorCode.enum';
import PropTypes from 'prop-types';

import Button from '@components/atoms/Button';
import { Input } from '@components/atoms/Input';
import { googleLoginUrl } from '@config/consts';
import { trackAmplitudeEvent } from '@handlers/amplitude';
import { AmplitudeEvent, AmplitudeLoginMethod } from '@handlers/amplitude/data';
import { getAmplitudeEventPropertiesForUser } from '@handlers/amplitude/data';
import googleIcon from '@images/icons/google.svg';
import apiSlice from '@services/api';

const GoogleAuth = ({ onSuccess, inviteData }) => {
  const { t } = useTranslation();
  const [googleLogin] = apiSlice.useGoogleLoginMutation();
  const [passwordNeeded, setPasswordNeeded] = useState(false);
  const [googleLoginError, setGoogleLoginError] = useState();
  const [password, setPassword] = useState();
  const googleAuth = async ({ profileObj = {}, tokenObj: { id_token: idToken } }) => {
    setGoogleLoginError();
    setPassword();

    const { givenName, familyName, email } = profileObj;

    try {
      const { error, data } = await googleLogin({
        name: `${givenName || ''} ${familyName || ''}`,
        ...(inviteData ? { inviteToken: inviteData.inviteToken } : { email }),
        password,
        idToken,
      });

      if (error?.data?.message) {
        if (error?.data?.message?.toLowerCase()?.includes('password needed')) {
          setPasswordNeeded(true);
        }

        if (error?.data?.message?.toLowerCase()?.includes('login failed')) {
          setGoogleLoginError(t('emailOrPasswordIncorrectErrorText', 'Entered email or password is incorrect'));
        }

        if (error?.data?.errorCode === ErrorCode.UNAUTHORIZED_PARTNER) {
          setGoogleLoginError(t('partnerErrorText', 'You are not a partner'));
        }
      } else {
        onSuccess();
        trackAmplitudeEvent(AmplitudeEvent.LOGIN, {
          ...getAmplitudeEventPropertiesForUser(data.user),
          method: AmplitudeLoginMethod.GOOGLE,
        });
      }
    } catch (e) {
      setGoogleLoginError(e);
    }
  };

  return (
    <>
      {googleLoginError && (
        <>
          <div className="mt-3 text-sm font-medium text-vermillion">
            {t('somethingWentWrong', 'Something went wrong')}.
          </div>
          <div className="mb-3 text-xs text-gray-400">{`Error: ${googleLoginError}`}</div>
        </>
      )}
      <GoogleLogin
        disabled={passwordNeeded}
        clientId={googleLoginUrl}
        loginHint={inviteData?.email}
        buttonText=""
        onSuccess={googleAuth}
        // onFailure={(response) => { debugger }}
        cookiePolicy="single_host_origin"
        className="w-full !border-none !shadow-none [&>span]:w-full [&>span]:!p-0"
        icon={false}
      >
        <div className="flex h-14 w-full items-center justify-center space-x-2 rounded-full bg-gray-100 hover:bg-gray-200">
          <img src={googleIcon} alt="Google" />
          <span className="w-auto font-poppins text-base font-semibold text-black sm:flex">
            {t('continueWithGoogle', 'Continue with Google')}
          </span>
        </div>
      </GoogleLogin>
      {passwordNeeded && (
        <div className="w-full">
          <div className="mb-3 mt-4 text-xs font-medium text-gray-600">
            {t(
              'googleAuthPasswordNeededText',
              'Existing account found with your Google email. To confirm that this is your account, please enter account’s password:',
            )}
          </div>
          <Input
            type="password"
            error={!!googleLoginError}
            label={t('password', 'Password')}
            value={password}
            onChange={({ target: { value } }) => {
              setGoogleLoginError();
              setPassword(value);
            }}
          />
          <Button className="mt-3 w-full" disabled={!password} onClick={googleAuth}>
            {t('confirm', 'Confirm')}
          </Button>
          <button
            type="button"
            className="mt-3 w-full text-xs text-gray-400"
            onClick={() => {
              setPasswordNeeded(false);
              setPassword();
              setGoogleLoginError();
            }}
          >
            {t('cancel', 'Cancel')}
          </button>
        </div>
      )}
    </>
  );
};

GoogleAuth.propTypes = {
  inviteData: PropTypes.shape({
    email: PropTypes.string,
    inviteToken: PropTypes.string,
  }),
  onSuccess: PropTypes.func,
};

GoogleAuth.defaultProps = {
  inviteData: null,
  onSuccess: () => {},
};

export default GoogleAuth;
