import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';

import { isNil } from 'lodash';
import { ConnectorComputedStatus, ConnectorMeterValueKey, DeviceStatus, PowerType } from 'models/device.enums';

import { MultiEnergyGraphWithPolling } from '@components/multipleEnergySupplied/MultiEnergyGraphWithPolling';
import { DeviceOutletContextType } from '@views/Devices/DeviceOutletContextType';
import { UnifiedConnector, getUnifiedConnectors } from '@views/Devices/connectorsUtil';

import { ConnectorTypeIcon } from './ConnectorTypeIcon';
import { CurrVoltRadialChart } from './CurrVoltRadialChart';
import { RadialChart } from './RadialChart';
import { CHART_BACKGROUND_COLORS, CHART_COLORS, useComputedStatusDefs } from './useComputedStatusDefs';

export const ChargerActivity = () => {
  const { t } = useTranslation();
  const { device: charger } = useOutletContext<DeviceOutletContextType>();
  const isOffline = charger?.status === DeviceStatus.OFFLINE;
  const unifiedConnectors = getUnifiedConnectors(charger);
  const computedStatusDefs = useComputedStatusDefs();

  const getPhasesData = (connector: UnifiedConnector) => {
    return [
      {
        key: 'L1',
        current: connector[ConnectorMeterValueKey.CURRENT_L1],
        voltage: connector[ConnectorMeterValueKey.VOLTAGE_L1],
      },
      {
        key: 'L2',
        current: connector[ConnectorMeterValueKey.CURRENT_L2],
        voltage: connector[ConnectorMeterValueKey.VOLTAGE_L2],
      },
      {
        key: 'L3',
        current: connector[ConnectorMeterValueKey.CURRENT_L3],
        voltage: connector[ConnectorMeterValueKey.VOLTAGE_L3],
      },
    ];
  };

  const isConnectorAvailable = (connector: UnifiedConnector) =>
    ![
      ConnectorComputedStatus.FAULTED,
      ConnectorComputedStatus.NEVER_CONNECTED,
      ConnectorComputedStatus.OFFLINE,
    ].includes(connector.computedStatus);

  const getCommonData = (connector: UnifiedConnector) => {
    const { colorClassName, chartColor, statusLabel, isDark } = computedStatusDefs[connector.computedStatus];
    return {
      icon: () => (
        <ConnectorTypeIcon
          connectorId={connector.connectorId}
          connectorType={connector.connectorType}
          label={t('connector', 'Connector')}
          className="w-32 flex-row-reverse items-center gap-x-2 py-2"
        />
      ),
      statusLabel,
      dark: !!isDark,
      getBackgroundStyle: (index?: number) =>
        !isNil(index) && isConnectorAvailable(connector) ? CHART_BACKGROUND_COLORS[index] : colorClassName,
      getChartColor: (index?: number) =>
        !isNil(index) && isConnectorAvailable(connector) ? CHART_COLORS[index] : chartColor,
    };
  };

  return (
    <div className="flex w-full flex-col items-start">
      <h1 className="pb-4 pt-2 font-poppins text-lg font-semibold">{t('currentActivity', 'Current activity')}</h1>

      <div className="mb-4 flex h-full w-full flex-col gap-4 md:flex-row">
        {(() => {
          if (unifiedConnectors.length <= 1) {
            const [connector] = unifiedConnectors;
            const phasesData = getPhasesData(connector);
            const commonData = getCommonData(connector);
            return phasesData.map((phase, index) => (
              <div className="h-[256px] w-full" key={phase.key}>
                <CurrVoltRadialChart
                  deviceType={charger.type}
                  current={phase.current}
                  voltage={phase.voltage}
                  max={Number(charger?.gridConnection) || 16}
                  offline={isOffline}
                  color={commonData.getChartColor(index)}
                  backgroundColor={commonData.getBackgroundStyle(index)}
                  label={phase.key}
                  dark={commonData.dark}
                />
              </div>
            ));
          }
          return unifiedConnectors.map((connector) => {
            const commonData = getCommonData(connector);
            return (
              <div className="h-[256px] w-full" key={connector.connectorId}>
                <div className="flex h-full flex-col gap-y-2">
                  <commonData.icon />
                  {connector.powerType === PowerType.AC ? (
                    getPhasesData(connector).map((phase, index) => (
                      <CurrVoltRadialChart
                        key={phase.key}
                        current={phase.current}
                        voltage={phase.voltage}
                        label={phase.key}
                        max={Number(charger?.gridConnection) || 16}
                        offline={isOffline}
                        color={commonData.getChartColor(index)}
                        backgroundColor={commonData.getBackgroundStyle(index)}
                        dark={commonData.dark}
                        variant="small"
                      />
                    ))
                  ) : (
                    <RadialChart
                      label="kW"
                      extraLabel={commonData.statusLabel}
                      max={connector.maxPowerKw}
                      value={connector.active_power}
                      extraData={{
                        A: connector.current,
                        V: connector.voltage,
                      }}
                      offline={isOffline}
                      color={commonData.getChartColor()}
                      backgroundColor={commonData.getBackgroundStyle()}
                      dark={commonData.dark}
                    />
                  )}
                </div>
              </div>
            );
          });
        })()}
      </div>
      <MultiEnergyGraphWithPolling deviceSerialNr={charger.serialNumber!} />
      <Outlet />
    </div>
  );
};
