import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import PropTypes from 'prop-types';

import { CHARGE_STATUSES } from '@handlers/vehicle/constVehicle';
import { VehicleStatusInfo } from '@views/Vehicles/VehiclesTable/vehicleDataComponents';

const statusColors = {
  [CHARGE_STATUSES.CHARGING]: '#EB4E20',
  [CHARGE_STATUSES.CONNECTED]: '#407090',
  [CHARGE_STATUSES.NOT_CONNECTED]: '#386957',
};

const bgStatusColorClasses = {
  [CHARGE_STATUSES.CHARGING]: 'bg-vermillion-200',
  [CHARGE_STATUSES.CONNECTED]: '#bg-queen-200',
  [CHARGE_STATUSES.NOT_CONNECTED]: 'bg-forest-200',
  [CHARGE_STATUSES.UNKNOWN]: 'bg-white',
};

const VehicleBattery = ({ vehicle }) => {
  const { t } = useTranslation();
  const status = CHARGE_STATUSES[vehicle?.status?.chargeStatus] || CHARGE_STATUSES.UNKNOWN;
  const batteryLevel = vehicle?.status?.batteryLevel || 0;

  const statusColor = statusColors[status] || statusColors[CHARGE_STATUSES.NOT_CONNECTED];
  const bgStatusColorClass = bgStatusColorClasses[status] || bgStatusColorClasses[CHARGE_STATUSES.UNKNOWN];
  const formattedUpdateTime = moment(vehicle?.data?.format?.timestamp).format('MMM DD, YYYY HH:mm');

  return (
    <div className={`flex min-w-max flex-col items-center rounded-2xl p-4 ${bgStatusColorClass}`}>
      <div className="h-20 w-20">
        <CircularProgressbar
          value={batteryLevel}
          minValue={0}
          maxValue={100}
          text={`${batteryLevel}%`}
          styles={{
            text: {
              fill: statusColor,
              fontSize: '30px',
              fontWeight: '600',
            },
            path: {
              stroke: statusColor,
            },
            trail: {
              stroke: '#D6D6D6',
            },
          }}
        />
      </div>
      <VehicleStatusInfo vehicle={{ ...vehicle, status }} className="w-full justify-center" />
      <div className="px-2 font-poppins text-xs text-gray-400">
        {t('lastUpdate', 'Last update')}: {formattedUpdateTime}
      </div>
    </div>
  );
};

VehicleBattery.propTypes = {
  vehicle: PropTypes.object.isRequired,
};

export default VehicleBattery;
