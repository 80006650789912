const buildDataEndpoints = (builder) => ({
  vehicles: builder.query({
    query: (params) => ({
      url: '/cars',
      method: 'GET',
      params,
    }),
    providesTags: ['CompanyContext', 'Vehicle'],
  }),
  getVehicle: builder.query({
    query: ({ uuid }) => ({
      url: `/cars/${uuid}`,
      method: 'GET',
    }),
  }),
  updateVehicle: builder.mutation({
    query: ({ uuid, batteryCapacity, name, siteUuid, privateFlag }) => ({
      url: `/cars/${uuid}`,
      method: 'PUT',
      body: {
        name,
        siteUuid,
        batteryCapacity,
        privateFlag,
      },
    }),
    invalidatesTags: ['Vehicle'],
  }),
  getLoginLinkPageVehicle: builder.mutation({
    query: (body) => ({
      url: '/cars/login-page',
      method: 'POST',
      body,
    }),
  }),
  connectVehicleAccount: builder.mutation({
    query: (body) => ({
      url: '/cars/connect-account',
      method: 'POST',
      body,
    }),
  }),
  deleteVehicleAccount: builder.mutation({
    query: (uuid) => ({
      url: `/cars/deauthorize/${uuid}`,
      method: 'DELETE',
    }),
  }),
  linkedAccounts: builder.query({
    query: () => ({
      url: '/cars/accounts',
      method: 'GET',
    }),
  }),
  getVehiclesNotLinkedByBrand: builder.mutation({
    query: ({ provider, brand }) => ({
      url: `/cars/not-linked/${provider}/${brand}`,
      method: 'GET',
    }),
  }),
  addVehicles: builder.mutation({
    query: ({ brand, vins }) => ({
      url: '/cars',
      method: 'POST',
      body: {
        brand,
        vinCodes: vins,
      },
    }),
  }),
  deleteVehicle: builder.mutation({
    query: (uuid) => ({
      url: `/cars/${uuid}`,
      method: 'DELETE',
    }),
  }),
  startStopChargingVehicle: builder.mutation({
    query: ({ carUuid, status }) => ({
      url: '/cars/charging',
      method: 'POST',
      body: {
        carUuid,
        status,
      },
    }),
  }),
  startSmartChargingSession: builder.mutation({
    query: ({ carUuid, siteUuid, scheduledEndTime }) => ({
      url: '/chargingSessions',
      method: 'POST',
      body: {
        carUuid,
        siteUuid,
        scheduledEndTime,
      },
    }),
  }),
  getSmartChargingSessions: builder.query({
    query: (params) => ({
      url: '/chargingSessions',
      method: 'GET',
      params,
    }),
  }),
  getCurrentSmartChargingSession: builder.query({
    query: ({ carUuid }) => ({
      url: `/chargingSessions/current?carUuid=${carUuid} `,
      method: 'GET',
    }),
  }),
  stopSmartChargingSession: builder.mutation({
    query: (uuid) => ({
      url: `/chargingSessions/${uuid}/stop`,
      method: 'POST',
    }),
  }),
  getChargingSessionsLog: builder.query({
    query: ({ carUuid }) => ({
      url: `/chargingSessions/log?carUuid=${carUuid}`,
      method: 'GET',
    }),
  }),
  getSmartChargingSessionsSummary: builder.query({
    query: (params) => ({
      url: '/chargingSessions/summary',
      method: 'GET',
      params,
    }),
  }),
  getVehiclesMileageSummary: builder.query({
    query: (params) => ({
      url: '/cars/summary',
      method: 'GET',
      params,
    }),
  }),
});

export default buildDataEndpoints;
