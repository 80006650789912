import { useEffect, useState } from 'react';

import { EditingSidebarControllableBase, EditingSidebarControllableBaseProps } from './EditingSidebarControllableBase';

export const EditingSidebarBase = (props: Omit<EditingSidebarControllableBaseProps, 'isOpen' | 'onClose'>) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(true), []); // needed to enable slide-in transition

  return <EditingSidebarControllableBase isOpen={isOpen} onClose={() => setIsOpen(false)} {...props} />;
};
