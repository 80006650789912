import { Menu, PaperProps, PopoverOrigin } from '@mui/material';

export type MenuContainerProps = {
  anchorEl?: HTMLElement;
  isMobile?: boolean;
  width?: string;
  marginTop?: string;
  horizontalOrigin?: PopoverOrigin['horizontal'];
  paperProps?: PaperProps;
  children: React.ReactNode;
  onClose: () => void;
};

export const MenuContainer = ({
  anchorEl,
  isMobile,
  width,
  marginTop,
  horizontalOrigin,
  paperProps,
  children,
  onClose,
}: MenuContainerProps) => {
  const open = Boolean(anchorEl);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      // disable transition because Virtuoso is not working properly with it
      // container size is incorreclty measured during transition
      transitionDuration={0}
      onClose={(e: Event) => {
        e?.stopPropagation();
        onClose();
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: horizontalOrigin ?? 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: horizontalOrigin ?? 'right',
      }}
      marginThreshold={0}
      slotProps={{
        paper: {
          ...paperProps,
          sx: {
            ...(isMobile
              ? {
                  width: '100%',
                  maxWidth: '100%',
                  height: '100%',
                  maxHeight: '100%',
                  top: '0 !important',
                  left: '0 !important',
                  paddingBottom: '16px',
                }
              : {
                  width,
                  marginTop: marginTop ?? '4px',
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  borderRadius: '8px',
                  border: '1px solid #D6D6D6',
                  boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
                }),
            ...paperProps?.sx,
          },
        },
      }}
      MenuListProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          padding: 0,
        },
      }}
    >
      {children}
    </Menu>
  );
};
