import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EventLogDto } from 'models/event-log';

import { TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { LocationCell } from '@views/reporting/table/LocationCell';
import { StartTimeCell } from '@views/reporting/table/StartTimeCell';

import { CategoryCell } from './CategoryCell';
import { EventLogTableRowSkeleton, EventLogTableSkeleton } from './EventLogTableSkeleton';
import { EventLogContext } from './context/EventLogProvider';

type EventLogColumnData = ColumnData<EventLogDto>;

const useColumns = (): EventLogColumnData[] => {
  const { t } = useTranslation();

  const columns: EventLogColumnData[] = [
    {
      width: 100,
      label: t('time', 'Time'),
      subtitle: t('date', 'Date'),
      dataKey: 'addTime',
      sortable: true,
    },
    {
      width: 80,
      label: t('category', 'Category'),
      dataKey: 'type',
    },
    {
      width: 260,
      label: t('message', 'Message'),
      dataKey: 'message',
    },
    {
      width: 160,
      label: t('site', 'Site'),
      subtitle: t('charger', 'Charger'),
      dataKey: 'siteName',
    },
  ];

  return columns;
};

const renderRows = (rows: EventLogDto[], columns: EventLogColumnData[]) => {
  return rows.map((row) => (
    <StyledTableRow key={row.id}>
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'addTime':
            return <StartTimeCell key={column.dataKey} startTime={row.addTime} width={column.width} />;
          case 'type':
            return <CategoryCell key={column.dataKey} type={row.type} />;
          case 'siteName':
            return (
              <LocationCell
                key={column.dataKey}
                siteName={row.siteName}
                deviceName={row.deviceName}
                width={column.width}
              />
            );
          case 'menu':
            return null;
          default:
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Typography variant="p14">{String(row[column.dataKey])}</Typography>
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  ));
};

export const EventLogMaterialTable = () => {
  const { data, dataLoading, loadMore, order, orderBy, handleRequestSort } = useContext(EventLogContext);

  return (
    <MaterialTable
      context={EventLogContext}
      data={data}
      isLoading={dataLoading}
      headerProps={{
        columns: useColumns(),
        onRequestSort: handleRequestSort,
        order,
        orderBy,
      }}
      renderRows={renderRows}
      loadMore={loadMore}
      loaders={{ TableLoader: EventLogTableSkeleton, RowLoader: EventLogTableRowSkeleton }}
    />
  );
};
