import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceFirmwareUpdateStatus, DeviceOcppActionOption } from 'models/device.enums';
import moment from 'moment';

import Button from '@components/atoms/Button';
import { MessageBar, MessageLevel } from '@components/messages/MessageBar';
import { useUpdateDeviceMutation } from '@services/devices/endpoints';

import { DeviceOcppTimer } from './DeviceOcppTimer';

type DeviceOcppMessageProps = {
  device: Device;
  deviceOcppAction?: DeviceOcppActionOption;
  onClose: () => void;
  onUpdateAgain: () => void;
};

export const DeviceOcppMessage = ({ device, deviceOcppAction, onClose, onUpdateAgain }: DeviceOcppMessageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { firmwareUpdateStatus, firmwareVersion, firmwareUpdateStatusTime } = device;

  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateTakingTooLong, setIsUpdateTakingTooLong] = useState(false);

  const isBeingUpdated =
    !!firmwareUpdateStatus && Object.values(DeviceFirmwareUpdateStatus).includes(firmwareUpdateStatus);

  const [updateDevice] = useUpdateDeviceMutation();

  const handleClose = () => {
    if (isBeingUpdated) {
      setIsUpdateTakingTooLong(false);
      updateDevice({
        uuid: device.uuid,
        firmwareUpdateStatus: null,
      });
    }
    setIsOpen(false);
    onClose();
  };

  const navigateToDeviceLogs = () => navigate(`/logs/?deviceUuid=${device.uuid}`);

  const ocppMessage = useMemo(() => {
    if (firmwareUpdateStatus?.includes('Failed')) {
      return {
        level: MessageLevel.WARNING,
        closeAvailable: true,
        Children: () => (
          <>
            <span>
              {t('updatingTheDeviceFailed', 'Updating the device {{deviceName}} failed', { deviceName: '' })}.
            </span>
            <Button className="-my-2 !px-2" variant="link" onClick={onUpdateAgain}>
              {t('tryAgain', 'Try again')}
            </Button>
            |
            <Button className="-my-2 !px-2" variant="link" onClick={navigateToDeviceLogs}>
              {t('viewLogs', 'View logs')}
            </Button>
          </>
        ),
        timeInfo: !!firmwareUpdateStatusTime && moment.utc(firmwareUpdateStatusTime).local().format('DD.MM.YY, HH:mm'),
      };
    }

    if (firmwareUpdateStatus === DeviceFirmwareUpdateStatus.INSTALLED) {
      return {
        level: MessageLevel.SUCCESS,
        closeAvailable: true,
        Children: () => (
          <>
            {firmwareVersion
              ? t('deviceWasUpdatedToVersion', 'The device was updated to {{version}}.', { version: firmwareVersion })
              : t('deviceWasUpdated', 'The device was updated.')}
            <Button className="-my-2 !px-2 !text-forest" variant="link" onClick={navigateToDeviceLogs}>
              {t('viewLogs', 'View logs')}
            </Button>
          </>
        ),
        timeInfo: !!firmwareUpdateStatusTime && moment.utc(firmwareUpdateStatusTime).local().format('DD.MM.YY, HH:mm'),
      };
    }

    if (firmwareUpdateStatus === DeviceFirmwareUpdateStatus.INSTALL_REBOOTING) {
      return {
        level: MessageLevel.INFO,
        Children: () => <>{t('deviceHasUpdatedAndRebooting', 'The device has updated and is rebooting.')}</>,
      };
    }

    if (firmwareUpdateStatus?.includes('Install') || firmwareUpdateStatus?.includes('Download')) {
      return {
        level: MessageLevel.INFO,
        closeAvailable: isUpdateTakingTooLong,
        Children: () => (
          <>
            {isUpdateTakingTooLong
              ? t(
                  'deviceIsBeingUpdatedButTakingLonger',
                  'The device is being updated, but taking longer than expected. Please check the device.',
                )
              : t('deviceIsBeingUpdated', 'The device is being updated. This usually takes ~6–9 minutes.')}
          </>
        ),
        timeInfo: !!firmwareUpdateStatusTime && (
          <DeviceOcppTimer
            startTime={firmwareUpdateStatusTime}
            longTimeAlertDisabled={isUpdateTakingTooLong}
            onLongTimeAlert={() => setIsUpdateTakingTooLong(true)}
          />
        ),
      };
    }

    return {
      level: MessageLevel.INFO,
      closeAvailable: true,
      Children: () => (
        <>
          {t('singleDeviceBaseText', 'The device is being')} {deviceOcppAction && t(deviceOcppAction, deviceOcppAction)}
          .
        </>
      ),
    };
  }, [firmwareUpdateStatus, firmwareVersion, deviceOcppAction, isUpdateTakingTooLong]);

  useEffect(() => {
    setIsOpen(!!(deviceOcppAction === DeviceOcppActionOption.OCPP_RESET || isBeingUpdated));
  }, [deviceOcppAction, firmwareUpdateStatus]);

  if (!isOpen) {
    return null;
  }

  return (
    <MessageBar level={ocppMessage.level} onClose={ocppMessage.closeAvailable ? handleClose : undefined}>
      {ocppMessage.timeInfo && <div className="float-right font-semibold">{ocppMessage.timeInfo}</div>}
      <ocppMessage.Children />
    </MessageBar>
  );
};
