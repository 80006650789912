import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { Car } from 'models/car';
import { VehicleModalOption } from 'models/car.enum';

import Button from '@components/atoms/Button';
import NoItemsCard from '@components/cards/NoItemsCard';
import { selectActiveCompanyUuid } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';
import { selectPrivateVehiclesOrByCompanyUuid } from '@services/vehicles/selectors';
import { DeleteVehicleModal } from '@views/Vehicles/VehicleView/DeleteVehicleModal';

import { SortedVehiclesTable } from './SortedVehiclesTable';

type VehiclesTableProps = {
  onAddVehicle: () => Promise<void>;
};

export const VehiclesTable = ({ onAddVehicle }: VehiclesTableProps) => {
  const { t } = useTranslation();
  const { vehicleUuid: selectedVehicleUuidFromUrl } = useParams();
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const vehicles = useAppSelector((state) => selectPrivateVehiclesOrByCompanyUuid(state, activeCompanyUuid));

  const [selectedVehicle, setSelectedVehicle] = useState<Car>();
  const [modalOption, setModalOption] = useState<VehicleModalOption>();

  const selectedVehicleFromUrl = useMemo<Car | undefined>(
    () => vehicles.find((vehicle) => vehicle.uuid === selectedVehicleUuidFromUrl),
    [selectedVehicleUuidFromUrl],
  );

  return (
    <>
      {!!vehicles.length && (
        <Button variant="primary" className="float-right -mt-0.5" onClick={onAddVehicle}>
          {t('addVehicle', 'Add a Vehicle')}
        </Button>
      )}
      <h2 className="mb-4 mt-8 font-poppins text-lg font-semibold leading-8">{t('vehicles', 'Vehicles')}</h2>

      {!vehicles.length ? (
        <NoItemsCard
          message={t('noVehiclesText', 'There are currently no vehicles.')}
          buttonLabel={t('addVehicle', 'Add a Vehicle')}
          onButtonClick={onAddVehicle}
        />
      ) : (
        <SortedVehiclesTable
          vehicles={vehicles}
          selectedVehicle={selectedVehicle || selectedVehicleFromUrl}
          onVehicleModalOptionSelected={(option, vehicle) => {
            setModalOption(option);
            setSelectedVehicle(vehicle);
          }}
        />
      )}
      {selectedVehicle && (
        <DeleteVehicleModal
          vehicle={selectedVehicle}
          isOpen={modalOption === VehicleModalOption.DELETE}
          onClose={() => setModalOption(undefined)}
        />
      )}
      <Outlet />
    </>
  );
};
