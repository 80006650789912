import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PayoutType } from 'models/payment.enums';

import { useForm } from '@hooks/useTypedForm';
import { useGetCompanyPayoutDetailsQuery, useSaveCompanyPayoutDetailsMutation } from '@services/companies/endpoints';
import { selectActiveCompanyUuid } from '@services/companies/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { PayoutDetailsForm, getPayoutDetailsDefaultValues, usePayoutDetailsSchema } from './usePayoutDetailsSchema';

export type CompanyPayoutDetails = {
  companyUuid: string;
  payoutType: string;
  accountName: string;
  iban: string;
  swift: string;
  addressJson: {
    streetAddress: string;
    apt: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  };
  stripePaymentMethodId: string;
};

export const usePayoutDetailsForm = () => {
  const { t } = useTranslation();
  const schema = usePayoutDetailsSchema();
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const dispatch = useAppDispatch();
  const form = useForm<PayoutDetailsForm>({
    schema,
    defaultValues: getPayoutDetailsDefaultValues(activeCompanyUuid!),
  });
  const { handleSubmitAndResolve, reset, watch } = form;

  const [payoutType, stripePaymentMethodId] = watch(['payoutType', 'cardDetails.stripePaymentMethodId']);
  const cardIsNotSelected = payoutType === PayoutType.CARD && !stripePaymentMethodId;

  const { data: companyPayoutDetailsResponse, isLoading: isCompanyPayoutDetailsRetrieveLoading } =
    useGetCompanyPayoutDetailsQuery<{
      data: CompanyPayoutDetails;
      isLoading: boolean;
    }>(undefined, { refetchOnMountOrArgChange: true });

  const [saveCompanyPayoutDetails, { isLoading: isPayoutDetailsSaveLoading }] = useSaveCompanyPayoutDetailsMutation();

  const submit: (data: PayoutDetailsForm) => Promise<boolean> = async (data) => {
    try {
      const companyPayoutDetails: CompanyPayoutDetails = {
        companyUuid: data.companyUuid,
        payoutType: data.payoutType,
        ...data.bankAccountDetails,
        ...data.cardDetails,
      };
      await saveCompanyPayoutDetails(companyPayoutDetails).unwrap();
      dispatch(addToastMessage({ type: 'success', title: t('payoutDetailsSaved', 'Payout details saved') }));
      return true;
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('savingPayoutDetailsFailed', 'Saving payout details failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  useEffect(() => {
    if (companyPayoutDetailsResponse) {
      reset({
        companyUuid: activeCompanyUuid,
        payoutType: companyPayoutDetailsResponse.payoutType,
        bankAccountDetails: {
          accountName: companyPayoutDetailsResponse.accountName,
          iban: companyPayoutDetailsResponse.iban,
          swift: companyPayoutDetailsResponse.swift,
          addressJson: companyPayoutDetailsResponse.addressJson,
        },
        cardDetails: {
          stripePaymentMethodId: companyPayoutDetailsResponse.stripePaymentMethodId,
        },
      });
    }
  }, [companyPayoutDetailsResponse]);

  const submitPayoutDetails = handleSubmitAndResolve(submit);

  return {
    form,
    isLoading: isCompanyPayoutDetailsRetrieveLoading,
    isSaving: isPayoutDetailsSaveLoading,
    saveDisabled: isCompanyPayoutDetailsRetrieveLoading || cardIsNotSelected,
    submitPayoutDetails,
  };
};
