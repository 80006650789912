import { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';

type WorkspaceSetupSidebarContextType = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  anyChanges: boolean;
  setAnyChanges: Dispatch<SetStateAction<boolean>>;
  closeRequested: boolean;
  setCloseRequested: Dispatch<SetStateAction<boolean>>;
  closeSidebar: boolean;
  setCloseSidebar: Dispatch<SetStateAction<boolean>>;
};

export const WorkspaceSetupSidebarContext = createContext<WorkspaceSetupSidebarContextType>({
  isOpen: false,
  setIsOpen: () => {},
  anyChanges: false,
  setAnyChanges: () => {},
  closeRequested: false,
  setCloseRequested: () => {},
  closeSidebar: false,
  setCloseSidebar: () => {},
});

type WorkspaceSetupSidebarProviderProps = {
  children: React.ReactNode;
};

export const WorkspaceSetupSidebarProvider = ({ children }: WorkspaceSetupSidebarProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [anyChanges, setAnyChanges] = useState(false);
  const [closeRequested, setCloseRequested] = useState(false);
  const [closeSidebar, setCloseSidebar] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      anyChanges,
      setAnyChanges,
      closeRequested,
      setCloseRequested,
      closeSidebar,
      setCloseSidebar,
    }),
    [isOpen, anyChanges, closeRequested, closeSidebar],
  );

  return <WorkspaceSetupSidebarContext.Provider value={value}>{children}</WorkspaceSetupSidebarContext.Provider>;
};
