import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { XIcon } from '@heroicons/react/solid';
import { mdiCookie } from '@mdi/js';
import Icon from '@mdi/react';

const CookieBanner = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className="center-cookie-banner fixed inset-x-0 top-16 z-50 mx-auto flex max-w-4xl
               rounded-lg bg-primary p-2 px-2 shadow-lg sm:top-8 sm:p-3 sm:px-6 lg:px-8"
    >
      <div className="flex flex-1 items-center">
        <span className="flex rounded-lg bg-primary p-2">
          <Icon path={mdiCookie} size="24px" className="text-white" />
        </span>
        <p className="ml-3 text-sm text-white">
          <span>{t('acceptCookie', 'By using our services you accept our Cookies')}</span>
        </p>
      </div>
      <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
        <button
          type="button"
          className="-mr-1 flex rounded-md p-2 hover:bg-primary focus:outline-none focus:ring-2 focus:ring-white"
          onClick={onClose}
        >
          <span className="sr-only">Dismiss</span>
          <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

CookieBanner.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CookieBanner;
