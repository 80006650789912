import { useTranslation } from 'react-i18next';

import { useInviteTokenFromQueryParam } from '../Welcome/inviteTokenHook';
import RegisterBox from './RegisterBox';

const Register = () => {
  const { t } = useTranslation();
  const { inviteData, outcomeComponent: OutcomeComponent, inviteHeader: InviteHeader } = useInviteTokenFromQueryParam();
  if (OutcomeComponent) {
    return <OutcomeComponent />;
  }
  return (
    <div className="w-11/12 max-w-md space-y-6 rounded-lg bg-white p-9 md:w-full">
      {InviteHeader ? (
        <InviteHeader />
      ) : (
        <>
          <div className="w-full">
            <div className="font-everett text-3xl">{t('registerTitle', 'Getting started')}</div>
            <span className="font-poppins">
              {t('registerText', 'We love new faces around here!')} <br />
              {t('registerSubText', 'Let’s set you up for charging.')}
            </span>
          </div>
        </>
      )}
      <RegisterBox inviteData={inviteData} />
    </div>
  );
};

export default Register;
