import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';

import { range, uniq } from 'lodash';
import { ConnectorMeterValueKey, DeviceStatus } from 'models/device.enums';

import { CHART_COLORS } from '@components/energySupplied/energySuppliedChart';
import { MultiEnergyGraphWithPolling } from '@components/multipleEnergySupplied/MultiEnergyGraphWithPolling';
import { CurrVoltRadialChart } from '@views/Chargers/CurrVoltRadialChart';
import { CHART_BACKGROUND_COLORS } from '@views/Chargers/useComputedStatusDefs';
import { DeviceOutletContextType } from '@views/Devices/DeviceOutletContextType';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';

import { LmcStatus } from './LmcStatus';

export const LmcOverview = () => {
  const { t } = useTranslation();
  const { device: lmc, site } = useOutletContext<DeviceOutletContextType>();

  const connectors = getUnifiedConnectors(lmc);
  const currentKeys = uniq(
    connectors.flatMap((connector) =>
      Object.keys(connector).filter((key) => key.startsWith('current_')),
    ) as ConnectorMeterValueKey[],
  );
  const nrOfPhases = currentKeys.length || 3;
  const voltageKeys = uniq(
    connectors.flatMap((connector) =>
      Object.keys(connector).filter((key) => key.startsWith('voltage_')),
    ) as ConnectorMeterValueKey[],
  );
  const currents = currentKeys.flatMap((key) => connectors.map((connector) => connector[key]));
  const voltages = voltageKeys.flatMap((key) => connectors.map((connector) => connector[key]));

  const phases = range(nrOfPhases).map((index) => ({
    key: index,
    current: Number(currents[index]) || 0,
    voltage: Number(voltages[index]) || 0,
    color: CHART_COLORS[index],
    backgroundColor: CHART_BACKGROUND_COLORS[index],
  }));

  const detailsData = [
    {
      label: t('serialNumber', 'Serial number'),
      value: lmc.serialNumber || '-',
    },
    {
      label: t('type', 'Type'),
      value: t('lmc', 'LMC'),
    },
    {
      label: t('deviceName', 'Device name'),
      value: lmc.name || '-',
    },
    {
      label: t('connection', 'Connection'),
      value: `${lmc?.gridConnection || 32}A`,
    },
    {
      label: t('site', 'Site'),
      value: site?.name || '-',
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-y-10 font-poppins">
        <div className="flex flex-col gap-x-4 gap-y-2 md:flex-row">
          <LmcStatus lmc={lmc} />
          <div className="w-full space-y-2 rounded-2xl bg-white p-4 font-poppins text-sm">
            <div className="flex justify-between">
              <span>{t('details', 'Details')}</span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-1">
              {detailsData.map((detail) => (
                <div key={detail.label} className="flex gap-x-1 font-semibold">
                  {detail.label}: <span className="font-normal">{detail.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <h1 className="font-poppins text-lg font-semibold">{t('currentActivity', 'Current activity')}</h1>
          <div className="flex h-full w-full flex-col gap-4 md:flex-row">
            {phases.map((phase) => (
              <div className="h-[212px] w-full" key={phase.key}>
                <CurrVoltRadialChart
                  current={phase.current}
                  voltage={phase.voltage}
                  max={lmc?.gridConnection || 32}
                  color={phase.color}
                  offline={lmc.status === DeviceStatus.OFFLINE}
                  backgroundColor={phase.backgroundColor}
                />
              </div>
            ))}
          </div>
          <MultiEnergyGraphWithPolling deviceSerialNr={lmc.serialNumber!} />
        </div>
        <Outlet />
      </div>
    </>
  );
};
