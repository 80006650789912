import { useTranslation } from 'react-i18next';

import { startCase, sum } from 'lodash';
import { Device } from 'models/device';
import { DeviceStatus } from 'models/device.enums';

import { Close } from '@mui/icons-material';
import { Box, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as UnknownIcon } from '@images/icons/DevicePlugType/Unknown.svg';
import { ReactComponent as CheckIcon } from '@images/icons/subscriptions/check.svg';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';

import { LmcStatusPill } from './LmcStatusPill';

type LmcStatusProps = {
  lmc: Device;
  smaller?: boolean;
};

export const LmcStatus = ({ lmc, smaller }: LmcStatusProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const connectors = getUnifiedConnectors(lmc);
  const currents: number[] = connectors.flatMap((connector) =>
    Object.entries(connector)
      .filter(([key]) => key.startsWith('current_'))
      .map(([, value]) => value),
  );

  const currentLoadPercentage = (sum(currents) / ((lmc?.gridConnection || 32) * currents.length)) * 100 || 0;
  const { status } = lmc;

  const getStatusIcon = () => {
    if (status === DeviceStatus.ONLINE) {
      return CheckIcon;
    }
    if (status === DeviceStatus.OFFLINE) {
      return Close;
    }
    return UnknownIcon;
  };

  const StatusIcon = getStatusIcon();
  const statusBackgroundStyle: SxProps =
    status === DeviceStatus.ONLINE
      ? {
          bgcolor: theme.palette.lightGreen,
          borderColor: theme.palette.forestGreenCrayola,
          color: theme.palette.forestGreenCrayola,
        }
      : {
          bgcolor: theme.palette.lightGray,
          borderColor: theme.palette.darkGray,
          color: theme.palette.darkGray,
        };

  return (
    <Box
      width={1}
      borderRadius={4}
      sx={
        smaller
          ? {
              display: 'flex',
              py: 2,
              pl: 2,
              pr: 1,
              gap: 1,
              ...statusBackgroundStyle,
            }
          : {
              minWidth: !isMobile ? '248px' : '100%',
              bgcolor: theme.palette.lightGreen,
              p: 2,
              width: !isMobile ? '1/3' : 'full',
            }
      }
    >
      {smaller && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={100}
          width={40}
          height={40}
          border={2}
          sx={statusBackgroundStyle}
        >
          <StatusIcon width={20} height={20} />
        </Box>
      )}
      <Box display="flex" flexDirection={smaller ? 'column-reverse' : 'column'}>
        {smaller ? (
          <Typography variant="p12" color={theme.palette.darkGray}>
            {currentLoadPercentage.toFixed()}% {t('ofTotalConnection', 'Of total connection')}
          </Typography>
        ) : (
          <>
            <Typography variant="h3" color={theme.palette.raisingBlack}>
              {currentLoadPercentage.toFixed()}%
            </Typography>
            <Typography variant="p14" color={theme.palette.darkGray}>
              {t('ofTotalConnection', 'Of total connection')}
            </Typography>
          </>
        )}
        {smaller ? (
          <Typography variant="p14b" color={theme.palette.raisingBlack}>
            {startCase(status)}
          </Typography>
        ) : (
          <LmcStatusPill status={status} />
        )}
      </Box>
    </Box>
  );
};
