import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Site } from 'models/site';
import { FeatureDto, FeatureId } from 'models/subscriptions';

import { Box, Typography, useTheme } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { StyledIconButton } from '@components/atoms/StyledIconButton';
import { SidebarBase } from '@components/sidebars/SidebarBase';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import PublicChargingHero from '@images/icons/subscriptions/public-charging-hero.png';
import ScheduledReportsHero from '@images/icons/subscriptions/scheduled-reports-hero.png';
import ThirdPartyChargersHero from '@images/icons/subscriptions/third-party-chargers-hero.png';
import { useAppDispatch } from '@services/hooks';
import { useSubscribeToFeatureMutation } from '@services/subscriptions/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { CancelSubscriptionSidebarContent } from './CancelSubscriptionSidebarContent';
import { CancelSubscriptionSidebarFooter } from './CancelSubscriptionSidebarFooter';
import { StartSubscriptionSidebarContent } from './StartSubscriptionSidebarContent';
import { StartSubscriptionSidebarFooter } from './StartSubscriptionSidebarFooter';
import { SubscriptionSidebarWelcome } from './SubscriptionSidebarWelcome';

const heroMap: Record<FeatureId, string> = {
  'device.public-charging': PublicChargingHero,
  'company.report-schedules': ScheduledReportsHero,
  'device.third-party-chargers': ThirdPartyChargersHero,
};

export const SubscriptionsSidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const context = useOutletContext<{ feature?: FeatureDto; site?: Site }>();
  const [subscribeToFeature, { isLoading }] = useSubscribeToFeatureMutation();
  const [isOpen, setIsOpen] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  if (!context?.feature) {
    return null;
  }

  const feature = context.feature;
  const hasTrial = (feature.trialPeriodDays ?? 0) > 0;
  const hasDiscount = !!feature.companyFeatureDiscount;
  const hasSubscription = !!feature.subscription?.isActive;

  const handleClose = () => {
    setIsOpen(false);

    const timeoutId = setTimeout(() => {
      navigate(context?.site && isSuccess ? '../availability' : '../');
    }, 300);

    return () => clearTimeout(timeoutId);
  };

  const handleSubscribe = async () => {
    setIsSubscribing(true);
    try {
      await subscribeToFeature({ featureUuid: feature.uuid }).unwrap();
      setIsSuccess(true);
    } catch (error) {
      setIsSuccess(false);
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('failedToSubscribeToTheFeature', 'Failed to subscribe to the feature'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      handleClose();
      console.error(error);
    }
  };

  return (
    <SidebarBase isOpen={isOpen} onClose={handleClose} isWide>
      <Box display="flex" flexDirection="column" gap={2} height={1}>
        <Box display="flex" flexDirection="column" gap={4} p="24px 24px 16px 24px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{feature.name}</Typography>
            <StyledIconButton onClick={handleClose}>
              <CloseIcon />
            </StyledIconButton>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} overflow="auto" gap={4} p="0 24px 24px 24px">
          {isSubscribing ? (
            <SubscriptionSidebarWelcome featureId={feature.featureId} isLoading={isLoading} />
          ) : (
            <>
              <img src={heroMap[feature.featureId]} alt={feature.name} />
              {hasSubscription ? (
                <CancelSubscriptionSidebarContent feature={feature} />
              ) : (
                <StartSubscriptionSidebarContent feature={feature} hasTrial={hasTrial} />
              )}
            </>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          padding="16px 24px"
          borderTop={`1px solid ${theme.palette.lightGray}`}
        >
          {isSubscribing ? (
            <StyledButton color="success" disabled={!isSuccess} onClick={handleClose} large>
              {t('continue', 'Continue')}
            </StyledButton>
          ) : hasSubscription ? (
            <CancelSubscriptionSidebarFooter feature={feature} onGoBack={handleClose} />
          ) : (
            <StartSubscriptionSidebarFooter feature={feature} hasDiscount={hasDiscount} onSubscribe={handleSubscribe} />
          )}
        </Box>
      </Box>
    </SidebarBase>
  );
};
