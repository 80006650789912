import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { InverterSession } from 'models/inverter';

import TableRow from '@components/atoms/TableRow';
import SessionStatus from '@components/sessions/SessionStatus';
import SortableTable from '@components/tables/SortableTable';
import { useAppSelector } from '@services/hooks';
import { selectInverterSummary } from '@services/inverters/selectors';

type InverterDataTableColumn = {
  key: keyof InverterSessionWithStats | 'menu';
  label: string;
  tdClassName?: string;
  className?: string;
  sortingDisabled?: boolean;
  reverseSorting?: boolean;
  extraData?: string;
  extraDataClassName?: string;
  tooltip?: string;
  value: (vehicle: InverterSessionWithStats) => React.ReactNode;
};

type InverterSessionWithStats = InverterSession & {
  startTime: string;
  min: number;
  max: number;
  mean: number;
  total: number;
};

export const InverterHistory = () => {
  const { t } = useTranslation();

  const { sessions: inverterSessions } = useAppSelector(selectInverterSummary);

  const inverterSessionsWithStats = useMemo<InverterSessionWithStats[]>(
    () =>
      inverterSessions?.map((session) => ({
        ...session,
        startTime: session.timestamp,
        min: session.productionStatsInKw?.min,
        max: session.productionStatsInKw?.max,
        mean: session.productionStatsInKw?.mean,
        total: session.totalProductionInKwh,
      })) || [],
    [inverterSessions],
  );

  const transactionsDataTableColumns: InverterDataTableColumn[] = [
    {
      key: 'startTime',
      label: t('startTime', 'Start time'),
      tdClassName: 'w-1/6',
      reverseSorting: true,
      value: (session) => <SessionStatus startTime={session.timestamp} solarInverterSessionFlag />,
    },
    {
      key: 'max',
      label: t('max', 'Max'),
      className: 'justify-end text-right',
      tdClassName: 'pr-4 text-right',
      extraData: 'kW',
      extraDataClassName: 'text-gray-400',
      value: ({ max }) => (max || 0).toFixed(2),
    },
    {
      key: 'mean',
      label: t('mean', 'Mean'),
      className: 'justify-end text-right',
      tdClassName: 'pr-4 text-right',
      extraData: 'kW',
      extraDataClassName: 'text-gray-400',
      value: ({ mean }) => (mean || 0).toFixed(2),
    },
    {
      key: 'total',
      label: t('total', 'Total'),
      className: 'justify-end text-right',
      tdClassName: 'pr-4 text-right',
      extraData: 'kWh',
      extraDataClassName: 'text-gray-400',
      value: ({ total }) => (total || 0).toFixed(2),
    },
  ];

  return (
    <div className="flex flex-col items-start">
      <div className="flex w-full items-center justify-between pb-4 pt-2">
        <h1 className="font-poppins text-lg font-semibold">{t('history', 'History')}</h1>=
      </div>
      <SortableTable
        columns={transactionsDataTableColumns}
        data={inverterSessionsWithStats || []}
        keyAttr="timestamp"
        sortingStorageKey="deviceTransactionsTable"
        defaultSortingParam="-startTime"
        tableRow={TableRow}
      />
      <Outlet />
    </div>
  );
};
