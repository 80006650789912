import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import warningIcon from '@images/icons/Warning.svg';

export const SitePricingModelWarning = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className="mb-8 flex cursor-pointer items-center justify-start gap-2 rounded-2xl bg-vermillion-200 p-4 text-sm text-vermillion"
      onClick={() => navigate('details')}
      aria-hidden
    >
      <img src={warningIcon} alt="warning" />
      <p>
        {t('siteMissingPricingModel', 'This site is missing information about electricity costs.')}{' '}
        <span className="underline">{t('addThemHere', 'You can add them here')}</span>
      </p>
    </div>
  );
};
