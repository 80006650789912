import { TableRow, styled } from '@mui/material';

export const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    borderBottom: '0 !important',
  },
  '& > *': {
    borderBottom: '1px solid #F4F4F4 !important',
  },
  '&:hover': {
    backgroundColor: '#f4f4f480',
  },
});

export const StyledSkeletonTableRow = styled(StyledTableRow)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});
