import { FeatureDto } from 'models/subscriptions';

import apiSlice from '@services/api';

export const subscriptionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeatures: builder.query<FeatureDto[], void>({
      query: () => ({
        url: '/subscriptions/features',
        method: 'GET',
      }),
      providesTags: ['CompanyContext', 'Subscription'],
    }),
    subscribeToFeature: builder.mutation<void, { featureUuid: string }>({
      query: ({ featureUuid }) => ({
        url: `/subscriptions/features/${featureUuid}/subscribe`,
        method: 'POST',
      }),
      invalidatesTags: ['Subscription'],
    }),
    cancelFeatureSubscription: builder.mutation<void, { subscriptionUuid: string }>({
      query: ({ subscriptionUuid }) => ({
        url: `/subscriptions/${subscriptionUuid}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Subscription'],
    }),
  }),
});

export const { useGetFeaturesQuery, useSubscribeToFeatureMutation, useCancelFeatureSubscriptionMutation } =
  subscriptionsApi;
