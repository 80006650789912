import { createSlice } from '@reduxjs/toolkit';

import api from '@services/api';

import { clearSiteDashboardAction, updateDashboardSettingsAction } from './actions';

const setCompanyDashboard = (state, { payload }) => {
  state.company = { ...state.company, ...payload.data };
};
const setSiteDashboard = (state, { payload }) => {
  state.site = { ...state.site, ...payload.data };
};

const updateDashboardSettings = (state, { payload }) => {
  state.company = { ...state.company, ...payload };
  state.site = { ...state.site, ...payload };
  localStorage.setItem('dashboardSettings', JSON.stringify(payload?.dashboardSettings));
};

const initialState = {
  company: { dashboardSettings: JSON.parse(localStorage.getItem('dashboardSettings')) || {} },
  site: { dashboardSettings: JSON.parse(localStorage.getItem('dashboardSettings')) || {} },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(clearSiteDashboardAction, (state) => {
      state.site = null;
    });
    builder.addCase(updateDashboardSettingsAction, updateDashboardSettings);
    builder.addMatcher(api.endpoints.getCompanyDashboard.matchFulfilled, setCompanyDashboard);
    builder.addMatcher(api.endpoints.getSiteDashboard.matchFulfilled, setSiteDashboard);
  },
});

export default dashboardSlice.reducer;
