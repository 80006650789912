import { Trans, useTranslation } from 'react-i18next';

import { FeatureDto } from 'models/subscriptions';

import { Box, Typography, useTheme } from '@mui/material';

import { BulletList } from '@components/atoms/BulletList';

type PublicChargingPricingProps = {
  feature: FeatureDto;
};

export const PublicChargingPricing = ({ feature }: PublicChargingPricingProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" color={theme.palette.darkGray}>
      <Typography variant="p14b">
        {t('publicCharging.pleaseProvide', 'Before public charging can be enabled, please provide:')}
      </Typography>
      <BulletList variant="p14">
        {t('aPaymentMethod', 'A payment method')}
        {t('yourCompanyDetails', 'Your company details')}
        {t('aPayoutMethod', 'A payout method')}
      </BulletList>
      <Typography variant="p14">
        <Trans
          i18nKey="publicCharging.onceTheseAreAdded"
          defaults="Your sites will be accessible to EV owners through <1><2>charge.vool.com</2></1>."
          components={{
            1: (
              <Typography
                variant="p14"
                color={theme.palette.mediumVermilion}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              />
            ),
            2: (
              <a
                href="https://charge.vool.com/"
                target="_blank"
                rel="noreferrer"
                aria-label="Link to public charging portal"
              />
            ),
          }}
        />
      </Typography>
      <Box>&nbsp;</Box>
      <Typography variant="p14b">{t('generalPricingInfo', 'General pricing information')}</Typography>
      <BulletList variant="p14">
        {t('publicCharging.revenueShare', '{{percentage}}% of the revenue goes to VOOL', {
          percentage: feature.featurePrice.revenueSharePercentage
            ? (Number(feature.featurePrice.revenueSharePercentage) * 100).toFixed(0)
            : 0,
        })}
        {t('pricePerConnector', '{{price}}€ per connector', {
          price: Number(feature.featurePrice.price).toFixed(2),
        })}
        {t('allPricesExcludeVat', 'All prices exclude VAT')}
      </BulletList>
      <Typography variant="p14">{t('voolWillInvoiceYouOnceAMonth', 'VOOL will invoice you once a month.')}</Typography>
    </Box>
  );
};
