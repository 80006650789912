import { useTranslation } from 'react-i18next';

import { compact, get } from 'lodash';
import { Device, DeviceConfigItem, UpdateDeviceDataValues } from 'models/device';
import { ControlChannel, DeviceStatus, Network } from 'models/device.enums';

import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useUpdateDeviceConfigurationMutation, useUpdateDeviceMutation } from '@services/devices/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { areAllParametersAccepted, isChargerSupportingConnectivityConf } from '../deviceConfigurationUtils';

export type ChargerConnectivityValues = {
  wifiEnabled: boolean;
  wifiSsid: string;
  password: string;
  cellularEnabled: boolean;
  controlChannels: ControlChannel[];
};

export const useSaveDevice = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [
    updateDeviceConfiguration,
    { isLoading: isUpdateDeviceConfigurationLoading, isError: isUpdateDeviceConfigurationError },
  ] = useUpdateDeviceConfigurationMutation();
  const [updateDevice, { isLoading: isUpdateDeviceLoading, isError: isUpdateDeviceError }] = useUpdateDeviceMutation();

  const saveDevice = async (device: Device, { deviceUpdateData, deviceConfig }: UpdateDeviceDataValues) => {
    try {
      const promises = [];
      if (deviceUpdateData) {
        promises.push(updateDevice(deviceUpdateData).unwrap());
      }

      if (deviceConfig?.length && device.status !== DeviceStatus.OFFLINE) {
        promises.push(
          updateDeviceConfiguration({
            uuid: device.uuid,
            deviceConfig,
          }).unwrap(),
        );
      }
      const [, deviceConfigurationResponse] = await Promise.all(promises);
      const someParametersWereRejected = !areAllParametersAccepted(get(deviceConfigurationResponse, 'variables', []));
      if (someParametersWereRejected) {
        dispatch(
          addToastMessage({
            type: 'error',
            title: t('someParametersWereRejectedPleaseTryAgain', 'Some parameters were rejected. Please try again'),
          }),
        );
        return false;
      }

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('deviceUpdated', 'The device {{deviceName}} was updated', {
            deviceName: device.name || device.serialNumber,
          }),
        }),
      );

      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheDeviceFailed', 'Updating the device {{deviceName}} failed', {
            deviceName: device.name || device.serialNumber,
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return [
    saveDevice,
    {
      isLoading: isUpdateDeviceConfigurationLoading || isUpdateDeviceLoading,
      isError: isUpdateDeviceConfigurationError || isUpdateDeviceError,
    },
  ] as const;
};

export const useSaveChargerConnectivity = () => {
  const { WIFI_SSID, WIFI_PASSWORD, ENABLED_NETWORKS, ENABLED_CONTROL_CHANNELS } = DEVICE_CONFIGURATION_PARAMETERS;

  const [saveDevice, { isLoading, isError }] = useSaveDevice();

  const saveChargerConnectivity = async (
    device: Device,
    { wifiEnabled, wifiSsid, password, cellularEnabled, controlChannels }: ChargerConnectivityValues,
  ) => {
    const deviceConfiguration = device.configuration ?? {};
    const chargerConnectivityConfig: DeviceConfigItem[] = compact([
      wifiEnabled && wifiSsid !== deviceConfiguration[WIFI_SSID.key]?.value && { key: WIFI_SSID.key, value: wifiSsid },
      wifiEnabled && password && { key: WIFI_PASSWORD.key, value: password },
      isChargerSupportingConnectivityConf(device) && {
        key: ENABLED_NETWORKS.key,
        value: compact([Network.ETHERNET, wifiEnabled && Network.WIFI, cellularEnabled && Network.GSM]),
      },
      { key: ENABLED_CONTROL_CHANNELS.key, value: controlChannels },
    ]);

    return saveDevice(device, { deviceConfig: chargerConnectivityConfig });
  };

  return [saveChargerConnectivity, { isLoading, isError }] as const;
};
