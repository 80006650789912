import { ConnectorConfig, ConnectorState, Device, DeviceConnectorActiveProgram } from 'models/device';
import { ConnectorComputedStatus, DeviceStatus, DeviceType } from 'models/device.enums';

export type UnifiedConnector = Partial<ConnectorConfig> &
  ConnectorState & {
    activeProgram?: DeviceConnectorActiveProgram;
    deviceUuid: string;
    deviceName: string;
    companyUuid?: string;
    deviceSerialNumber?: string;
    voolDeviceFlag: boolean;
    status: DeviceStatus;
    computedStatus: ConnectorComputedStatus;
    isTheOnlyConnector: boolean;
    type: DeviceType;
  };

export const getUnifiedConnectors = (charger: Device): UnifiedConnector[] => {
  const unifiedConnectors = [];
  const numConnectors = Math.max(charger.configOfConnectors?.length ?? 1, charger.stateOfConnectors?.length ?? 1);
  for (let connectorId = 1; connectorId <= numConnectors; connectorId++) {
    const config = charger.configOfConnectors?.find((c) => c.connectorId === connectorId);
    const state = charger.stateOfConnectors?.find((c) => c.connectorId === connectorId);
    const activeProgram = charger.activePrograms?.find((c) => c.connectorId === connectorId);
    const getComputedStatus = () => {
      if (!state || state.computedStatus === ConnectorComputedStatus.NEVER_CONNECTED) {
        return ConnectorComputedStatus.NEVER_CONNECTED;
      }
      if (charger.status === DeviceStatus.OFFLINE) {
        return ConnectorComputedStatus.OFFLINE;
      }
      return state.computedStatus ?? ConnectorComputedStatus.UNKNOWN;
    };
    unifiedConnectors.push({
      ...config,
      ...state,
      activeProgram,
      voolDeviceFlag: !!charger.voolDeviceFlag,
      connectorId,
      maxPowerKw: config?.maxPowerKw || 22,
      active_power: state?.active_power || 0,
      current: state?.current || 0,
      voltage: state?.voltage || 0,
      ocppStatus: state?.ocppStatus ?? charger.ocppStatus,
      transactionStatus: state?.transactionStatus ?? charger.transactionStatus,
      // TODO: use computedStatus directly once we are confident
      // that NEVER_CONNECTED and OFFLINE statuses are present reliably
      computedStatus: getComputedStatus(),
      deviceUuid: charger.uuid,
      deviceName: charger.name,
      companyUuid: charger.companyUuid,
      deviceSerialNumber: charger.serialNumber,
      status: charger.status,
      reserved: !!state?.reserved,
      isTheOnlyConnector: numConnectors === 1,
      type: charger.type,
    });
  }
  return unifiedConnectors;
};
