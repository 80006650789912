import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventLogDto, EventLogType } from 'models/event-log';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';

export const useEventLogTypeMenu = (eventLogs?: EventLogDto[]) => {
  const { t } = useTranslation();
  const [typeMenuOptions, setTypeMenuOptions] = useState<StyledMenuItemProps[]>([]);

  const optionLabels: Record<EventLogType, string> = {
    info: t('info', 'Info'),
    warning: t('warning', 'Warning'),
    error: t('error', 'Error'),
  };

  const options: StyledMenuItemProps[] = Object.entries(optionLabels).map(([uuid, label]) => ({
    uuid,
    checked: false,
    label,
    onClick: () => toggleOptionChecked(uuid),
  }));

  useEffect(() => {
    setTypeMenuOptions((prevState) =>
      options.map((option) => ({
        ...option,
        checked: !!prevState?.find((prevOption) => prevOption.uuid === option.uuid)?.checked,
        resultCount: eventLogs?.filter((eventLog) => eventLog.type === option.uuid).length,
      })),
    );
  }, [eventLogs]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setTypeMenuOptions((prevOptions) =>
      prevOptions?.map((option) => {
        if (option.uuid === selectedOptionUuid) {
          return { ...option, checked: !option.checked };
        }
        return option;
      }),
    );
  };

  return { typeMenuOptions, setTypeMenuOptions };
};
