import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLogout } from '@hooks/useLogout';
import logo from '@images/vool-orange.png';

export const ErrorWithLogout = () => {
  const logout = useLogout();
  const { t } = useTranslation();

  useEffect(() => {
    const logoutTimeout = setTimeout(logout, 2000);
    const navigateTimeout = setTimeout(() => {
      window.location.href = '/login';
    }, 10000);
    return () => {
      clearTimeout(logoutTimeout);
      clearTimeout(navigateTimeout);
    };
  }, []);

  return (
    <div className="mt-10 flex flex-col items-center">
      <div className="mb-5">
        <img className="max-h-[45px]" src={logo} alt="" />
      </div>
      <h1 className="mb-1 text-2xl">{t('errorWithLogoutTitle', 'ERROR! Logging you out.')}</h1>
      <h2 className="mb-2 text-base">
        {t('errorWithLogoutText', 'We are truly sorry but something went terribly wrong.')}
      </h2>
      <div className="text-xs">
        {t(
          'errorWithLogoutSubtext',
          `But don't worry, we have been notified and we are already working hard to solve this problem.`,
        )}
      </div>
    </div>
  );
};
