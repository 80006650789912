import React, { useState } from 'react';

import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';

import { Input, InputProps } from '@components/atoms/Input';

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [passwordMasked, setPasswordMasked] = useState(true);
  return (
    <div className="relative w-full items-center">
      <Input
        {...props}
        type={passwordMasked ? 'password' : 'text'}
        className="flex-grow"
        ref={ref}
        trailingIcon={() => (
          <button type="button" onClick={() => setPasswordMasked((mask) => !mask)}>
            {passwordMasked ? <EyeOffIcon className="h-6 w-6" /> : <EyeIcon className="h-6 w-6" />}
          </button>
        )}
      />
    </div>
  );
});

PasswordInput.displayName = 'PasswordInput';
