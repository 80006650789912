import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';

import { sum } from 'lodash';
import { Device, DeviceConfigurationItem } from 'models/device';
import { ControlChannel, Network } from 'models/device.enums';
import * as yup from 'yup';

import { Box, Divider, Typography } from '@mui/material';

import Button from '@components/atoms/Button';
import { EditingSidebarControllableBase } from '@components/sidebars/EditingSidebarControllableBase';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useForm } from '@hooks/useTypedForm';
import warningIcon from '@images/icons/Warning.svg';

import {
  getNetworksData,
  isChargerSupportingConnectivityConf,
  useControlChannelCombinations,
} from '../deviceConfigurationUtils';
import { CellularEnabledCheckbox } from './connectivityFormParts/CellularEnabledCheckbox';
import { EthernetEnabledByDefaultCheckbox } from './connectivityFormParts/EthernetEnabledByDefaultCheckbox';
import { WifiSection } from './connectivityFormParts/WifiSection';
import { ChargerConnectivityValues, useSaveChargerConnectivity } from './saveDeviceHooks';

export const useConnectivitySchema = (
  deviceConfiguration: Record<string, DeviceConfigurationItem>,
  fieldNameSuffix: string = '',
) => {
  const { t } = useTranslation();
  const n = (name: string) => `${name}${fieldNameSuffix}`;

  return yup
    .object()
    .shape({
      [n('wifiEnabled')]: yup.bool(),
      [n('wifiSsid')]: yup.string().when(n('wifiEnabled'), {
        is: true,
        then: yup.lazy((_, { parent }) =>
          parent[n('password')] ? yup.string().required(t('required*', 'Required*')) : yup.string(),
        ),
      }),
      [n('password')]: yup.string().when(n('wifiEnabled'), {
        is: true,
        then: yup.lazy((_, { parent }) =>
          parent[n('wifiSsid')] &&
          parent[n('wifiSsid')] !== (deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.WIFI_SSID.key]?.value ?? '')
            ? yup.string().required(t('required*', 'Required*'))
            : yup.string(),
        ),
      }),
      [n('cellularEnabled')]: yup.bool(),
      [n('controlChannels')]: yup.array(),
    })
    .required();
};

export type ConnectivityOutletContext = {
  device: Device;
  controlChannels: ControlChannel[];
  wifiSsid: string;
  wifiPassword: string;
  isCellularSupported: boolean;
  onValuesChange: (values: Partial<ChargerConnectivityValues>) => void;
  onCloseConnectivityFormSidebar: () => void;
};

export const ChargerConnectivityFormSidebar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { device } = useOutletContext<{ device: Device }>();

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => setIsOpen(true), []); // needed to enable slide-in transition

  const { WIFI_SSID, WIFI_PASSWORD, SUPPORTED_NETWORKS, ENABLED_CONTROL_CHANNELS } = DEVICE_CONFIGURATION_PARAMETERS;
  const deviceConfiguration = device.configuration ?? {};
  const { enabledNetworksMap } = getNetworksData(device);
  const isOnlyOneNetworkEnabled = sum(Object.values(enabledNetworksMap)) === 1;
  const isCellularSupported = deviceConfiguration[SUPPORTED_NETWORKS.key]?.value?.includes(Network.GSM);

  const schema = useConnectivitySchema(deviceConfiguration);
  const { formState, reset, register, handleSubmitAndResolve, setValue, watch } = useForm<ChargerConnectivityValues>({
    schema,
  });
  const wifiEnabled = watch('wifiEnabled');

  const initialControlChannels = device.configuration?.[ENABLED_CONTROL_CHANNELS.key]?.value ?? [];
  const controlChannels = watch('controlChannels') ?? [];
  const { getCombinationDef } = useControlChannelCombinations();
  const controlChannelsDef = controlChannels.length ? getCombinationDef(controlChannels) : null;

  const [saveChargerConnectivity, { isLoading, isError }] = useSaveChargerConnectivity();

  const handleValuesChange = (values: Partial<ChargerConnectivityValues>) => {
    Object.entries(values).forEach(([key, value]) =>
      setValue(key as keyof ChargerConnectivityValues, value, { shouldDirty: true }),
    );
  };

  const saveValues = async (values: ChargerConnectivityValues) => saveChargerConnectivity(device, values);

  useEffect(() => {
    reset({
      wifiEnabled: enabledNetworksMap.WIFI,
      wifiSsid: deviceConfiguration?.[WIFI_SSID.key]?.value ?? '',
      password: deviceConfiguration?.[WIFI_PASSWORD.key]?.value ?? '',
      cellularEnabled: enabledNetworksMap.GSM,
      controlChannels: initialControlChannels,
    });
  }, [device.configuration]);

  const connectivityOutletContext: ConnectivityOutletContext = {
    device,
    controlChannels,
    wifiSsid: deviceConfiguration?.[WIFI_SSID.key]?.value ?? '',
    wifiPassword: deviceConfiguration?.[WIFI_PASSWORD.key]?.value ?? '',
    isCellularSupported,
    onValuesChange: handleValuesChange,
    onCloseConnectivityFormSidebar: () => setIsOpen(false),
  };

  return (
    <EditingSidebarControllableBase
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={t('connectivity', 'Connectivity')}
      subtitle={device.name || device.serialNumber}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      anyDataChanged={formState.isDirty}
      onSaveValues={handleSubmitAndResolve(saveValues)}
      updateLoading={isLoading}
      updateFailed={isError}
      isSubSidebar
    >
      <Button onClick={() => navigate('./wizard')}>{t('openConnectivityWizard', 'Open Connectivity Wizard')}</Button>
      <Divider color="#C1C1C1" sx={{ mt: 3 }} />
      {isChargerSupportingConnectivityConf(device) && (
        <>
          <Box display="flex" flexDirection="column" gap={3} mt={3}>
            <Typography variant="p16b">{t('ethernet', 'Ethernet')}</Typography>
            <EthernetEnabledByDefaultCheckbox />
            <Typography variant="p16b">{t('wifi', 'WiFi')}</Typography>
            <WifiSection
              enabled={wifiEnabled}
              enabledProps={register('wifiEnabled')}
              ssidProps={register('wifiSsid')}
              passwordProps={register('password')}
              errors={formState.errors}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={3} mt={4}>
            <Typography variant="p16b">{t('cellular', 'Cellular')}</Typography>
            <CellularEnabledCheckbox isCellularSupported={isCellularSupported} {...register('cellularEnabled')} />
          </Box>
        </>
      )}

      <Box display="flex" flexDirection="column" mt={3} gap={3}>
        <Typography variant="p16b">{t('connectionMode', 'Connection mode')}</Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="p16">{t('selectedMode', 'Selected mode')}:</Typography>
          {controlChannelsDef ? (
            <>
              <Typography variant="p16b">{controlChannelsDef.name}</Typography>
              <Typography variant="p16">{controlChannelsDef.description}</Typography>
            </>
          ) : (
            <Typography variant="p16b">{t('none', 'None')}</Typography>
          )}
          <Typography variant="p16" py={1.5}>
            {t('useConnectivityWizardOr', 'Use the connectivity wizard or ...')}
          </Typography>
          <Button variant="secondary" onClick={() => navigate('./choice')}>
            {t('chooseConnectionModeManually', 'Choose connection mode manually')}
          </Button>
        </Box>
      </Box>
      {isOnlyOneNetworkEnabled && (
        <Box display="flex" flexDirection="column" flex={1} justifyContent="flex-end">
          <Box display="flex" alignItems="flex-start" gap={1}>
            <img src={warningIcon} alt="warning icon" />
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography variant="p16" color="#EB4E20">
                {t(
                  'thisDeviceOnlyHasOneActiveConnection',
                  'This device only has one active connection. Changing it remotely may result in a disconnected device.',
                )}
              </Typography>
              <Typography variant="p16" color="#EB4E20">
                {t(
                  'ifThisHappensYouWillNeedToConnectViaBluetooth',
                  'If this happens you will need to use the VOOL mobile app to reconnect it via Bluetooth.',
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Outlet context={connectivityOutletContext} />
    </EditingSidebarControllableBase>
  );
};
