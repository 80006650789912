import React from 'react';

import { ConnectorType } from 'models/device.enums';

import { Tooltip } from '@mui/material';

import { useConnectorTypes } from './chargerConst';

type ConnectorTypeIconProps = {
  connectorType?: ConnectorType;
  connectorId?: number;
  label?: string;
  className: string;
};

export const ConnectorTypeIcon = ({ connectorType, connectorId, label, className }: ConnectorTypeIconProps) => {
  const connectorTypes = useConnectorTypes();
  // TODO: replace fallbacks with connectorTypeIcons.UNKNOWN once existing devices in DB have been updated
  const { icon: TypeIcon, label: typeLabel } =
    (connectorType && connectorTypes[connectorType]) || connectorTypes.TYPE_2;

  return (
    <Tooltip placement="top" title={typeLabel}>
      <div className={`scheme-color flex gap-y-0.5 font-poppins text-sm ${className}`}>
        {!!connectorId && <span className="w-2 text-center">{connectorId}</span>}
        {!!label && <span>{label}</span>}
        <TypeIcon className="h-6 w-6" />
      </div>
    </Tooltip>
  );
};
