import { useTranslation } from 'react-i18next';

import { Box, Tooltip, Typography } from '@mui/material';

import warningIcon from '@images/icons/Warning.svg';

import { ChargerWithStats, LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { LmcChargerCommunicationStatus } from './LmcChargerCommunicationStatus';

type ChargerControllerGroupProps = {
  charger: ChargerWithStats;
  lmcMultipoints: LmcMultipointWithStats[];
};

export const ChargerControllerGroup = ({ charger, lmcMultipoints }: ChargerControllerGroupProps) => {
  const { t } = useTranslation();

  const chargerMultipoint = lmcMultipoints.find(
    ({ lmcMultipointUuid }) => lmcMultipointUuid === charger.lmcMultipointUuid,
  );

  return (
    <Box display="flex" alignItems="center" gap={1}>
      {chargerMultipoint ? (
        <>
          <Typography variant="p14">{chargerMultipoint?.displayName}</Typography>
          <LmcChargerCommunicationStatus charger={charger} lmcMultipoints={lmcMultipoints} />
        </>
      ) : (
        <>
          <Typography variant="p14">{t('loadManagementNotConfigured', 'Load management not configured')}</Typography>
          <Tooltip
            title={t(
              'configureLoadManagementTooltipText',
              'Configure load management by adding the charger to the LMC group on the charger configuration panel. And then click on Auto-configure option next to the LMC (opens from the three-dot menu).',
            )}
          >
            <img src={warningIcon} alt="warning" height={16} width={16} />
          </Tooltip>
        </>
      )}
    </Box>
  );
};
