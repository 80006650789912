import { useTranslation } from 'react-i18next';

import { GetDeviceVariableResponseItem, SetVariableAttributeStatus } from 'models/device';

import { Skeleton, Table, TableBody, TableCell, TableContainer } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { MaterialTable } from '@components/atoms/MaterialTable';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';

import { OcppVariableColumnData } from './OcppVariablesSidebar.common';
import { OcppVariablesTableRow } from './OcppVariablesTableRow';

const renderRows = (
  rows: GetDeviceVariableResponseItem[],
  columns: OcppVariableColumnData[],
  variableValues: Record<string, string>,
  onVariableChange: (variable: string, value: string) => void,
  variableStatuses: Record<string, SetVariableAttributeStatus>,
) =>
  rows.map((row) => (
    <OcppVariablesTableRow
      key={row.variable}
      row={row}
      columns={columns}
      variableValue={variableValues[row.variable]}
      onValueChange={(value: string) => onVariableChange(row.variable, value)}
      variableStatus={variableStatuses[row.variable]}
    />
  ));

const OcppVariablesTableRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
  </StyledSkeletonTableRow>
);

const OcppVariablesTableSkeleton = () => (
  <TableContainer component={CardContainer} sx={{ p: 0 }}>
    <Table>
      <TableBody>
        <OcppVariablesTableRowSkeleton />
        <OcppVariablesTableRowSkeleton />
        <OcppVariablesTableRowSkeleton />
      </TableBody>
    </Table>
  </TableContainer>
);

type OcppVariablesTableProps = {
  data: GetDeviceVariableResponseItem[] | undefined;
  isLoading: boolean;
  variableValues: Record<string, string>;
  setVariableValue: (variable: string, value: string) => void;
  variableStatuses: Record<string, SetVariableAttributeStatus>;
};

export const OcppVariablesTable = ({
  data,
  isLoading,
  variableValues,
  setVariableValue,
  variableStatuses,
}: OcppVariablesTableProps) => {
  const { t } = useTranslation();

  const columns: OcppVariableColumnData[] = [
    {
      label: t('name', 'Name'),
      dataKey: 'variable',
      sortable: true,
      width: 270,
    },
    {
      label: t('value', 'Value'),
      dataKey: 'attributeValue',
      numeric: true, // right-aligned
    },
    {
      label: '',
      dataKey: 'menu',
    },
  ];

  return (
    <MaterialTable
      data={data}
      isLoading={isLoading}
      headerProps={{ columns, orderBy: 'variable', order: 'asc' }}
      renderRows={(rows, cols) => renderRows(rows, cols, variableValues, setVariableValue, variableStatuses)}
      loaders={{ TableLoader: OcppVariablesTableSkeleton, RowLoader: OcppVariablesTableSkeleton }}
      sortingStorageKey="ocppVariables"
    />
  );
};
