import { Box, Skeleton, Table, TableBody, TableCell, TableContainer } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';

export const InvoicesTableRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell width={180}>
      <Box display="flex" alignItems="center" gap={2}>
        <Skeleton width={48} height={48} variant="rounded" animation="wave" sx={{ borderRadius: '12px' }} />
        <Box display="flex" flexDirection="column" flex={1}>
          <Skeleton variant="text" animation="wave" width="60%" />
          <Skeleton variant="text" animation="wave" />
        </Box>
      </Box>
    </TableCell>
    {Array.from(Array(3).keys()).map(() => (
      <TableCell key={self.crypto.randomUUID()} width={160}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
    ))}
    <TableCell width={30}>
      <Skeleton variant="rounded" width={24} height={24} />
    </TableCell>
  </StyledSkeletonTableRow>
);

export const InvoicesTableSkeleton = () => {
  return (
    <TableContainer component={CardContainer} sx={{ p: 0 }}>
      <Table>
        <TableBody>
          {Array.from(Array(5).keys()).map(() => (
            <InvoicesTableRowSkeleton key={self.crypto.randomUUID()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
