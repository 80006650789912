import React from 'react';
import Chart from 'react-apexcharts';

import { isEqual, maxBy, times } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import { CHART_COLORS, CHART_LIGHT_COLORS } from '@views/Chargers/useComputedStatusDefs';

const COLORS_LIGHT_MODE = [...CHART_COLORS, ...CHART_LIGHT_COLORS];
const GRAPH_CATEGORIES = {
  hour: 'HH:mm:ss',
  day: 'MMM DD HH:mm',
  week: 'MMM DD HH:mm',
  month: 'MMM DD',
  sixMonth: 'MMM',
  year: 'MMM',
};
const TOOLTIP_UNITS = ['A', 'A', 'A', 'V', 'V', 'V'];

const YAXIS_SERIES_CURRENT = 'current_l1';
const YAXIS_SERIES_VOLTAGE = 'voltage_l1';

const MultiEnergyGraph = React.memo(
  ({ transactionsArray, filter }) => {
    const COLORS = COLORS_LIGHT_MODE;
    const LABEL_COLOR = '#9d9d9d';
    const transactionWithMaxLength = maxBy(transactionsArray, 'length');
    const series = transactionsArray.map((tra, i) => ({
      color: COLORS[i],
      name: tra[0].key,
      data: times(transactionWithMaxLength?.length, (index) => tra[index]?.value),
    }));
    return (
      <Chart
        type="area"
        width="100%"
        height="250"
        options={{
          legend: {
            show: true,
            markers: {
              width: 8,
              height: 8,
              radius: 100,
            },
            labels: {
              colors: [LABEL_COLOR],
            },
          },
          responsive: [
            {
              breakpoint: undefined,
              options: {},
            },
          ],
          tooltip: {
            y: {
              formatter: (value, { seriesIndex }) =>
                `${(value && value.toFixed(2)) ?? ' - '}${TOOLTIP_UNITS[seriesIndex]}`,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
          },
          stroke: {
            show: true,
            lineCap: 'butt',
            curve: 'smooth',
            width: 1,
            dashArray: 0,
            colors: COLORS,
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            id: 'basic-line',
            toolbar: {
              show: true,
              autoSelected: 'selection',
              tools: {
                download: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                reset: true,
                selection: false,
                pan: false,
              },
              offsetY: -5,
            },
            animations: {
              enabled: false,
              easing: 'easeout',
              animateGradually: {
                enabled: false,
                delay: 150,
              },
              dynamicAnimation: {
                enabled: true,
              },
            },
          },
          xaxis: {
            tooltip: {
              enabled: false,
            },
            categories: ((transactionsArray.length && transactionWithMaxLength) || []).map((s) =>
              moment.utc(s.time).local().format(GRAPH_CATEGORIES[filter]),
            ),
            labels: {
              show: true,
              offsetY: -3,
              showDuplicates: false,
              rotate: 0,
              style: {
                fontSize: 10,
                fontFamily: 'Roboto, sans-serif',
                colors: LABEL_COLOR,
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: [
            {
              seriesName: YAXIS_SERIES_CURRENT,
              showAlways: true,
              title: {
                text: 'A',
                rotate: 0,
                offsetX: 0,
                style: {
                  color: LABEL_COLOR,
                },
              },
              decimalsInFloat: 0,
              labels: {
                offsetX: -50,
                formatter: (number) => number.toFixed(2),
                rotate: 0,
                style: {
                  fontSize: 9,
                  fontFamily: 'Roboto, sans-serif',
                  colors: LABEL_COLOR,
                },
              },
              axisBorder: {
                show: false,
              },
            },
            { seriesName: YAXIS_SERIES_CURRENT, labels: { show: false } },
            { seriesName: YAXIS_SERIES_CURRENT, labels: { show: false } },
            {
              seriesName: YAXIS_SERIES_VOLTAGE,
              showAlways: true,
              opposite: true,
              title: {
                text: 'V',
                rotate: 0,
                style: {
                  color: LABEL_COLOR,
                },
              },
              labels: {
                formatter: (number) => number,
                rotate: 0,
                style: {
                  fontSize: 9,
                  fontFamily: 'Roboto, sans-serif',
                  colors: LABEL_COLOR,
                },
              },
            },
            { seriesName: YAXIS_SERIES_VOLTAGE, labels: { show: false } },
            { seriesName: YAXIS_SERIES_VOLTAGE, labels: { show: false } },
          ],
          grid: {
            show: true,
            width: 0.5,
            borderColor: '#f1f1f1',
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          fill: {
            opacity: 0,
            type: 'solid',
          },
        }}
        series={series}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.transactionsArray, nextProps.transactionsArray),
);

MultiEnergyGraph.displayName = 'MultiEnergyGraph';

MultiEnergyGraph.propTypes = {
  transactionsArray: PropTypes.array,
  filter: PropTypes.string,
};

MultiEnergyGraph.defaultProps = {
  transactionsArray: [],
  filter: 'week',
};

export default MultiEnergyGraph;
