import { useTranslation } from 'react-i18next';

import moment from 'moment/moment';

import { Box, Typography } from '@mui/material';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { TimePeriodSelector, TimePeriodSelectorProps } from './TimePeriodSelector';

type TimePeriodSelectorHeaderProps = TimePeriodSelectorProps & {
  labelPrefix?: string;
};

export const TimePeriodSelectorHeader = ({ labelPrefix, ...props }: TimePeriodSelectorHeaderProps) => {
  const { t } = useTranslation();
  const { isSmallScreen } = useBreakpoints();
  const { selectedDuration } = props;

  const getDurationLabel = () => {
    if (!selectedDuration || selectedDuration === 'all') {
      return t('allTime', 'All time');
    }
    if (selectedDuration === '24-hours') {
      return t('last24Hours', 'Last 24 hours');
    }
    const [amountStr, unit] = selectedDuration.split('-');
    const duration = moment.duration(parseInt(amountStr, 10), unit as moment.unitOfTime.DurationConstructor);
    const now = moment();
    const start = moment(now).subtract(duration);
    if (now.year() !== start.year()) {
      const fmt = 'MMMM D, YYYY';
      return `${start.format(fmt)} – ${now.format(fmt)}`;
    }
    if (now.month() !== start.month()) {
      const fmt = 'MMMM D';
      return `${start.format(fmt)} – ${now.format(fmt)}`;
    }
    return `${start.format('MMMM D')}–${now.date()}`;
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" mb="16px">
      <Typography variant="p18">
        {labelPrefix} {getDurationLabel()}
      </Typography>
      <TimePeriodSelector isMobile={isSmallScreen} {...props} />
    </Box>
  );
};
