import { Context, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { Box, Divider, Typography } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { FilterMenuOption } from '@components/filters';
import { FiltersBaseContextType } from '@components/filters/context/FiltersContext';
import { useBreakpoints } from '@hooks/useBreakpoints';

dayjs.extend(isBetween);
dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.updateLocale('en', {
  weekStart: 1,
});

type DateRangeCalendarProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
  onClose?: () => void;
};

export const useDateRangeCalendar = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
  onClose,
}: DateRangeCalendarProps<ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const { isSmallScreen } = useBreakpoints();
  const [currentDate, setCurrentDate] = useState(dayjs().startOf('day'));

  const generateMonthArray = (currentDate: Dayjs, offset: number) =>
    Array.from({ length: 6 }, (_, i) =>
      Array.from({ length: 7 }, (_, j) => {
        const day = currentDate
          .add(offset, 'month')
          .startOf('month')
          .weekday(j)
          .add(i * 7, 'day');
        return day.month() === currentDate.add(offset, 'month').month() ? day : undefined;
      }),
    );

  const { startDate, setStartDate, endDate, setEndDate } = useContext(context);

  const selectDate = (date: Dayjs) => {
    if (startDate == undefined) {
      setStartDate(date);
    } else if (endDate == undefined) {
      if (date.isBefore(startDate)) {
        setEndDate(startDate.endOf('day'));
        setStartDate(date);
      } else {
        setEndDate(date.endOf('day'));
      }
    } else {
      setStartDate(date);
      setEndDate(undefined);
    }
  };

  const isBetweenOrSame = (date: Dayjs) => {
    if (startDate == undefined || endDate == undefined) {
      return false;
    }
    return date.isBetween(startDate, endDate, 'day', '[]');
  };

  const isSameOrMondayOrStartOfMonth = (date: Dayjs) => {
    if (startDate == undefined || endDate == undefined) {
      return false;
    }

    return date.isSame(startDate, 'day') || date.weekday() === 0 || date.date() === 1;
  };

  const isSameOrSundayOrEndOfMonth = (date: Dayjs) => {
    if (startDate == undefined || endDate == undefined) {
      return false;
    }

    return date.isSame(endDate, 'day') || date.weekday() === 6 || date.date() === date.daysInMonth();
  };

  const isSundayAndMatchesStartDate = (date: Dayjs) => {
    if (startDate == undefined || endDate == undefined) {
      return false;
    }

    return (
      (date.weekday() === 6 || date.date() === date.daysInMonth()) &&
      date.isSame(startDate, 'day') &&
      endDate.isAfter(startDate, 'day')
    );
  };

  const isMondayAndMatchesEndDate = (date: Dayjs) => {
    if (startDate == undefined || endDate == undefined) {
      return false;
    }

    return (
      (date.weekday() === 0 || date.date() === 1) && date.isSame(endDate, 'day') && startDate.isBefore(endDate, 'day')
    );
  };

  const startAndEndAreSame = startDate?.isSame(endDate, 'day');

  const isBetweenStartAndEndAndOnlyDayInWeek = (date: Dayjs) => {
    if (startDate == undefined || endDate == undefined) {
      return false;
    }

    const isBetween = date.isBetween(startDate, endDate, 'day', '()');

    return (
      (isBetween && date.date() === 1 && date.weekday() === 6) ||
      (isBetween && date.date() === date.daysInMonth() && date.weekday() === 0)
    );
  };

  const setDateRangeToday = () => {
    const today = dayjs().startOf('day');
    setStartDate(today);
    setEndDate(undefined);
    setCurrentDate(today);
  };

  const setDateRangeThisMonth = () => {
    const thisMonth = dayjs().startOf('day');
    setStartDate(thisMonth.startOf('month'));
    setEndDate(thisMonth.endOf('month'));
    setCurrentDate(thisMonth);
  };

  const setDateRangeLastMonth = () => {
    const lastMonth = dayjs().startOf('day').subtract(1, 'month');
    setStartDate(lastMonth.startOf('month'));
    setEndDate(lastMonth.endOf('month'));
    setCurrentDate(lastMonth);
  };

  const renderGrid = (month: (Dayjs | undefined)[][]) => {
    return month.map((week) => (
      <Box key={self.crypto.randomUUID()} display="flex" width={1}>
        {week.map((day) => (
          <Box
            key={self.crypto.randomUUID()}
            width={isSmallScreen ? '100%' : '40px'}
            height="32px"
            position="relative"
            onClick={() => day && selectDate(day)}
            sx={{ cursor: 'pointer' }}
          >
            {day && (
              <Box
                width={1}
                height={1}
                position="absolute"
                sx={{
                  backgroundColor: isBetweenOrSame(day) ? '#D7E1DD' : 'unset',
                  ...(isSameOrMondayOrStartOfMonth(day) && {
                    borderRadius: '50px 0 0 50px',
                    marginLeft: '4px',
                    width: 'calc(100% - 4px)',
                  }),
                  ...(isSameOrSundayOrEndOfMonth(day) && {
                    borderRadius: '0 50px 50px 0',
                    marginRight: '4px',
                    width: 'calc(100% - 4px)',
                  }),
                  ...(isBetweenStartAndEndAndOnlyDayInWeek(day) && {
                    borderRadius: '50px',
                    marginX: '4px',
                    width: 'calc(100% - 8px)',
                  }),
                  ...(isSundayAndMatchesStartDate(day) && {
                    backgroundColor: 'unset',
                  }),
                  ...(isMondayAndMatchesEndDate(day) && {
                    backgroundColor: 'unset',
                  }),
                  ...(startAndEndAreSame && {
                    backgroundColor: 'unset',
                  }),
                }}
              />
            )}
            {day && (
              <Typography
                variant="p14"
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="32px"
                height="32px"
                borderRadius="50%"
                position="absolute"
                sx={{
                  left: '50%',
                  transform: 'translateX(-50%)',
                  backgroundColor: startDate?.isSame(day, 'day') || endDate?.isSame(day, 'day') ? '#386957' : 'unset',
                  color: startDate?.isSame(day, 'day') || endDate?.isSame(day, 'day') ? 'white' : 'unset',
                }}
              >
                {day.format('D')}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    ));
  };

  const currentMonth = generateMonthArray(currentDate, 0);
  const nextMonth = generateMonthArray(currentDate, 1);

  return {
    startDate,
    endDate,
    DateRangeCalendar: (
      <CardContainer sx={{ p: 0 }}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" padding={isSmallScreen ? '24px 16px' : '8px 8px 8px 24px'}>
            <Typography variant="h3" alignContent="center">
              {startDate?.format('MMMM DD')}
              {endDate && ` - ${endDate.format('MMMM DD')}`}
            </Typography>
            <Close onClick={onClose} sx={{ cursor: 'pointer', height: 48, width: 48, p: 1.5 }} />
          </Box>
          {!isSmallScreen && <Divider sx={{ borderBottom: '1px solid #F4F4F4' }} />}
          <Box
            display="flex"
            position="relative"
            sx={
              isSmallScreen
                ? {
                    flexDirection: 'column',
                    px: 0,
                  }
                : {
                    flexDirection: 'row',
                    px: 3,
                  }
            }
          >
            <Box
              display="flex"
              gap={1}
              sx={
                isSmallScreen
                  ? {
                      flexDirection: 'row',
                      padding: '16px',
                      gap: 3,
                    }
                  : {
                      flexDirection: 'column',
                      padding: '24px 24px 24px 0',
                    }
              }
            >
              <Typography variant="p16" color="#5E5E5E" onClick={() => setDateRangeToday()} sx={{ cursor: 'pointer' }}>
                {t('today', 'Today')}
              </Typography>
              <Typography
                variant="p16"
                color="#5E5E5E"
                onClick={() => setDateRangeThisMonth()}
                sx={{ cursor: 'pointer' }}
              >
                {t('thisMonth', 'This month')}
              </Typography>
              <Typography
                variant="p16"
                color="#5E5E5E"
                onClick={() => setDateRangeLastMonth()}
                sx={{ cursor: 'pointer' }}
              >
                {t('lastMonth', 'Last month')}
              </Typography>
            </Box>
            <Divider
              orientation={isSmallScreen ? 'horizontal' : 'vertical'}
              flexItem
              sx={{
                borderRight: '1px solid #F4F4F4',
              }}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection={isSmallScreen ? 'column' : 'row'}
            >
              <Box
                display="flex"
                flexDirection="column"
                padding={isSmallScreen ? '24px' : '24px 0px 24px 24px'}
                width={1}
              >
                <Box display="flex" position="relative">
                  <ArrowBack
                    onClick={() => setCurrentDate(currentDate.subtract(1, 'month'))}
                    sx={{ cursor: 'pointer' }}
                  />
                  <Typography variant="p16" position="absolute" left="50%" sx={{ transform: 'translateX(-50%)' }}>
                    {currentDate.format('MMMM YYYY')}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={1} mt={2} width={1}>
                  {renderGrid(currentMonth)}
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                padding={isSmallScreen ? '24px' : '24px 0px 24px 24px'}
                width={1}
              >
                <Box display="flex" justifyContent="end" position="relative">
                  <Typography variant="p16" position="absolute" left="50%" sx={{ transform: 'translateX(-50%)' }}>
                    {currentDate.add(1, 'month').format('MMMM YYYY')}
                  </Typography>
                  <ArrowForward
                    onClick={() => setCurrentDate(currentDate.add(1, 'month'))}
                    sx={{ cursor: 'pointer' }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" gap={1} mt={2} width={1}>
                  {renderGrid(nextMonth)}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContainer>
    ),
  };
};
