import { Box, TableCell, TableCellProps, Typography } from '@mui/material';

type LocationCellProps = TableCellProps & {
  siteName?: string;
  deviceName?: string;
};

export const LocationCell = ({ siteName, deviceName, ...props }: LocationCellProps) => {
  return (
    <TableCell align="left" {...props}>
      <Box display="flex" flexDirection="column">
        <Typography variant="p16">{siteName}</Typography>
        <Typography variant="p14" color="#5E5E5E">
          {deviceName}
        </Typography>
      </Box>
    </TableCell>
  );
};
