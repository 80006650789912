import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { isNumber } from 'lodash';
import PropTypes from 'prop-types';

import MultipleEnergySupplied from '@components/multipleEnergySupplied';
import { pollingTimeout } from '@helpers/idleTimeout';
import { clearDeviceTransactions } from '@services/devices/actions';
import { useGetDeviceTransactionsQuery } from '@services/devices/endpoints';
import { selectDeviceTransactions } from '@services/devices/selectors';

export const MultiEnergyGraphWithPolling = ({ deviceSerialNr }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const POLLING_INTERVAL = 30000;

  const [pollingInterval, setPollingInterval] = useState(null);
  const [influxParams, setInfluxParams] = useState(null);
  const [skipTransactionsQuery, setSkipTransactionsQuery] = useState(true);

  const deviceConsumption = useSelector(selectDeviceTransactions);

  const orderedConsumptionList = Object.entries(deviceConsumption)
    .filter(([key]) => key !== 'energy_imported')
    .sort()
    .map(([, value]) => value);

  const pollingInfo = pollingInterval
    ? t('autoUpdatePollingText', 'Auto update in every {{seconds}}s', {
        seconds: Math.round(pollingInterval / 1000),
      })
    : t('autoUpdatePausedText', 'Auto update has been paused');

  const { isLoading: deviceTransactionsLoading, refetch } = useGetDeviceTransactionsQuery(
    {
      deviceSerialNr,
      ...influxParams,
    },
    {
      skip: skipTransactionsQuery,
      refetchOnMountOrArgChange: true,
      pollingInterval,
    },
  );

  useEffect(() => {
    if (influxParams) {
      setSkipTransactionsQuery(false);
    }
  }, [influxParams]);

  useEffect(() => {
    pollingTimeout(() => {
      setPollingInterval(0);
    }, 5);
  }, []);

  useEffect(() => () => dispatch(clearDeviceTransactions()), []);

  return (
    <div className="w-full rounded-2xl bg-white">
      {isNumber(pollingInterval) && (
        <div className="w-full px-4 py-1">
          <span className="text-xxs text-vermillion">{pollingInfo}</span>
          {!pollingInterval && (
            <button
              className="ml-2 rounded-md border px-2 py-1 text-xxs"
              type="button"
              onClick={() => {
                setPollingInterval(POLLING_INTERVAL);
                refetch();
              }}
            >
              {t('resume', 'Resume')}
            </button>
          )}
        </div>
      )}
      <MultipleEnergySupplied
        isFetching={deviceTransactionsLoading}
        noBorder
        noInfo
        noTitle
        transactionsArray={Object.values(orderedConsumptionList) || {}}
        fetchData={(ip) => {
          if (ip.polling) {
            setPollingInterval(POLLING_INTERVAL);
          } else {
            setPollingInterval(null);
          }
          setInfluxParams(ip);
        }}
      />
    </div>
  );
};

MultiEnergyGraphWithPolling.propTypes = {
  deviceSerialNr: PropTypes.string.isRequired,
};
