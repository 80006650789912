import { useTranslation } from 'react-i18next';

import { every, isEqual, mapValues } from 'lodash';
import { Device, SetDeviceConfigResponseItem } from 'models/device';
import { ControlChannel, LmcConnectionType, Network } from 'models/device.enums';

import {
  CAN_CMD,
  CAN_LM,
  CONTROL_CHANNEL_COMBINATIONS,
  DEVICE_CONFIGURATION_PARAMETERS,
  LAN_CMD,
  LAN_LM,
} from '@handlers/device/deviceConst';

export const isChargerInLmcGroup = (charger: Device) => !!charger.lmcMultipointUuid;

export const isChargerSupportingConnectivityConf = (charger: Device) => charger.ocppVersion === '1.6';

export const isLmcSupportingWifiConf = (lmc: Device) => !!lmc.firmwareVersion && lmc.firmwareVersion >= 'v4.2.0';

export const isLmcSupportingChargersAutoConfAndWifiSignalStrength = (lmc: Device) =>
  !!lmc.firmwareVersion && lmc.firmwareVersion >= 'v4.4.0';

export const isLmcSupportingChargerCommStatusesRequest = (lmc?: Device) =>
  !!lmc?.firmwareVersion && lmc.firmwareVersion.split('-')[0] >= 'v4.6.0';

// TODO: rename to something more accurate
export const areAllParametersAccepted = (parameters: SetDeviceConfigResponseItem[]) =>
  !parameters.length || every(parameters, (parameter) => ['Accepted', 'RebootRequired'].includes(parameter.status));

export const getNetworksData = ({ configuration = {} }: Device) => {
  const getNetworks = (key: string): string[] => configuration[key]?.value ?? [];

  const enabledNetworksValue = getNetworks(DEVICE_CONFIGURATION_PARAMETERS.ENABLED_NETWORKS.key);
  const availableNetworksValue = getNetworks(DEVICE_CONFIGURATION_PARAMETERS.AVAILABLE_NETWORKS.key);

  return {
    enabledNetworksMap: mapValues(Network, (network) => enabledNetworksValue.includes(network)),
    availableNetworks: availableNetworksValue,
  };
};

export const getControlChannelsData = ({ configuration = {} }: Device) => {
  const enabledControlChannels =
    configuration[DEVICE_CONFIGURATION_PARAMETERS.ENABLED_CONTROL_CHANNELS.key]?.value ?? [];

  const validateChannels = (channels: ControlChannel[]) => {
    const result = channels.every((channel) => enabledControlChannels.includes(channel));
    if (channels.length === 1) {
      return result && enabledControlChannels.length === channels.length;
    }
    return result;
  };

  const channelData = mapValues(CONTROL_CHANNEL_COMBINATIONS, validateChannels);

  const isSyncWithLmcNetwork = !!enabledControlChannels.length && !channelData.standalone;
  const isRemoteControlOverLmc = [CAN_CMD, LAN_CMD].some((channel) => enabledControlChannels.includes(channel));
  const isLoadManagementOn = [CAN_LM, LAN_LM].some((channel) => enabledControlChannels.includes(channel));

  const getConnectionType = () => {
    if (channelData.lmcCanWithOcpp || channelData.lmcCanWithoutOcpp) {
      return LmcConnectionType.CAN;
    }
    if (channelData.lmcLanWithOcpp || channelData.lmcLanWithoutOcpp) {
      return LmcConnectionType.LAN;
    }
    return null;
  };

  return {
    isSyncWithLmcNetwork,
    isRemoteControlOverLmc,
    isLoadManagementOn,
    lmcConnectionType: getConnectionType(),
  };
};

type ControlChannelCombinationDef = {
  key: string;
  name: string;
  channels: ControlChannel[];
  description: string;
};

export const useControlChannelCombinations = () => {
  const { t } = useTranslation();
  const defs: ControlChannelCombinationDef[] = [
    {
      key: 'app_cmd',
      name: t('standalone', 'Standalone'),
      channels: [ControlChannel.APP_CMD],
      description: `${t('chargerStandalone', 'Charger standalone')}.`,
    },
    {
      key: 'lan_cmd_lan_lm',
      name: t('ctrlLanCmdLanLmName', 'LAN + commands through LMC'),
      description: t('ctrlLanCmdLanLmText', 'Charger connected to LMC over LAN with commands through LMC.'),
      channels: [ControlChannel.LAN_CMD, ControlChannel.LAN_LM],
    },
    {
      key: 'can_cmd_can_lm',
      name: t('ctrlCanCmdCanLmName', 'CAN + commands through LMC'),
      description: t('ctrlCanCmdCanLmText', 'Charger connected to LMC over CAN with commands through LMC.'),
      channels: [ControlChannel.CAN_CMD, ControlChannel.CAN_LM],
    },
    {
      key: 'app_cmd_lan_lm',
      name: t('ctrlAppCmdLanLmName', 'LAN + commands directly to charger'),
      description: t('ctrlAppCmdLanLmText', 'Charger connected to LMC over LAN with commands directly to charger.'),
      channels: [ControlChannel.APP_CMD, ControlChannel.LAN_LM],
    },
    {
      key: 'app_cmd_can_lm',
      name: t('ctrlAppCmdCanLmName', 'CAN + commands directly to charger'),
      description: t('ctrlAppCmdCanLmText', 'Charger connected to LMC over CAN with commands directly to charger.'),
      channels: [ControlChannel.APP_CMD, ControlChannel.CAN_LM],
    },
  ];
  const getCustomCombinationDef = (channels: ControlChannel[]): ControlChannelCombinationDef => {
    const sortedChannels = [...channels].sort(undefined);
    return {
      key: 'custom',
      name: t('custom', 'Custom'),
      description: `${t('ctrlCustomText', 'Custom combination of control channel(s):')} ${sortedChannels.join(', ')}.`,
      channels: sortedChannels,
    };
  };
  const getCombinationDef = (channels: ControlChannel[]) => {
    const sortedChannels = [...channels].sort(undefined);
    const foundDef = defs.find((def) => isEqual(def.channels, sortedChannels));
    return foundDef ?? getCustomCombinationDef(channels);
  };
  return { defs, getCustomCombinationDef, getCombinationDef };
};
