const buildTransactionsEndpoints = (builder) => ({
  transactions: builder.query({
    query: (params) => ({
      url: 'transactions',
      method: 'GET',
      params,
    }),
    providesTags: ['CompanyContext'],
  }),
  transactionData: builder.query({
    query: (transactionId) => ({
      url: `graphs/transaction/${transactionId}`,
      method: 'GET',
    }),
  }),
  deleteTransaction: builder.mutation({
    query: (uuid) => ({
      url: `transactions/${uuid}`,
      method: 'DELETE',
    }),
  }),
  activeTransaction: builder.query({
    query: () => ({
      url: '/transactions/activeTransaction',
      method: 'GET',
    }),
  }),
});

export default buildTransactionsEndpoints;
