import { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import { ReactComponent as SearchIcon } from '@images/icons/Search.svg';

import SearchResults from './SearchResults';

const SearchModal = ({ isOpen, closeModal }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [inputElem, setInputElem] = useState(null);
  const [selectedResultUuid, setSelectedResultUuid] = useState(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto rounded-2xl transition duration-500"
        onClose={closeModal}
      >
        <div className="flex h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block max-h-full w-full transform overflow-hidden rounded-2xl bg-white
              text-left align-bottom font-poppins shadow-xl transition-all sm:mt-36 sm:w-full sm:max-w-[632px] sm:align-top"
            >
              <div className="flex items-center border-b border-b-gray-100 p-4 font-everett text-2xl font-normal leading-8 text-black sm:px-6">
                <SearchIcon className="-mt-1" />
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  ref={setInputElem}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-1 border-0 font-poppins text-base focus:outline-none focus:ring-0"
                />
                <button
                  type="button"
                  className="float-right -m-2 -mt-1 rounded-full p-2 sm:hover:bg-gray-200 sm:active:bg-gray-400"
                  onClick={closeModal}
                >
                  <CloseIcon />
                </button>
              </div>
              {/* FIXME: get rid of this calc() trickery */}
              <div className="max-h-[calc(100vh-280px)] py-6">
                <SearchResults
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  selectedResultUuid={selectedResultUuid}
                  setSelectedResultUuid={setSelectedResultUuid}
                  closeModal={closeModal}
                  keyboardTargetElem={inputElem}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SearchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SearchModal;
