import { createSlice } from '@reduxjs/toolkit';
import { RFIDCard } from 'models/rfid-card';

import { rfidCardsApi } from './endpoints';

type RFIDCardsState = {
  rfidCards: RFIDCard[];
};

const initialState: RFIDCardsState = {
  rfidCards: [],
};

const setRfidCards = (state: RFIDCardsState, { payload }: { payload: RFIDCard[] }) => {
  state.rfidCards = payload || [];
};

const addRfidCard = (state: RFIDCardsState, { payload }: any) => {
  if (payload.data) {
    state.rfidCards.push(payload.data);
  }
};

const updateRfidCard = (state: RFIDCardsState, { payload }: { payload: RFIDCard }) => {
  if (payload) {
    const rfidCard = state.rfidCards.find((rc) => rc.uuid === payload?.uuid);
    if (rfidCard) {
      Object.assign(rfidCard, payload);
    }
  }
};

const removeRfidCard = (state: RFIDCardsState, { payload }: any) => {
  state.rfidCards = state.rfidCards.filter(({ uuid }) => uuid !== payload.rfidCardUuid);
};

const clearRfidCardsIfForbidden = (state: RFIDCardsState, { payload }: any) => {
  if (payload?.status === 403) {
    state.rfidCards = [];
  }
};

const rfidCardsSlice = createSlice({
  name: 'rfidCards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(rfidCardsApi.endpoints.getRfidCards.matchFulfilled, setRfidCards);
    builder.addMatcher(rfidCardsApi.endpoints.addRfidCard.matchFulfilled, addRfidCard);
    builder.addMatcher(rfidCardsApi.endpoints.updateRfidCard.matchFulfilled, updateRfidCard);
    builder.addMatcher(rfidCardsApi.endpoints.getRfidCards.matchRejected, clearRfidCardsIfForbidden);
    builder.addMatcher(rfidCardsApi.endpoints.deleteRfidCard.matchFulfilled, removeRfidCard);
  },
});

export default rfidCardsSlice.reducer;
