import { updateSmartChargingSessionFromPusher } from 'src/services/vehicles/actions';

import DataHandler from '../data';
import { ACTIONS_TYPES } from '../pusherData/constHandlersPushers';

export default class PusherSmartChargingSessionsDataHandler {
  data;

  meta;

  action;

  object;

  constructor({ data, meta, object }) {
    this.action = meta.action;
    this.object = object;
    this.data = data;
    this.meta = meta;
  }

  getActions() {
    const actionType = ACTIONS_TYPES(this.object)[this.action];
    if (actionType) {
      return this[actionType]();
    }
    return null;
  }

  updateChargingSession() {
    const smartChargingSession = {
      [this.meta.fieldName]: this.meta.fieldValue,
      ...this.data.current,
    };
    const smartChargingSessionData = DataHandler.cleanDataObject(smartChargingSession);
    return [updateSmartChargingSessionFromPusher({ smartChargingSession: smartChargingSessionData })];
  }
}
