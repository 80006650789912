import defaultTheme from 'tailwindcss/defaultTheme';

import { PaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    p18: React.CSSProperties;
    p16b: React.CSSProperties;
    p16: React.CSSProperties;
    p14b: React.CSSProperties;
    p14: React.CSSProperties;
    p12b: React.CSSProperties;
    p12: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    p18?: React.CSSProperties;
    p16b?: React.CSSProperties;
    p16?: React.CSSProperties;
    p14b?: React.CSSProperties;
    p14?: React.CSSProperties;
    p12b?: React.CSSProperties;
    p12?: React.CSSProperties;
  }
}

// https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    p18: true;
    p16b: true;
    p16: true;
    p14b: true;
    p14: true;
    p12b: true;
    p12: true;
    // Disabling default styles
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
    overline: false;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    mediumVermilion: string;
    forestGreenCrayola: string;
    lightGreen: string;
    raisingBlack: string;
    darkGray: string;
    spanishGray: string;
    lightGray: string;
    cultured: string;
    pureWhite: string;
    status: PaletteColorOptions;
  }

  interface PaletteOptions {
    mediumVermilion?: string;
    forestGreenCrayola?: string;
    lightGreen?: string;
    raisingBlack?: string;
    darkGray?: string;
    spanishGray?: string;
    lightGray?: string;
    cultured?: string;
    pureWhite?: string;
    status?: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    status: true;
  }
}

const poppins = ['Poppins', ...defaultTheme.fontFamily.sans].join(',');

export const theme = createTheme({
  palette: {
    mediumVermilion: '#EB4E20',
    forestGreenCrayola: '#386957',
    lightGreen: '#D7E1DD',
    raisingBlack: '#282828',
    darkGray: '#5E5E5E',
    spanishGray: '#999898',
    lightGray: '#D6D6D6',
    cultured: '#F4F4F4',
    pureWhite: '#FFFFFF',
    status: {
      main: '#D7E1DD',
    },
  },
  breakpoints: {
    values: {
      xs: 361,
      sm: 568,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: 'TwkEverett,sans-serif',
    h1: {
      fontWeight: 400,
      fontSize: '80px',
      lineHeight: '100px',
    },
    h2: {
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '36px',
    },
    h3: {
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '32px',
    },
    p18: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '32px',
      letterSpacing: '2%',
    },
    p16b: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    p16: {
      fontFamily: poppins,
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    p14b: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
    },
    p14: {
      fontFamily: poppins,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
    },
    p12: {
      fontFamily: poppins,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
    p12b: {
      fontFamily: poppins,
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#282828',
          borderRadius: '8px',
          padding: '8px 12px',
        },
      },
    },
  },
});
