import { createSlice } from '@reduxjs/toolkit';
import { merge, uniqBy } from 'lodash';
import { Device, SetDeviceConfigResponseItem, UpdateDeviceConfigurationDataDto } from 'models/device';
import { ChargingAction, ConnectorComputedStatus, DeviceStatus, DeviceTransactionStatus } from 'models/device.enums';
import { LmcMultipoint } from 'models/lmc-multipoint';

import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { sitesApi } from '@services/sites/endpoints';

import {
  clearDeviceTransactions,
  clearLocalFailedAction,
  setChargerMultiPoint,
  updateDeviceFromPusher,
} from './actions';
import { devicesApi } from './endpoints';

const addDevice = (state: DevicesState, { payload }: any) => {
  const existingDevice = state.list.find(({ uuid }) => uuid === payload?.device?.uuid);
  if (existingDevice) {
    existingDevice.activePrograms = undefined;
    merge(existingDevice, payload.device);
  } else {
    state.list.push(payload.device);
  }
};

const updateDeviceByUuid = (state: DevicesState, { payload }: { payload: Device }) => {
  const device = state.list.find(({ uuid }) => uuid === payload?.uuid);

  if (device) {
    Object.assign(device, payload);
  }
};

const updateDeviceBySerialNumber = (state: DevicesState, { payload }: any) => {
  const device = state.list.find(({ serialNumber }) => serialNumber === payload.device?.deviceSerialNumber);
  if (!device) {
    return;
  }
  const { connectorId } = payload.device.stateOfConnector || {};
  if (connectorId) {
    if (!device.stateOfConnectors) {
      device.stateOfConnectors = [];
    }
    const { stateOfConnectors } = device;
    const stateOfConnector = stateOfConnectors.find((s) => s.connectorId === connectorId);
    if (stateOfConnector) {
      Object.assign(stateOfConnector, payload.device.stateOfConnector);
    } else {
      stateOfConnectors.push(payload.device.stateOfConnector);
    }
  } else {
    Object.assign(device, payload.device);
    if (payload.device.status === DeviceStatus.OFFLINE) {
      // FIXME: Delete this workaround, once backend has been improved
      device.stateOfConnectors?.forEach((connector) => {
        connector.transactionStatus = DeviceTransactionStatus.NONE;
      });
    }
  }
};

const updateDeviceStatus = (state: DevicesState, { payload }: any) => {
  const device = state.list.find(({ serialNumber }) => serialNumber === `${payload?.deviceStatus?.device_id}`);
  if (device) {
    const { data, connectors } = payload.deviceStatus;
    merge(device, data);
    device.stateOfConnectors = device?.stateOfConnectors?.map((connector) => ({
      ...connector,
      ...connectors?.find((c: any) => c.connector_id === connector.connectorId),
    }));
  }
};

const addDevices = (state: DevicesState, { payload }: { payload: { devices: Device[] } }) => {
  state.list = uniqBy(
    [
      ...state.list.map((d) => ({ ...d, ...(payload.devices.find((pl: any) => pl.uuid === d.uuid) || {}) })),
      ...payload.devices,
    ],
    'uuid',
  );
};

const setDeviceTransactions = (state: DevicesState, { payload }: any) => {
  state.deviceTransactions = payload;
};

const deleteDevice = (state: DevicesState, { payload }: any) => {
  const deviceIndex = state.list.findIndex(({ uuid }) => uuid === payload?.deviceUuid);
  if (deviceIndex !== -1) {
    state.list.splice(deviceIndex, 1);
  }
};

type SetLocalFailedActionPayload = {
  deviceUuid?: string;
  deviceSerialNumber?: string;
  connectorId?: number;
  action: ChargingAction | undefined;
};

const setFailedAction = (state: DevicesState, arg: { payload: SetLocalFailedActionPayload }) => {
  const { deviceUuid, deviceSerialNumber, connectorId, action } = arg.payload;
  const device = state.list.find((d) => (deviceUuid ? d.uuid === deviceUuid : d.serialNumber === deviceSerialNumber));
  if (!device) {
    return;
  }
  const connectorState = connectorId
    ? device.stateOfConnectors?.find((sc) => sc.connectorId === connectorId)
    : device.stateOfConnectors?.[0];
  if (connectorState) {
    connectorState.localFailedAction = action ?? undefined;
    connectorState.localFailedActionTime = action ? new Date().toISOString() : undefined;
  }
};

const makeSetFailedAction =
  (action: ChargingAction | undefined) =>
  (state: DevicesState, { meta }: any) => {
    const deviceArgs = meta.arg.originalArgs as Omit<SetLocalFailedActionPayload, 'action'>;
    setFailedAction(state, {
      payload: {
        ...deviceArgs,
        action,
      },
    });
  };

const makeSetComputedStatus =
  (computedStatus: ConnectorComputedStatus, onlyIfCancelling?: boolean) =>
  (state: DevicesState, { meta }: any) => {
    const { deviceUuid, deviceSerialNumber, connectorId, isCancelling } = meta.arg.originalArgs;
    if (onlyIfCancelling && !isCancelling) {
      return;
    }
    const device = state.list.find((d) => (deviceUuid ? d.uuid === deviceUuid : d.serialNumber === deviceSerialNumber));
    if (!device) {
      return;
    }
    const connector = connectorId
      ? device.stateOfConnectors?.find((c) => c.connectorId === connectorId)
      : device.stateOfConnectors?.[0];
    if (connector) {
      connector.computedStatus = computedStatus;
    }
  };

const setDeviceTransactionsSummary = (state: DevicesState, { payload }: any) => {
  state.deviceTransactionsSummary = payload.summary;
};

const addLmcMultipoints = (state: DevicesState, { payload }: any) => {
  state.lmcMultipoints = uniqBy([...payload.data, ...state.lmcMultipoints], 'uuid');
};

const addLmcMultipoint = (state: DevicesState, { payload }: any) => {
  state.lmcMultipoints = uniqBy([payload.data, ...state.lmcMultipoints], 'uuid');
};

const deleteLmcMultipoint = (state: DevicesState, { payload }: any) => {
  const { multipointUuid } = payload;
  const multipointIndex = state.lmcMultipoints.findIndex(({ uuid }) => uuid === multipointUuid);
  if (multipointIndex !== -1) {
    state.lmcMultipoints.splice(multipointIndex, 1);
  }
};

const updateDeviceGridConnectionFromConfig = (device: Device) => {
  const maximumChargingCurrent =
    device.configuration?.[DEVICE_CONFIGURATION_PARAMETERS.MAXIMUM_CHARGING_CURRENT.key]?.value;
  if (maximumChargingCurrent != null) {
    device.gridConnection = maximumChargingCurrent;
  }
};

const addDeviceConfigurationByDeviceUuid = (state: DevicesState, { payload, meta }: any) => {
  const { uuid } = meta.arg.originalArgs;
  const device = state.list.find(({ uuid: deviceUuid }) => deviceUuid === uuid);
  if (device) {
    device.configuration = Object.fromEntries(
      payload.variables.map(({ key, value, readonly }: any) => [key, { value, readonly }]),
    );
    updateDeviceGridConnectionFromConfig(device);
  }
};

const updateDeviceConfigurationByDeviceUuid = (
  state: DevicesState,
  {
    payload,
    meta,
  }: {
    payload: { variables: SetDeviceConfigResponseItem[] };
    meta: { arg: { originalArgs: UpdateDeviceConfigurationDataDto } };
  },
) => {
  const { uuid, deviceConfig } = meta.arg.originalArgs;
  const device = state.list.find(({ uuid: deviceUuid }) => deviceUuid === uuid);
  if (device?.configuration) {
    Object.assign(
      device.configuration,
      Object.fromEntries(
        payload.variables
          .filter(({ status }: any) => ['Accepted', 'RebootRequired'].includes(status))
          .map(({ key }: any) => {
            const parameter = deviceConfig.find(({ key: parameterKey }: any) => parameterKey === key);
            return parameter ? [key, { value: parameter.value }] : [];
          })
          .filter((item: any) => !!item.length),
      ),
    );
    updateDeviceGridConnectionFromConfig(device);
  }
};

const updateDeviceUserFavoriteFlag = (state: DevicesState, { meta }: any) => {
  const { deviceUuid, userFavoriteFlag } = meta.arg.originalArgs;
  const device = state.list.find(({ uuid }) => uuid === deviceUuid);
  if (device) {
    device.userFavoriteFlag = userFavoriteFlag ? 1 : 0;
  }
};

export type DevicesState = {
  list: Device[];
  lmcMultipoints: LmcMultipoint[];
  deviceTransactions: any;
  deviceTransactionsSummary: any;
};

const initialState: DevicesState = {
  list: [],
  lmcMultipoints: [],
  deviceTransactions: {},
  deviceTransactionsSummary: {},
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateDeviceFromPusher, updateDeviceBySerialNumber);
    builder.addCase(clearDeviceTransactions, (state) => {
      state.deviceTransactions = {};
    });
    builder.addCase(setChargerMultiPoint, (state, { payload }: any) => {
      const device = state.list.find(({ uuid }) => uuid === payload?.device.uuid);
      if (device) {
        device.lmcMultipointUuid = payload.lmcMultipointUuid;
      }
    });
    builder.addCase(clearLocalFailedAction, (state, arg) =>
      setFailedAction(state, { ...arg, payload: { ...(arg.payload ?? {}), action: undefined } }),
    );
    builder.addMatcher(devicesApi.endpoints.addDevice.matchFulfilled, addDevice);
    builder.addMatcher(devicesApi.endpoints.getDevice.matchFulfilled, addDevice);
    builder.addMatcher(devicesApi.endpoints.getDevices.matchFulfilled, addDevices);
    builder.addMatcher(devicesApi.endpoints.getAllDevices.matchFulfilled, addDevices);
    builder.addMatcher(devicesApi.endpoints.getDeviceStatus.matchFulfilled, updateDeviceStatus);
    builder.addMatcher(devicesApi.endpoints.getDeviceTransactions.matchFulfilled, setDeviceTransactions);
    builder.addMatcher(devicesApi.endpoints.getDeviceTransactionsSummary.matchFulfilled, setDeviceTransactionsSummary);
    builder.addMatcher(devicesApi.endpoints.updateDevice.matchFulfilled, updateDeviceByUuid);
    builder.addMatcher(devicesApi.endpoints.deleteDevice.matchFulfilled, deleteDevice);
    builder.addMatcher(sitesApi.endpoints.getSiteDevices.matchFulfilled, addDevices);

    builder.addMatcher(
      devicesApi.endpoints.startCharging.matchPending,
      makeSetComputedStatus(ConnectorComputedStatus.STARTING),
    );
    builder.addMatcher(devicesApi.endpoints.startCharging.matchPending, makeSetFailedAction(undefined));
    builder.addMatcher(
      devicesApi.endpoints.startCharging.matchRejected,
      makeSetComputedStatus(ConnectorComputedStatus.PLUGGED_IN),
    );
    builder.addMatcher(devicesApi.endpoints.startCharging.matchRejected, makeSetFailedAction(ChargingAction.START));

    builder.addMatcher(
      devicesApi.endpoints.stopCharging.matchPending,
      makeSetComputedStatus(ConnectorComputedStatus.STOPPING),
    );
    builder.addMatcher(devicesApi.endpoints.stopCharging.matchPending, makeSetFailedAction(undefined));
    builder.addMatcher(
      devicesApi.endpoints.stopCharging.matchRejected,
      makeSetComputedStatus(ConnectorComputedStatus.CHARGING),
    );
    builder.addMatcher(devicesApi.endpoints.stopCharging.matchRejected, makeSetFailedAction(ChargingAction.STOP));
    builder.addMatcher(
      devicesApi.endpoints.stopCharging.matchFulfilled,
      makeSetComputedStatus(ConnectorComputedStatus.PLUGGED_IN, true),
    );

    builder.addMatcher(devicesApi.endpoints.getLmcMultipoints.matchFulfilled, addLmcMultipoints);
    builder.addMatcher(devicesApi.endpoints.addLmcMultipoint.matchFulfilled, addLmcMultipoint);
    builder.addMatcher(devicesApi.endpoints.updateLmcMultipoint.matchFulfilled, addLmcMultipoint);
    builder.addMatcher(devicesApi.endpoints.deleteLmcMultipoint.matchFulfilled, deleteLmcMultipoint);
    builder.addMatcher(devicesApi.endpoints.getDeviceConfiguration.matchFulfilled, addDeviceConfigurationByDeviceUuid);
    builder.addMatcher(
      devicesApi.endpoints.updateDeviceConfiguration.matchFulfilled,
      updateDeviceConfigurationByDeviceUuid,
    );
    builder.addMatcher(devicesApi.endpoints.toggleUserFavoriteDevice.matchFulfilled, updateDeviceUserFavoriteFlag);
  },
});

export default devicesSlice.reducer;
