import { useTranslation } from 'react-i18next';

import { Step, StepLabel, Stepper, Typography } from '@mui/material';

import { SquareStepIcon } from './SquareStepIcon';
import { StyledStepConnector } from './StyledStepConnector';

type GroupStepperProps = {
  activeStep: number;
  completedSteps: { [key: number]: boolean };
};

export const GroupStepper = ({ activeStep, completedSteps }: GroupStepperProps) => {
  const { t } = useTranslation();
  const steps = [t('details', 'Details'), t('members', 'Members'), t('assignment', 'Assignment')];

  return (
    <Stepper activeStep={activeStep} connector={<StyledStepConnector />}>
      {steps.map((label, index) => (
        <Step key={label} completed={completedSteps[index]}>
          <StepLabel StepIconComponent={SquareStepIcon}>
            <Typography variant={activeStep === index ? 'p14b' : 'p14'}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
