import { useTranslation } from 'react-i18next';

import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';

import { SiteAvailability } from './sidebarHelpers/SiteAvailability';
import { useSiteAvailabilityForm } from './sidebarHelpers/useSiteAvailabilityForm';

export const SiteAvailabilitySidebar = () => {
  const { t } = useTranslation();
  const { site, form, isSaving, isLoading, returnPath, updateFailed, updateFailedMessage, submitSiteAvailability } =
    useSiteAvailabilityForm();

  return (
    site && (
      <EditingSidebarBase
        title={t('publicCharging', 'Public charging')}
        updateLoading={isSaving}
        saveDisabled={isLoading}
        anyDataChanged={form.formState.isDirty}
        onSaveValues={submitSiteAvailability}
        getReturnPath={() => returnPath}
        updateFailed={updateFailed}
        updateFailedMessage={updateFailedMessage}
      >
        <SiteAvailability site={site} form={form} />
      </EditingSidebarBase>
    )
  );
};
