import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';

import { isVoolDevice } from './siteDevicesUtils';

export const DeviceVendorModel = ({ device }: { device: Device }) => {
  const { t } = useTranslation();
  const { vendor, model, type } = device;
  const vendorLabel = isVoolDevice(device) ? 'VOOL' : vendor ?? t('unknown', 'Unknown');
  const modelLabel = model ?? (type === DeviceType.CHARGER ? t('charger', 'Charger') : '');
  const lmcLabel = !modelLabel.toLowerCase().includes(DeviceType.LMC) && type === DeviceType.LMC ? t('lmc', 'LMC') : '';
  return (
    <span>
      {vendorLabel} · {modelLabel} {lmcLabel}
    </span>
  );
};
