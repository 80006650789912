import { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { ConnectorComputedStatus, DeviceStatus, DeviceType } from 'models/device.enums';

import { useLazyGetLmcMultipointsQuery } from '@services/devices/endpoints';
import { selectDevicesBy, selectLmcMultipoints } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { DeviceTypeOption } from '@views/reporting/hooks/useDeviceTypeMenu';

import { DevicesAndGroupsCommonProps, LmcMultipointWithStats } from '../devicesAndGroupsTypes';
import { isMainLmc, isMultipoint } from './siteDevicesUtils';

export const useLmcMultipointsWithStats = ({ siteUuid, companyUuid }: DevicesAndGroupsCommonProps) => {
  const { deviceUuid: selectedDeviceUuidFromUrl } = useParams();

  const rawLmcMultipoints = useAppSelector(selectLmcMultipoints);
  const [searchParams] = useSearchParams();
  const selectedLmcMultipointFromUrl = searchParams.get('lmcMultipointUuid');

  const rawLmcDevices = useAppSelector((state) => selectDevicesBy(state, DeviceType.LMC, siteUuid, companyUuid));

  const rawLmcDeviceUuids = rawLmcDevices.map((lmc) => lmc.uuid);

  const [getLmcMultipoints, { isLoading: isLoadingGetLmcMultipoints }] = useLazyGetLmcMultipointsQuery();

  const lmcConnectorComputedStatus: Record<DeviceStatus, ConnectorComputedStatus> = {
    [DeviceStatus.ONLINE]: ConnectorComputedStatus.AVAILABLE,
    [DeviceStatus.OFFLINE]: ConnectorComputedStatus.OFFLINE,
    [DeviceStatus.FAULTED]: ConnectorComputedStatus.FAULTED,
  };

  const lmcMultipointsWithStats = useMemo<LmcMultipointWithStats[]>(
    () =>
      rawLmcDevices.flatMap((lmc) => {
        const lmcMultipoints = rawLmcMultipoints.filter((mp) => mp.lmcDeviceUuid === lmc.uuid);
        return lmcMultipoints.map((mp) => {
          const lmcName = lmc.name || lmc.serialNumber;
          return {
            ...mp,
            ...lmc,
            lmcMultipointUuid: mp.uuid,
            isMainLmc: isMainLmc(lmc) && !isMultipoint(mp),
            gridConnection: mp.currentLimit || lmc.gridConnection,
            lmcName,
            displayName: !isMultipoint(mp) ? lmcName : mp.name,
            computedStatus: lmcConnectorComputedStatus[lmc.status],
            deviceType: isMultipoint(mp) ? DeviceTypeOption.MULTIPOINT : DeviceTypeOption.LMC,
          };
        });
      }),
    [rawLmcDevices, rawLmcMultipoints],
  );

  const selectedLmcMultipoint = useMemo(
    () =>
      lmcMultipointsWithStats.find(
        (lmcMultipoint) =>
          lmcMultipoint.lmcMultipointUuid === selectedLmcMultipointFromUrl ||
          lmcMultipoint.uuid === selectedDeviceUuidFromUrl,
      ),
    [selectedLmcMultipointFromUrl, selectedDeviceUuidFromUrl],
  );

  useEffect(() => {
    rawLmcDevices.forEach((lmc) => {
      const lmcMultipoint = rawLmcMultipoints.find((mp) => mp.lmcDeviceUuid === lmc.uuid);
      if (!lmcMultipoint) {
        getLmcMultipoints(lmc.uuid);
      }
    });
  }, [rawLmcDeviceUuids.join(','), rawLmcDevices.length]);

  return {
    lmcMultipointsWithStats,
    selectedLmcMultipoint,
    isLoading: isLoadingGetLmcMultipoints,
  };
};
