import { useState } from 'react';

import { Permission } from 'models/permission.enum';
import { CompanyRole } from 'models/role.enum';
import { User } from 'models/user';

import { MenuComponent } from '@components/dropdown/MenuComponent';
import activityInlineIcon from '@images/icons/Activity_inline.svg';
import { ReactComponent as MenuArrowIcon } from '@images/icons/MenuArrow.svg';
import { ReactComponent as CheckIcon } from '@images/icons/check.svg';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useUpdateUserRoleMutation } from '@services/users/endpoints';

import { WorkspaceRoleDefDetails, useWorkspaceRolesDef } from './workspaceHooks';

type MemberRoleMenuProps = {
  member: User;
  companyUuid?: string;
};

export const MemberRoleMenu = ({ member, companyUuid }: MemberRoleMenuProps) => {
  const self = useAppSelector(selectUser);
  const rolesDef = useWorkspaceRolesDef();

  const [updateUserRole, { isLoading }] = useUpdateUserRoleMutation();
  const [newRole, setNewRole] = useState<CompanyRole>();

  const canEditThisMember =
    useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_USERS)) && member.uuid !== self?.uuid;

  const menuItems = Object.entries<WorkspaceRoleDefDetails>(rolesDef)
    .filter(([, roleDef]) => roleDef.choosable)
    .map(([key, roleDef]) => ({
      key,
      title: roleDef.label,
      onClick: () => {
        const role: CompanyRole = key as CompanyRole;
        setNewRole(role);
        updateUserRole({ uuid: member.uuid, role, companyUuid });
      },
    }));

  return (
    <MenuComponent
      menuItems={menuItems.map((menuItem) => ({
        ...menuItem,
        ...(member.role === menuItem.key
          ? { className: 'font-semibold', icon: () => <CheckIcon className="h-5 w-5 [&_path]:fill-black" /> }
          : { className: 'pl-6' }),
      }))}
      buttonIcon={() => {
        const role = isLoading ? newRole : member.role;
        return (
          <div className="group min-w-[100px] text-left text-black">
            {role && rolesDef[role]?.label}{' '}
            {canEditThisMember && !isLoading && <MenuArrowIcon className="inline-block" />}
            {isLoading && <img src={activityInlineIcon} alt="Loading" className="inline-block animate-spin" />}
          </div>
        );
      }}
      disabled={!canEditThisMember}
      menuPosition="left-0"
    />
  );
};
