import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

import { Skeleton } from '@mui/material';

import { FormAutocomplete } from '@components/atoms/StyledAutocomplete';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { useForm } from '@hooks/useTypedForm';
import { useAppDispatch } from '@services/hooks';
import { useUpdateCompanyPartnershipMutation } from '@services/partners/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { useCompanyPartnership } from './useCompanyPartnership';

type CompanyPartnershipForm = {
  partnerCompany: {
    label: string;
    value: string;
  } | null;
};

const schema = yup
  .object()
  .shape({
    partnerCompany: yup
      .object()
      .shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
      .nullable(),
  })
  .required();

export const EditCompanyPartnershipSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { partnerCompanies, partnerCompany, isCompanyPartnershipsLoading } = useCompanyPartnership();

  const { control, formState, handleSubmitAndResolve, reset } = useForm<CompanyPartnershipForm>({
    schema,
    defaultValues: {
      partnerCompany: null,
    },
  });

  const selectedPartnerCompany = partnerCompanies.find((company) => company.uuid === partnerCompany?.uuid);
  const companyPartnershipLabel = t('companyPartnership', 'Company partnership');

  useEffect(() => {
    if (selectedPartnerCompany) {
      reset({
        partnerCompany: {
          label: selectedPartnerCompany.name,
          value: selectedPartnerCompany.uuid,
        },
      });
    }
  }, [selectedPartnerCompany]);

  const [updateCompanyPartnership, { isLoading: isCompanyPartnershipUpdating }] = useUpdateCompanyPartnershipMutation();

  const submit = async (data: CompanyPartnershipForm) => {
    const { partnerCompany } = data;
    try {
      await updateCompanyPartnership(partnerCompany?.value).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemSaved', '{{item}} saved', { item: companyPartnershipLabel }),
        }),
      );
      return true;
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheItemFailed', 'Updating the {{item}} failed', {
            item: companyPartnershipLabel.toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return (
    <EditingSidebarBase
      title={t('partnerCompany', 'Partner company')}
      containerClassName="space-y-7 font-poppins"
      saveDisabled={isCompanyPartnershipsLoading}
      updateLoading={isCompanyPartnershipUpdating}
      anyDataChanged={formState.isDirty}
      onSaveValues={handleSubmitAndResolve(submit)}
    >
      {isCompanyPartnershipsLoading ? (
        <Skeleton variant="rounded" animation="wave" height={39} />
      ) : (
        <FormAutocomplete
          name="partnerCompany"
          control={control}
          label={`${t('partnerCompany', 'Partner company')} (${t('optional', 'Optional').toLowerCase()})`}
          options={partnerCompanies.map((company) => ({
            label: company.name,
            value: company.uuid,
          }))}
        />
      )}
    </EditingSidebarBase>
  );
};
