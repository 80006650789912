import { Fragment, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';

import ArrowDown from '@images/icons/ArrowDown.svg';

const Autocomplete = ({ options, label, value, onChange, disabled, questionMarkTooltip, isError, helpText }) => {
  const selectedOption = useMemo(
    () => options.find((opt) => opt.value === value) || { label: '', value: '' },
    [value, options],
  );
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(() => {
    if (!query) {
      return options;
    }
    const processedQuery = query.toLowerCase().replace(/\s+/g, '');
    if (!processedQuery) {
      return options;
    }
    return options.filter((option) => option.label.toLowerCase().replace(/\s+/g, '').includes(processedQuery));
  }, [query, options]);

  return (
    <div className="relative w-full pt-4">
      <label
        className={`absolute left-2 top-label z-10 flex items-center bg-white px-1 font-poppins text-sm font-normal ${
          isError ? 'text-vermillion' : 'text-gray-400'
        }`}
      >
        {label}
        {questionMarkTooltip && (
          <Tooltip title={questionMarkTooltip} placement="top">
            <div>
              <QuestionMarkCircleIcon className="ml-1 h-4 w-4 text-gray-400" />
            </div>
          </Tooltip>
        )}
      </label>
      <Combobox
        value={selectedOption}
        disabled={disabled}
        onChange={(option) => {
          onChange(option.value);
        }}
      >
        <div className="relative mt-1">
          <Combobox.Button className="w-full">
            <Combobox.Input
              className={`
                h-[42px]
                w-full
                rounded-md
                border-2
                px-2
                py-2
                text-left
                text-sm
                focus:outline-none
                focus:ring-1
                disabled:opacity-60
                ${
                  isError
                    ? 'border-red-300 text-vermillion placeholder-red-300 focus:border-vermillion focus:ring-vermillion'
                    : 'border-gray-200 text-black placeholder-gray-400 focus:border-forest focus:ring-forest'
                }`}
              displayValue={(option) => (option.extraLabel ? `${option.label} ${option.extraLabel}` : option.label)}
              onChange={(event) => {
                setQuery(event.target.value);
                if (event.target.value === '') {
                  onChange(null);
                }
              }}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-2">
              <img src={ArrowDown} alt="Arrow down" />
            </div>
          </Combobox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id ?? option.value}
                    className={({ active }) =>
                      `${
                        active ? 'bg-gray-100' : ''
                      } relative cursor-default select-none py-2 pl-10 pr-4 font-poppins font-normal text-black`
                    }
                    value={option}
                    disabled={option.disabled}
                  >
                    {({ selected }) => (
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-3">
                          {!!option.icon && <span>{option.icon}</span>}
                          <span
                            className={`${selected ? 'font-medium' : 'font-normal'} ${
                              option.italic ? 'italic' : ''
                            } block truncate`}
                          >
                            {option.label}
                          </span>
                        </div>
                        {option.extraLabel && (
                          <span className="text-sm leading-6 text-gray-600">{option.extraLabel}</span>
                        )}
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {helpText && (
        <span className={`mt-1 block text-sm leading-6 ${isError ? 'text-vermillion' : 'text-gray-400'}`}>
          {helpText}
        </span>
      )}
    </div>
  );
};

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      italic: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      disabled: PropTypes.bool,
      extraLabel: PropTypes.string,
      icon: PropTypes.node,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  questionMarkTooltip: PropTypes.string,
  isError: PropTypes.bool,
  helpText: PropTypes.string,
};

Autocomplete.defaultProps = {
  options: [],
  value: null,
  disabled: false,
  questionMarkTooltip: undefined,
  onChange: () => {},
  isError: false,
  helpText: undefined,
};

export default Autocomplete;
