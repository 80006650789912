import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupDto } from 'models/group.dto';
import * as yup from 'yup';

import { useForm } from '@hooks/useTypedForm';

const useGroupDetailsSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    name: yup.string().required(t('required*', 'Required*')),
    description: yup.string().optional(),
  });
};

export interface GroupDetails {
  name: string;
  description?: string;
}

export const useGroupDetailsState = (group?: GroupDto) => {
  const schema = useGroupDetailsSchema();
  const { control, trigger, watch, setValue, formState } = useForm<GroupDetails>({
    schema,
    defaultValues: {
      name: group?.name ?? '',
      description: group?.description ?? '',
    },
  });

  useEffect(() => {
    if (group) {
      setValue('name', group.name);
      setValue('description', group.description ?? '');
    }
  }, [group]);

  const { name, description } = watch();

  useEffect(() => {
    if (name !== '') {
      trigger();
    }
  }, [name]);

  return { control, name, description, anyDataChanged: formState.isDirty, trigger };
};
