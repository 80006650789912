import { Fragment, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Listbox, Transition } from '@headlessui/react';
import { SelectorIcon } from '@heroicons/react/outline';
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';

const DEFAULT_OPTION = { label: '---', value: null, id: null };

const Select = ({ options, value, label, questionMarkTooltip, onChange, disabled, required, error, helpText }) => {
  const optionsRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuShownAbove, setIsMenuShownAbove] = useState(false);

  const parsedOptions = required && options ? [...options] : [DEFAULT_OPTION, ...options];
  const selectedOption = useMemo(() => options.find((opt) => opt.value === value) || DEFAULT_OPTION, [value]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (optionsRef.current && isOpen && !isMenuShownAbove) {
        const optionsRect = optionsRef.current.getBoundingClientRect();
        setIsMenuShownAbove(optionsRect.bottom > window.innerHeight);
      }
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <div className="relative w-full pt-4">
      <label
        className={`absolute left-2 top-label z-10 flex items-center bg-white px-1 font-poppins text-sm font-normal ${
          error ? 'text-vermillion' : 'text-gray-400'
        }`}
      >
        {label}
        {questionMarkTooltip && (
          <Tooltip title={questionMarkTooltip} placement="top">
            <div>
              <QuestionMarkCircleIcon className="ml-1 h-4 w-4 text-gray-400" />
            </div>
          </Tooltip>
        )}
      </label>
      <Listbox
        disabled={disabled}
        value={selectedOption}
        className={classNames({ 'opacity-60': disabled })}
        onChange={(val) => {
          onChange(val.value);
        }}
      >
        <div className="relative mt-1">
          <Listbox.Button
            className={`
              relative
              flex
              h-12
              w-full
              gap-x-2
              rounded-md
              border-2
              p-2
              text-left
              text-sm
              focus:outline-none
              focus:ring-1
              ${
                error
                  ? 'border-red-300 text-vermillion placeholder-red-300 focus:border-vermillion focus:ring-vermillion'
                  : 'text-black placeholder-gray-400 focus:border-forest focus:ring-forest'
              }
            `}
            onClick={() => setIsOpen(!isOpen)}
          >
            {selectedOption.Icon && <selectedOption.Icon />}
            <span
              className={`block truncate font-poppins text-base font-normal ${selectedOption.italic ? 'italic' : ''}`}
            >
              {selectedOption.label}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              ref={optionsRef}
              className={`absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg
                ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${isMenuShownAbove ? 'bottom-16' : ''}`}
            >
              {parsedOptions.map((option, index) => (
                <Listbox.Option
                  key={option.id ?? option.value}
                  className={({ active }) =>
                    `${active ? 'bg-gray-100' : ''} ${
                      option.sectionType === 'sectionHeader' && index ? 'border-t border-gray-100 pt-4' : ''
                    }
                    relative cursor-default select-none py-2 pl-10 pr-4 font-poppins font-normal text-black`
                  }
                  value={option}
                  disabled={['sectionHeader', 'sectionGroup'].includes(option.sectionType)}
                >
                  {({ selected }) => (
                    <>
                      <div
                        className={`
                          flex items-center gap-x-2 truncate
                         ${selected ? 'font-medium' : 'font-normal'} 
                         ${option.italic ? 'italic' : ''}
                       `}
                      >
                        {option.Icon && <option.Icon />}
                        <span
                          className={`w-full ${option.sectionType === 'sectionGroup' ? 'text-base font-semibold' : ''}`}
                        >
                          {option.optionLabelOverride ?? option.label}
                        </span>
                      </div>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {helpText && (
        <span className={`mt-1 block text-sm leading-6 ${error ? 'text-vermillion' : 'text-gray-400'}`}>
          {helpText}
        </span>
      )}
    </div>
  );
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      sectionType: PropTypes.oneOf(['sectionHeader', 'sectionGroup', 'sectionOption']),
      label: PropTypes.string.isRequired,
      optionLabelOverride: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      italic: PropTypes.bool,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      Icon: PropTypes.elementType,
    }),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  questionMarkTooltip: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helpText: PropTypes.string,
};

Select.defaultProps = {
  options: [],
  value: null,
  questionMarkTooltip: undefined,
  disabled: false,
  required: false,
  onChange: () => {},
  error: false,
  helpText: undefined,
};

export default Select;
