import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UpdateUserDto, UpdateUserPasswordDto } from 'models/user';
import * as yup from 'yup';

import { Box, Typography } from '@mui/material';

import { Checkbox } from '@components/atoms/Checkbox';
import { FormTextField } from '@components/atoms/FormTextField';
import { Modal } from '@components/modals/Modal';
import { useForm } from '@hooks/useTypedForm';
import { ReactComponent as GoogleIcon } from '@images/icons/google.svg';
import { useUpdateUserMutation } from '@services/auth/endpoints';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useUpdateUserPasswordMutation } from '@services/users/endpoints';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      name: yup.string().required(t('required*', 'Required*')),
      emailNotificationsFlag: yup.bool(),
      currentPassword: yup.string(),
      newPassword: yup.string().test('required', t('required*', 'Required*'), (value, context) => {
        const { currentPassword } = context.parent;
        return !currentPassword || !!value;
      }),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], t('passwordsMustMatch', 'Passwords must match')),
    })
    .required();
};

type UserSettingsModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

export const UserSettingsModal = ({ isOpen, closeModal }: UserSettingsModalProps) => {
  const { t } = useTranslation();
  const self = useAppSelector(selectUser);

  const schema = useSchema();
  const { control, register, handleSubmit, reset, setError } = useForm({
    schema,
  });

  const [updateUser, { isLoading: userUpdateLoading }] = useUpdateUserMutation();
  const [updatePassword, { isLoading: passwordUpdateLoading }] = useUpdateUserPasswordMutation();

  const loading = userUpdateLoading || passwordUpdateLoading;

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const onSubmit = async ({
    name,
    emailNotificationsFlag,
    currentPassword,
    newPassword,
  }: UpdateUserDto & UpdateUserPasswordDto) => {
    try {
      await Promise.all([
        updateUser({
          name,
          emailNotificationsFlag: emailNotificationsFlag ? 1 : 0,
        }).unwrap(),
        currentPassword &&
          newPassword &&
          updatePassword({
            currentPassword,
            newPassword,
          }).unwrap(),
      ]);

      handleCloseModal();
    } catch (error: any) {
      const errorMessage = error.data.message || error.message;
      if (errorMessage.includes('password')) {
        setError('currentPassword', { message: t('currentPasswordIncorrect', 'Current password is incorrect') });
      } else {
        setError('name', { message: errorMessage });
      }
    }
  };

  useEffect(() => {
    if (self) {
      reset({
        name: self.name,
        emailNotificationsFlag: Boolean(self.emailNotificationsFlag),
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    }
  }, [self?.name, self?.emailNotificationsFlag]);

  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      confirmText={t('saveChanges', 'Save changes')}
      title={t('userSettings', 'User settings')}
      isLoading={loading}
      onConfirm={handleSubmit(onSubmit)}
    >
      <Box display="flex" flexDirection="column" gap={1.5} mt={2}>
        <Box display="flex" gap={2}>
          {!!self?.googleVerified && <GoogleIcon height={24} width={24} />}
          <Typography variant="p18">{self?.email}</Typography>
        </Box>
        <FormTextField name="name" control={control} label={t('name', 'Name')} />
      </Box>
      <Box display="flex" flexDirection="column" mt={5} gap={2}>
        <Typography variant="p18">{t('notifications', 'Notifications')}</Typography>
        <Checkbox
          label={t('receiveNotificationEmails', 'Receive notification emails')}
          {...register('emailNotificationsFlag')}
        />
        <Typography variant="p12" color="#5E5E5E">
          {t(
            'notificationEmailsDisabledNotice',
            'By disabling notification emails, you may miss warnings or alerts for important issues at your site(s).',
          )}
        </Typography>
      </Box>
      {!!self?.hasPassword && (
        <Box display="flex" flexDirection="column" mt={5} gap={2}>
          <Typography variant="p18">{t('changePassword', 'Change password')}</Typography>
          <FormTextField
            control={control}
            name="currentPassword"
            label={t('currentPassword', 'Current password')}
            type="password"
          />
          <FormTextField
            control={control}
            name="newPassword"
            label={t('newPassword', 'New password')}
            type="password"
            autoComplete="new-password"
          />
          <FormTextField
            control={control}
            name="confirmPassword"
            label={t('confirmPassword', 'Confirm password')}
            type="password"
            autoComplete="new-password"
          />
        </Box>
      )}
    </Modal>
  );
};
