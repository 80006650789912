import { useTranslation } from 'react-i18next';

import { isBoolean, isNil } from 'lodash';
import { Device } from 'models/device';
import { DeviceStatus } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import { SettingsSection } from '@components/settings/SettingsSection';
import { SettingsSectionItem } from '@components/settings/SettingsSectionItem';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { ReactComponent as WifiIcon } from '@images/icons/Wifi.svg';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import {
  isLmcSupportingChargersAutoConfAndWifiSignalStrength,
  isLmcSupportingWifiConf,
} from './deviceConfigurationUtils';

type LmcConnectivityProps = {
  lmc: Device;
  isLoading: boolean;
};

export const LmcConnectivity = ({ lmc, isLoading }: LmcConnectivityProps) => {
  const { t } = useTranslation();

  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));
  const isOffline = lmc.status === DeviceStatus.OFFLINE;

  const wifiSsid = lmc.configuration?.[DEVICE_CONFIGURATION_PARAMETERS.WIFI_SSID.key]?.value;
  const isWifiOn = !!wifiSsid;
  const wifiSignalStrength = lmc.configuration?.[DEVICE_CONFIGURATION_PARAMETERS.WIFI_SIGNAL_STRENGTH.key]?.value;

  const getValueComponent = (value: any) => {
    if (isLoading || isNil(value)) {
      return () => undefined;
    }
    if (isOffline) {
      return () => '-';
    }
    if (isBoolean(value)) {
      return () => (value ? t('on', 'On') : t('off', 'Off'));
    }
    return () => value;
  };

  if (!isLmcSupportingWifiConf(lmc)) {
    return null;
  }

  return (
    <SettingsSection
      title={t('connectivity', 'Connectivity')}
      editPath={canEditDevices ? './connectivity' : undefined}
      editDisabled={isOffline}
    >
      <SettingsSectionItem
        Icon={WifiIcon}
        label={t('wifi', 'WiFi')}
        component={getValueComponent(isWifiOn ? wifiSsid : false)}
        {...(isLmcSupportingChargersAutoConfAndWifiSignalStrength(lmc) &&
          isWifiOn &&
          !isNil(wifiSignalStrength) && {
            subLabel: t('signalStrength', 'Signal strength'),
            subComponent: getValueComponent(`${wifiSignalStrength}%`),
          })}
      />
    </SettingsSection>
  );
};
