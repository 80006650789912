import { useTranslation } from 'react-i18next';

import { CompanyWithUser } from 'models/company';

import { Box, Typography } from '@mui/material';

import MapIcon from '@images/icons/nav/Map.svg';
import { selectDevicesBy } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { selectSitesByCompanyUuid } from '@services/sites/selectors';

type PartnerWorkspaceNameContentProps = {
  company: CompanyWithUser;
};

export const PartnerWorkspaceNameContent = ({ company }: PartnerWorkspaceNameContentProps) => {
  const { t } = useTranslation();

  const sites = useAppSelector((state) => selectSitesByCompanyUuid(state, company.uuid));
  const devices = useAppSelector((state) => selectDevicesBy(state, undefined, undefined, company.uuid));

  return (
    <Box display="flex" gap={2}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: '48px',
          height: '48px',
          borderRadius: '12px',
          backgroundColor: '#D7E1DD',
        }}
      >
        <img src={MapIcon} alt="Map" />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="p16b">{company.name}</Typography>
        <Typography variant="p14" color="#5E5E5E">
          {`${t('sites', 'Sites', {
            count: sites.length,
          })} • ${t('devices', 'Devices', {
            count: devices.length,
          })}`}
        </Typography>
      </Box>
    </Box>
  );
};
