import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { Box, InputAdornment } from '@mui/material';

import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { StyledTextField } from '@components/atoms/StyledTextField';
import { FilterMenuOption } from '@components/filters';
import { useFilterMenu } from '@components/filters/hooks/useFilterMenu';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { ReactComponent as SearchIcon } from '@images/icons/reporting/search.svg';

import { ReportScheduleContext } from './context/ReportScheduleProvider';
import { ReportingTransactionsFilterMenuOption } from './context/ReportingTransactionsProvider';

export const ReportScheduleFilterSidebar = () => {
  const { filter: selectedFilter = '' } = useParams();
  const capitalizedFilter = selectedFilter.charAt(0).toUpperCase() + selectedFilter.slice(1);
  const { filterOptionsMap, filter, setFilter } = useContext(ReportScheduleContext);
  const options = filterOptionsMap[selectedFilter as ReportingTransactionsFilterMenuOption];
  const { filterLabel } = useFilterMenu(filterOptionsMap);
  const filterMenuOptions = (options?: StyledMenuItemProps[]) => {
    return options?.filter((option) => option.label?.toLowerCase().includes(filter.toLowerCase()));
  };

  const [filteredMenuOptions, setFilteredMenuOptions] = useState(filterMenuOptions(options));
  const filteredMenuOptionsLength = filteredMenuOptions?.length ?? 0;

  useEffect(() => {
    setFilteredMenuOptions(filterMenuOptions(options));
  }, [filter, options]);

  return (
    <EditingSidebarBase
      title={capitalizedFilter}
      containerClassName="flex flex-col gap-2"
      containerBottomPadding="pb-6"
      noActions
      isSubSidebar
    >
      <StyledTextField
        name="vool-filter"
        autoComplete="off"
        placeholder={filterLabel[selectedFilter as FilterMenuOption]}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ color: '#282828', cursor: 'default' }}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box display="flex" flexDirection="column" height={1}>
        {filteredMenuOptionsLength < 19 ? (
          filteredMenuOptions?.map((option) => <StyledMenuItem key={option.uuid} width={1} {...option} />)
        ) : (
          <Virtuoso
            totalCount={filteredMenuOptionsLength}
            itemContent={(index) => (
              <StyledMenuItem key={filteredMenuOptions?.[index].uuid} width={1} {...filteredMenuOptions?.[index]} />
            )}
          />
        )}
      </Box>
    </EditingSidebarBase>
  );
};
