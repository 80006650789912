import { FunctionComponent, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps, Checkbox, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { StringAvatar } from './StringAvatar';

export type StyledMenuItemProps = BoxProps & {
  uuid?: string;
  checked?: boolean;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>> | OverridableComponent<SvgIconTypeMap>;
  avatar?: string;
  label?: string;
  subtitle?: string;
  resultCount?: number;
  disabled?: boolean;
};

export const StyledMenuItem = ({
  checked,
  Icon,
  avatar,
  label,
  subtitle,
  resultCount,
  title,
  alignItems,
  hidden,
  disabled,
  onClick,
  ...props
}: StyledMenuItemProps) => {
  const { t } = useTranslation();

  return (
    <Box
      display={hidden ? 'none' : 'flex'}
      alignItems="center"
      gap={1}
      p={1}
      height={subtitle ? 56 : 40}
      width="333px"
      {...props}
      onClick={onClick && !disabled ? onClick : undefined}
      sx={{
        ...(disabled
          ? {
              color: '#D6D6D6',
              cursor: 'default',
            }
          : {
              cursor: 'pointer',
              '&:hover': {
                borderRadius: '4px',
                backgroundColor: '#F4F4F4',
              },
            }),
        ...props.sx,
      }}
    >
      {checked !== undefined && (
        <Checkbox
          checked={checked}
          sx={{
            padding: 0,
            width: '16px',
            height: '16px',
            color: '#D6D6D6',
            '&.Mui-checked': { color: '#386957' },
          }}
        />
      )}
      {Icon && <Icon sx={{ fontSize: '16px' }} />}
      {avatar && <StringAvatar name={avatar}></StringAvatar>}
      {(label || title || subtitle) && (
        <Box display="flex" flexDirection="column" flex={1} overflow="hidden" alignItems={alignItems}>
          <Typography variant="p14" noWrap>
            {label ?? title}
          </Typography>
          <Typography variant="p12" color="#5E5E5E" noWrap>
            {subtitle}
          </Typography>
        </Box>
      )}
      {resultCount !== undefined && (
        <Typography variant="p12" color="#999898">{`${resultCount} ${t('results', 'results')}`}</Typography>
      )}
    </Box>
  );
};
