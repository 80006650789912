import { Box } from '@mui/material';

import { useBreakpoints } from '@hooks/useBreakpoints';

import { PartnersDevicesDashboard } from './PartnersDevicesDashboard';
import { PartnersSummary } from './PartnersSummary';
import { PartnersWorkspacesDashboard } from './PartnersWorkspacesDashboard';

export const PartnersDashboard = () => {
  const { isSmallScreen } = useBreakpoints();

  return (
    <Box display="flex" flexDirection="column" gap={2} width={1}>
      <PartnersSummary />
      <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={2} alignItems="stretch">
        <PartnersDevicesDashboard />
        <PartnersWorkspacesDashboard />
      </Box>
    </Box>
  );
};
