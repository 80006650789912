import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AddSiteDto } from 'models/site';

import { useAppDispatch } from '@services/hooks';
import { useAddSiteMutation } from '@services/sites/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { SiteDetailsSidebarBase } from './SiteDetailsSidebarBase';

export const AddSiteSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [addSite, { isLoading }] = useAddSiteMutation();

  const addedSiteUuidRef = useRef<string>();

  const saveValues = async (attributes: AddSiteDto) => {
    try {
      const data = await addSite(attributes).unwrap();

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('siteAdded', 'Site added'),
          message: t('youCanSeeTheSiteInTheList', 'You can see the site in your sites list.'),
        }),
      );
      addedSiteUuidRef.current = data.uuid;

      return true;
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('addingSiteFailed', 'Adding the site failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );

      return false;
    }
  };

  const getReturnPath = () => (addedSiteUuidRef.current ? `/sites/${addedSiteUuidRef.current}` : undefined);

  return (
    <SiteDetailsSidebarBase
      title={t('addNewSite', 'Add a new site')}
      saveLabel={t('add', 'Add')}
      discardLabel={t('cancel', 'Cancel')}
      isSaveLoading={isLoading}
      getReturnPath={getReturnPath}
      onSaveValues={saveValues}
    />
  );
};
