import moment from 'moment';

import DataHandler from '../data';
import { BRANDS_INFO_LIST, CHARGE_STATUSES, VEHICLE_STATUS_OPTIONS } from './constVehicle';

export default class VehicleHandler {
  vehicle = null;

  constructor(vehicle) {
    this.vehicle = vehicle;
  }

  getData() {
    return this.vehicle;
  }

  getStatusDataByOption(option) {
    const value = this.getData()?.status?.[option];
    if (!value) {
      return CHARGE_STATUSES.UNKNOWN;
    }
    if (option === VEHICLE_STATUS_OPTIONS.chargeStatus) {
      return CHARGE_STATUSES[value] || CHARGE_STATUSES.UNKNOWN;
    }
    if (option === VEHICLE_STATUS_OPTIONS.batteryLevel) {
      return `${value} %`;
    }
    if (option === VEHICLE_STATUS_OPTIONS.distanceToBatteryEmpty) {
      return `${value} km`;
    }
    if (option === VEHICLE_STATUS_OPTIONS.timeToFullyCharged) {
      return DataHandler.isEqual(this.getData().status.batteryLevel, 100) ? '0 mins' : `${value} mins`;
    }
    return value;
  }

  getBrandData() {
    if (!this.getData()) return null;
    return BRANDS_INFO_LIST[this.getData().brand.toLowerCase()];
  }

  getLastUpdateTimeText() {
    const data = this.getData();
    if (!data?.updateTime) return 'Last Update: On hold';
    const time = moment(data.updateTime).local().format('LL HH:mm');
    return `Last update: ${time}`;
  }

  static getBrandListInfo() {
    return Object.entries(BRANDS_INFO_LIST)
      .filter(([, info]) => !info.disabled)
      .map(([key, info]) => ({ key, ...info }));
  }
}
