import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { ReactComponent as StarIcon } from '@images/icons/Star.svg';
import { ReactComponent as OutlinedStarIcon } from '@images/icons/Star_Outlined.svg';

export enum FavoriteOption {
  FAVORITES = '1',
  NON_FAVORITES = '0',
}

export const useFavoriteMenu = () => {
  const { t } = useTranslation();
  const [favoriteMenuOptions, setFavoriteMenuOptions] = useState<StyledMenuItemProps[]>([
    {
      uuid: FavoriteOption.FAVORITES,
      Icon: () => <StarIcon color="#386957" height={16} width={16} />,
      checked: false,
      label: t('favorites', 'Favorites'),
      onClick: () => toggleOptionChecked(FavoriteOption.FAVORITES),
    },
    {
      uuid: FavoriteOption.NON_FAVORITES,
      Icon: () => <OutlinedStarIcon height={16} width={16} />,
      checked: false,
      label: t('nonFavorites', 'Non-favorites'),
      onClick: () => toggleOptionChecked(FavoriteOption.NON_FAVORITES),
    },
  ]);

  const toggleOptionChecked = (selectedOption: string) => {
    setFavoriteMenuOptions((prevOptions) =>
      prevOptions.map((option) => {
        if (option.uuid === selectedOption) {
          return { ...option, checked: !option.checked };
        }
        return option;
      }),
    );
  };

  return { favoriteMenuOptions, setFavoriteMenuOptions };
};
