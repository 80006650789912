import { Country } from 'models/country';

import apiSlice from '@services/api';

const countriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => ({
        url: '/countries',
        method: 'GET',
      }),
      transformResponse: ({ data }: { data: Country[] }) => data,
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
