import { sumBy } from 'lodash';

const USER_PROFILE_COLORS = [
  '#EB4E20',
  '#407090',
  '#386957',
  '#EB8520',
  '#454090',
  '#409040',
  '#DE1E3E',
  '#2085EB',
  '#B2B22B',
  '#7B4090',
];

const cachedColorByString: Record<string, string> = {};

export const getUserProfileColorByString = (value: string) => {
  if (cachedColorByString[value]) {
    return cachedColorByString[value];
  }
  const hash = sumBy(value, (char) => char.charCodeAt(0));
  const color = USER_PROFILE_COLORS[hash % USER_PROFILE_COLORS.length];
  cachedColorByString[value] = color;
  return color;
};
