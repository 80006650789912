import { Context, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { FilterMenuOption } from '@components/filters';
import { FiltersBaseContextType } from '@components/filters/context/FiltersContext';

type NoResultsCardProps<ContextType, ExtractedType extends FilterMenuOption> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType>>;
  hideFilterReset?: boolean;
};

export const NoResultsCard = <ContextType, ExtractedType extends FilterMenuOption>({
  context,
  hideFilterReset,
}: NoResultsCardProps<ContextType, ExtractedType>) => {
  const { t } = useTranslation();
  const { reset, anyActiveFilters } = useContext(context);

  return (
    <CardContainer
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={2}
      sx={{
        p: '40px 56px',
      }}
    >
      <Box
        width={80}
        height={80}
        border="4px solid #999898"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Close
          sx={{
            color: '#999898',
            fontSize: '32px',
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="h2" textAlign="center">
          {t('noResultsFound', 'No results found')}
        </Typography>
        {anyActiveFilters && !hideFilterReset && (
          <Typography variant="p16" textAlign="center">
            {t('adjustOrClearFilterSettingsToSeeResults', 'Adjust or clear filter settings to see results.')}
          </Typography>
        )}
      </Box>
      {anyActiveFilters && !hideFilterReset && (
        <Typography
          variant="p16"
          textAlign="center"
          color="#EB4E20"
          onClick={() => reset?.()}
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {t('resetFilters', 'Reset filters')}
        </Typography>
      )}
    </CardContainer>
  );
};
