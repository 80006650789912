import React from 'react';
import { ChangeHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@components/atoms/Checkbox';

type CellularEnabledCheckboxProps = {
  isCellularSupported: boolean;
  name: string;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
};

export const CellularEnabledCheckbox = React.forwardRef<HTMLInputElement, CellularEnabledCheckboxProps>(
  ({ isCellularSupported, name, onChange, onBlur }, ref) => {
    const { t } = useTranslation();
    return (
      <div className="flex flex-col gap-y-6">
        <Checkbox
          name={name}
          label={t('cellularEnabled', 'Cellular enabled')}
          className="!text-base"
          disabled={!isCellularSupported}
          onChange={onChange}
          onBlur={onBlur}
          ref={ref}
        />
        <div className="text-gray-600">
          {isCellularSupported
            ? t('cellularEnabledText', 'Allow the charger to communicate via LTE network.')
            : t('cellularNotSupportedText', 'The charger does not support LTE.')}
        </div>
      </div>
    );
  },
);

CellularEnabledCheckbox.displayName = 'CellularEnabledCheckbox';
