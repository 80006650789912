import { Box, Skeleton, Table, TableBody, TableCell, TableContainer } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';

export const PartnersWorkspacesTableRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell width={380}>
      <Box display="flex" justifyContent="right">
        <Skeleton variant="text" animation="wave" width="30%" />
      </Box>
    </TableCell>
  </StyledSkeletonTableRow>
);

export const PartnersWorkspacesTableSkeleton = () => (
  <TableContainer component={CardContainer} sx={{ p: 0 }}>
    <Table>
      <TableBody>
        {Array.from(Array(5).keys()).map(() => (
          <PartnersWorkspacesTableRowSkeleton key={self.crypto.randomUUID()} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
