import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useOutletContext } from 'react-router-dom';

import { Site } from 'models/site';

import { Box, Typography, useTheme } from '@mui/material';

import { ReactComponent as CheckIcon } from '@images/icons/subscriptions/check.svg';

import { WorkspaceSetupSidebarBase } from './WorkspaceSetupSidebarBase';

export const WorkspaceSetupSidebar = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const context = useOutletContext<{ site?: Site }>();
  const returnPath = context?.site ? '../' : '/workspace/subscriptions';
  const [site] = useState(context?.site);

  const steps = {
    paymentMethod: t('payment', 'Payment'),
    companyDetails: t('details', 'Details'),
    payoutDetails: t('payout', 'Payout'),
  };

  const location = useLocation();
  const urlSegment = location.pathname.split('/').pop();
  const currentStepIndex = Object.keys(steps).indexOf(urlSegment as string);

  return (
    <WorkspaceSetupSidebarBase title={t('workspaceSetup', 'Workspace setup')} returnPath={returnPath}>
      <Box display="flex">
        {Object.keys(steps).map((stepPath, index) => (
          <Typography
            key={crypto.randomUUID()}
            variant={stepPath === urlSegment ? 'p16b' : 'p16'}
            p={1}
            flex={1}
            textAlign="center"
            borderBottom={`2px solid ${
              currentStepIndex < index ? theme.palette.lightGray : theme.palette.mediumVermilion
            }`}
          >
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
              {currentStepIndex > index ? <CheckIcon /> : <span>{`${index + 1}.`}</span>}
              {steps[stepPath as keyof typeof steps]}
            </Box>
          </Typography>
        ))}
      </Box>
      <Outlet context={{ site }} />
    </WorkspaceSetupSidebarBase>
  );
};
