import { ConnectorComputedStatus } from 'models/device.enums';

import { Tooltip } from '@mui/material';

import { useComputedStatusDefs } from '@views/Chargers/useComputedStatusDefs';

export const InverterStatusContent = ({ isProducing }: { isProducing: boolean }) => {
  const computedStatusDefs = useComputedStatusDefs();
  const statusLabel = isProducing ? 'Producing' : 'Not producing';
  const statusDef = isProducing
    ? computedStatusDefs[ConnectorComputedStatus.AVAILABLE]
    : computedStatusDefs[ConnectorComputedStatus.OFFLINE];
  return (
    <Tooltip title={statusLabel} placement="top">
      <div className="flex items-center">
        <img
          src={statusDef.icon}
          alt={statusLabel}
          className={`mr-2 inline-block rounded-full ${statusDef.iconClassName}`}
        />
        <span className={`${statusDef.textClassName} whitespace-nowrap`}>{statusLabel}</span>
      </div>
    </Tooltip>
  );
};
