import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Company } from 'models/company';

import { PartnerWorkspaceContext } from '@core/WorkspaceGuard';
import { trackAmplitudeEvent } from '@handlers/amplitude';
import { AmplitudeEvent, getAmplitudeEventPropertiesForUser } from '@handlers/amplitude/data';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import apiSlice from '@services/api';
import { useUpdateUserAndInvalidateCompanyMutation } from '@services/auth/endpoints';
import { selectUser } from '@services/auth/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { useNavigateToSites } from './useNavigateToSites';

export const useNavigateAfterCompanySwitch = () => {
  const navigate = useNavigate();
  const { navigateToSites } = useNavigateToSites();

  return () => {
    const [, rootSegment] = window.location.pathname.split('/');
    if (rootSegment === 'devices') {
      navigateToSites();
    } else if (rootSegment !== 'company') {
      navigate(`/${rootSegment}`);
    }
  };
};

export const useSwitchToCompany = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setActivePartnerCompanyUuid } = useContext(PartnerWorkspaceContext);
  const navigateAfterCompanySwitch = useNavigateAfterCompanySwitch();

  const user = useAppSelector(selectUser);
  const [updateUserAndInvalidateCompany] = useUpdateUserAndInvalidateCompanyMutation();

  return async (company: Company) => {
    try {
      if (user?.activeCompanyUuid === company.uuid && !isSubdomainPartner()) {
        return;
      }
      if (isSubdomainPartner()) {
        setActivePartnerCompanyUuid(company.uuid);
        dispatch(apiSlice.util.invalidateTags(['PartnerContext']));
      } else {
        await updateUserAndInvalidateCompany({ activeCompanyUuid: company.uuid }).unwrap();
      }

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('switchedToWorkspace', 'Switched to workspace: {{companyName}}', { companyName: company.name }),
        }),
      );
      trackAmplitudeEvent(AmplitudeEvent.SWITCH_WORKSPACE, {
        ...getAmplitudeEventPropertiesForUser(user!),
        companyId: company.uuid,
      });

      navigateAfterCompanySwitch();
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('failedToSwitchToWorkspace', 'Failed to switch to workspace: {{companyName}}', {
            companyName: company.name,
          }),
        }),
      );
    }
  };
};
