import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { Site, SiteSettings } from 'models/site';

import { sitesApi } from './endpoints';

type SitesState = {
  list: Site[];
};

const initialState: SitesState = {
  list: [],
};

const addSite = (state: SitesState, { payload }: { payload: Site }) => {
  state.list = uniqBy([payload, ...state.list], 'uuid');
};

const addSites = (state: SitesState, { payload }: { payload: Site[] }) => {
  state.list = uniqBy(
    [...state.list.map((s) => ({ ...s, ...(payload.find((pl: any) => pl.uuid === s.uuid) || {}) })), ...payload],
    'uuid',
  );
};

const updateSite = (state: SitesState, { payload }: { payload: Site }) => {
  const siteIndex = state.list.findIndex(({ uuid }) => uuid === payload.uuid);
  state.list[siteIndex] = payload;
};

const updateSiteSettings = (state: SitesState, { payload, meta }: { payload: SiteSettings; meta: any }) => {
  const { uuid } = meta.arg.originalArgs;
  const site = state.list.find((s) => s.uuid === uuid);
  if (site) {
    site.settings = payload;
  }
};

const deleteSite = (state: SitesState, { payload: { siteUuid } }: { payload: { siteUuid: string } }) => {
  const siteIndex = state.list.findIndex(({ uuid }) => uuid === siteUuid);
  state.list.splice(siteIndex, 1);
};

export const dataSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(sitesApi.endpoints.getSites.matchFulfilled, addSites);
    builder.addMatcher(sitesApi.endpoints.getAllSites.matchFulfilled, addSites);
    builder.addMatcher(sitesApi.endpoints.addSite.matchFulfilled, addSite);
    builder.addMatcher(sitesApi.endpoints.getSite.matchFulfilled, addSite);
    builder.addMatcher(sitesApi.endpoints.updateSite.matchFulfilled, updateSite);
    builder.addMatcher(sitesApi.endpoints.updateSiteSettings.matchFulfilled, updateSiteSettings);
    builder.addMatcher(sitesApi.endpoints.deleteSite.matchFulfilled, deleteSite);
  },
});

export default dataSlice.reducer;
