import { Helmet } from 'react-helmet';

import { tail } from 'lodash';

import { Box } from '@mui/material';

import { useBreakpoints } from '@hooks/useBreakpoints';
import { ReactComponent as SidebarToggleIcon } from '@images/icons/nav/Sidebar_toggle.svg';

import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';
import { StyledBreadcrumbs } from './StyledBreadCrumbs';

type BreadcrumbsBaseProps = {
  breadcrumbsItems: BreadcrumbItemProps[];
};

export const BreadcrumbsBase = ({ breadcrumbsItems }: BreadcrumbsBaseProps) => {
  const { isMobile } = useBreakpoints();

  const pageTitle = (breadcrumbsItems.length > 2 ? tail(breadcrumbsItems) : breadcrumbsItems)
    .map((item) => item.label)
    .join(' ► ');

  const getFormattedBreadcrumbs = (breadcrumbsItems: BreadcrumbItemProps[]) => {
    if ((breadcrumbsItems.length > 2 && isMobile) || breadcrumbsItems.length > 3) {
      const [first, ...rest] = breadcrumbsItems;
      const last = rest.pop() as BreadcrumbItemProps;
      return [first, { label: '...', path: '', options: rest }, last];
    }
    return breadcrumbsItems;
  };
  const formattedBreadcrumbs = getFormattedBreadcrumbs(breadcrumbsItems);
  const isTheLastBreadcrumb = (index: number) => index === formattedBreadcrumbs.length - 1;

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" py={isMobile ? 2 : 3} overflow="hidden">
      <Helmet>
        <title>{pageTitle} - VOOL</title>
      </Helmet>
      <StyledBreadcrumbs separator={<SidebarToggleIcon className="h-5 w-5 rotate-180 object-cover sm:h-8 sm:w-8" />}>
        {formattedBreadcrumbs.map(({ path, ...rest }, index) => (
          <BreadcrumbItem
            key={path}
            isMobile={isMobile}
            {...(isTheLastBreadcrumb(index)
              ? {
                  path: '#',
                  color: '#282828',
                  sx: { cursor: 'default' },
                }
              : {
                  path,
                  color: '#999898',
                  sx: { cursor: 'pointer' },
                })}
            {...rest}
          />
        ))}
      </StyledBreadcrumbs>
    </Box>
  );
};
