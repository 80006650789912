import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Button from '@components/atoms/Button';

const QuickFilters = ({ data, filters, onSelectedData, onActiveFilterChange }) => {
  const [activeFilters, setActiveFilters] = useState([]);
  const [filterCounts, setFilterCounts] = useState({});

  const isItemInArray = (item, array) => array.some((arrayItem) => arrayItem.key === item.key);

  const handleFilterClick = (filter) => {
    const isActive = isItemInArray(filter, activeFilters);
    const newActiveFilters = isActive
      ? activeFilters.filter(({ key }) => key !== filter.key)
      : [...activeFilters, filter];

    setActiveFilters(newActiveFilters);
  };

  useEffect(() => {
    const newFilterCounts = Object.fromEntries(filters.map((filter) => [filter.key, 0]));
    const newFilteredData = data.filter((item) => {
      const matchedFilters = filters.filter(({ filterFunction }) => filterFunction(item));
      const isMatched = !activeFilters.length || activeFilters.every((filter) => isItemInArray(filter, matchedFilters));
      if (isMatched) {
        matchedFilters.forEach(({ key }) => {
          newFilterCounts[key] += 1;
        });
      }
      return isMatched;
    });

    setFilterCounts(newFilterCounts);
    onSelectedData(newFilteredData);
  }, [data, activeFilters]);

  useEffect(() => {
    onActiveFilterChange(activeFilters);
  }, [filterCounts]);

  return (
    <div className="flex gap-x-2">
      {filters.map((filter) => {
        const isActive = isItemInArray(filter, activeFilters);
        return (
          <Button
            key={filter.key}
            className={`flex min-w-[100px] items-center gap-x-2 px-[7px] py-1 ${isActive ? '!bg-gray-600' : ''} ${
              filter.icon ? '' : 'pl-3'
            }`}
            variant="secondary"
            onClick={() => handleFilterClick(filter)}
          >
            {filter.icon && (
              <filter.icon className={`h-5 w-5 ${isActive ? '[&_path]:fill-white' : '[&_path]:fill-gray-600'}`} />
            )}
            <span className={isActive ? 'text-white' : 'text-gray-600'}>{filter.label}</span>
            <span
              className={`rounded-2xl px-2 py-0.5 ${isActive ? 'bg-white text-gray-600' : 'bg-gray-600 text-white'}`}
            >
              {filterCounts[filter.key] ?? 0}
            </span>
          </Button>
        );
      })}
    </div>
  );
};

QuickFilters.propTypes = {
  data: PropTypes.array.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.elementType,
      filterFunction: PropTypes.func.isRequired,
    }),
  ).isRequired,
  onSelectedData: PropTypes.func,
  onActiveFilterChange: PropTypes.func,
};

QuickFilters.defaultProps = {
  onSelectedData: () => {},
  onActiveFilterChange: () => {},
};

export default QuickFilters;
