import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from '@components/atoms/Input';
import { Modal } from '@components/modals/Modal';
import { useUpdateActiveCompanyMutation } from '@services/companies/endpoints';
import { selectActiveCompany } from '@services/companies/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

type WorkspaceRenameModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const WorkspaceRenameModal = ({ isOpen, onClose }: WorkspaceRenameModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const activeCompany = useAppSelector(selectActiveCompany);

  const [name, setName] = useState('');

  const workspaceLabel = t('workspace', 'Workspace');

  const [updateActiveCompany, { isLoading }] = useUpdateActiveCompanyMutation();

  useEffect(() => {
    if (isOpen) {
      setName(activeCompany?.name || '');
    }
  }, [activeCompany?.name, isOpen]);

  const handleSave = async () => {
    try {
      await updateActiveCompany({ name: name.trim() }).unwrap();
      dispatch(
        addToastMessage({ type: 'success', title: t('itemRenamed', '{{item}} renamed', { item: workspaceLabel }) }),
      );
      onClose();
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('renamingTheItemFailed', 'Renaming the {{item}} failed', {
            item: workspaceLabel.toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  const canSave = !isLoading && !!name?.trim();

  return (
    <Modal
      title={t('renameItem', 'Rename {{item}}', { item: workspaceLabel.toLowerCase() })}
      onConfirm={handleSave}
      confirmText={t('renameItem', 'Rename {{item}}', { item: workspaceLabel.toLowerCase() })}
      confirmButtonDisabled={!canSave}
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isLoading}
    >
      <Input
        name="newName"
        label={t('itemName', '{{item}} name', { item: workspaceLabel })}
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputProps={{ onKeyDown: (e) => e.key === 'Enter' && canSave && handleSave() }}
      />
    </Modal>
  );
};
