import { useTranslation } from 'react-i18next';

import { DeviceConnectorActiveProgram } from 'models/device';
import { DeviceConnectorChargingProgramAlgorithm } from 'models/device.enums';

import { Typography } from '@mui/material';

type ConnectorActiveProgramContentProps = {
  activeProgram: DeviceConnectorActiveProgram;
};

export const ConnectorActiveProgramContent = ({ activeProgram }: ConnectorActiveProgramContentProps) => {
  const { t } = useTranslation();

  const chargingProgramAlgorithmLabels = {
    [DeviceConnectorChargingProgramAlgorithm.CHEAPEST_MINUTES]: t('cheapestMinutes', 'Cheapest minutes'),
    [DeviceConnectorChargingProgramAlgorithm.FULL_BATTERY_WITH_CHEAPEST_HOURS]: t(
      'fullBatteryWithCheapestHours',
      'Full battery with cheapest hours',
    ),
    [DeviceConnectorChargingProgramAlgorithm.INVERTER_CONTROLLED]: t('solar', 'Solar'),
    [DeviceConnectorChargingProgramAlgorithm.MANUAL]: t('manual', 'Manual'),
    [DeviceConnectorChargingProgramAlgorithm.PRICE_BASED]: t('priceBased', 'Price-based'),
  };

  const chargingProgramAlgorithmLabel = chargingProgramAlgorithmLabels[activeProgram.algorithm] ?? '';

  return <Typography variant="p14">{chargingProgramAlgorithmLabel}</Typography>;
};
