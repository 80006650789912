import { TransactionStatus } from 'models/transaction.enums';

import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

export const selectSlice = (state: RootState) => state.transactions;

export const selectTransactions = createAppSelector(selectSlice, (slice) => slice.transactions);
export const selectEndedTransactions = createAppSelector(selectTransactions, (transactions) =>
  transactions.list.filter((t) => t.startTime && t.transactionId && t.status === TransactionStatus.ENDED),
);
