import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, useTheme } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { DiscardChangesModal } from '@components/modals/DiscardChangesModal';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';

import { WorkspaceSetupSidebarContext } from './WorkspaceSetupSidebarProvider';

type WorkspaceSetupFooterProps = {
  onNext: () => void;
  onCancel?: () => void;
  saveDisabled?: boolean;
  isFinalStep?: boolean;
  isSetup?: boolean;
};

export const WorkspaceSetupFooter = ({
  onNext,
  onCancel,
  saveDisabled,
  isFinalStep,
  isSetup,
}: WorkspaceSetupFooterProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { anyChanges, setAnyChanges, closeRequested, setCloseSidebar } = useContext(WorkspaceSetupSidebarContext);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  useEffect(() => {
    if (closeRequested && anyChanges) {
      setIsDiscardModalOpen(true);
    }

    return () => {
      setAnyChanges(false);
    };
  }, [closeRequested]);

  const handleClose = () => {
    if (closeRequested) {
      setAnyChanges(false);
      setCloseSidebar(true);
    } else {
      onCancel?.();
    }
  };

  return (
    <Box display="flex" justifyContent="end" gap={2} p="16px 24px" borderTop={`1px solid ${theme.palette.lightGray}`}>
      <StyledButton
        color="secondary"
        onClick={() => (anyChanges ? setIsDiscardModalOpen(true) : handleClose())}
        sx={{ flex: 1 }}
      >
        {isSetup ? t('back', 'Back') : t('cancel', 'Cancel')}
      </StyledButton>
      <StyledButton color="success" onClick={onNext} disabled={saveDisabled} sx={{ flex: 2 }}>
        <Box display="flex" alignItems="center" gap={1}>
          {!isSetup && t('saveChanges', 'Save changes')}
          {!isFinalStep && isSetup && t('next', 'Next')}
          {isFinalStep && isSetup && t('finish', 'Finish')}
          {!isFinalStep && isSetup && (
            <ArrowLeftIcon
              width={16}
              height={16}
              color={saveDisabled ? theme.palette.spanishGray : theme.palette.pureWhite}
              style={{ transform: 'rotate(180deg)' }}
            />
          )}
        </Box>
      </StyledButton>
      <DiscardChangesModal
        isOpen={isDiscardModalOpen}
        onClose={() => setIsDiscardModalOpen(false)}
        onDiscard={() => {
          setIsDiscardModalOpen(false);
          setAnyChanges(false);
          handleClose();
        }}
      />
    </Box>
  );
};
