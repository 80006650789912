import React, { useRef } from 'react';

import { Menu } from '@headlessui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { Typography } from '@mui/material';

import { ReactComponent as MenuArrowImage } from '@images/icons/MenuArrow.svg';

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

export type MenuItem = {
  title: string;
  key: string;
  onClick: () => void;
  className?: string;
  hidden?: boolean;
  disabled?: boolean;
  icon?: any;
  trailingIcon?: any;
  items?: MenuItem[];
};

type MenuComponentProps = {
  menuItems: MenuItem[];
  buttonText?: string;
  buttonIcon?: any;
  disabled?: boolean;
  isSubmenu?: boolean;
  closeMenu?: () => void;
  noMenuHover?: boolean;
  menuPosition?: string;
};

export const MenuComponent = React.forwardRef(
  (
    {
      menuItems,
      buttonText,
      buttonIcon,
      disabled,
      isSubmenu,
      closeMenu,
      noMenuHover,
      menuPosition = 'origin-top-right',
    }: MenuComponentProps,
    ref: React.ForwardedRef<HTMLElement>,
  ) => {
    const areAllMenuItemsHidden = menuItems.every((item) => item.hidden);
    const containerRef = useRef<HTMLDivElement>(null);
    const itemsContainerRef = useRef<HTMLDivElement>(null);

    const getIconComponent = (icon: any) => {
      if (typeof icon === 'string') {
        return <img src={icon} alt="menu icon" className="h-5 w-5 min-w-[20px]" />;
      }
      const Icon = icon;
      return <Icon className="h-5 w-5 min-w-[20px]" />;
    };

    const ButtonIcon = () =>
      buttonIcon ? getIconComponent(buttonIcon) : <DotsHorizontalIcon className="h-5 w-5 min-w-[20px]" />;

    const getItemsPositionStyle = (isOpen: boolean) => {
      if (isOpen && containerRef.current && itemsContainerRef.current) {
        const { right, bottom, height: containerHeight } = containerRef.current.getBoundingClientRect();
        const { width, height: itemsContainerHeight } = itemsContainerRef.current.getBoundingClientRect();

        const isOverflowingRight = right + width > window.innerWidth;
        const isOverflowingBottom = bottom + itemsContainerHeight > window.innerHeight;

        const horizontalPosition = isOverflowingRight ? 'left' : 'right';
        const verticalPosition = isOverflowingBottom ? 'bottom' : 'top';

        const positionStyle = isSubmenu
          ? { [horizontalPosition]: -width - 4, top: -16 }
          : { [verticalPosition]: containerHeight + 4, right: 0 };

        return positionStyle;
      }

      return { right: 0 };
    };

    return (
      <Menu as="div" className="relative" ref={ref} hidden={areAllMenuItemsHidden}>
        {({ open }) => (
          <div ref={containerRef}>
            <Menu.Button
              disabled={disabled}
              className={`flex w-full max-w-xs items-center rounded p-1 
              disabled:text-gray-200 disabled:[&_path]:stroke-gray-200
              ${!noMenuHover ? 'enabled:hover:bg-gray-100' : ''}}`}
            >
              {isSubmenu ? (
                <>
                  <Typography variant="p14" px={3.5} py={0.5}>
                    {buttonText}
                  </Typography>
                  <MenuArrowImage className="absolute right-2" style={{ transform: 'rotate(-90deg)' }} />
                </>
              ) : (
                <ButtonIcon />
              )}
            </Menu.Button>
            <Menu.Items
              className={`absolute z-30 mt-2 min-w-[200px] ${menuPosition} rounded-lg bg-white py-2 shadow ring-1 ring-black ring-opacity-5 focus:outline-none`}
              style={getItemsPositionStyle(open)}
            >
              <div ref={itemsContainerRef}>
                {menuItems
                  ?.filter((item) => !item.hidden)
                  .map((item) => {
                    return (
                      <Menu.Item key={item.key} disabled={item.disabled}>
                        {({ active, close }) =>
                          item.items ? (
                            <MenuComponent
                              key={item.key}
                              menuItems={item.items}
                              buttonText={item.title}
                              disabled={item.disabled}
                              closeMenu={close}
                              isSubmenu
                            />
                          ) : (
                            <div
                              onKeyUp={() => {}}
                              tabIndex={0}
                              onClick={() => {
                                item.onClick();
                                closeMenu?.();
                              }}
                              role="button"
                              className={classNames(
                                item.disabled ? 'cursor-default text-gray-200' : 'cursor-pointer',
                                active ? 'bg-gray-100' : '',
                                'flex items-center px-4 py-2',
                              )}
                            >
                              {item.icon && <item.icon />}
                              <div
                                className={classNames(
                                  item.className ?? '',
                                  item.icon ? 'pl-1' : '',
                                  'block whitespace-nowrap px-4 font-poppins text-sm leading-6',
                                )}
                              >
                                {item.title}
                              </div>
                              {!!item.trailingIcon && getIconComponent(item.trailingIcon)}
                            </div>
                          )
                        }
                      </Menu.Item>
                    );
                  })}
              </div>
            </Menu.Items>
          </div>
        )}
      </Menu>
    );
  },
);

MenuComponent.displayName = 'MenuComponent';
