import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import Button from '@components/atoms/Button';
import { ReactComponent as ErrorBoundaryIcon } from '@images/icons/errorBoundary.svg';

type ErrorBoundaryFallbackProps = { description: string };

export const ErrorBoundaryFallback = ({ description }: ErrorBoundaryFallbackProps) => {
  const { t } = useTranslation();
  const handleReload = () => window.location.reload();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '16px',
      }}
    >
      <ErrorBoundaryIcon />
      <Typography variant="h2" maxWidth="425px">
        {t('somethingUnknownHappened', 'Something unknown happened')}
      </Typography>
      <Typography variant="p14" maxWidth="425px" color="#5E5E5E">
        {description ?? t('tryReloading', 'Try reloading.')}
      </Typography>
      <Button variant="primary" bigger onClick={handleReload}>
        {t('reload', 'Reload')}
      </Button>
    </Box>
  );
};
