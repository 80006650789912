import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { Company } from 'models/company';

import { CompanyResponse, GetCompaniesResponse, companiesApi } from './endpoints';

type CompaniesState = {
  list: Company[];
};

const initialState: CompaniesState = {
  list: [],
};

const setCompanies = (state: CompaniesState, { payload }: { payload: GetCompaniesResponse }) => {
  state.list = payload.data || [];
};

const addCompany = (state: CompaniesState, { payload }: { payload: CompanyResponse }) => {
  state.list = uniqBy([...state.list, payload.data], 'uuid');
};

const updateCompany = (state: CompaniesState, { payload }: { payload: CompanyResponse }) => {
  const { uuid, ...data } = payload.data || {};
  const companyInStore = state.list.find((c) => c.uuid === uuid);
  if (companyInStore) {
    Object.assign(companyInStore, data);
  }
};

export const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(companiesApi.endpoints.getCompanies.matchFulfilled, setCompanies);
    builder.addMatcher(companiesApi.endpoints.createCompany.matchFulfilled, addCompany);
    builder.addMatcher(companiesApi.endpoints.updateActiveCompany.matchFulfilled, updateCompany);
    builder.addMatcher(companiesApi.endpoints.saveCompanyPaymentMethod.matchFulfilled, updateCompany);
  },
});

export default companiesSlice.reducer;
