import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Site } from 'models/site';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetAllSitesQuery, useGetSitesQuery } from '@services/sites/endpoints';

export const useSiteMenu = <ContextDataType,>(items?: ContextDataType[], resultCountFn?: (site: Site) => number) => {
  const { t } = useTranslation();
  const { companyUuid } = useParams();
  const self = useAppSelector(selectUser);
  const skipGetAll = isSubdomainPartner() ? !!companyUuid : !self?.admin;
  const { data, isFetching, isError } = useGetSitesQuery({ companyUuid }, { skip: !skipGetAll });
  const {
    data: dataAdmin,
    isFetching: isFetchingAdmin,
    isError: isErrorAdmin,
  } = useGetAllSitesQuery(undefined, { skip: skipGetAll });
  const [siteMenuOptions, setSiteMenuOptions] = useState<StyledMenuItemProps[]>([]);

  useEffect(() => {
    const sites = data ?? dataAdmin;
    if (!isFetching && !isError && !isFetchingAdmin && !isErrorAdmin) {
      setSiteMenuOptions(
        (prevState) =>
          sites
            ?.map((site) => ({
              uuid: site.uuid,
              checked: prevState.find((option) => option.uuid === site.uuid)?.checked ?? false,
              label: site.name || t('unknown', 'Unknown'),
              subtitle: site.address,
              resultCount: resultCountFn?.(site),
              onClick: () => toggleOptionChecked(site.uuid),
            }))
            .sort(compareMenuOptions) ?? [],
      );
    }
  }, [isFetching, isFetchingAdmin, items]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setSiteMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { siteMenuOptions, setSiteMenuOptions };
};
