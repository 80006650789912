import { useSelector } from 'react-redux';

import { useGetCompaniesQuery } from '@services/companies/endpoints';
import { selectActiveCompany } from '@services/companies/selectors';
import { useGetCountriesQuery } from '@services/countries/endpoints';

export const useActiveCurrency = () => {
  useGetCompaniesQuery();
  const { data: countries } = useGetCountriesQuery();

  const activeCompany = useSelector(selectActiveCompany);

  if (!activeCompany || !countries?.length) {
    return {
      code: '',
      symbol: '',
      centsName: 'cents',
      centsSymbol: '',
    };
  }

  const country = countries.find((c) => c.currency === activeCompany.settings?.defaultCurrency);

  if (country) {
    return {
      code: country.currency,
      symbol: country.currencySymbol,
      centsName: country.currencyCentsName,
      centsSymbol: country.currencyCentsSymbol,
    };
  }

  const eurCountry = countries.find((c) => c.currency === 'EUR');

  return {
    code: eurCountry?.currency,
    symbol: eurCountry?.currencySymbol,
    centsName: eurCountry?.currencyCentsName,
    centsSymbol: eurCountry?.currencyCentsSymbol,
  };
};
