import { ReactElement, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';

type RadialChartProps = {
  max?: number;
  value?: number;
  extraData?: {
    A?: number;
    V?: number;
  };
  label?: string;
  extraLabel?: string;
  color?: string;
  icon?: () => ReactElement;
  offline?: boolean;
  backgroundColor?: string;
  dark?: boolean;
};

export const RadialChart = ({
  max = 0,
  value = 0,
  extraData,
  label = '',
  extraLabel = '',
  color = 'green',
  icon: Icon,
  offline = true,
  backgroundColor = '',
  dark = false,
}: RadialChartProps) => {
  const offlineRef = useRef(offline);
  offlineRef.current = offline;

  const extraDataArray = Object.entries(extraData || {}).map(([key, data]) => ({
    key,
    data,
  }));

  const options = {
    series: [(value / max) * 100],
    options: {
      chart: {
        type: 'radialBar' as const,
        sparkline: {
          enabled: true,
        },
        offsetY: -5,
      },
      plotOptions: {
        radialBar: {
          startAngle: -130,
          endAngle: 130,
          track: {
            background: 'e7e7e7',
            strokeWidth: '100%',
            margin: 5, // margin is in pixels
          },
          hollow: {
            size: '80%',
          },
          dataLabels: {
            name: {
              offsetY: extraData ? -10 : 15,
              show: true,
              color: dark ? '#fff' : '#999898',
              fontSize: '14px',
              fontFamily: 'Poppins',
              fontWeight: 400,
            },
            value: {
              offsetY: extraData ? -45 : -30,
              color: dark ? '#fff' : '#5E5E5E',
              formatter: (val: number) => (offlineRef.current ? '-' : ((val / 100) * max).toFixed(2)),
              fontSize: '24px',
              fontWeight: 400,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          gradientToColors: [color],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      colors: [color],
      labels: [label],
    },
  };

  return (
    <div
      className={`relative flex h-full w-full items-center justify-center rounded-2xl font-poppins text-sm text-black ${backgroundColor}`}
    >
      <div className={`absolute bottom-3 left-[15%] ${dark ? 'text-white' : 'text-gray-600'}`}>0</div>
      <ReactApexChart options={options.options} series={options.series} type="radialBar" width="100%" height="100%" />
      <div className={`absolute bottom-3 right-[15%] ${dark ? 'text-white' : 'text-gray-600'}`}>{max}</div>
      {extraData && (
        <div className="absolute bottom-12 flex items-center gap-x-4 text-sm">
          {extraDataArray.map((data) => (
            <div key={data.key} className={dark ? 'text-white' : 'text-gray-600'}>
              {offline ? '- ' : Number(data.data).toFixed(data.key === 'V' ? 1 : 2)}
              <span className={dark ? 'text-white' : 'text-gray-400'}>{data.key}</span>
            </div>
          ))}
        </div>
      )}
      <div className={`absolute bottom-2 text-sm ${dark ? 'text-white' : 'text-gray-600'}`}>{extraLabel}</div>
      {!!Icon && (
        <div className="absolute right-2 top-2">
          <Icon />
        </div>
      )}
    </div>
  );
};
