import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import { useFilterMenu } from '@components/filters/hooks/useFilterMenu';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';

import { ReportScheduleContext } from './context/ReportScheduleProvider';
import { ReportingTransactionsFilterMenuOption } from './context/ReportingTransactionsProvider';

type ReportScheduleFilterOptionProps = {
  option: StyledMenuItemProps;
};

export const ReportScheduleFilterOption = ({ option }: ReportScheduleFilterOptionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { filterOptionsMap, filterInclusivityMap, optionChecked, setFilterInclusive } =
    useContext(ReportScheduleContext);
  const filter = option.uuid!;
  const { filterPluralLabels } = useFilterMenu(filterOptionsMap);

  const options = filterOptionsMap[filter as ReportingTransactionsFilterMenuOption];
  const checkedOptionsCount = useMemo(() => options?.filter(optionChecked).length ?? 0, [options]);
  const isSingleOption = checkedOptionsCount === 1;
  const inclusive = filterInclusivityMap[filter as keyof typeof filterInclusivityMap];

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={2}
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`./filter/${filter}`)}
    >
      <Box>{option.Icon && <option.Icon width="24px" height="24px" />}</Box>
      <Typography variant="p14">{option.label}</Typography>
      {checkedOptionsCount > 0 && (
        <>
          <Typography
            variant="p14"
            color="#5E5E5E"
            noWrap
            flexShrink={0}
            onClick={(e) => {
              e.stopPropagation();
              setFilterInclusive(filter as FilterMenuOption, !inclusive);
            }}
          >
            {isSingleOption && inclusive && t('is', 'is')}
            {!isSingleOption && inclusive && t('isAnyOf', 'is any of')}
            {!inclusive && t('isNot', 'is not')}
          </Typography>
          <Box display="table" width="100%" sx={{ tableLayout: 'fixed' }}>
            <Typography
              display="table-cell"
              variant="p14"
              textTransform={isSingleOption || filter === 'rfid' ? 'none' : 'lowercase'}
              sx={{ cursor: 'pointer' }}
              noWrap
              textAlign="end"
            >
              {isSingleOption
                ? options?.find(optionChecked)?.label
                : `${checkedOptionsCount} ${filterPluralLabels[filter as FilterMenuOption]}`}
            </Typography>
          </Box>
        </>
      )}
      <Box display="flex" flex={checkedOptionsCount > 0 ? 0 : 1} justifyContent="end">
        <ArrowLeftIcon className="rotate-180" />
      </Box>
    </Box>
  );
};
