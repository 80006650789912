import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export type CompanyDetailsForm = {
  legalName: string;
  addressJson: {
    country: string;
    streetAddress: string;
    apt: string;
    city: string;
    state: string;
    zipCode: string;
  };
  registrationNumber: string;
  vatNumber: string;
  vatRegisteredFlag: boolean;
};

export const companyDetailsDefaultValues: CompanyDetailsForm = {
  legalName: '',
  addressJson: {
    country: '',
    streetAddress: '',
    apt: '',
    city: '',
    state: '',
    zipCode: '',
  },
  registrationNumber: '',
  vatNumber: '',
  vatRegisteredFlag: false,
};

export const useCompanyDetailsSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('required*', 'Required*');

  return yup
    .object()
    .shape({
      legalName: yup.string().required(requiredLabel),
      registrationNumber: yup.string().required(requiredLabel),
      vatRegisteredFlag: yup.boolean(),
      vatNumber: yup
        .string()
        .nullable()
        .when('vatRegisteredFlag', {
          is: true,
          then: yup.string().required(requiredLabel),
        }),
      addressJson: yup.object().shape({
        country: yup.string().required(requiredLabel),
        streetAddress: yup.string().required(requiredLabel),
        apt: yup.string(),
        city: yup.string().required(requiredLabel),
        state: yup.string().required(requiredLabel),
        zipCode: yup.string().required(requiredLabel),
      }),
    })
    .required();
};
