import { isNil } from 'lodash';
import moment from 'moment';

export default class DataHandler {
  static getCapitalizedString(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static cleanDataObject(object: Record<string, any>): Record<string, any> {
    const objectCopy = { ...object };
    Object.keys(objectCopy).forEach((key) => {
      if (objectCopy[key] === undefined) {
        delete objectCopy[key];
      }
    });
    return objectCopy;
  }

  static isEqual(value1: any, value2: any): boolean {
    return String(value1) === String(value2);
  }

  static getMilitaryTimeFromTime(time: string): string {
    return moment(time).format('HHmm');
  }

  static getTimeFromMilitaryNumber(number: string | null): moment.Moment | null {
    if (!number) return null;
    return moment(number, 'HHmm');
  }

  static checkIfObjectHasNilValues(object: Record<string, any>): boolean {
    if (isNil(object)) return true;
    return Object.values(object).some((value) => isNil(value));
  }
}
