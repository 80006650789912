const buildDashboardEndpoints = (builder) => ({
  getCompanyDashboard: builder.query({
    query: ({ duration }) => ({
      url: `/dashboard/self${duration ? `?duration=${duration}` : ''}`,
      method: 'GET',
    }),
    providesTags: ['CompanyContext'],
  }),
  getSiteDashboard: builder.query({
    query: ({ uuid, duration }) => ({
      url: `/dashboard/site/${uuid}${duration ? `?duration=${duration}` : ''}`,
      method: 'GET',
    }),
  }),
});

export default buildDashboardEndpoints;
