import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Slide, Typography, useTheme } from '@mui/material';

import { useSaveCompanyPaymentMethodMutation } from '@services/companies/endpoints';
import { selectActiveCompany } from '@services/companies/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { WorkspaceSetupFooter } from '../setup/WorkspaceSetupFooter';
import { WorkspaceSetupSidebarContext } from '../setup/WorkspaceSetupSidebarProvider';
import { PaymentMethodSelector } from './PaymentMethodSelector';

type CompanyPaymentMethodProps = {
  isSetup?: boolean;
};

export const CompanyPaymentMethod = ({ isSetup }: CompanyPaymentMethodProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [saveCompanyPaymentMethod, { isLoading: saveLoading }] = useSaveCompanyPaymentMethodMutation();
  const activeCompany = useAppSelector(selectActiveCompany);
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState<string>();
  const { setAnyChanges, setCloseSidebar } = useContext(WorkspaceSetupSidebarContext);

  useEffect(() => {
    setStripePaymentMethodId(activeCompany?.stripePaymentMethodId);
  }, [activeCompany]);

  useEffect(() => {
    setAnyChanges(stripePaymentMethodId !== activeCompany?.stripePaymentMethodId);
    return () => setAnyChanges(false);
  }, [stripePaymentMethodId]);

  const handleSave = async () => {
    try {
      if (!stripePaymentMethodId) return false;
      if (activeCompany?.stripePaymentMethodId !== stripePaymentMethodId) {
        await saveCompanyPaymentMethod({ stripePaymentMethodId }).unwrap();
        setAnyChanges(false);
        dispatch(addToastMessage({ type: 'success', title: t('paymentMethodSaved', 'Payment method saved') }));
      }
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('addingPaymentMethodFailed', 'Adding payment method failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }

    if (isSetup) {
      navigate('../companyDetails');
    } else {
      setCloseSidebar(true);
    }
  };

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box display="flex" flexDirection="column" flex={1} overflow="auto" gap={4} p="0 24px">
          <Typography variant="p16" color={theme.palette.darkGray}>
            {t('companyPaymentMethodDescription', 'Set the payment method to use for your monthly plan.')}
          </Typography>
          <PaymentMethodSelector
            paymentMethodId={stripePaymentMethodId}
            savePaymentMethodId={(paymentMethodId) => {
              setStripePaymentMethodId(paymentMethodId);
            }}
            isLoading={saveLoading}
          />
        </Box>
      </Slide>
      <WorkspaceSetupFooter
        onNext={handleSave}
        onCancel={() => (isSetup ? navigate(-1) : setCloseSidebar(true))}
        saveDisabled={!stripePaymentMethodId || saveLoading}
        isSetup={isSetup}
      />
    </>
  );
};
