import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';

import { Site } from 'models/site';

import { Box, Typography } from '@mui/material';

import NoItemsCard from '@components/cards/NoItemsCard';
import { useAppSelector } from '@services/hooks';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectSitesByCompanyUuid } from '@services/sites/selectors';
import { DeleteSiteModal } from '@views/Sites/DeleteSiteModal';
import { SitesMaterialTable } from '@views/Sites/SitesTable/SitesMaterialTable';

export const PartnerWorkspaceSitesView = () => {
  const { t } = useTranslation();
  const { companyUuid } = useParams();
  const [searchParams] = useSearchParams();
  const sites = useAppSelector((state) => (companyUuid ? selectSitesByCompanyUuid(state, companyUuid) : undefined));

  const { isLoading: isLoadingGetSites } = useGetSitesQuery({ companyUuid });

  const siteUuid = searchParams.get('siteUuid') ?? '';
  const selectedSiteFromUrl = sites?.find((site) => site.uuid === siteUuid);

  const [siteToDelete, setSiteToDelete] = useState<Site>();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="p18">{t('allSites', 'All sites')}</Typography>
      {!sites?.length && !isLoadingGetSites ? (
        <NoItemsCard message={t('noSitesText', 'There are currently no sites.')} />
      ) : (
        <SitesMaterialTable
          sites={sites ?? []}
          selectedSite={selectedSiteFromUrl}
          isLoading={isLoadingGetSites}
          onOpenDeleteSiteModal={setSiteToDelete}
        />
      )}
      {siteToDelete && (
        <DeleteSiteModal site={siteToDelete} isOpen={!!siteToDelete} closeModal={() => setSiteToDelete(undefined)} />
      )}
      <Outlet context={{ site: selectedSiteFromUrl }} />
    </Box>
  );
};
