import React, { ChangeEventHandler } from 'react';

type RadioButtonProps = {
  name: string;
  label: string;
  value: string;
  error?: boolean;
  helpText?: string;
  helpTextClassName?: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
};

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ name, label, value, error, helpText, helpTextClassName, checked, onChange, disabled }, ref) => (
    <div className="flex flex-col font-poppins">
      <label className={`flex items-center ${disabled ? 'text-gray-300' : 'cursor-pointer'}`}>
        <input
          ref={ref}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          className={`mr-2 appearance-none text-forest focus:ring-0 ${error ? 'border-vermillion' : 'border-gray-300'}`}
          onChange={onChange}
          disabled={disabled}
        />
        {label}
      </label>
      {helpText && (
        <span className={`${helpTextClassName} mt-1 block ${error ? 'text-vermillion' : 'text-gray-500'}`}>
          {helpText}
        </span>
      )}
    </div>
  ),
);

RadioButton.displayName = 'RadioButton';
