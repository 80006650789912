import React, { ChangeEventHandler, InputHTMLAttributes, ReactNode } from 'react';

type CheckboxProps = {
  label?: string;
  description?: string;
  error?: boolean;
  helpText?: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler;
  children?: ReactNode;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      description,
      error,
      helpText,
      name,
      checked,
      disabled,
      className = '',
      inputProps,
      onChange,
      onBlur,
      children,
    },
    ref,
  ) => (
    <div className={`font-poppins text-sm leading-6 text-black ${className}`}>
      <div className="relative flex items-center gap-x-4">
        <input
          ref={ref}
          id={`checkbox-${name}`}
          aria-describedby={`${name}-description`}
          name={name}
          type="checkbox"
          className={`h-6 w-6 rounded-lg text-forest focus:ring-0 disabled:opacity-40 ${
            error ? 'border-vermillion' : 'border-gray-300'
          }`}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          {...inputProps}
        />
        <label htmlFor={`checkbox-${name}`} className={disabled ? 'text-gray-300' : ''}>
          {label || children}
        </label>
        {description && (
          <span id={`${name}-description`} className="text-gray-500">
            <span className="sr-only">{label}</span>
            &nbsp;
            {description}
          </span>
        )}
      </div>
      {helpText && <span className={`mt-1 block ${error ? 'text-vermillion' : 'text-gray-500'}`}>{helpText}</span>}
    </div>
  ),
);

Checkbox.displayName = 'Checkbox';
