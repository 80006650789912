import idleTimeout from 'idle-timeout';

const IDLE_TIMEOUTS = {
  1: 1000 * 60 * 1,
  5: 1000 * 60 * 5,
  10: 1000 * 60 * 10,
};

export const pollingTimeout = (callback, time = 1) =>
  idleTimeout(callback, {
    element: document,
    timeout: IDLE_TIMEOUTS[time],
    loop: false,
  });
