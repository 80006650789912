import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InviteData } from 'models/company';
import * as yup from 'yup';

import Button from '@components/atoms/Button';
import { Card } from '@components/atoms/Card';
import { Input } from '@components/atoms/Input';
import SingleCardWrapper from '@components/atoms/SingleCardWrapper';
import { Avatar } from '@components/avatar/Avatar';
import CardSpinner from '@components/spinner/CardSpinner';
import { useForm } from '@hooks/useTypedForm';
import { getErrorMessage } from '@services/api/api-errors';
import { useResetPasswordMutation } from '@services/auth/endpoints';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      email: yup
        .string()
        .email(t('invalidEmail', 'Invalid email'))
        .required(t('required*', 'Required*'))
        .matches(/^\S+@\S+$/, { message: t('pleaseProvideValidEmail', 'Please provide a valid email address') }),
    })
    .required();
};

type ResetPasswordValues = {
  email: string;
};

type ResetPasswordBoxProps = {
  inviteData?: InviteData;
  showLoginBox: () => void;
  children?: ReactNode;
};

export const ResetPasswordBox = ({ inviteData, showLoginBox, children }: ResetPasswordBoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = useSchema();

  const successHandler =
    showLoginBox ||
    (() => {
      navigate('/login');
    });

  const [formError, setFormError] = useState('');
  const { register, handleSubmit, formState, reset } = useForm<ResetPasswordValues>({ schema });
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [success, setSuccess] = useState(false);

  const onSubmit = async ({ email }: ResetPasswordValues) => {
    try {
      await resetPassword(inviteData ? { inviteToken: inviteData.inviteToken } : { email }).unwrap();
      setSuccess(true);
      setTimeout(successHandler, 5000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ERROR: ', error);
      setFormError(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (inviteData) {
      reset({ email: inviteData.email });
    }
  }, [inviteData]);

  if (isLoading) {
    return <CardSpinner />;
  }

  if (success) {
    return (
      <SingleCardWrapper>
        <Card>{t('passwordResetLinkWasSentText', 'Password reset link was sent to your email.')}</Card>
      </SingleCardWrapper>
    );
  }

  return (
    <div className="bg-white sm:rounded-lg">
      <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
        {(formError || children) && <div className="mb-1 text-sm text-vermillion">{formError || children}</div>}
        {inviteData ? (
          <div className="flex items-center gap-x-2 font-poppins text-lg leading-8">
            <Avatar email={inviteData.email} />
            <span className="font-semibold">{inviteData.email}</span>
          </div>
        ) : (
          <Input
            label={t('emailAddress', 'Email address')}
            error={Boolean(formState.errors?.email)}
            helpText={formState.errors?.email?.message}
            {...register('email')}
          />
        )}
        <div className="space-y-1 text-center">
          <Button type="submit" variant="primary" bigger className="w-full">
            {t('sendPasswordResetLink', 'Send password reset link')}
          </Button>

          <Button variant="link" bigger onClick={showLoginBox}>
            {t('cancel', 'Cancel')}
          </Button>
        </div>
      </form>
    </div>
  );
};
