import { useOutletContext } from 'react-router-dom';

import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';

import { ChargerConnectivityFormSidebar } from './ChargerConnectivityFormSidebar';
import { LmcConnectivityFormSidebar } from './LmcConnectivityFormSidebar';

export const DeviceConnectivityFormSidebar = () => {
  const { device } = useOutletContext<{ device?: Device }>();

  if (device?.type === DeviceType.LMC) {
    return <LmcConnectivityFormSidebar />;
  } else if (device?.type === DeviceType.CHARGER) {
    return <ChargerConnectivityFormSidebar />;
  } else {
    return null;
  }
};
