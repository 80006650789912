import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Device } from 'models/device';

import { Box, Typography } from '@mui/material';

import { useAppSelector } from '@services/hooks';
import { selectSiteByUuid } from '@services/sites/selectors';
import { DeviceIcon } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/DeviceIcon';
import { getLinkToDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

type PartnerDeviceContentProps = {
  device: Device;
};

export const PartnerDeviceContent = ({ device }: PartnerDeviceContentProps) => {
  const { t } = useTranslation();

  const site = useAppSelector((state) => selectSiteByUuid(state, device.siteUuid));

  return (
    <Box
      display="flex"
      alignItems="center"
      component={site ? Link : 'div'}
      to={site ? getLinkToDevice(device) : undefined}
      width={0.5}
    >
      <DeviceIcon device={device} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        pl={{
          lg: 2,
        }}
      >
        <Typography variant="p16b">{device.name || device.serialNumber}</Typography>
        <Typography variant="p14">{site ? site.name : t('deletedSite', 'Deleted site')}</Typography>
      </Box>
    </Box>
  );
};
