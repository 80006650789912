import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Skeleton, Typography } from '@mui/material';

import { FormTextField } from '@components/atoms/FormTextField';

import { GroupDetails } from './hooks/useGroupDetailsState';

type GroupDetailsProps = {
  control: Control<GroupDetails>;
  isLoading?: boolean;
};

export const GroupDetailsForm = ({ control, isLoading }: GroupDetailsProps) => {
  const { t } = useTranslation();

  return (
    <Box className="mt-6 space-y-6" display="flex" flexDirection="column">
      <Typography variant="p16" color="#5E5E5E">
        {t(
          'groupAllowsToControlVisibilityText',
          'A group allows you to control visibility of items in your workspace to members.',
        )}
      </Typography>
      {isLoading ? (
        <>
          <Skeleton variant="rounded" animation="wave" height={39} />
          <Skeleton variant="rounded" animation="wave" height={39} />
        </>
      ) : (
        <>
          <FormTextField control={control} disabled={isLoading} name="name" label={t('groupName', 'Group name')} />
          <FormTextField
            control={control}
            disabled={isLoading}
            name="description"
            label={`${t('groupDescription', 'Group description')} (${t('optional', 'Optional').toLowerCase()})`}
          />
        </>
      )}
    </Box>
  );
};
