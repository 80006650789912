import { Box, Skeleton, Table, TableBody, TableCell, TableContainer } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';

export const ReportingTableRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell>
      <Box display="flex" alignItems="center" gap={2}>
        <Skeleton width={48} height={48} variant="rounded" animation="wave" sx={{ borderRadius: '12px' }} />
        <Box display="flex" flexDirection="column" flex={1}>
          <Skeleton variant="text" animation="wave" width="60%" />
          <Skeleton variant="text" animation="wave" />
        </Box>
      </Box>
    </TableCell>
    <TableCell>
      <Box display="flex" flexDirection="column">
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
      </Box>
    </TableCell>
    {Array.from(Array(6).keys()).map(() => (
      <TableCell key={self.crypto.randomUUID()}>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
    ))}
  </StyledSkeletonTableRow>
);

export const ReportingTableSkeleton = () => {
  return (
    <TableContainer component={CardContainer} sx={{ p: 0 }}>
      <Table>
        <TableBody>
          {Array.from(Array(5).keys()).map(() => (
            <ReportingTableRowSkeleton key={self.crypto.randomUUID()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
