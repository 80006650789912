import PropTypes from 'prop-types';

import { Switch } from '@headlessui/react';

const ToggleSwitch = ({ checked, onChange, className }) => (
  <Switch
    checked={checked}
    onChange={onChange}
    className={`${
      checked ? 'bg-forest' : 'bg-gray-400'
    } relative inline-flex h-6 w-10 items-center rounded-full ${className}`}
  >
    <span
      className={`${
        checked ? 'translate-x-5' : 'translate-x-1'
      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-150`}
    />
  </Switch>
);

ToggleSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  className: '',
};

export default ToggleSwitch;
