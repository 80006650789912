import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { ExclamationIcon } from '@heroicons/react/outline';

const ComponentError = ({ error }) => {
  const { t } = useTranslation();
  return (
    <>
      <ExclamationIcon className="mb-3 mt-5 h-10 w-10" />
      <div className="text-center">
        <div>{t('somethingWentWrong', 'Something went wrong')}</div>
        <div className="mt-1 text-xs text-gray-400 dark:text-warmgray-400">{`${t('error', 'Error')}: ${
          error?.data?.error
        }`}</div>
      </div>
    </>
  );
};

ComponentError.propTypes = {
  error: PropTypes.object,
};

ComponentError.defaultProps = {
  error: {},
};

export default ComponentError;
