import DataHandler from '@handlers/data';
import { ACTIONS_TYPES } from '@handlers/pusherData/constHandlersPushers';
import { createInverterFromPusher, updateInverterFromPusher } from '@services/inverters/actions';

export default class PusherInvertersDataHandler {
  data;

  meta;

  action;

  object;

  constructor({ data, meta, object }) {
    this.action = meta.action;
    this.object = object;
    this.data = data;
    this.meta = meta;
  }

  getActions() {
    const actionType = ACTIONS_TYPES(this.object)[this.action];
    if (actionType) {
      return this[actionType]();
    }
    return null;
  }

  createInverter() {
    const { status, ...rest } = this.data.current;
    const inverter = {
      [this.meta.fieldName]: this.meta.fieldValue,
      ...rest,
      ...(status && { data: status }),
    };
    const inverterData = DataHandler.cleanDataObject(inverter);
    return [createInverterFromPusher(inverterData)];
  }

  updateInverter() {
    const { status, ...rest } = this.data.current;
    const inverter = {
      [this.meta.fieldName]: this.meta.fieldValue,
      ...rest,
      ...(status && { data: status }),
    };
    const inverterData = DataHandler.cleanDataObject(inverter);
    return [updateInverterFromPusher(inverterData)];
  }
}
