import Pusher from 'pusher-js';

const appConfigPusher = JSON.parse(process.env.PUSHER_CONFIG)[process.env.REACT_APP_CONF_FILE];

export default class PusherConfig {
  static instance;

  static token;

  static getInstance(token) {
    if (!this.instance || (this.token !== token && token !== undefined)) {
      this.instance = this.initPusher(token);
      this.token = token;
    }
    return this.instance;
  }

  static initPusher(token) {
    return new Pusher(appConfigPusher.key, {
      authEndpoint: `${appConfigPusher.authEndpoint ? appConfigPusher.authEndpoint : ''}/pusher/auth`,
      auth: {
        headers: { 'X-CSRF-Token': token },
      },
      cluster: appConfigPusher.cluster,
      useTLS: true,
    });
  }
}
