import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { DeviceStatus, DeviceType } from 'models/device.enums';
import moment from 'moment';

import { Spinner } from '@components/atoms/Spinner';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import warningIcon from '@images/icons/Warning.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import {
  useGetDeviceConfigurationQuery,
  useGetDeviceQuery,
  useGetDeviceStatusQuery,
} from '@services/devices/endpoints';
import { selectDeviceByUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetSiteDevicesQuery, useGetSiteQuery } from '@services/sites/endpoints';
import { selectSiteByUuid } from '@services/sites/selectors';
import { ChargerButton } from '@views/Chargers/ChargerButton';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';
import { LmcStatus } from '@views/LMC/LmcStatus';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

import { ChargerConnectivity } from './ChargerConnectivity';
import { DeviceActions } from './DeviceActions';
import { DeviceDetails } from './DeviceDetails';
import { DeviceSettings } from './DeviceSettings';
import { LmcConnectivity } from './LmcConnectivity';

export const DeviceDetailsSidebar = () => {
  const { t } = useTranslation();
  const { deviceUuid, companyUuid } = useParams();

  const device = useAppSelector((state) => selectDeviceByUuid(state, deviceUuid));
  const site = useAppSelector((state) => selectSiteByUuid(state, device?.siteUuid));

  const titleLabel = device?.name || device?.serialNumber || t('configuration', 'Configuration');
  const subtitleLabel = site?.name;
  const connectors = device ? getUnifiedConnectors(device) : [];
  const isMultiConnector = connectors.length > 1;
  const isCharger = device?.type === DeviceType.CHARGER;
  const isLmc = device?.type === DeviceType.LMC;
  const isOffline = device?.status === DeviceStatus.OFFLINE;

  useGetSiteQuery(device?.siteUuid ?? skipToken, {
    skip: !isSubdomainPartner() || !!companyUuid,
  });

  useGetSiteDevicesQuery(device?.siteUuid ?? skipToken, {
    refetchOnFocus: true,
    skip: isLmc || (isSubdomainPartner() && !companyUuid),
  });

  useGetDeviceQuery(
    { uuid: deviceUuid },
    {
      refetchOnFocus: true,
    },
  );

  const { isLoading, isError } = useGetDeviceConfigurationQuery(
    {
      uuid: device?.uuid,
    },
    {
      skip: !device?.uuid || isOffline || !isVoolDevice(device),
      refetchOnMountOrArgChange: true,
    },
  );

  useGetDeviceStatusQuery(device?.uuid, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    skip: !device?.uuid || !device?.status || isOffline || isCharger,
  });

  return (
    <EditingSidebarBase
      title={titleLabel}
      subtitle={subtitleLabel}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      noActions
    >
      <div className="flex flex-col gap-y-8 font-poppins">
        {isOffline && (
          <div className="flex items-center gap-x-2">
            <img src={warningIcon} alt="warning icon" />
            <span className="text-vermillion">
              {t(
                'deviceOfflineLimitedInfoAndConfigurationText',
                'The device is offline - info and configuration is limited',
              )}
            </span>
          </div>
        )}
        {isLoading && (
          <div className="flex items-center gap-x-2">
            <Spinner size={6} className="[&_path]:fill-queen" />
            <span className="text-queen">{t('retrievingDeviceInformation', 'Retrieving device information')}</span>
          </div>
        )}
        {isError && (
          <div className="flex items-center gap-x-2">
            <img src={warningIcon} alt="warning icon" />
            <span className="text-vermillion">
              {t('failedToReceiveDeviceInformation', 'Failed to receive device information')}
            </span>
          </div>
        )}
        {device && site && (
          <>
            <DeviceDetails device={device} />
            <div className="flex w-full flex-col gap-2">
              {isCharger && (
                <>
                  {isMultiConnector && <h4 className="font-semibold">{t('connectors', 'Connectors')}</h4>}
                  {connectors.map((connector) => (
                    <ChargerButton
                      site={site}
                      connector={connector}
                      key={connector.connectorId || connector.deviceUuid}
                      className="w-full"
                      smaller
                    />
                  ))}
                </>
              )}
              {isLmc && <LmcStatus lmc={device} smaller />}
            </div>
            <DeviceSettings device={device} isLoading={isLoading} />
            {isCharger && isVoolDevice(device) && <ChargerConnectivity charger={device} isLoading={isLoading} />}
            {isLmc && <LmcConnectivity lmc={device} isLoading={isLoading} />}
            <DeviceActions device={device} />
            <div className="flex justify-between text-sm">
              <span className="font-semibold">{t('added', 'Added')}:</span>
              <span className="text-gray-600">
                {moment.utc(device.addTime).local().format('DD MMMM, YYYY • HH:mm')}
              </span>
            </div>
          </>
        )}
      </div>
      <Outlet context={{ device, site }} />
    </EditingSidebarBase>
  );
};
