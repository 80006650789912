import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { cloneDeep } from 'lodash';
import { ConnectorComputedStatus, DeviceType } from 'models/device.enums';

import { selectDevicesBy } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetTransactionSummaryQuery } from '@services/transactions/endpoints';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';
import { DeviceTypeOption } from '@views/reporting/hooks/useDeviceTypeMenu';

import { ChargerWithStats, DevicesAndGroupsCommonProps, LmcMultipointWithStats } from '../devicesAndGroupsTypes';

type UseChargersWithStats = DevicesAndGroupsCommonProps & {
  lmcMultipointsWithStats?: LmcMultipointWithStats[];
};

type TransactionSummary = {
  deviceUuid: string;
  numberOfSessions: number;
  totalConsumption: number;
};

export const useChargersWithStats = ({
  siteUuid,
  companyUuid,
  selectedDuration,
  lmcMultipointsWithStats,
}: UseChargersWithStats) => {
  const { deviceUuid: selectedDeviceUuidFromUrl } = useParams();

  const { data: transactionSummary } = useGetTransactionSummaryQuery(
    { siteUuid: siteUuid!, selectedDuration: selectedDuration! },
    { skip: !siteUuid || !selectedDuration },
  );

  const rawSiteChargers = useAppSelector((state) => selectDevicesBy(state, DeviceType.CHARGER, siteUuid, companyUuid));

  const chargersWithStats = useMemo<ChargerWithStats[]>(() => {
    const clonedChargers = cloneDeep(rawSiteChargers)
      .filter((charger) => charger.type === DeviceType.CHARGER)
      .map((charger) => {
        const connectors = getUnifiedConnectors(charger);
        return {
          ...charger,
          deviceType: DeviceTypeOption.CHARGER,
          numSessions: 0,
          consumption: 0,
          connectors,
          displayName: charger.name || charger.serialNumber,
          computedStatus: connectors.map((connector) => connector.computedStatus).join(', ') as ConnectorComputedStatus,
        };
      });
    const chargersByUuid = Object.fromEntries(clonedChargers.map((charger) => [charger.uuid, charger]));

    transactionSummary?.summary.forEach(({ deviceUuid, numberOfSessions, totalConsumption }: TransactionSummary) => {
      const charger = chargersByUuid[deviceUuid];
      if (charger) {
        charger.numSessions = numberOfSessions;
        charger.consumption = totalConsumption;
      }
    });

    return Object.values(chargersByUuid);
  }, [rawSiteChargers, lmcMultipointsWithStats, transactionSummary]);

  const selectedCharger = useMemo<ChargerWithStats | undefined>(
    () => chargersWithStats.find((device) => device.uuid === selectedDeviceUuidFromUrl),
    [selectedDeviceUuidFromUrl],
  );

  return {
    chargersWithStats,
    selectedCharger,
  };
};
