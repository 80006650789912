import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

type ErrorBoundaryFallbackWithNavigationProps = {
  resetErrorBoundary: () => void;
};

export const ErrorBoundaryFallbackWithNavigation = ({
  resetErrorBoundary,
}: ErrorBoundaryFallbackWithNavigationProps) => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (!isInitialRender) {
      resetErrorBoundary();
    }
    setIsInitialRender(false);
  }, [location.pathname]);

  return (
    <ErrorBoundaryFallback
      description={t('tryReloadingOrNavigatingElsewhere', 'Try reloading or navigating elsewhere.')}
    />
  );
};
