import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return { isMobile, isLargeScreen, isSmallScreen, isXSmallScreen };
};
