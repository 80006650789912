export enum ErrorCode {
  CAR_NOT_FOUND = 'car_not_found',
  COMPANY_DETAILS_NOT_FOUND = 'company_details_not_found',
  COMPANY_NOT_FOUND = 'company_not_found',
  COMPANY_UUID_IS_REQUIRED = 'company_uuid_is_required',
  DATABASE_ERROR = 'database_error',
  DEVICE_NOT_CONNECTED = 'device_not_connected',
  DEVICE_NOT_FOUND = 'device_not_found',
  DEVICE_NOT_OCPP_16 = 'device_not_ocpp_16',
  DEVICE_NOT_ONLINE = 'device_not_online',
  FIRMWARE_VERSION_NOT_SUPPORTED = 'firmware_version_not_supported',
  LOGIN_FAILED = 'login_failed',
  NO_CHARGERS_FOUND_IN_SITE = 'no_chargers_found_in_site',
  NO_PASSWORD_USE_GOOGLE_LOGIN = 'no_password_use_google_login',
  NOT_FOUND = 'not_found',
  OTHER_ERROR = 'other_error',
  PARTNER_COMPANY_NOT_FOUND = 'partner_company_not_found',
  PAYMENT_SESSION_NOT_FOUND = 'payment_session_not_found',
  PIN_CODE_INCORRECT = 'pin_code_incorrect',
  PUBLIC_CHARGING_SUBSCRIPTION_NOT_FOUND = 'public_charging_subscription_not_found',
  RFID_CARD_NOT_FOUND = 'rfid_card_not_found',
  SITE_MARKET_PRICE_NOT_FOUND = 'site_market_price_not_found',
  SITE_NOT_FOUND = 'site_not_found',
  SITE_PRICING_MODEL_NOT_FOUND = 'site_pricing_model_not_found',
  SITE_PRICING_MODEL_NOT_VALID = 'site_pricing_model_not_valid',
  SITE_TIMEZONE_NOT_FOUND = 'site_timezone_not_found',
  TRANSACTION_NOT_FOUND = 'transaction_not_found',
  UNAUTHENTICATED = 'unauthenticated',
  UNAUTHORIZED = 'unauthorized',
  UNAUTHORIZED_PARTNER = 'unauthorized_partner',
  USER_IS_NOT_INSTALLER = 'user_is_not_installer',
  USER_NOT_FOUND = 'user_not_found',
  VALIDATION_ERROR = 'validation_error',
  WRONG_DEVICE_TYPE = 'wrong_device_type',
}
