import { PaymentSessionSummary } from 'models/payment-session-summary';

import apiSlice from '@services/api';
import { PagedDataQuery } from '@services/reporting/endpoints';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postPaymentSessionSummaries: builder.query<PaymentSessionSummary[], PagedDataQuery<PaymentSessionSummary>>({
      query: (body) => ({
        method: 'POST',
        url: 'payments/summaries/company',
        body,
      }),
      transformResponse: (response: { data: PaymentSessionSummary[] }) => response.data,
    }),
    getInvoiceUrl: builder.query<string, string>({
      query: (invoiceId) => `payments/invoices/${invoiceId}/url`,
      transformResponse: (response: { data: string }) => response.data,
    }),
  }),
});

export const {
  usePostPaymentSessionSummariesQuery,
  useLazyPostPaymentSessionSummariesQuery,
  useLazyGetInvoiceUrlQuery,
} = extendedApi;
export const paymentsApi = extendedApi;
