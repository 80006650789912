import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Car } from 'models/car';
import { ReportingTransaction } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import apiSlice from '@services/api';

export const useVehicleMenu = (transactions?: ReportingTransaction[]) => {
  const { companyUuid } = useParams();
  const { data, isFetching, isError } = apiSlice.useVehiclesQuery({ companyUuid });
  const [vehicleMenuOptions, setVehicleMenuOptions] = useState<StyledMenuItemProps[]>([]);

  useEffect(() => {
    const response = data as { data: Car[] };
    if (!isFetching && !isError) {
      setVehicleMenuOptions(
        (prevState) =>
          response.data
            ?.map((vehicle) => ({
              uuid: vehicle.uuid,
              checked: prevState.find((option) => option.uuid === vehicle.uuid)?.checked ?? false,
              label: `${vehicle.brand} ${vehicle?.name || vehicle.model}`,
              resultCount: transactions?.filter((transaction) => transaction.carUuid === vehicle.uuid).length,
              onClick: () => toggleOptionChecked(vehicle.uuid),
            }))
            .sort(compareMenuOptions) ?? [],
      );
    }
  }, [isFetching, transactions]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setVehicleMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { vehicleMenuOptions, setVehicleMenuOptions };
};
