import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';

export enum DeviceTypeOption {
  CHARGER = 'charger',
  INVERTER = 'inverter',
  LMC = 'lmc',
  MULTIPOINT = 'multipoint',
}

export const useDeviceTypeMenu = () => {
  const { t } = useTranslation();
  const [deviceTypeMenuOptions, setDeviceTypeMenuOptions] = useState<StyledMenuItemProps[]>([
    {
      uuid: DeviceTypeOption.CHARGER,
      checked: false,
      label: t('charger', 'Charger'),
      onClick: () => toggleOptionChecked(DeviceTypeOption.CHARGER),
    },
    {
      uuid: DeviceTypeOption.INVERTER,
      checked: false,
      label: t('inverter', 'Inverter'),
      onClick: () => toggleOptionChecked(DeviceTypeOption.INVERTER),
    },
    {
      uuid: DeviceTypeOption.LMC,
      checked: false,
      label: t('lmc', 'LMC'),
      onClick: () => toggleOptionChecked(DeviceTypeOption.LMC),
    },
  ]);

  const toggleOptionChecked = (selectedOption: string) => {
    setDeviceTypeMenuOptions((prevOptions) =>
      prevOptions.map((option) => {
        if (option.uuid === selectedOption) {
          return { ...option, checked: !option.checked };
        }
        return option;
      }),
    );
  };

  return { deviceTypeMenuOptions, setDeviceTypeMenuOptions };
};
