import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { startCase } from 'lodash';

import { DeleteModal } from '@components/modals/DeleteModal';
import { ErrorModal } from '@components/modals/ErrorModal';
import { useDeleteDeviceMutation, useDeleteLmcMultipointMutation } from '@services/devices/endpoints';
import { useAppDispatch } from '@services/hooks';
import { useDeleteInverterMutation } from '@services/inverters/endpoints';
import { addToastMessage } from '@services/toastMessages';
import { DeviceWithMultipointAttrs } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/DeviceIcon';

type DeleteDeviceModalProps = {
  device: DeviceWithMultipointAttrs;
  isOpen: boolean;
  closeModal: () => void;
  refetchDevices?: () => void;
};

export const DeleteDeviceModal = ({ device, isOpen, closeModal, refetchDevices }: DeleteDeviceModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initiallyTransactionOngoing = useMemo(
    () =>
      device?.status === 'online' &&
      device.stateOfConnectors?.some((c) => c.transactionStatus && c.transactionStatus !== 'none'),
    [device?.uuid, isOpen],
  );

  const deviceLabel = (device.multipointId ? t('group', 'Group') : t('device', 'Device')).toLowerCase();

  const [deleteDevice, { isLoading: deleteDeviceLoading }] = useDeleteDeviceMutation();
  const [deleteLmcMultipoint, { isLoading: deleteLmcMultipointLoading }] = useDeleteLmcMultipointMutation();
  const [deleteInverter, { isLoading: deleteInverterLoading }] = useDeleteInverterMutation();
  const isLoading = deleteDeviceLoading || deleteLmcMultipointLoading || deleteInverterLoading;

  const handleNavigateToSite = (siteId?: string) =>
    !window.location.pathname.includes('sites') && navigate(`/sites/${siteId}`);

  const handleDeleteDevice = async ({
    uuid,
    siteUuid,
    multipointId,
    inverterId,
    lmcMultipointUuid,
  }: DeviceWithMultipointAttrs) => {
    try {
      if (multipointId) {
        await deleteLmcMultipoint(lmcMultipointUuid).unwrap();
      } else if (inverterId) {
        await deleteInverter(uuid).unwrap();
      } else {
        await deleteDevice(uuid).unwrap();
      }
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemDeleted', '{{item}} deleted', { item: startCase(deviceLabel) }),
          message: t('historicalDataIsStillAvailable', 'Historical data is still available in Reporting.'),
        }),
      );
      closeModal();
      handleNavigateToSite(siteUuid);
      refetchDevices?.();
    } catch (e: any) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletingTheItemFailed', 'Deleting the {{item}} failed', {
            item: deviceLabel,
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  if (initiallyTransactionOngoing) {
    return (
      <ErrorModal title="Unable to delete device" isOpen={isOpen} onClose={closeModal}>
        <p className="text-vermillion">
          It is not possible to delete a device while there is an ongoing charging session.
        </p>
        <br />
        <p>If possible, please end the session and try again.</p>
      </ErrorModal>
    );
  }

  return (
    <DeleteModal
      isOpen={isOpen}
      onClose={closeModal}
      onDelete={() => handleDeleteDevice(device)}
      isLoading={isLoading}
      confirmText={`${t('yes', 'Yes')}, ${t('deleteTheItem', 'Delete the {{item}}', {
        item: deviceLabel,
      }).toLowerCase()}`}
      cancelText="No"
      title={`${t('delete', 'Delete')} ${deviceLabel}`}
    >
      <p>
        {t('areYouSureDeleteItem', ' Are you sure you want to delete the {{item}}', { item: deviceLabel })}
        {(device?.displayName ?? device.name) && (
          <span className="font-semibold"> {device?.displayName ?? device.name}</span>
        )}
        ?
      </p>
    </DeleteModal>
  );
};
