import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { startCase } from 'lodash';

import { BreadcrumbsBase } from '@components/breadcrumbs/BreadcrumbsBase';
import {
  BackofficeBreadcrumbs,
  DeviceBreadcrumbs,
  InverterBreadcrumbs,
  ReportingBreadcrumbs,
  SiteBreadcrumbs,
  VehicleBreadcrumbs,
  WorkspaceBreadcrumbs,
  WorkspacesBreadcrumbs,
} from '@components/breadcrumbs/breadcrumbsComponents';

export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter((path) => path !== '');

  const breadcrumbsComponents: Record<string, React.FC> = {
    sites: SiteBreadcrumbs,
    devices: DeviceBreadcrumbs,
    vehicles: VehicleBreadcrumbs,
    workspace: WorkspaceBreadcrumbs,
    backoffice: BackofficeBreadcrumbs,
    inverters: InverterBreadcrumbs,
    reporting: ReportingBreadcrumbs,
    workspaces: WorkspacesBreadcrumbs,
    'access-management': WorkspaceBreadcrumbs,
  };

  const BreadcrumbsComponent = breadcrumbsComponents[pathSegments[0]];

  if (BreadcrumbsComponent) {
    return <BreadcrumbsComponent />;
  }

  return (
    <BreadcrumbsBase
      breadcrumbsItems={pathSegments.map((pathSegment, index) => ({
        label: t(pathSegment, startCase(pathSegment)),
        path: `/${pathSegments.slice(0, index + 1).join('/')}`,
      }))}
    />
  );
};
