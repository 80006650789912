import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { Permission } from 'models/permission.enum';

import NoItemsCard from '@components/cards/NoItemsCard';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetRfidCardsQuery } from '@services/rfidCards/endpoints';
import { selectRfidCards } from '@services/rfidCards/selectors';

import { RfidCardsMaterialTable } from './RfidCardsTable/RfidCardsMaterialTable';

export const WorkspaceRfidCardsView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { companyUuid, rfidCardUuid } = useParams();

  useGetRfidCardsQuery(companyUuid ? { companyUuid } : undefined);

  const canAddRfidCards = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_ADD_RFID_CARDS));
  const rfidCards = useAppSelector(selectRfidCards);

  const selectedRfidCard = rfidCards?.find((rfidCard) => rfidCard.uuid === rfidCardUuid);
  return (
    <>
      {!rfidCards?.length ? (
        <NoItemsCard
          message={t('noRfidCardsText', 'There are currently no RFID cards in your workspace.')}
          {...(canAddRfidCards && {
            buttonLabel: t('addNewItem', 'Add a new {{item}}', { item: t('rfidCard', 'RFID card') }),
            onButtonClick: () => navigate('./add'),
          })}
        />
      ) : (
        <RfidCardsMaterialTable />
      )}
      <Outlet context={{ rfidCard: selectedRfidCard }} />
    </>
  );
};
