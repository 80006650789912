import { useTranslation } from 'react-i18next';

import { Device } from 'models/device';

import { IconButton } from '@mui/material';

import { ReactComponent as StarIcon } from '@images/icons/Star.svg';
import { ReactComponent as OutlinedStarIcon } from '@images/icons/Star_Outlined.svg';
import { useToggleUserFavoriteDeviceMutation } from '@services/devices/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

type UserFavoriteDeviceStarProps = {
  device: Device;
};

export const UserFavoriteDeviceStar = ({ device }: UserFavoriteDeviceStarProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [toggleUserFavoriteDevice, { isLoading }] = useToggleUserFavoriteDeviceMutation();

  const toggleFavorite = () => {
    try {
      toggleUserFavoriteDevice({
        deviceUuid: device.uuid,
        userFavoriteFlag: !device.userFavoriteFlag,
      }).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('deviceFavoriteUpdated', 'Device favorite updated'),
        }),
      );
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deviceFavoriteUpdateFailed', 'Device favorite update failed'),
        }),
      );
    }
  };

  return (
    <IconButton
      onClick={toggleFavorite}
      disabled={isLoading}
      sx={{ color: device.userFavoriteFlag ? '#386957' : '#282828' }}
    >
      {device.userFavoriteFlag ? <StarIcon /> : <OutlinedStarIcon />}
    </IconButton>
  );
};
