import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { InviteData } from 'models/company';
import * as yup from 'yup';

import { AppleAuth } from '@components/apple/AppleAuth';
import Button from '@components/atoms/Button';
import { Input } from '@components/atoms/Input';
import { PasswordInput } from '@components/atoms/PasswordInput';
import { Avatar } from '@components/avatar/Avatar';
import GoogleAuth from '@components/google/GoogleAuth';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { trackAmplitudeEvent } from '@handlers/amplitude';
import { AmplitudeEvent, AmplitudeLoginMethod } from '@handlers/amplitude/data';
import { getAmplitudeEventPropertiesForUser } from '@handlers/amplitude/data';
import { useForm } from '@hooks/useTypedForm';
import { DEFAULT_PATH } from '@routesConfig/routesConst';
import { useLoginMutation } from '@services/auth/endpoints';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      email: yup.string().email(t('invalidEmail', 'Invalid email')).required(t('required*', 'Required*')),
      password: yup.string().required(t('required*', 'Required*')),
    })
    .required();
};

type LoginValues = {
  email: string;
  password: string;
};

type LoginBoxProps = {
  inviteData?: InviteData;
};

export const LoginBox = ({ inviteData }: LoginBoxProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schema = useSchema();
  const [formError, setFormError] = useState('');
  const { register, handleSubmit, formState, reset } = useForm<LoginValues>({ schema });
  const [login, { isLoading, isError, error: apiError }] = useLoginMutation();

  const onSubmit = async ({ email, password }: LoginValues) => {
    try {
      const response = await login({
        ...(inviteData ? { inviteToken: inviteData.inviteToken } : { email }),
        password,
      }).unwrap();
      if (!inviteData) {
        navigate(DEFAULT_PATH);
      }
      trackAmplitudeEvent(AmplitudeEvent.LOGIN, {
        ...getAmplitudeEventPropertiesForUser(response.user),
        method: AmplitudeLoginMethod.EMAIL,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('ERROR: ', error);
    }
  };

  useEffect(() => {
    if (apiError) {
      setFormError(t('somethingWentWrong', 'Something went wrong'));
    }
  }, [isError]);

  useEffect(() => {
    if (inviteData) {
      reset({ email: inviteData.email });
    }
  }, [inviteData]);

  const handleAuthSuccess = () => {
    if (!inviteData) {
      navigate(DEFAULT_PATH);
    }
  };

  const navigateToForgotPassword = () => {
    navigate('/forgot-password' + (inviteData ? `?inviteToken=${inviteData.inviteToken}` : ''));
  };

  return (
    <div className="bg-white sm:rounded-lg">
      <form id="Login-form" className="relative flex flex-col space-y-1" onSubmit={handleSubmit(onSubmit)}>
        {formError && (
          <div id="Login-form-error-messages" className="text-sm text-vermillion">
            {formError}
          </div>
        )}
        {inviteData ? (
          <div className="flex items-center gap-x-2 font-poppins text-lg leading-8">
            <Avatar email={inviteData.email} />
            <span className="font-semibold">{inviteData.email}</span>
          </div>
        ) : (
          <Input
            id="Login-form-email"
            label={t('emailAddress', 'Email address')}
            error={Boolean(formState.errors?.email)}
            helpText={formState.errors?.email?.message}
            {...register('email')}
          />
        )}
        <PasswordInput
          id="Login-form-password"
          label={t('password', 'Password')}
          error={Boolean(formState.errors?.password)}
          helpText={formState.errors?.password?.message}
          {...register('password')}
        />

        <div className="flex flex-col space-y-2">
          <Button variant="link" bigger onClick={navigateToForgotPassword}>
            {t('forgotYourPassword', 'Forgot your password?')}
          </Button>
          <Button type="submit" variant="primary" bigger className="w-full">
            {t('signIn', 'Sign in')}
          </Button>

          <div className="py-4 text-center font-poppins text-base text-black">
            {t('orSignInWith', 'Or sign in with').toLowerCase()}
          </div>
          <GoogleAuth inviteData={inviteData} onSuccess={handleAuthSuccess} />
          {!inviteData?.googleVerified && <AppleAuth inviteData={inviteData} onSuccess={handleAuthSuccess} />}
          {!inviteData && (
            <Button variant="link" bigger onClick={() => navigate('/register')}>
              {t('newHere?SignUp', 'New here? SIGN UP!')}
            </Button>
          )}
        </div>

        {isLoading && <OverflowSpinner />}
      </form>
    </div>
  );
};
