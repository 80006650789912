import { useTranslation } from 'react-i18next';

import { FeatureDto } from 'models/subscriptions';
import moment from 'moment';

import { Box, Divider, Typography, useTheme } from '@mui/material';

type CancelledSubscriptionDetailsProps = {
  feature: FeatureDto;
};

export const CancelledSubscriptionDetails = ({ feature }: CancelledSubscriptionDetailsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box display="flex" gap={0.5}>
        <Typography variant="p14">{t('cancelledBy', 'Cancelled by')}:</Typography>
        <Typography variant="p14b">{feature.subscription?.deactivatedByUser}</Typography>
      </Box>
      <Divider sx={{ borderBottom: `1px solid ${theme.palette.cultured}` }} />
      <Box display="flex" gap={0.5}>
        <Typography variant="p14">{t('cancelledSince', 'Cancelled since')}:</Typography>
        <Typography variant="p14b">
          {feature.subscription ? moment(feature.subscription.deactivatedAt).format('DD.MM.YYYY') : 'N/A'}
        </Typography>
      </Box>
      {feature.subscription?.trialEndsAt && new Date(feature.subscription.trialEndsAt) > new Date() && (
        <>
          <Divider sx={{ borderBottom: `1px solid ${theme.palette.cultured}` }} />
          <Box display="flex" gap={0.5}>
            <Typography variant="p14">{t('trialEnding', 'Trial ending')}:</Typography>
            <Typography variant="p14b" color={theme.palette.mediumVermilion}>
              {feature.subscription?.trialEndsAt
                ? moment(feature.subscription.trialEndsAt).format('DD.MM.YYYY')
                : 'N/A'}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
