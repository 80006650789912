import audiLogo from '@images/supported-cars/audi.png';
import bmwLogo from '@images/supported-cars/bmw.png';
import cupraLogo from '@images/supported-cars/cupra.png';
import enodeLogo from '@images/supported-cars/enode.png';
import hyundaiLogo from '@images/supported-cars/hyundai.png';
import kiaLogo from '@images/supported-cars/kia.png';
import nissanLogo from '@images/supported-cars/nissan.png';
import peugeotLogo from '@images/supported-cars/peugeot.png';
import porscheLogo from '@images/supported-cars/porsche.png';
import renaultLogo from '@images/supported-cars/renault.png';
import skodaLogo from '@images/supported-cars/skoda.png';
import teslaLogo from '@images/supported-cars/tesla.png';
import volkswagenLogo from '@images/supported-cars/volkswagen.png';
import volvoLogo from '@images/supported-cars/volvo.png';
import voolLogo from '@images/vool-orange.png';

export const BRANDS_INFO_LIST = {
  tesla: {
    name: 'Tesla',
    link: true,
    logo: teslaLogo,
  },
  volvo: {
    name: 'Volvo',
    logo: volvoLogo,
  },
  kia: {
    name: 'Kia',
    pin: true,
    logo: kiaLogo,
  },
  hyundai: {
    name: 'Hyundai',
    logo: hyundaiLogo,
  },
  volkswagen: {
    name: 'Volkswagen',
    logo: volkswagenLogo,
  },
  volkswagenid: {
    name: 'VolkswagenId',
    logo: volkswagenLogo,
  },
  audi: {
    name: 'Audi',
    logo: audiLogo,
  },
  nissan: {
    name: 'Nissan',
    logo: nissanLogo,
  },
  bmw: {
    name: 'BMW',
    logo: bmwLogo,
  },
  porsche: {
    name: 'Porsche',
    logo: porscheLogo,
  },
  peugeot: {
    name: 'Peugeot',
    logo: peugeotLogo,
  },
  cupra: {
    name: 'Cupra',
    logo: cupraLogo,
  },
  renault: {
    name: 'Renault',
    logo: renaultLogo,
  },
  skoda: {
    name: 'Skoda',
    logo: skodaLogo,
  },
  vool: {
    name: 'Vool',
    pin: true,
    logo: voolLogo,
    logoClassName: 'p-5',
    disabled: process.env.REACT_APP_CONF_FILE === 'production',
  },
  enode: {
    name: 'Enode',
    provider: 'enode',
    link: true,
    logo: enodeLogo,
    logoClassName: 'p-5',
  },
};

export const CHARGE_STATUSES = {
  CHARGING: 'Charging',
  CONNECTED: 'Plugged in',
  NOT_CONNECTED: 'On the road',
  UNKNOWN: 'Unavailable',
};

export const VEHICLE_STATUS_OPTIONS = {
  batteryLevel: 'batteryLevel',
  chargeStatus: 'chargeStatus',
  distanceToBatteryEmpty: 'distanceToBatteryEmpty',
  timeToFullyCharged: 'timeToFullyCharged',
  vin: 'vin',
};
