import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Car } from 'models/car';

import { DeleteModal } from '@components/modals/DeleteModal';
import apiSlice from '@services/api';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

type DeleteVehicleModalProps = {
  vehicle: Car;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteVehicleModal = ({ vehicle, isOpen, onClose }: DeleteVehicleModalProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [deleteVehicleByUuid, { isLoading }] = apiSlice.useDeleteVehicleMutation();

  const handleNavigateToVehicles = () => window.location.pathname.includes('/vehicles/') && navigate('/vehicles');
  const vehicleLabel = t('vehicle', 'Vehicle');

  const handleDeleteVehicle = async () => {
    try {
      await deleteVehicleByUuid(vehicle.uuid).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemDeleted', '{{item}} deleted', { item: vehicleLabel }),
          message: t('historicalDataIsStillAvailable', 'Historical data is still available in Reporting.'),
        }),
      );
      onClose();
      handleNavigateToVehicles();
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletingTheItemFailed', 'Deleting the {{item}} failed', {
            item: vehicleLabel.toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  return (
    <DeleteModal
      isOpen={isOpen}
      onClose={onClose}
      onDelete={handleDeleteVehicle}
      isLoading={isLoading}
      confirmText={`${t('yes', 'Yes')}, ${t('deleteTheItem', 'Delete the {{item}}', {
        item: vehicleLabel,
      }).toLowerCase()}`}
      cancelText={t('no', 'No')}
      title={`${t('delete', 'Delete')} ${vehicleLabel}`}
    >
      <p className="font-poppins">
        {t('areYouSureDeleteItem', ' Are you sure you want to delete the {{item}}', {
          item: vehicleLabel.toLowerCase(),
        })}
        {vehicle?.brand && vehicle?.model && (
          <span className="font-semibold">
            {' '}
            {vehicle?.name || vehicle.model} - {vehicle.brand}
          </span>
        )}
        ?
      </p>
    </DeleteModal>
  );
};
