import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Car } from 'models/car';

import { Box } from '@mui/material';

import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import Select from '@components/atoms/Select';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { useForm } from '@hooks/useTypedForm';
import { useAppSelector } from '@services/hooks';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectActiveCompanySites } from '@services/sites/selectors';

import { UpdateVehicleQuery, useVehicleDetailsSchema } from './vehicleDetailsSchema';

type VehicleDetailsSidebarBaseProps = {
  vehicle: Car;
  title: string;
  saveLabel?: string;
  discardLabel?: string;
  isSaveLoading: boolean;
  onSaveValues: (attributes: UpdateVehicleQuery) => Promise<boolean>;
};

export const VehicleDetailsSidebarBase = ({
  vehicle,
  title,
  saveLabel,
  discardLabel,
  isSaveLoading,
  onSaveValues,
}: VehicleDetailsSidebarBaseProps) => {
  const { t } = useTranslation();
  const sites = useAppSelector(selectActiveCompanySites);
  const [updateFailed, setUpdateFailed] = useState(false);
  const schema = useVehicleDetailsSchema();

  const { formState, reset, setValue, register, handleSubmitAndResolve, watch } = useForm<UpdateVehicleQuery>({
    schema,
  });

  const siteUuid = watch('siteUuid');
  const privateFlag = watch('privateFlag');

  const siteOptions = useMemo(
    () =>
      sites.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      })),
    [sites],
  );

  const handleSaveValues = async ({ name, siteUuid, privateFlag }: UpdateVehicleQuery) => {
    const success = await onSaveValues({
      uuid: vehicle.uuid,
      name: name?.trim() || null,
      siteUuid,
      privateFlag,
    });
    setUpdateFailed(!success);
    return success;
  };

  const loadValues = () => {
    reset({
      name: vehicle.name,
      siteUuid: vehicle.siteUuid,
      privateFlag: vehicle.privateFlag,
    });
  };

  useEffect(loadValues, [vehicle.uuid]);

  useGetSitesQuery({});

  return (
    <EditingSidebarBase
      title={title}
      {...(saveLabel && { saveLabel })}
      {...(discardLabel && { discardLabel })}
      onSaveValues={handleSubmitAndResolve(handleSaveValues)}
      anyDataChanged={formState.isDirty}
      updateLoading={isSaveLoading}
      updateFailed={updateFailed}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <div className="font-semibold">{t('details', 'Details')}</div>
        <Input
          label={`${t('name', 'Name')} (${t('optional', 'Optional').toLowerCase()})`}
          type="text"
          error={Boolean(formState.errors?.name)}
          helpText={formState.errors?.name?.message}
          {...register('name')}
        />
        <Select
          label={t('site', 'Site')}
          options={siteOptions}
          value={siteUuid}
          onChange={(newSite) => {
            setValue('siteUuid', newSite, { shouldDirty: true });
          }}
        />
        <Checkbox
          label={t('privateCar', 'Private car')}
          name="privateFlag"
          helpText={t(
            'privateCarText',
            "Enable this for exclusive access to your vehicle's data, ensuring privacy and security. Manage your car's data with confidence.",
          )}
          checked={privateFlag}
          onChange={(e) => {
            setValue('privateFlag', e.target.checked, { shouldDirty: true });
          }}
        />
      </Box>
    </EditingSidebarBase>
  );
};
