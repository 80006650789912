import { useState } from 'react';
import { FieldValues, UseFormProps, useForm as useReactHookForm } from 'react-hook-form';

import * as Yup from 'yup';

import { Box, Fade, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as AlertIcon } from '@images/icons/network-alert.svg';
import { getErrorMessage } from '@services/api/api-errors';

type FormProps<T extends FieldValues> = UseFormProps<T> & {
  schema: Yup.AnyObjectSchema;
};

export const useForm = <T extends FieldValues>({ schema, ...props }: FormProps<T>) => {
  const [err, setErr] = useState<unknown>();

  const showError = (error: unknown) => {
    setErr(error);
    setTimeout(() => {
      setErr(undefined);
    }, 5000);
  };

  const useTypedForm = () =>
    useReactHookForm<T>({
      ...props,
      resolver: yupResolver(schema),
    });

  const { handleSubmit, ...typedFormRest } = useTypedForm();

  const handleSubmitAndResolve = (submitFn: (values: T) => Promise<boolean>) => () =>
    new Promise<boolean>((resolve) => {
      handleSubmit((attrs) => Promise.resolve(submitFn(attrs as T)).then((success) => resolve(success)))();
    });

  return {
    ...typedFormRest,
    handleSubmit,
    handleSubmitAndResolve,
    showError,
    FormError: (
      <Fade in={!!err}>
        <Box
          display={err ? 'flex' : 'none'}
          p="12px 16px"
          color="#FFF"
          borderRadius="20px"
          gap={2}
          sx={{ backgroundColor: '#282828' }}
        >
          <Box flexShrink={0}>
            <AlertIcon />
          </Box>
          <Typography variant="p14">{getErrorMessage(err)}</Typography>
        </Box>
      </Fade>
    ),
  };
};
