import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

const selectSlice = (state: RootState) => state.rfidCards;

export const selectRfidCards = createAppSelector(selectSlice, (slice) => slice.rfidCards);

export const makeSelectRfidCardByUuid = () =>
  createAppSelector(
    selectRfidCards,
    (state: RootState, uuid: string) => uuid,
    (rfidCards, uuid) => rfidCards.find((r) => r.uuid === uuid),
  );
