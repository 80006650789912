import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConnectorComputedStatus } from 'models/device.enums';
import PropTypes from 'prop-types';

import { Input } from '@components/atoms/Input';
import QuickFilters from '@components/filterComponents/QuickFilters';
import { ReactComponent as NeedsAttentionIcon } from '@images/icons/DeviceStatus/Status_Fault.svg';
import { ReactComponent as SearchIcon } from '@images/icons/Search.svg';
import { ReactComponent as VoolAsteriskIcon } from '@images/icons/nav/Vool_asterisk.svg';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

const DeviceFilters = ({ devices, onFilteredDevicesChange }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilteredDevices, setCategoryFilteredDevices] = useState([]);

  const filters = [
    {
      key: 'voolDevices',
      label: t('voolDevices', 'Vool Devices'),
      icon: VoolAsteriskIcon,
      filterFunction: isVoolDevice,
    },
    {
      key: 'needsAttention',
      label: t('needsAttention', 'Needs Attention'),
      icon: NeedsAttentionIcon,
      filterFunction: (device) => device.computedStatus === ConnectorComputedStatus.FAULTED,
    },
  ];

  useEffect(() => {
    if (!searchQuery || !categoryFilteredDevices.length) {
      onFilteredDevicesChange(categoryFilteredDevices);
    } else {
      const newFilteredData = categoryFilteredDevices.filter((device) => {
        const { name, serialNumber, ownerData } = device;
        const optionsToSearch = [name, serialNumber, ownerData?.email];
        return optionsToSearch.some((option) => option?.toLowerCase().includes(searchQuery.toLowerCase()));
      });
      onFilteredDevicesChange(newFilteredData);
    }
  }, [categoryFilteredDevices, searchQuery]);

  return (
    <div className="flex items-center justify-between">
      <QuickFilters data={devices} filters={filters} onSelectedData={setCategoryFilteredDevices} />
      <Input
        name="search"
        className="!h-10 w-64 rounded-lg bg-gray-100"
        backgroundColor="bg-gray-100"
        leadingIcon={SearchIcon}
        leadingIconClassName="!h-4 !w-4"
        placeholder={t('search', 'Search')}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  );
};

DeviceFilters.propTypes = {
  devices: PropTypes.array.isRequired,
  onFilteredDevicesChange: PropTypes.func,
};

DeviceFilters.defaultProps = {
  onFilteredDevicesChange: () => {},
};

export default DeviceFilters;
