import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { trimEnd } from 'lodash';
import { DeviceModalOption, DeviceStatus } from 'models/device.enums';
import { Permission } from 'models/permission.enum';

import { MenuItem } from '@components/dropdown/MenuComponent';
import { selectPermissionByName, selectUser } from '@services/auth/selectors';
import { useTriggerLmcManagerChargersAutoConfMutation } from '@services/devices/endpoints';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { isLmcSupportingChargersAutoConfAndWifiSignalStrength } from '@views/Devices/DeviceDetailsSidebar/deviceConfigurationUtils';
import { UsageLocation } from '@views/Devices/useGetDeviceMenuItems';
import { LmcMultipointWithStats } from '@views/Sites/SiteDevicesAndGroups/devicesAndGroupsTypes';
import {
  isMultipoint,
  isVoolDevice,
} from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

type GetLmcMultipointMenuItemsFunction = (lmcMultipoint: LmcMultipointWithStats) => MenuItem[];

type UseGetLmcMultipointMenuItemsProps = {
  onModalOptionSelected: Dispatch<SetStateAction<DeviceModalOption | undefined>>;
  onDeviceSelected?: (device: LmcMultipointWithStats) => void;
  openEditModal?: () => void;
  navigateToAddGroup?: (lmcMultipoint: LmcMultipointWithStats) => void;
  usageLocation?: UsageLocation;
};

export const useGetLmcMultipointMenuItems = ({
  onModalOptionSelected,
  onDeviceSelected,
  openEditModal,
  navigateToAddGroup,
  usageLocation,
}: UseGetLmcMultipointMenuItemsProps): GetLmcMultipointMenuItemsFunction => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const trimmedPathname = trimEnd(pathname, '/');
  const canEditDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_DEVICES));
  const canAutoConfDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_AUTO_CONF_DEVICES));
  const canDeleteDevices = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_DEVICES));
  const isAdmin = useAppSelector(selectUser)?.admin;

  const [triggerLmcManagedChargersAutoConf] = useTriggerLmcManagerChargersAutoConfMutation();

  const handleAutoConfChargers = async (lmcDevice: LmcMultipointWithStats) => {
    try {
      const response: any = await triggerLmcManagedChargersAutoConf(lmcDevice.uuid).unwrap();
      const responseData = response.data;
      if (responseData.status === 'Fail') {
        dispatch(
          addToastMessage({
            type: 'error',
            title: t('autoConfChargersFailed', 'Auto-configuring chargers failed'),
          }),
        );
      } else if (responseData.status === 'Partial') {
        // TODO: use a modal instead, maybe for all of the messages?
        // TODO: or use a closable persistent toast?
        dispatch(
          addToastMessage({
            type: 'error',
            title: t('autoConfChargersPartiallyFailed', 'Auto-configuring chargers partially failed'),
            message:
              t('autoConfChargersPartiallyFailedSerials', 'Failed to auto-configure charger(s)') +
              ': ' +
              responseData.failedSerials?.join(', '),
          }),
        );
      } else if (responseData.status === 'Success') {
        dispatch(
          addToastMessage({
            type: 'success',
            title: t('autoConfChargersSuccess', 'Auto-configuring chargers succeeded'),
          }),
        );
      } else {
        dispatch(
          addToastMessage({
            type: 'error',
            title: t('autoConfChargersUnknownFailure', 'Auto-configuring chargers failed'),
            message: t('unknownError', 'Unknown error'),
          }),
        );
      }
    } catch (err) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('autoConfChargersFailed', 'Auto-configuring chargers failed'),
          message: t('autoConfChargersTriggerFailedText', 'Failed to trigger LMC-managed chargers auto-conf.'),
        }),
      );
    }
  };

  const getLmcMultipointMenuItems: GetLmcMultipointMenuItemsFunction = (lmcMultipoint) => [
    {
      title: t('configuration', 'Configuration'),
      key: 'configuration',
      onClick: () =>
        navigate(
          `${trimmedPathname}/details/${usageLocation !== UsageLocation.SINGLE_DEVICE ? lmcMultipoint.uuid : ''}`,
        ),
      hidden: isMultipoint(lmcMultipoint) || usageLocation === UsageLocation.SINGLE_DEVICE,
    },
    {
      title: t('autoConfChargers', 'Auto-conf chargers'),
      key: 'autoConfChargers',
      onClick: () => handleAutoConfChargers(lmcMultipoint),
      disabled: lmcMultipoint.status === DeviceStatus.OFFLINE,
      hidden:
        isMultipoint(lmcMultipoint) ||
        usageLocation === UsageLocation.SINGLE_DEVICE ||
        !canAutoConfDevices ||
        !isLmcSupportingChargersAutoConfAndWifiSignalStrength(lmcMultipoint),
    },
    {
      title: isMultipoint(lmcMultipoint) ? t('editGroup', 'Edit group') : t('editDevice', 'Edit device'),
      key: 'edit',
      onClick: () => {
        if (isMultipoint(lmcMultipoint)) {
          navigateToAddGroup?.(lmcMultipoint);
        } else {
          onDeviceSelected?.(lmcMultipoint);
          openEditModal?.();
          onModalOptionSelected(DeviceModalOption.EDIT);
        }
      },
      hidden: !canEditDevices,
    },
    {
      title: t('addGroup', 'Add a group'),
      key: 'addGroup',
      onClick: () => navigateToAddGroup?.(lmcMultipoint),
      hidden:
        isMultipoint(lmcMultipoint) ||
        !isVoolDevice(lmcMultipoint) ||
        usageLocation !== UsageLocation.SITE_DEVICES ||
        !canEditDevices,
    },
    {
      title: t('ocppVariables', 'OCPP variables'),
      key: 'ocpp-variables',
      onClick: () => {
        onDeviceSelected?.(lmcMultipoint);
        onModalOptionSelected(DeviceModalOption.OCPP_VARIABLES);
      },
      disabled: lmcMultipoint.status !== DeviceStatus.ONLINE,
      hidden: !isAdmin || isMultipoint(lmcMultipoint) || !canEditDevices,
    },
    {
      title: t('datadogLogs', 'DATADOG logs'),
      key: 'datadog-logs',
      onClick: () =>
        window.open(
          `https://app.datadoghq.eu/logs?query=source%3A%28ocpp-data-transfer-worker%20OR%20pusher-worker%20OR%20device-status-change-worker%29%20%40meta.fieldValue%3A${lmcMultipoint.serialNumber}&agg_m=count&agg_q=%40pid&agg_t=count&index=&integration_id=redis&integration_short_name=pid_overview&saved_view=&step=30000&top_n=50&viz=stream&live=true`,
        ),
      hidden:
        !isAdmin ||
        !lmcMultipoint.serialNumber ||
        usageLocation === UsageLocation.BACKOFFICE_DEVICES ||
        isMultipoint(lmcMultipoint),
    },
    {
      title: isMultipoint(lmcMultipoint) ? t('deleteGroup', 'Delete group') : t('deleteDevice', 'Delete device'),
      className: 'text-vermillion',
      key: 'delete',
      onClick: () => {
        onDeviceSelected?.(lmcMultipoint);
        onModalOptionSelected(DeviceModalOption.DELETE);
      },
      hidden: !canDeleteDevices,
    },
  ];

  return getLmcMultipointMenuItems;
};
