import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import GoogleMapReact from 'google-map-react';

import { googleApiKey } from '@config/consts';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { selectSites } from '@services/sites/selectors';

import { SiteMarker } from './SiteMarker';

export const OverviewMap = () => {
  const sites = useSelector(selectSites);
  const [center, setCenter] = useState(undefined);

  useGetSitesQuery({});

  const defaultProps = {
    center: {
      lat: 59.43696079999999,
      lng: 24.7535746,
    },
    zoom: 14,
  };

  return (
    <div className="h-screen max-h-[calc(100vh-200px)] w-full overflow-hidden rounded-2xl">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: googleApiKey,
          libraries: ['places'],
          version: 'weekly',
          id: '__googleMapsScriptId',
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={center}
        options={{
          fullscreenControl: true,
          draggable: true,
          gestureHandling: 'greedy',
        }}
      >
        {sites.map((site) => (
          <SiteMarker
            key={site.uuid}
            lat={site.addressJson.lat}
            lng={site.addressJson.lng}
            onClick={() => setCenter({ lat: site.addressJson.lat, lng: site.addressJson.lng })}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};
