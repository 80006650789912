import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import Sepa from '@images/icons/Sepa.svg';

export const PaymentMethodItemSepa = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <img src={Sepa} alt="Card icon" />
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        <Typography variant="p14b">SEPA</Typography>
        <Typography variant="p14">{t('bankDirectDebit', 'Bank direct debit')}</Typography>
        <Box>&nbsp;</Box>
      </Box>
    </>
  );
};
