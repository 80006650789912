import { useIntercom } from 'react-use-intercom';

import { trackAmplitudeEvent } from '@handlers/amplitude';
import { AmplitudeEvent, getAmplitudeEventPropertiesForUser } from '@handlers/amplitude/data';
import { removeCredentials } from '@services/auth';
import { selectUser } from '@services/auth/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';

export const useLogout = () => {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();
  const { shutdown } = useIntercom();
  return () => {
    shutdown();
    dispatch(removeCredentials());
    trackAmplitudeEvent(AmplitudeEvent.LOGOUT, getAmplitudeEventPropertiesForUser(user!));
  };
};
