import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { TimePeriodSelectorHeader } from '@components/filterComponents/TimePeriodSelectorHeader';
import { useNavigateToSites } from '@hooks/useNavigateToSites';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';
import {
  DurationKey,
  durationToTimeFrame,
  getFormattedStartTimeForDuration,
} from '@services/transactions/chartDataParser';

import { ChargingSessionsSummary } from './ChargingSessionsSummary';
import { DashboardSummary } from './DashboardSummary';
import { DevicesSummary } from './DevicesSummary';

export const DashboardView = () => {
  const { t } = useTranslation();
  const isAdmin = useAppSelector(selectUser)?.admin;
  const { navigateToSites } = useNavigateToSites();

  const [selectedDuration, setSelectedDuration] = useState<DurationKey>();

  const periodStartTime = useMemo(() => getFormattedStartTimeForDuration(selectedDuration), [selectedDuration]);

  const timeFrame = selectedDuration && durationToTimeFrame[selectedDuration];

  useEffect(() => {
    if (isAdmin) {
      navigateToSites();
    }
  }, [isAdmin]);

  return (
    <>
      <DashboardSummary />
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('overviewFor', 'Overview for')}
        storageKey="dashboardDuration"
        withoutAll
      />
      <Box display="flex" flexDirection="column" gap={2}>
        <ChargingSessionsSummary timeFrame={timeFrame} periodStartTime={periodStartTime} />
        <DevicesSummary timeFrame={timeFrame} periodStartTime={periodStartTime} />
      </Box>
    </>
  );
};
