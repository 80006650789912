import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import apiSlice from '@services/api';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { selectVehicleByUuid } from '@services/vehicles/selectors';

import { VehicleDetailsSidebarBase } from './VehicleDetailsSidebarBase';
import { UpdateVehicleQuery } from './vehicleDetailsSchema';

export const VehicleDetailsSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { vehicleUuid } = useParams();
  const vehicle = useAppSelector((state) => (vehicleUuid ? selectVehicleByUuid(state, vehicleUuid) : undefined));

  const [updateCar, { isLoading }] = apiSlice.useUpdateVehicleMutation();

  const saveValues = async (attributes: UpdateVehicleQuery) => {
    try {
      await updateCar(attributes).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemUpdated', '{{item}} updated', { item: t('vehicle', 'Vehicle') }),
        }),
      );
      return true;
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheItemFailed', 'Updating the {{item}} failed', {
            item: t('vehicle', 'Vehicle').toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return (
    vehicle && (
      <VehicleDetailsSidebarBase
        vehicle={vehicle}
        title={t('vehicleDetails', 'Vehicle details')}
        isSaveLoading={isLoading}
        onSaveValues={saveValues}
      />
    )
  );
};
