import { useTranslation } from 'react-i18next';

import { DeleteModal } from './DeleteModal';

type DiscardChangesModalProps = {
  isOpen: boolean;
  onDiscard: () => void;
  onClose: () => void;
};

export const DiscardChangesModal = ({ isOpen, onDiscard, onClose }: DiscardChangesModalProps) => {
  const { t } = useTranslation();
  return (
    <DeleteModal
      title={t('unsavedChanges', 'Unsaved changes')}
      confirmText={t('yesDiscardChanges', 'Yes, discard changes')}
      cancelText={t('no', 'No')}
      isOpen={isOpen}
      onDelete={onDiscard}
      onClose={onClose}
    >
      <div>{t('areYouSureDiscardChanges', 'Are you sure you want to discard the changes?')}</div>
    </DeleteModal>
  );
};
