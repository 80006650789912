import { Breadcrumbs, styled } from '@mui/material';

export const StyledBreadcrumbs = styled(Breadcrumbs)(() => {
  return {
    width: '100%',
    '.MuiBreadcrumbs-ol': {
      flexWrap: 'nowrap',
    },
    '.MuiBreadcrumbs-li': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.MuiBreadcrumbs-separator': {
      margin: '0px',
    },
  };
});
