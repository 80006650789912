import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { isEmpty } from 'lodash';
import { Site } from 'models/site';
import { FeatureDto } from 'models/subscriptions';

import { Box } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useGetCompanyDetailsQuery, useGetCompanyPayoutDetailsQuery } from '@services/companies/endpoints';
import { selectActiveCompany } from '@services/companies/selectors';
import { useAppSelector } from '@services/hooks';

import { SubscriptionPrice } from './SubscriptionPrice';
import { SubscriptionTermsOfUseBox } from './SubscriptionTermsOfUseBox';

type StartSubscriptionSidebarFooterProps = {
  feature: FeatureDto;
  hasDiscount: boolean;
  onSubscribe: () => void;
};

export const StartSubscriptionSidebarFooter = ({
  feature,
  hasDiscount,
  onSubscribe,
}: StartSubscriptionSidebarFooterProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();
  const context = useOutletContext<{ site?: Site }>();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const { data: companyDetails } = useGetCompanyDetailsQuery();
  const { data: payoutDetailsResponse } = useGetCompanyPayoutDetailsQuery();
  const activeCompany = useAppSelector(selectActiveCompany);
  const needsCompanyDetails =
    isEmpty(companyDetails) || isEmpty(payoutDetailsResponse) || !activeCompany?.stripePaymentMethodId;

  const location = useLocation();
  const urlSegment = location.pathname.split('/').pop();

  const hasTrial = (feature.trialPeriodDays ?? 0) > 0;
  const hasSubscription = !!feature.subscription?.isActive;
  const trialExpired = feature.subscription?.trialEndsAt && new Date(feature.subscription.trialEndsAt) < new Date();
  const eligibleForTrial = hasTrial && !hasSubscription && !trialExpired;

  useEffect(() => {
    const handleComplete = async () => {
      onSubscribe();
    };

    if (urlSegment === 'complete') {
      handleComplete();
    }
  }, [location]);

  const handleClick = async () => {
    if (needsCompanyDetails) {
      navigate(context?.site ? '../setup/paymentMethod' : '/workspace/subscriptions/setup/paymentMethod');
      return;
    }

    onSubscribe();
  };

  return (
    <>
      <SubscriptionTermsOfUseBox termsAccepted={termsAccepted} onTermsAcceptedChange={setTermsAccepted} />
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'start' : 'center'}
        gap={2}
      >
        <SubscriptionPrice feature={feature} hasDiscount={hasDiscount} flex={1} />
        <StyledButton
          color="success"
          onClick={handleClick}
          disabled={!termsAccepted}
          large={!isMobile}
          fullWidth={isMobile}
        >
          {eligibleForTrial ? t('startTrial', 'Start trial') : t('subscribe', 'Subscribe')}
        </StyledButton>
      </Box>
    </>
  );
};
