import PropTypes from 'prop-types';

const CardFilters = ({ onClick, filters, activeFilter }) => (
  <div className="space-x-2 text-xxs text-gray-400 dark:text-warmgray-400">
    {filters.map(({ label, value }) => (
      <button
        type="button"
        className={`hover:text-primary${activeFilter === value ? ' text-primary' : ''}`}
        key={label}
        onClick={() => onClick(value)}
      >
        {label}
      </button>
    ))}
  </div>
);

CardFilters.propTypes = {
  onClick: PropTypes.func,
  filters: PropTypes.array,
  activeFilter: PropTypes.string,
};

CardFilters.defaultProps = {
  onClick: () => {},
  filters: [],
  activeFilter: '',
};

export default CardFilters;
