import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { Car } from 'models/car';

import api from '@services/api';

import { updateSmartChargingSessionFromPusher, updateVehicleFromPusher } from './actions';

type VehiclesState = {
  vehicles: Car[];
  linkedAccounts: any[];
  vehiclesNotLinked: any[];
  smartChargingSessions: any[];
  smartChargingSessionsSummary: any;
  vehiclesMileage: any;
  currentSmartChargingSession: any;
};

const initialState: VehiclesState = {
  vehicles: [],
  linkedAccounts: [],
  vehiclesNotLinked: [],
  smartChargingSessions: [],
  smartChargingSessionsSummary: {},
  vehiclesMileage: {},
  currentSmartChargingSession: null,
};

const setLinkedAccounts = (state: VehiclesState, { payload }: any) => {
  state.linkedAccounts = uniqBy([...payload.data], 'uuid');
};
const setVehiclesNotLinked = (state: VehiclesState, { payload }: any) => {
  state.vehiclesNotLinked = uniqBy([...payload.data], 'vin');
};
const addVehicle = (state: VehiclesState, { payload }: any) => {
  state.vehicles = uniqBy([payload.data, ...state.vehicles], 'uuid');
};
const addVehicles = (state: VehiclesState, { payload }: any) => {
  state.vehicles = uniqBy([...payload.data, ...state.vehicles], 'uuid');
};
const setVehicles = (state: VehiclesState, { payload }: any) => {
  state.vehicles = uniqBy([...payload.data], 'uuid');
};
const setVehiclesMileage = (state: VehiclesState, { payload: { data } }: any) => {
  state.vehiclesMileage = data;
};
const deleteVehicle = (state: VehiclesState, { payload: { carUuid } }: any) => {
  const vehicleIndex = state.vehicles.findIndex(({ uuid }) => uuid === carUuid);
  state.vehicles.splice(vehicleIndex, 1);
};
const updateVehicle = (state: VehiclesState, { payload }: any) => {
  const vehicleData = payload?.vehicle || payload?.data;
  const vehicle = state.vehicles.find(({ uuid }) => uuid === vehicleData?.uuid);
  if (vehicle) {
    Object.assign(vehicle, vehicleData);
  }
};
const deleteAccountVehicle = (state: VehiclesState, { payload: { carAccountUuid } }: any) => {
  const accountVehicleIndex = state.linkedAccounts.findIndex(({ uuid }) => uuid === carAccountUuid);
  state.linkedAccounts.splice(accountVehicleIndex, 1);
};

const addSmartChargingSession = (state: VehiclesState, { payload: { data } }: any) => {
  state.smartChargingSessions = uniqBy([data, ...state.smartChargingSessions], 'uuid');
  state.currentSmartChargingSession = data;
};
const setSmartChargingSessions = (state: VehiclesState, { payload }: any) => {
  state.smartChargingSessions = uniqBy([...payload.data], 'uuid');
};
const updateSmartChargingSessionByUuid = (state: VehiclesState, { payload: { data } }: any) => {
  const smartChargingSession = state.smartChargingSessions.find(({ uuid }) => uuid === data.uuid);
  if (smartChargingSession) {
    Object.assign(smartChargingSession, data);
  }
};
const setCurrentSmartChargingSession = (state: VehiclesState, { payload: { data } }: any) => {
  updateSmartChargingSessionByUuid(state, { payload: { data } });
  state.currentSmartChargingSession = data;
};
const stopSmartChargingSession = (state: VehiclesState, { payload: { data } }: any) => {
  updateSmartChargingSessionByUuid(state, { payload: { data } });
  state.currentSmartChargingSession = null;
};

const updateSmartChargingSessionByPusher = (state: VehiclesState, { payload: { smartChargingSession } }: any) => {
  updateSmartChargingSessionByUuid(state, { payload: { data: smartChargingSession } });
  if (state.currentSmartChargingSession?.uuid === smartChargingSession.uuid) {
    state.currentSmartChargingSession = {
      ...state.currentSmartChargingSession,
      ...smartChargingSession,
    };
  }
  if (['stopped', 'failed'].includes(state.currentSmartChargingSession?.status)) {
    state.currentSmartChargingSession = null;
  }
};

const setSmartChargingSessionsSummary = (state: VehiclesState, { meta, payload: { data } }: any) => {
  const args = meta.arg?.originalArgs;
  if (args?.carUuid) {
    const vehicle = state.vehicles.find(({ uuid }) => uuid === args.carUuid);
    if (vehicle) {
      vehicle.chargingSessionsSummary = data;
    }
  } else {
    state.smartChargingSessionsSummary = data;
  }
};

export const dataSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSmartChargingSessionFromPusher, updateSmartChargingSessionByPusher);
    builder.addCase(updateVehicleFromPusher, updateVehicle);
    builder.addMatcher(api.endpoints.vehicles.matchFulfilled, setVehicles);
    builder.addMatcher(api.endpoints.addVehicles.matchFulfilled, addVehicles);
    builder.addMatcher(api.endpoints.getVehicle.matchFulfilled, addVehicle);
    builder.addMatcher(api.endpoints.updateVehicle.matchFulfilled, updateVehicle);
    builder.addMatcher(api.endpoints.getVehiclesMileageSummary.matchFulfilled, setVehiclesMileage);
    builder.addMatcher(api.endpoints.linkedAccounts.matchFulfilled, setLinkedAccounts);
    builder.addMatcher(api.endpoints.connectVehicleAccount.matchFulfilled, setVehiclesNotLinked);
    builder.addMatcher(api.endpoints.getVehiclesNotLinkedByBrand.matchFulfilled, setVehiclesNotLinked);
    builder.addMatcher(api.endpoints.deleteVehicle.matchFulfilled, deleteVehicle);
    builder.addMatcher(api.endpoints.deleteVehicleAccount.matchFulfilled, deleteAccountVehicle);
    builder.addMatcher(api.endpoints.getSmartChargingSessions.matchFulfilled, setSmartChargingSessions);
    builder.addMatcher(api.endpoints.startSmartChargingSession.matchFulfilled, addSmartChargingSession);
    builder.addMatcher(api.endpoints.stopSmartChargingSession.matchFulfilled, stopSmartChargingSession);
    builder.addMatcher(api.endpoints.getCurrentSmartChargingSession.matchFulfilled, setCurrentSmartChargingSession);
    builder.addMatcher(api.endpoints.getSmartChargingSessionsSummary.matchFulfilled, setSmartChargingSessionsSummary);
  },
});

export default dataSlice.reducer;
