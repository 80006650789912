import { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { StyledIconButton } from '@components/atoms/StyledIconButton';
import { SidebarBase } from '@components/sidebars/SidebarBase';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

import { WorkspaceSetupSidebarContext, WorkspaceSetupSidebarProvider } from './WorkspaceSetupSidebarProvider';

type WorkspaceSetupSidebarBaseProps = {
  title: string;
  children: ReactNode;
  returnPath?: string;
};

export const WorkspaceSetupSidebarBase = ({ title, children, returnPath }: WorkspaceSetupSidebarBaseProps) => {
  return (
    <WorkspaceSetupSidebarProvider>
      <WorkspaceSetupSidebarWithContext title={title} returnPath={returnPath}>
        {children}
      </WorkspaceSetupSidebarWithContext>
    </WorkspaceSetupSidebarProvider>
  );
};

const WorkspaceSetupSidebarWithContext = ({ title, children, returnPath }: WorkspaceSetupSidebarBaseProps) => {
  const navigate = useNavigate();
  const { isOpen, setIsOpen, anyChanges, setCloseRequested, closeSidebar } = useContext(WorkspaceSetupSidebarContext);

  const handleClose = () => {
    if (anyChanges) {
      setCloseRequested(true);
      return;
    }

    setIsOpen(false);
    const timeoutId = setTimeout(() => {
      navigate(returnPath ?? '../');
    }, 300);

    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    if (closeSidebar) {
      handleClose();
    }
  }, [closeSidebar]);

  return (
    <SidebarBase isOpen={isOpen} onClose={handleClose} isWide>
      <Box display="flex" flexDirection="column" gap={4} height={1}>
        <Box display="flex" flexDirection="column" gap={4} p="24px 24px 16px 24px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h3">{title}</Typography>
            <StyledIconButton onClick={handleClose}>
              <CloseIcon />
            </StyledIconButton>
          </Box>
        </Box>
        {children}
      </Box>
    </SidebarBase>
  );
};
