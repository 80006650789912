import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { ErrorBoundaryFallbackWithNavigation } from '@components/errors/ErrorBoundaryFallbackWithNavigation';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { googleApiKey } from '@config/consts';
import AuthGuard from '@core/AuthGuard';
import { WorkspaceGuard } from '@core/WorkspaceGuard';
import { Loader } from '@googlemaps/js-api-loader';

import { Header } from './Header';
import { NavigationComponent } from './NavigationComponent';
import { ToastsOverlay } from './ToastsOverlay';

export const LoggedinBasicLayout = () => {
  const [childrenLoading, setChildrenLoading] = useState(false);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>();

  useEffect(() => {
    setIsNavCollapsed(!!localStorage.getItem('isNavCollapsed'));
  }, []);

  useEffect(() => {
    if (isNavCollapsed !== undefined) {
      localStorage.setItem('isNavCollapsed', isNavCollapsed ? 'true' : '');
    }
  }, [isNavCollapsed]);

  const loadGoogle = async () => {
    const loader = new Loader({
      apiKey: googleApiKey,
      id: '__googleMapsScriptId',
      version: 'weekly',
      libraries: ['places'],
    });

    await loader.load();
    setGoogleLoaded(true);
  };

  useEffect(() => {
    loadGoogle();
  }, []);

  return (
    <AuthGuard>
      <WorkspaceGuard>
        <NavigationComponent isCollapsed={!!isNavCollapsed} setIsCollapsed={setIsNavCollapsed} />

        <main className={`relative  sm:ml-20 ${!isNavCollapsed ? 'lg:ml-72' : ''}`}>
          <div className="mx-auto w-full max-w-7xl px-4 max-sm:pb-20 sm:px-10">
            {childrenLoading && <OverflowSpinner backgroundColor="transparent" />}
            {googleLoaded && (
              <div className={`${childrenLoading ? 'hidden' : 'flex h-full flex-col'}`}>
                <Header />
                <div className="mt-8 h-full w-full">
                  <ErrorBoundary FallbackComponent={ErrorBoundaryFallbackWithNavigation}>
                    <Outlet context={setChildrenLoading} />
                  </ErrorBoundary>
                </div>
              </div>
            )}
            <ToastsOverlay />
          </div>
        </main>
      </WorkspaceGuard>
    </AuthGuard>
  );
};
