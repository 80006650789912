import { Path, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddSiteDto, DualPriceSchema, PricingModel } from 'models/site';

import { Typography, useTheme } from '@mui/material';

import { FormTextField } from '@components/atoms/FormTextField';
import { useActiveCurrency } from '@hooks/useActiveCurrency';

import { PeriodicalCostInputs } from './PeriodicalCostInputs';

type FixedPriceInputsProps = {
  form: UseFormReturn<AddSiteDto>;
  name: keyof PricingModel;
  costItemLabel: string;
};

export const FixedPriceInputs = ({ form, name, costItemLabel }: FixedPriceInputsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currency = useActiveCurrency();
  const { control, formState, watch, setValue } = form;

  const isSinglePrice = watch('pricingModel.isSinglePrice');
  const isSingleTransmission = watch('pricingModel.isSingleTransmission');
  const dualPrice = watch('pricingModel.dualPrice');
  const isTransmission = name.includes('transmission');
  const transmissionDualPrice = watch('pricingModel.transmissionDualPrice');
  const namePath = `pricingModel.${name}` as Path<AddSiteDto>;
  const isSingle = isTransmission ? isSingleTransmission : isSinglePrice;
  const dualPriceValue = isTransmission ? transmissionDualPrice : dualPrice;
  const costLabel = t('priceComponentCost', '{{priceComponent}} cost', {
    priceComponent: costItemLabel,
  });

  const updateTimeValue = (dualPricePath: Path<DualPriceSchema>, value: string) => {
    const path = `${namePath}.${dualPricePath}` as Path<AddSiteDto>;
    setValue(path, value.replace(':', ''), { shouldDirty: true });
  };

  return (
    <>
      {isSingle ? (
        <FormTextField
          control={control}
          name={namePath}
          type="number"
          label={costLabel}
          InputProps={{
            endAdornment: (
              <Typography variant="p14" color="darkGray">
                {currency.centsName?.toLowerCase()}
                /kWh
              </Typography>
            ),
          }}
        />
      ) : (
        <>
          <PeriodicalCostInputs
            periodLabel={t('day', 'Day')}
            costLabel={costLabel}
            startTime={dualPriceValue?.dayHours?.start}
            endTime={dualPriceValue?.dayHours?.end}
            cost={dualPriceValue?.price?.day}
            onStartTimeChange={(value) => updateTimeValue('dayHours.start', value)}
            onEndTimeChange={(value) => updateTimeValue('dayHours.end', value)}
            onCostChange={(value) =>
              setValue(`${namePath}.price.day` as Path<AddSiteDto>, value, { shouldDirty: true })
            }
            errors={formState.errors.pricingModel?.[name]}
          />
          <PeriodicalCostInputs
            periodLabel={t('night', 'Night')}
            periodInputsDisabled
            costLabel={costLabel}
            startTime={dualPriceValue?.dayHours?.end}
            endTime={dualPriceValue?.dayHours?.start}
            cost={dualPriceValue?.price?.night}
            onCostChange={(value) =>
              setValue(`${namePath}.price.night` as Path<AddSiteDto>, value, { shouldDirty: true })
            }
            errors={formState.errors.pricingModel?.[name]}
          />
        </>
      )}
      <Typography
        variant="p16"
        onClick={() =>
          setValue(isTransmission ? 'pricingModel.isSingleTransmission' : 'pricingModel.isSinglePrice', !isSingle, {
            shouldDirty: true,
          })
        }
        color={theme.palette.forestGreenCrayola}
        sx={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        {isSingle
          ? t('useDayNightComponentPrices', 'Use day/night {{priceComponent}} prices', {
              priceComponent: costItemLabel.toLowerCase(),
            })
          : t('useSingleComponentPrice', 'Use a single {{priceComponent}} price', {
              priceComponent: costItemLabel.toLowerCase(),
            })}
      </Typography>
    </>
  );
};
