import { useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

const Chevron = ({ closed }: { closed: boolean }) => {
  if (closed) {
    return <ChevronDownIcon className="h-4 w-4 text-gray-500 dark:text-warmgray-400" />;
  }

  return <ChevronUpIcon className="h-4 w-4 text-gray-500 dark:text-warmgray-400" />;
};

export type CardProps = {
  children: React.ReactNode;
  title?: string;
  full?: boolean;
  className?: string;
  wrapperClass?: string;
  expandable?: boolean;
  closedProp?: boolean;
  border?: string;
  overflow?: string;
};

export const Card = ({
  children,
  title,
  full,
  className,
  wrapperClass,
  border,
  overflow,
  expandable = false,
  closedProp = false,
}: CardProps) => {
  const [closed, setClosed] = useState(closedProp);
  const fullTitle = full && title;
  const titleNotFull = title && !full;

  useEffect(() => {
    setClosed(closedProp);
  }, [closedProp]);

  return (
    <div className={wrapperClass}>
      {fullTitle && <div className="pb-1 text-xs">{title}</div>}
      <div
        className={`relative
           rounded-sm
           ${border || 'bg-white dark:border-warmgray-700 dark:bg-truegray-800'}
           flex
           flex-col
           items-center
           ${overflow || 'overflow-hidden'}
           ${className}`}
      >
        {titleNotFull && (
          <div
            className={`
              w-full
              ${closed ? '' : 'pb-1'} 
              ${expandable ? 'cursor-pointer' : ''} 
              flex
              items-center
            `}
            {...(expandable && {
              onClick: () => expandable && setClosed(!closed),
              role: 'button',
              tabIndex: 0,
            })}
          >
            <div className={`pl-4 pt-4 font-poppins text-sm text-gray-600 ${closed ? 'font-bold' : ''}`}>{title}</div>
            {expandable && <Chevron closed={closed} />}
          </div>
        )}

        {!closed && children}
      </div>
    </div>
  );
};
