import { sumBy } from 'lodash';
import { Device } from 'models/device';
import { DeviceType } from 'models/device.enums';

import { isSubdomainPartner } from '@routesConfig/routesUtil';

export const isVoolDevice = (device: Device) =>
  (!!device.vendor && ['ampher', 'vool'].includes(device.vendor.toLowerCase())) || !!device.voolDeviceFlag;

export const isInverter = (device: { inverterId?: string }) => !!device.inverterId;

export const isMainLmc = (device: Device) =>
  !device.lmcMultipointUuid && device.type === DeviceType.LMC && isVoolDevice(device);

export const isMultipoint = (device: { multipointId?: number }) => !!device.multipointId;

export const getLmcMultipointSumBy = ({ lmcMultipoint, chargers, key }: any) =>
  sumBy(chargers, (charger: Device & Record<string, number>) =>
    !!charger.lmcMultipointUuid && charger.lmcMultipointUuid === lmcMultipoint.lmcMultipointUuid ? charger[key] : 0,
  );

export const getLinkToDevice = (device: Device) =>
  isSubdomainPartner() ? `/workspaces/${device.companyUuid}/devices/${device.uuid}` : `/devices/${device.uuid}`;

export const getLinkToAddGroup = (lmcMultipoint: Device) =>
  `./addGroup?deviceUuid=${lmcMultipoint.uuid}&lmcMultipointUuid=${lmcMultipoint.lmcMultipointUuid}`;
