import { useTranslation } from 'react-i18next';

import { FeatureDto, FeatureId } from 'models/subscriptions';

import { Box, Typography, useTheme } from '@mui/material';

import { PublicChargingPricing } from './PublicChargingPricing';

type StartSubscriptionSidebarContentProps = {
  feature: FeatureDto;
  hasTrial: boolean;
};

export const StartSubscriptionSidebarContent = ({ feature, hasTrial }: StartSubscriptionSidebarContentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Typography variant="h2">
        {hasTrial &&
          t('tryFreeForCountDaysCancelAnytime', 'Try free for {{count}} days! Cancel anytime.', {
            count: feature.trialPeriodDays,
          })}
        {!hasTrial &&
          feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING &&
          t('enablePublicChargingAndStartEarning', 'Enable public charging and start earning')}
      </Typography>
      {feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING ? (
        <PublicChargingPricing feature={feature} />
      ) : (
        <Box display="flex" flexDirection="column" color={theme.palette.darkGray}>
          <Typography variant="p14">{feature.description}</Typography>
        </Box>
      )}
    </>
  );
};
