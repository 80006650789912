import { useOutletContext } from 'react-router-dom';

import { UpdateDeviceDataValues } from 'models/device';
import { DeviceType } from 'models/device.enums';

import { DeviceOutletContextType } from '@views/Devices/DeviceOutletContextType';

import { useSaveDevice } from '../DeviceConnectivityFormSidebar/saveDeviceHooks';
import { ChargerSettingsFormSidebar } from './ChargerSettingsFormSidebar';
import { LmcSettingsFormSidebar } from './LmcSettingsFormSidebar';

export const DeviceSettingsFormSidebar = () => {
  const { device } = useOutletContext<DeviceOutletContextType>();

  const [saveDevice, { isLoading, isError }] = useSaveDevice();

  const formOptions: Record<DeviceType, typeof ChargerSettingsFormSidebar | typeof LmcSettingsFormSidebar | null> = {
    charger: ChargerSettingsFormSidebar,
    lmc: LmcSettingsFormSidebar,
    phaser: null,
  };
  const FormComponent = formOptions[device?.type];

  const saveValues = async ({ deviceUpdateData, deviceConfig }: UpdateDeviceDataValues) =>
    saveDevice(device, { deviceUpdateData, deviceConfig });

  return (
    FormComponent && (
      <FormComponent device={device} isUpdateLoading={isLoading} isUpdateError={isError} onSaveValues={saveValues} />
    )
  );
};
