import { useEffect, useState } from 'react';

import { GroupDto } from 'models/group.dto';

import { useGetDevicesQuery } from '@services/devices/endpoints';
import { useGetSitesQuery } from '@services/sites/endpoints';

export const useGroupAssignmentState = (group?: GroupDto, companyUuid?: string) => {
  const [siteUuids, setSiteUuids] = useState<string[]>(group?.siteUuids ?? []);
  const [deviceUuids, setDeviceUuids] = useState<string[]>(group?.deviceUuids ?? []);

  useGetSitesQuery({ companyUuid });
  useGetDevicesQuery({ companyUuid });

  useEffect(() => {
    setSiteUuids(group?.siteUuids ?? []);
    setDeviceUuids(group?.deviceUuids ?? []);
  }, [group]);

  return { siteUuids, setSiteUuids, deviceUuids, setDeviceUuids };
};
