import { createAction } from '@reduxjs/toolkit';

type ClearLocalFailedActionPayload = {
  deviceUuid?: string;
  deviceSerialNumber?: string;
  connectorId?: number;
};

export const updateDeviceFromPusher = createAction('devices/updateDeviceFromPusher');
export const clearDeviceTransactions = createAction('devices/clearDeviceTransactions');
export const clearLocalFailedAction = createAction<ClearLocalFailedActionPayload>('devices/clearLocalFailedAction');

export const setChargerMultiPoint = createAction('devices/setChargerMultiPoint');
