import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { FeatureDto } from 'models/subscriptions';

import { Box } from '@mui/material';

import { useGetFeaturesQuery } from '@services/subscriptions/endpoints';

import { FeatureCard } from './FeatureCard';
import { WorkspaceSubscriptionsViewHeader } from './WorkspaceSubscriptionsViewHeader';

export const WorkspaceSubscriptionsView = () => {
  const navigate = useNavigate();
  const { data: features } = useGetFeaturesQuery();
  const [selectedFeature, setSelectedFeature] = useState<FeatureDto | null>(null);

  const handleFeatureSelected = (feature: FeatureDto) => {
    setSelectedFeature(feature);
    navigate('./subscribe');
  };

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {/* <SubscriptionsTotalCards features={features} /> */}
      <Box display="flex" flexDirection="column" gap={2}>
        <WorkspaceSubscriptionsViewHeader />
        {features?.map((feature) => (
          <FeatureCard key={feature.uuid} feature={feature} onFeatureSelected={() => handleFeatureSelected(feature)} />
        ))}
      </Box>
      <Outlet context={{ feature: selectedFeature }} />
    </Box>
  );
};
