import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';

type AssignmentTreeItemProps = {
  nodeId: string;
  label: string;
  typeLabel: string;
  checked: boolean;
  onClick: () => void;
  indeterminate?: boolean;
  disabled?: boolean;
  omitCollapseIcon?: boolean;
  children?: React.ReactNode;
};

export const AssignmentTreeItem = ({
  nodeId,
  label,
  typeLabel,
  checked,
  onClick,
  indeterminate,
  disabled,
  omitCollapseIcon,
  children,
}: AssignmentTreeItemProps) => (
  <TreeItem
    nodeId={nodeId}
    label={
      <Box display="flex" flexDirection="row" alignItems="center" py={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              indeterminate={indeterminate}
              disabled={disabled}
              onClick={(e) => {
                e.stopPropagation();
                onClick();
              }}
              sx={{
                color: '#D6D6D6',
                '&.Mui-checked, &.MuiCheckbox-indeterminate': {
                  color: '#386957',
                },
              }}
            />
          }
          label={<Typography variant="p14b">{label}</Typography>}
          sx={{ flex: '1 1 0' }}
          onClick={(e) => e.stopPropagation()}
        />
        <Typography variant="p14" color="#5E5E5E" width="142px">
          {typeLabel}
        </Typography>
      </Box>
    }
    {...(omitCollapseIcon && {
      collapseIcon: <></>,
      expandIcon: <></>,
    })}
  >
    {children}
  </TreeItem>
);
