export enum CompanyRole {
  ADMIN = 'admin',
  OWNER = 'owner',
  INSTALLER = 'installer',
  MEMBER = 'member',
  PUBLIC_MEMBER = 'public_member',
}

export enum GroupRole {
  MANAGER = 'manager',
  MEMBER = 'member',
}
