import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';

import { Card } from '@components/atoms/Card';
import ComponentError from '@components/atoms/ComponentError';
import { SummaryCard } from '@components/cards/SummaryCard';
import { TimePeriodSelectorHeader } from '@components/filterComponents/TimePeriodSelectorHeader';
import { useActiveCurrency } from '@hooks/useActiveCurrency';
import { useNavigateToSites } from '@hooks/useNavigateToSites';
import apiSlice from '@services/api';
import { selectCompanyDashboard } from '@services/dashboard/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetSitesQuery } from '@services/sites/endpoints';
import { DurationKey } from '@services/transactions/chartDataParser';

import { SitesTable } from './SitesTable/SitesTable';

export const SitesView = () => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const setLoading = useOutletContext<Dispatch<SetStateAction<boolean>>>();
  const companyDashboard = useAppSelector(selectCompanyDashboard);

  const { isSingleSiteAvailable, navigateToSites } = useNavigateToSites();

  const [selectedDuration, setSelectedDuration] = useState<DurationKey>();

  const { isLoading: isSitesLoading, error: sitesError } = useGetSitesQuery({});

  apiSlice.useGetCompanyDashboardQuery(
    { duration: selectedDuration },
    {
      skip: !selectedDuration,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (isSingleSiteAvailable) {
      navigateToSites();
    }
  }, [isSingleSiteAvailable]);

  useEffect(() => {
    setLoading(isSitesLoading);
    return () => {
      setLoading(false);
    };
  }, [isSitesLoading]);

  if (sitesError) {
    const error = typeof sitesError === 'object' ? sitesError : new Error(t('An error ocurred while fetching sites'));
    return (
      <Card title={t('sites', 'Sites')} className="w-full">
        <ComponentError error={error} />
      </Card>
    );
  }

  return (
    <Box position="relative">
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('allSitesOverviewFor', 'All sites overview for')}
        storageKey="dashboardDuration"
      />
      <Box display="flex" flexWrap="wrap" alignItems="center" mb={5} gap={2}>
        <SummaryCard
          label={t('revenue', 'Revenue')}
          unit={currency.symbol}
          value={companyDashboard?.totalEnergyRevenue}
          valueDecimalPlaces={2}
          trend={companyDashboard?.totalEnergyRevenueTrend}
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard
          label={companyDashboard?.totalEnergyProfit < 0 ? t('loss', 'Loss') : t('profit', 'Profit')}
          unit={currency.symbol}
          value={companyDashboard?.totalEnergyProfit}
          valueDecimalPlaces={2}
          trend={companyDashboard?.totalEnergyProfitTrend}
          tooltip={t('vatNotIncluded', 'VAT not included')}
        />
        <SummaryCard
          label={t('sessions', 'Sessions')}
          value={companyDashboard?.totalTransactions}
          trend={companyDashboard?.totalTransactionsTrend}
        />
        <SummaryCard
          label={t('consumption', 'Consumption')}
          unit="kWh"
          value={companyDashboard?.totalEnergyKwh}
          valueDecimalPlaces={2}
          trend={companyDashboard?.totalEnergyKwhTrend}
        />
      </Box>
      <SitesTable />
    </Box>
  );
};
