import { Box } from '@mui/material';

import { FiltersCard } from '@components/filters/FiltersCard';

import { SessionsTotalCards } from './SessionsTotalCards';
import { ReportingTransactionsContext } from './context/ReportingTransactionsProvider';
import { ReportingMaterialTable } from './table/ReportingMaterialTable';

export const ReportingSessionsView = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2} pb={8}>
      <FiltersCard context={ReportingTransactionsContext} />
      <SessionsTotalCards context={ReportingTransactionsContext} />
      <ReportingMaterialTable context={ReportingTransactionsContext} />
    </Box>
  );
};
