import { useEffect, useState } from 'react';

import moment from 'moment';

type DeviceOcppTimerProps = {
  startTime?: string;
  longTimeAlertDisabled: boolean;
  onLongTimeAlert: () => void;
};

export const DeviceOcppTimer = ({ startTime, longTimeAlertDisabled, onLongTimeAlert }: DeviceOcppTimerProps) => {
  const [timer, setTimer] = useState(moment());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = moment();
      if (!longTimeAlertDisabled && !!startTime && currentTime.diff(startTime) > 10 * 60 * 1000) {
        onLongTimeAlert();
      }
      setTimer(currentTime);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  // @ts-expect-error format imported from moment-duration-format
  return <>{moment.duration(timer.diff(startTime ?? undefined)).format('HH:mm:ss')}</>;
};
