import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { sum } from 'lodash';
import { QueryInterval } from 'models/queryInterval.enum';

import { Box, useTheme } from '@mui/material';

import MetricsCard from '@components/cards/MetricsCard';
import LineChart from '@components/charts/LineChart';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useDevicesStatusLogDtosQuery } from '@services/eventLog/endpoints';
import { TimeFrameKey } from '@services/transactions/chartDataParser';

type DevicesSummaryProps = {
  timeFrame?: TimeFrameKey;
  periodStartTime?: string;
};

export const DevicesSummary = ({ timeFrame }: DevicesSummaryProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();

  const timeFrameToQueryInterval: Record<TimeFrameKey, QueryInterval> = {
    today: QueryInterval.DAY,
    week: QueryInterval.WEEK,
    month: QueryInterval.MONTH,
    sixMonths: QueryInterval.SIX_MONTHS,
    year: QueryInterval.YEAR,
  };

  const { data: devicesStatusLogs, isLoading } = useDevicesStatusLogDtosQuery(
    timeFrame
      ? {
          interval: timeFrameToQueryInterval[timeFrame],
          types: ['error', 'warning'],
        }
      : skipToken,
  );

  const totalWarningLogs = sum(devicesStatusLogs?.warning.map((log) => log.count));
  const totalErrorLogs = sum(devicesStatusLogs?.error.map((log) => log.count));

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
      <MetricsCard
        title={t('devicesInfo', 'Devices info')}
        metrics={[
          {
            label: t('totalWarningLogs', 'Total warnings logs'),
            value: totalWarningLogs,
          },
          {
            label: t('totalErrorLogs', 'Total errors logs'),
            value: totalErrorLogs,
          },
        ]}
        onClick={() => navigate('/logs?categories=error,warning')}
      />
      <LineChart
        noBorder
        title={t('devicesAlerts', 'Devices alerts')}
        series={[
          {
            name: t('errors', 'Errors'),
            data: devicesStatusLogs?.error || [],
          },
          {
            name: t('warnings', 'Warnings'),
            data: devicesStatusLogs?.warning || [],
            color: theme.palette.warning.main,
          },
        ]}
        isFetching={isLoading}
        filter={timeFrame}
      />
    </Box>
  );
};
