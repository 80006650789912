import CheckIcon from '@mui/icons-material/Check';
import { Box, StepIconProps, Typography, styled } from '@mui/material';

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'ownerState',
})<{ ownerState: StepIconProps }>(({ ownerState: { active, completed } }) => ({
  color: active ? '#FFFFFF' : '#5E5E5E',
  backgroundColor: active ? '#282828' : '#D6D6D6',
  ...(completed && { backgroundColor: '#D7E1DD' }),
  width: '40px',
  height: '40px',
  padding: '8px',
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const SquareStepIcon = (props: StepIconProps) => {
  const { completed } = props;
  return (
    <StyledBox ownerState={props}>
      {completed ? <CheckIcon sx={{ color: '#386957' }} /> : <Typography variant="p18">{props.icon}</Typography>}
    </StyledBox>
  );
};
