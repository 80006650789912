import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { trimEnd } from 'lodash';
import { Car } from 'models/car';
import { VehicleModalOption } from 'models/car.enum';
import { Permission } from 'models/permission.enum';

import { MenuItem } from '@components/dropdown/MenuComponent';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

type GetVehicleMenuItemsFunction = (vehicle: Car) => MenuItem[];

export type UseGetVehicleMenuItemsProps = {
  onVehicleModalOptionSelected: (option: VehicleModalOption, vehicle?: Car) => void;
  isVehicleView?: boolean;
};

export const useGetVehicleMenuItems = ({
  onVehicleModalOptionSelected,
  isVehicleView,
}: UseGetVehicleMenuItemsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const trimmedPathname = trimEnd(pathname, '/');
  const canEditVehicles = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_COMPANY_CARS));
  const canDeleteVehicles = useAppSelector((state) =>
    selectPermissionByName(state, Permission.CAN_DELETE_COMPANY_CARS),
  );

  const getVehicleMenuItems: GetVehicleMenuItemsFunction = (vehicle) => [
    {
      title: t('openChangeLog', 'Open change log'),
      key: 'openChangeLog',
      onClick: () => onVehicleModalOptionSelected(VehicleModalOption.CHANGELOG),
      hidden: !isVehicleView,
    },
    {
      title: t('vehicleDetails', 'Vehicle details'),
      key: 'vehicleDetails',
      onClick: () => navigate(`${trimmedPathname}/menu-details/${!isVehicleView ? vehicle.uuid : ''}`),
      hidden: !canEditVehicles && !vehicle.privateFlag,
    },
    {
      title: t('deleteVehicle', 'Delete vehicle'),
      className: 'text-vermillion',
      key: 'deleteVehicle',
      onClick: () => onVehicleModalOptionSelected(VehicleModalOption.DELETE, vehicle),
      hidden: !canDeleteVehicles && !vehicle.privateFlag,
    },
  ];

  return getVehicleMenuItems;
};
