import { useTranslation } from 'react-i18next';

import * as yup from 'yup';

export default ({ gridConnectionRequired }) => {
  const { t } = useTranslation();
  return yup.object({
    name: yup.string().default(undefined),
    newSite: yup.boolean().default(false),
    siteName: yup.string().when('newSite', {
      is: true,
      then: (s) => s.required(t('required*', 'Required*')),
    }),
    gridConnection: yup.lazy((value) => {
      const message = t('mustBeIntegerGreaterThanZero', 'Must be an integer greater than zero');
      if (value?.trim?.() === '') {
        return yup
          .string()
          .concat(
            gridConnectionRequired ? yup.string().typeError(message).required(t('required*', 'Required*')) : null,
          );
      }
      return yup
        .number()
        .typeError(message)
        .integer(message)
        .moreThan(0, message)
        .concat(gridConnectionRequired ? yup.number().typeError(message).required(t('required*', 'Required*')) : null);
    }),
    siteAddress: yup.string().when('newSite', {
      is: true,
      then: (s) => s.required(t('required*', 'Required*')),
    }),
  });
};
