import { useEffect, useMemo, useState } from 'react';

import { isEmpty, isNumber } from 'lodash';
import { AddSiteDto, DualPriceSchema, PricingModel, Site } from 'models/site';

import { useForm } from '@hooks/useTypedForm';
import { useGetCountriesQuery } from '@services/countries/endpoints';

import { defaultSiteDetailsValues, useSiteDetailsSchema } from './useSiteDetailsSchema';

const uncolonifyDualPrices = (dualPrice?: DualPriceSchema) =>
  dualPrice && {
    ...dualPrice,
    dayHours: {
      start: dualPrice.dayHours?.start?.replace(':', '') ?? '',
      end: dualPrice.dayHours?.end?.replace(':', '') ?? '',
    },
  };

const getTrimmedPricingModel = (model: PricingModel): PricingModel => ({
  isAutomatic: model.isAutomatic,
  isSinglePrice: model.isSinglePrice,
  isSingleTransmission: !!model.isSingleTransmission,
  ...(model.isAutomatic
    ? {
        region: model.region,
      }
    : {
        ...(model.isSinglePrice
          ? {
              singlePrice: model.singlePrice,
            }
          : {
              dualPrice: uncolonifyDualPrices(model.dualPrice),
            }),
      }),
  ...(model.isSingleTransmission
    ? {
        transmissionSinglePrice: model.transmissionSinglePrice,
      }
    : {
        transmissionDualPrice: uncolonifyDualPrices(model.transmissionDualPrice),
      }),
  extraFees: isNumber(model.extraFees) ? model.extraFees : null,
});

type UseSiteDetailsFormProps = {
  site?: Site;
  onSaveValues: (values: AddSiteDto) => Promise<boolean>;
};

export const useSiteDetailsForm = ({ site, onSaveValues }: UseSiteDetailsFormProps) => {
  const [updateFailed, setUpdateFailed] = useState(false);

  const schema = useSiteDetailsSchema();

  const form = useForm<AddSiteDto>({
    schema,
    defaultValues: defaultSiteDetailsValues,
  });

  const { reset, setValue, clearErrors, handleSubmitAndResolve } = form;

  const { data: countries } = useGetCountriesQuery();

  const regionOptions = useMemo(
    () =>
      (countries ?? []).flatMap(({ electricityPriceAreas, code: countryCode }) =>
        electricityPriceAreas
          .filter(({ activeFlag }) => activeFlag)
          .map(({ name, code }) => ({
            label: name,
            value: code,
            extraLabel: code,
            Icon: <span className={`fi fi-${countryCode.toLowerCase()}`}></span>,
          })),
      ),
    [countries],
  );

  const handleSetPricingModelValue = (attr: keyof PricingModel, value: any) => {
    const path = `pricingModel.${attr}` as keyof AddSiteDto;
    setValue(path, value, { shouldDirty: true });
    clearErrors(path);
  };

  const loadValues = () => {
    if (!site || isEmpty(site)) {
      reset();
    } else {
      reset({
        name: site.name,
        address: site.address,
        addressJson: site.addressJson,
        pricingModel: site.pricingModel,
      });
    }
  };

  useEffect(loadValues, [site?.pricingModel]);

  const handleSaveValues = async ({ name, address, addressJson, pricingModel }: AddSiteDto) => {
    const success = await onSaveValues({
      name: name?.trim(),
      addressJson,
      address,
      pricingModel: getTrimmedPricingModel(pricingModel),
    });
    setUpdateFailed(!success);
    return success;
  };

  return {
    form,
    regionOptions,
    updateFailed,
    handleSetPricingModelValue,
    submitSiteDetails: handleSubmitAndResolve(handleSaveValues),
  };
};
