import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { Inverter, InverterSummary } from 'models/inverter';

import { sitesApi } from '@services/sites/endpoints';

import { createInverterFromPusher, updateInverterFromPusher } from './actions';
import { invertersApi } from './endpoints';

type InvertersState = {
  list: Inverter[];
  linkedAccounts: any[];
  inverterSummary: InverterSummary;
};

const initialState: InvertersState = {
  list: [],
  linkedAccounts: [],
  inverterSummary: {
    summary: {
      maxProductionKw: 0,
      maxProductionKwTrend: 0,
      minProductionKw: 0,
      minProductionKwTrend: 0,
      totalProductionKwh: 0,
      totalProductionKwhTrend: 0,
    },
    sessions: [],
  },
};

const addInverter = (state: InvertersState, { payload }: { payload: Inverter }) => {
  state.list = uniqBy([payload, ...state.list], 'uuid');
};

const addInverters = (state: InvertersState, { payload }: { payload: Inverter[] }) => {
  state.list = uniqBy([...payload, ...state.list], 'uuid');
};

const updateInverter = (state: InvertersState, { payload }: { payload: Inverter }) => {
  const inverter = state.list.find(({ uuid }) => uuid === payload?.uuid);
  if (inverter) {
    Object.assign(inverter, payload);
  }
};

const deleteInverter = (state: InvertersState, { payload }: { payload: { inverterUuid: string } }) => {
  const inverterIndex = state.list.findIndex(({ uuid }) => uuid === payload?.inverterUuid);
  if (inverterIndex !== -1) {
    state.list.splice(inverterIndex, 1);
  }
};

const setInverterSummary = (state: InvertersState, { payload }: { payload: InverterSummary }) => {
  state.inverterSummary = payload;
};

const dataSlice = createSlice({
  name: 'inverters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createInverterFromPusher, addInverter);
    builder.addCase(updateInverterFromPusher, updateInverter);
    builder.addMatcher(invertersApi.endpoints.deleteInverter.matchFulfilled, deleteInverter);
    builder.addMatcher(invertersApi.endpoints.getInverter.matchFulfilled, addInverter);
    builder.addMatcher(invertersApi.endpoints.getInverterSummary.matchFulfilled, setInverterSummary);
    builder.addMatcher(invertersApi.endpoints.updateInverter.matchFulfilled, updateInverter);
    builder.addMatcher(invertersApi.endpoints.getInverters.matchFulfilled, addInverters);
    builder.addMatcher(invertersApi.endpoints.getAllInverters.matchFulfilled, addInverters);
    builder.addMatcher(sitesApi.endpoints.getSiteInverters.matchFulfilled, addInverters);
  },
});

export default dataSlice.reducer;
