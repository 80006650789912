import { useTranslation } from 'react-i18next';

import { DeviceStatus } from 'models/device.enums';

type LmcStatusPillProps = {
  status: DeviceStatus;
};

export const LmcStatusPill = ({ status }: LmcStatusPillProps) => {
  const { t } = useTranslation();

  const statusDefMapping: Record<DeviceStatus, { pillColor: string; label: string }> = {
    [DeviceStatus.ONLINE]: {
      pillColor: 'bg-forest',
      label: t('online', 'Online'),
    },
    [DeviceStatus.OFFLINE]: {
      pillColor: 'bg-gray-200',
      label: t('offline', 'Offline'),
    },
    [DeviceStatus.FAULTED]: {
      pillColor: 'bg-vermillion',
      label: t('faulted', 'Faulted'),
    },
  };

  const unknownStatusDef = {
    pillColor: 'bg-gray-200',
    label: t('unknown', 'Unknown'),
  };

  const statusDef = statusDefMapping[status] ?? unknownStatusDef;

  return (
    <div
      className={`
        w-fit
        ${statusDef.pillColor}
        flex
        items-center
        whitespace-nowrap
        rounded
        px-2
        leading-6
        text-white
      `}
    >
      {statusDef.label.toUpperCase()}
    </div>
  );
};
