export enum DeviceType {
  CHARGER = 'charger',
  LMC = 'lmc',
  PHASER = 'phaser',
}
export enum DeviceStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  FAULTED = 'faulted',
}
export enum OcppVersion {
  V1_6 = '1.6',
  V2_0 = '2.0',
}
export enum DeviceTransactionStatus {
  NONE = 'none',
  PENDING = 'pending',
  IN_PROGRESS = 'inProgress',
}
export enum Monitoring {
  VOOL = 'vool',
  OWNER = 'owner',
}
export enum Provider {
  VOOL = 'vool',
  ENODE = 'enode',
}

export enum PowerType {
  AC = 'AC',
  AC_1_PHASE = 'AC_1_PHASE',
  AC_3_PHASE = 'AC_3_PHASE',
  DC = 'DC',
}

export enum ConnectorType {
  TYPE_1 = 'TYPE_1',
  TYPE_2 = 'TYPE_2',
  CHADEMO = 'CHADEMO',
  CCS = 'CCS',
}

export enum ConnectorFormat {
  CABLE = 'CABLE',
  SOCKET = 'SOCKET',
}

export enum OcppStatus {
  OFFLINE = 'Offline',
  PLUGGED_IN = 'Plugged in',
  CHARGING = 'Charging',
  UNAVAILABLE = 'Not available',
  AVAILABLE = 'Available',
  OCCUPIED = 'Occupied',
  PENDING = 'Pending',
  RESERVED = 'Reserved',
}

export enum ConnectorComputedStatus {
  AVAILABLE = 'AVAILABLE',
  CHARGING = 'CHARGING',
  FAULTED = 'FAULTED',
  NEVER_CONNECTED = 'NEVER_CONNECTED',
  OFFLINE = 'OFFLINE',
  PLUGGED_IN = 'PLUGGED_IN',
  RESERVED = 'RESERVED',
  STARTING = 'STARTING',
  STOPPING = 'STOPPING',
  SUSPENDED_EV = 'SUSPENDED_EV',
  SUSPENDED_EVSE = 'SUSPENDED_EVSE',
  UNAVAILABLE = 'UNAVAILABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum ConnectorMeterValueKey {
  ACTIVE_POWER = 'active_power',
  CURRENT = 'current',
  CURRENT_L1 = 'current_l1',
  CURRENT_L2 = 'current_l2',
  CURRENT_L3 = 'current_l3',
  VOLTAGE = 'voltage',
  VOLTAGE_L1 = 'voltage_l1',
  VOLTAGE_L2 = 'voltage_l2',
  VOLTAGE_L3 = 'voltage_l3',
  STATE_OF_CHARGE = 'state_of_charge',
}

export enum ChargingAction {
  START = 'start',
  STOP = 'stop',
}

export enum Network {
  ETHERNET = 'ETHERNET',
  WIFI = 'WIFI',
  PLC = 'PLC',
  GSM = 'GSM',
}

export enum ControlChannel {
  APP_CMD = 'APP_CMD',
  APP_LM = 'APP_LM',
  LAN_CMD = 'LAN_CMD',
  LAN_LM = 'LAN_LM',
  CAN_CMD = 'CAN_CMD',
  CAN_LM = 'CAN_LM',
}

export enum LmcConnectionType {
  CAN = 'CAN',
  LAN = 'LAN',
}

export enum PowerMeterType {
  LMC = 'LMC',
  PAC3220 = 'PAC3220',
  NONE = 'NONE',
}

export enum SolarInverterType {
  NONE = 'NONE',
  HUAWEI_SMART_LOGGER_3000 = 'HUAWEI_SMART_LOGGER_3000',
}

export enum PowerSharingMethod {
  PRIORITIZED = 'PRIORITIZED',
  EQUAL = 'EQUAL',
}

export enum MainsMeteringType {
  HOUSE_AND_CHARGER_CONSUMPTION = 'HOUSE_AND_CHARGER_CONSUMPTION',
  HOUSE_CONSUMPTION = 'HOUSE_CONSUMPTION',
}

export enum DeviceModalOption {
  DELETE = 'delete',
  EDIT = 'edit',
  RENAME = 'rename',
  OCPP_SETUP = 'ocpp_setup',
  OCPP_VARIABLES = 'ocpp_variables',
  OCPP_RESET = 'ocpp_reset',
  OCPP_UPDATE = 'ocpp_update',
}

export enum DeviceOcppActionOption {
  OCPP_RESET = 'restarted',
  OCPP_UPDATE = 'updated',
}

export enum DeviceFirmwareUpdateStatus {
  DOWNLOADED = 'Downloaded',
  DOWNLOAD_FAILED = 'DownloadFailed',
  DOWNLOADING = 'Downloading',
  DOWNLOAD_SCHEDULED = 'DownloadScheduled',
  DOWNLOAD_PAUSED = 'DownloadPaused',
  INSTALLATION_FAILED = 'InstallationFailed',
  INSTALLING = 'Installing',
  INSTALLED = 'Installed',
  INSTALL_REBOOTING = 'InstallRebooting',
  INSTALL_SCHEDULED = 'InstallScheduled',
  INSTALL_VERIFICATION_FAILED = 'InstallVerificationFailed',
}

export enum DeviceConnectorChargingProgramAlgorithm {
  CHEAPEST_MINUTES = 'cheapest_minutes',
  FULL_BATTERY_WITH_CHEAPEST_HOURS = 'full_battery_with_cheapest_hours',
  INVERTER_CONTROLLED = 'inverter_controlled',
  MANUAL = 'manual',
  PRICE_BASED = 'price_based',
}

export enum GetOcppVariableStatus {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  UNKNOWN_COMPONENT = 'UnknownComponent',
  UNKNOWN_VARIABLE = 'UnknownVariable',
  NOT_SUPPORTED_ATTRIBUTE_TYPE = 'NotSupportedAttributeType',
}

export enum VoolDeviceAvailabilityStatus {
  AVAILABLE = 'available',
  IN_USE = 'in_use',
  NO_MATCH = 'no_match',
  ERROR = 'error',
}
