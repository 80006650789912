import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@components/atoms/Button';

type SettingsSectionProps = {
  title: string;
  editPath?: string;
  editDisabled?: boolean;
  children?: ReactNode;
};

export const SettingsSection = ({ title, editPath, editDisabled, children }: SettingsSectionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-y-2 font-poppins text-sm">
      <div className="flex items-center justify-between">
        <h4 className="font-semibold">{title}</h4>
        {!!editPath && (
          <Button variant="primary" disabled={editDisabled} onClick={() => navigate(editPath)}>
            {t('edit', 'Edit')}
          </Button>
        )}
      </div>
      <div className="flex flex-col overflow-hidden rounded-2xl">{children}</div>
    </div>
  );
};
