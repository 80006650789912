import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Slide, Typography, useTheme } from '@mui/material';

import { CountryAutocomplete } from '@components/atoms/CountryAutocomplete';
import { FormCheckbox } from '@components/atoms/FormCheckbox';
import { FormTextField } from '@components/atoms/FormTextField';

import { WorkspaceSetupFooter } from '../setup/WorkspaceSetupFooter';
import { WorkspaceSetupSidebarContext } from '../setup/WorkspaceSetupSidebarProvider';
import { useCompanyDetailsForm } from './useCompanyDetailsForm';

type CompanyDetailsProps = {
  isSetup?: boolean;
};

export const CompanyDetails = ({ isSetup }: CompanyDetailsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { setAnyChanges, setCloseSidebar } = useContext(WorkspaceSetupSidebarContext);

  const { form, submitCompanyDetails, isLoading, isSaving } = useCompanyDetailsForm();
  const { control, watch, formState } = form;

  const vatRegisteredFlag = watch('vatRegisteredFlag');

  useEffect(() => {
    setAnyChanges(formState.isDirty);
    return () => setAnyChanges(false);
  }, [formState.isDirty]);

  const handleSave = async () => {
    const success = await submitCompanyDetails();
    if (!success) return;
    setAnyChanges(false);

    if (isSetup) {
      navigate('../payoutDetails');
    } else {
      setCloseSidebar(true);
    }
  };

  return (
    <>
      <Slide direction="left" in={true} mountOnEnter unmountOnExit>
        <Box display="flex" flexDirection="column" overflow="auto" flex={1} gap={6} p="0 24px">
          <Typography variant="p16" color={theme.palette.darkGray}>
            {t(
              'pleaseProvideCompanyDetailsText',
              'Please provide your company details. These details will appear on invoices sent to your public charging customers.',
            )}
          </Typography>
          <Box display="flex" flexDirection="column" gap={4}>
            <FormTextField
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="legalName"
              label={t('legalName', 'Legal name')}
            />
            <CountryAutocomplete
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="addressJson.country"
            />
            <FormTextField
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="registrationNumber"
              label={t('registrationNumber', 'Registration number')}
            />
            <FormCheckbox
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="vatRegisteredFlag"
              title={t('vatRegistered', 'VAT registered')}
            />
            {!!vatRegisteredFlag && (
              <FormTextField
                control={control}
                isLoading={isLoading}
                disabled={isSaving}
                name="vatNumber"
                label={t('vatNumberAbbreviation', 'VAT number')}
              />
            )}
            <FormTextField
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="addressJson.streetAddress"
              label={t('streetAddress', 'Street address')}
            />
            <FormTextField
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="addressJson.apt"
              label={`${t('aptSuiteEtc', 'Apt, suite, etc.')} (${t('optional', 'Optional').toLowerCase()})`}
            />
            <FormTextField
              control={control}
              isLoading={isLoading}
              disabled={isSaving}
              name="addressJson.city"
              label={t('city', 'City')}
            />
            <Box display="flex" gap={4}>
              <FormTextField
                control={control}
                isLoading={isLoading}
                disabled={isSaving}
                name="addressJson.state"
                label={t('country/state', 'County/state')}
                sx={{ flex: 1 }}
              />
              <FormTextField
                control={control}
                isLoading={isLoading}
                disabled={isSaving}
                name="addressJson.zipCode"
                label={t('zipPostalCode', 'ZIP/postal code')}
                sx={{ flex: 1 }}
              />
            </Box>
          </Box>
        </Box>
      </Slide>
      <WorkspaceSetupFooter
        onNext={handleSave}
        onCancel={() => (isSetup ? navigate(-1) : setCloseSidebar(true))}
        saveDisabled={isLoading || isSaving}
        isSetup={isSetup}
      />
    </>
  );
};
