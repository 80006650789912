import { useEffect, useState } from 'react';

import { uniqBy } from 'lodash';
import { GroupDto } from 'models/group.dto';

import { useAppSelector } from '@services/hooks';
import { selectUsers } from '@services/users/selectors';

import { GroupUserOption } from '../GroupMembers';

export const useGroupMembersState = (group?: GroupDto) => {
  const companyUsers = useAppSelector(selectUsers);

  const makeOptions = (): GroupUserOption[] =>
    group?.users?.map((user) => ({
      uuid: user.userUuid,
      email: user.email,
      label: user.name || user.email,
      role: user.role,
    })) ?? [];

  const [groupUsers, setGroupUsers] = useState<GroupUserOption[]>(makeOptions());

  useEffect(() => {
    setGroupUsers(makeOptions());
  }, [companyUsers, group]);

  const handleAddUsers = (usersToBeAdded: GroupUserOption[]) => {
    setGroupUsers((prev) => uniqBy(prev.concat(usersToBeAdded), 'email'));
  };

  return {
    groupUsers,
    setGroupUsers,
    handleAddUsers,
  };
};
