import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { ReactComponent as AlertIcon } from '@images/icons/Alert_outlined.svg';

import { Modal, ModalProps } from './Modal';

type DeleteModalProps = Omit<ModalProps, 'onConfirm'> & {
  onDelete: (event: SyntheticEvent) => void;
};

export const DeleteModal = ({ children, onDelete, ...props }: DeleteModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal {...props} confirmButtonColor="primary" onConfirm={onDelete}>
      {children}
      <Typography display="flex" alignItems="center" gap={1} variant="p16" color="#EB4E20">
        <AlertIcon />
        {t('thisActionCannotBeUndone', 'This action cannot be undone.')}
      </Typography>
    </Modal>
  );
};
