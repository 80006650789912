import { useTranslation } from 'react-i18next';

import { PayoutType } from 'models/payment.enums';
import * as yup from 'yup';

export type PayoutDetailsForm = {
  companyUuid: string;
  payoutType: string;
  bankAccountDetails: {
    accountName: string;
    iban: string;
    swift: string;
    addressJson: {
      streetAddress: string;
      apt: string;
      city: string;
      state: string;
      zipCode: string;
      country: string;
    };
  };
  cardDetails: {
    stripePaymentMethodId: string;
  };
};

export const getPayoutDetailsDefaultValues = (activeCompanyUuid: string): PayoutDetailsForm => ({
  companyUuid: activeCompanyUuid,
  payoutType: PayoutType.BANK,
  bankAccountDetails: {
    accountName: '',
    iban: '',
    swift: '',
    addressJson: {
      streetAddress: '',
      apt: '',
      city: '',
      state: '',
      zipCode: '',
      country: '',
    },
  },
  cardDetails: {
    stripePaymentMethodId: '',
  },
});

export const usePayoutDetailsSchema = () => {
  const { t } = useTranslation();
  const requiredLabel = t('required*', 'Required*');

  const createField = (countryFieldKey = 'country'): yup.StringSchema<string | null | undefined> =>
    yup
      .string()
      .when(countryFieldKey, {
        is: (country: string) => country !== 'Estonia',
        then: yup.string().required(requiredLabel).nullable(),
      })
      .nullable();

  const bankAccountDetails = yup.object().shape({
    accountName: yup.string().required(requiredLabel).nullable(),
    iban: yup.string().required(requiredLabel).nullable(),
    swift: createField('addressJson.country'),
    addressJson: yup.object().shape({
      country: yup.string().required(requiredLabel).nullable(),
      streetAddress: createField(),
      apt: yup.string().nullable(),
      city: createField(),
      state: createField(),
      zipCode: createField(),
    }),
  });

  const cardDetails = yup.object().shape({
    stripePaymentMethodId: yup.string().required(requiredLabel).nullable(),
  });

  return yup.object().shape({
    companyUuid: yup.string().required(requiredLabel).nullable(),
    payoutType: yup.string().required(requiredLabel).nullable(),
    bankAccountDetails: yup.object().when('payoutType', {
      is: PayoutType.BANK,
      then: bankAccountDetails,
    }),
    cardDetails: yup.object().when('payoutType', {
      is: PayoutType.CARD,
      then: cardDetails,
    }),
  });
};
