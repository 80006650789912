import { createSlice } from '@reduxjs/toolkit';
import jsCookie from 'js-cookie';
import { Permission } from 'models/permission.enum';
import { User } from 'models/user';

import { companiesApi } from '@services/companies/endpoints';

import { removeCredentials, setCredentials } from './actions';
import { authApi } from './endpoints';

export type AuthState = {
  user?: User;
  token?: string;
  permissions: Permission[];
};

const initialState: AuthState = {
  permissions: [],
};

const setUser = (state: AuthState, { payload }: any) => {
  state.user = { ...payload.user, role: payload.role };
};

const setAuthAndUser = (state: AuthState, { payload }: any) => {
  if (payload.token) {
    jsCookie.set('ad_token', payload.token, { expires: 30, sameSite: 'Strict' });
    state.token = payload.token;
  }

  if (payload.refreshToken) {
    jsCookie.set('ad_refreshToken', payload.refreshToken, { expires: 30, sameSite: 'Strict' });
  }

  if (payload.user) {
    state.user = payload.user;
  }
};

const clearAuth = () => {
  jsCookie.remove('ad_token');
  jsCookie.remove('ad_refreshToken');
  return initialState;
};

const setPermissions = (state: AuthState, { payload }: { payload: { permissions: Permission[] } }) => {
  state.permissions = payload.permissions;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCredentials, setAuthAndUser).addCase(removeCredentials, clearAuth);

    builder.addMatcher(authApi.endpoints.login.matchFulfilled, setAuthAndUser);
    builder.addMatcher(authApi.endpoints.register.matchFulfilled, setAuthAndUser);
    builder.addMatcher(authApi.endpoints.self.matchFulfilled, setUser);
    builder.addMatcher(authApi.endpoints.googleLogin.matchFulfilled, setAuthAndUser);
    builder.addMatcher(authApi.endpoints.appleLogin.matchFulfilled, setAuthAndUser);
    builder.addMatcher(authApi.endpoints.changeResetPassword.matchFulfilled, setAuthAndUser);
    builder.addMatcher(authApi.endpoints.updateUser.matchFulfilled, setUser);
    builder.addMatcher(authApi.endpoints.updateUserAndInvalidateCompany.matchFulfilled, setUser);
    builder.addMatcher(companiesApi.endpoints.getCompanyUserPermissions.matchFulfilled, setPermissions);
  },
});

export { setCredentials, removeCredentials };

export default authSlice.reducer;
