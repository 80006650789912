import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import PropTypes from 'prop-types';

import { ErrorWithLogout } from '@components/errors/ErrorWithLogout';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { useSubscribeToPusherChannel } from '@hooks/pusherChannelHooks';
import api from '@services/api';
import { setCredentials } from '@services/auth/actions';
import { selectToken, selectUser } from '@services/auth/selectors';
import { selectActiveCompanyGeneralPusherChannel } from '@services/companies/selectors';

const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { update: intercomUpdate } = useIntercom();

  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const activeCompanyGeneralPusherChannel = useSelector(selectActiveCompanyGeneralPusherChannel);

  const [skipSelf, setSkipSelf] = useState(!token);
  const [showLoadingScreen, setShowLoadingScreen] = useState(!!token);
  const [hasTokenError, setHasTokenError] = useState(false);
  const { error } = api.useSelfQuery(null, {
    skip: skipSelf,
    refetchOnMountOrArgChange: true,
  });

  const statusCode = error?.data?.statusCode;

  useEffect(() => {
    if (statusCode === 401) {
      setHasTokenError(true);
    }
  }, [statusCode]);

  useEffect(() => {
    dispatch(setCredentials({ token }));
    if (token) {
      setSkipSelf(false);
    }
    if (user) {
      setShowLoadingScreen(false);
      intercomUpdate({
        name: user.name,
        email: user.email,
        user_id: user.uuid,
      });
    }
  }, [token, user]);

  useSubscribeToPusherChannel(activeCompanyGeneralPusherChannel);

  if (hasTokenError) {
    return <ErrorWithLogout />;
  }

  if (showLoadingScreen) {
    return <OverflowSpinner size="20" fit />;
  }

  if (user) {
    return children;
  }

  return <Navigate replace to="/welcome" />;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
