import { Dispatch, ReactNode, SetStateAction, createContext, useMemo } from 'react';

import { PaymentSessionSummary } from 'models/payment-session-summary';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import {
  FiltersBaseContextType,
  FiltersDataContextType,
  getFiltersBaseContextDefaultValues,
} from '@components/filters/context/FiltersContext';
import { useDataFiltering } from '@components/filters/hooks/useDataFiltering';
import { useLazyPostPaymentSessionSummariesQuery } from '@services/payments/endpoints';
import { useSiteMenu } from '@views/reporting/hooks/useSiteMenu';

type InvoicesFilterMenuOption = Extract<FilterMenuOption, 'site'>;

type InvoicesContextType = FiltersBaseContextType<InvoicesFilterMenuOption> &
  FiltersDataContextType<PaymentSessionSummary>;

export const InvoicesContext = createContext<InvoicesContextType>({
  ...getFiltersBaseContextDefaultValues<PaymentSessionSummary>(),
  filterOptionsMap: {
    site: [],
  },
});

export const InvoicesProvider = ({ children }: { children?: ReactNode }) => {
  const [fetchSummaries, { isFetching: dataLoading }] = useLazyPostPaymentSessionSummariesQuery();
  const { siteMenuOptions, setSiteMenuOptions } = useSiteMenu();

  const filterOptionsMap: Record<InvoicesFilterMenuOption, StyledMenuItemProps[]> = useMemo(() => {
    return {
      site: siteMenuOptions,
    };
  }, [siteMenuOptions]);

  const filterMap: Record<InvoicesFilterMenuOption, Dispatch<SetStateAction<StyledMenuItemProps[]>>> = {
    site: setSiteMenuOptions,
  };

  const uuidFilterMap: Record<InvoicesFilterMenuOption, keyof PaymentSessionSummary> = {
    site: 'siteUuid',
  };

  const dataFiltering = useDataFiltering<PaymentSessionSummary, InvoicesFilterMenuOption>(
    {
      filterOptionsMap,
      uuidFilterMap,
      filterMap,
      pageSize: 50,
      initialOrderBy: 'startTime',
    },
    fetchSummaries,
  );

  const value = useMemo(
    () => ({
      ...dataFiltering,
      dataLoading,
      filterOptionsMap,
    }),
    [filterOptionsMap, dataFiltering],
  );

  return <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>;
};
