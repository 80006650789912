import * as yup from 'yup';

export type UpdateVehicleQuery = {
  uuid: string;
  name: string | null;
  siteUuid: string | null;
  privateFlag: boolean;
};

export const useVehicleDetailsSchema = () =>
  yup
    .object()
    .shape({
      name: yup.string().nullable(),
      siteUuid: yup.string().nullable(),
      privateFlag: yup.boolean(),
    })
    .required();
