export enum StripePaymentMethodType {
  ACH_CREDIT_TRANSFER = 'ach_credit_transfer',
  ACH_DEBIT = 'ach_debit',
  ACSS_DEBIT = 'acss_debit',
  ALIPAY = 'alipay',
  AU_BECS_DEBIT = 'au_becs_debit',
  BANCONTACT = 'bancontact',
  CARD = 'card',
  CARD_PRESENT = 'card_present',
  EPS = 'eps',
  GIROPAY = 'giropay',
  IDEAL = 'ideal',
  KLARNA = 'klarna',
  MULTIBANCO = 'multibanco',
  P24 = 'p24',
  SEPA_CREDIT_TRANSFER = 'sepa_credit_transfer',
  SEPA_DEBIT = 'sepa_debit',
  SOFORT = 'sofort',
  THREE_D_SECURE = 'three_d_secure',
  WECHAT = 'wechat',
}
