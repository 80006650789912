import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';

import { ReportingTransaction, ReportingTransactionsSummary } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import {
  FiltersBaseContextType,
  FiltersDataContextType,
  getFiltersBaseContextDefaultValues,
} from '@components/filters/context/FiltersContext';
import { useDataFiltering } from '@components/filters/hooks/useDataFiltering';
import {
  useLazyPostReportingTransactionsQuery,
  useLazyPostReportingTransactionsSummaryQuery,
} from '@services/reporting/endpoints';

import { SessionsContextType } from '../SessionsTotalCards';
import { useChargerMenu } from '../hooks/useChargerMenu';
import { useRfidMenu } from '../hooks/useRfidMenu';
import { useSiteMenu } from '../hooks/useSiteMenu';
import { useTransactionTypeMenu } from '../hooks/useTransactionTypeMenu';
import { useUserMenu } from '../hooks/useUserMenu';
import { useVehicleMenu } from '../hooks/useVehicleMenu';
import { useWorkspaceMenu } from '../hooks/useWorkspaceMenu';

export type ReportingTransactionsFilterMenuOption = Extract<
  FilterMenuOption,
  'workspace' | 'site' | 'charger' | 'vehicle' | 'user' | 'rfid' | 'type'
>;

export type ReportingTransactionsContextType = FiltersBaseContextType<ReportingTransactionsFilterMenuOption> &
  FiltersDataContextType<ReportingTransaction> &
  SessionsContextType;

export const ReportingTransactionsContext = createContext<ReportingTransactionsContextType>({
  ...getFiltersBaseContextDefaultValues<ReportingTransaction>(),
  filterOptionsMap: {
    workspace: [],
    site: [],
    charger: [],
    vehicle: [],
    user: [],
    rfid: [],
    type: [],
  },
  summaryLoading: false,
});

export const ReportingTransactionsProvider = ({ children }: { children?: ReactNode }) => {
  const [summary, setSummary] = useState<ReportingTransactionsSummary>();
  const [fetchTransactions, { isFetching: dataLoading }] = useLazyPostReportingTransactionsQuery();
  const [fetchSummary, { isFetching: summaryLoading }] = useLazyPostReportingTransactionsSummaryQuery();

  const { workspaceMenuOptions, setWorkspaceMenuOptions } = useWorkspaceMenu();
  const { siteMenuOptions, setSiteMenuOptions } = useSiteMenu();
  const { chargerMenuOptions, setChargerMenuOptions } = useChargerMenu();
  const { vehicleMenuOptions, setVehicleMenuOptions } = useVehicleMenu();
  const { userMenuOptions, setUserMenuOptions } = useUserMenu();
  const { rfidMenuOptions, setRfidMenuOptions } = useRfidMenu();
  const { typeMenuOptions, setTypeMenuOptions } = useTransactionTypeMenu();

  const filterOptionsMap: Record<ReportingTransactionsFilterMenuOption, StyledMenuItemProps[]> = useMemo(() => {
    return {
      workspace: workspaceMenuOptions,
      site: siteMenuOptions,
      charger: chargerMenuOptions,
      vehicle: vehicleMenuOptions,
      user: userMenuOptions,
      rfid: rfidMenuOptions,
      type: typeMenuOptions,
    };
  }, [
    workspaceMenuOptions,
    siteMenuOptions,
    chargerMenuOptions,
    vehicleMenuOptions,
    userMenuOptions,
    rfidMenuOptions,
    typeMenuOptions,
  ]);

  const filterMap: Record<ReportingTransactionsFilterMenuOption, Dispatch<SetStateAction<StyledMenuItemProps[]>>> = {
    workspace: setWorkspaceMenuOptions,
    site: setSiteMenuOptions,
    charger: setChargerMenuOptions,
    vehicle: setVehicleMenuOptions,
    user: setUserMenuOptions,
    rfid: setRfidMenuOptions,
    type: setTypeMenuOptions,
  };

  const uuidFilterMap: Record<ReportingTransactionsFilterMenuOption, keyof ReportingTransaction> = {
    workspace: 'companyUuid',
    site: 'siteUuid',
    charger: 'deviceUuid',
    vehicle: 'carUuid',
    user: 'userUuid',
    rfid: 'rfidCardUuid',
    type: 'transactionType',
  };

  const dataFiltering = useDataFiltering<ReportingTransaction, ReportingTransactionsFilterMenuOption>(
    {
      filterOptionsMap,
      uuidFilterMap,
      filterMap,
      pageSize: 50,
      initialOrderBy: 'startTime',
    },
    fetchTransactions,
  );

  const { startDate, endDate, buildQuery, activeFiltersLength, filterInclusivityMap } = dataFiltering;

  const refetchSummary = async () => {
    try {
      const result = await fetchSummary(buildQuery({ omitPageSize: true })).unwrap();
      setSummary(result);
    } catch (error) {
      console.error('Error fetching transactions summary', error);
    }
  };

  useEffect(() => {
    refetchSummary();
  }, [startDate, endDate, activeFiltersLength, filterInclusivityMap]);

  const value = useMemo(
    () => ({
      ...dataFiltering,
      dataLoading,
      summary,
      summaryLoading,
      filterOptionsMap,
    }),
    [summary, summaryLoading, filterOptionsMap, dataFiltering],
  );

  return <ReportingTransactionsContext.Provider value={value}>{children}</ReportingTransactionsContext.Provider>;
};
