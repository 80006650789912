import { Context, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { StyledButton } from '@components/atoms/StyledButton';
import { StyledMenuItem } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import { FiltersBaseContextType, FiltersDataContextType } from '@components/filters/context/FiltersContext';
import { getApiUrl } from '@config/api';
import { PartnerWorkspaceContext } from '@core/WorkspaceGuard';
import { selectToken } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

type ExportReportButtonProps<ContextType, ExtractedType extends FilterMenuOption, ContextDataType> = {
  context: Context<ContextType & FiltersBaseContextType<ExtractedType> & FiltersDataContextType<ContextDataType>>;
  fullWidth?: boolean;
};

export const ExportReportButton = <ContextType, ExtractedType extends FilterMenuOption, ContextDataType>({
  context,
  fullWidth,
}: ExportReportButtonProps<ContextType, ExtractedType, ContextDataType>) => {
  const { t } = useTranslation();
  const { activePartnerCompanyUuid } = useContext(PartnerWorkspaceContext);
  const { startDate, endDate, buildQuery } = useContext(context);
  const token = useAppSelector(selectToken);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [loading, setLoading] = useState(false);

  const handleDownload = async (format: 'pdf' | 'csv') => {
    setLoading(true);
    try {
      const response = await fetch(`${getApiUrl()}/reporting/filtered/${format}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...(activePartnerCompanyUuid && { 'Partner-Company-Uuid': activePartnerCompanyUuid }),
        },
        body: JSON.stringify(
          buildQuery({
            omitPageSize: true,
          }),
        ),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Vool_Report_${startDate.format('DD/MM/YYYY')}${endDate && '_' + endDate.format('DD/MM/YYYY')}.${format}`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledButton
        color="success"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        disabled={loading}
        fullWidth={fullWidth}
      >
        {t('export', 'Export')}
      </StyledButton>
      <MenuContainer anchorEl={anchorEl} horizontalOrigin="center" onClose={() => setAnchorEl(undefined)}>
        <Box display="flex" flexDirection="column" justifyContent="center" px={0.5}>
          <StyledMenuItem
            width="88px"
            label="CSV"
            onClick={() => {
              handleDownload('csv');
              setAnchorEl(undefined);
            }}
          />
          <StyledMenuItem
            width="88px"
            label="PDF"
            onClick={() => {
              handleDownload('pdf');
              setAnchorEl(undefined);
            }}
          />
        </Box>
      </MenuContainer>
    </>
  );
};
