import { Typography } from '@mui/material';

import { useAppSelector } from '@services/hooks';
import { selectVehicleByUuid } from '@services/vehicles/selectors';

type ConnectorVehicleProps = {
  carUuid: string;
};

export const ConnectorVehicle = ({ carUuid }: ConnectorVehicleProps) => {
  const vehicle = useAppSelector((state) => selectVehicleByUuid(state, carUuid));

  if (!vehicle) {
    return null;
  }

  const batteryLevel = vehicle.data?.format?.batteryLevel;

  return (
    <Typography variant="p14" color="#282828">
      {vehicle.name ?? `${vehicle.brand} ${vehicle.model}`}
      {' • '}
      {batteryLevel ? (
        <span className={batteryLevel <= 25 ? 'text-vermillion' : 'text-black'}>{batteryLevel} %</span>
      ) : (
        '- %'
      )}
    </Typography>
  );
};
