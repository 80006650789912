import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledTextField } from 'components/atoms/StyledTextField';
import { countries } from 'config/countries';

import { Autocomplete, Box, TextFieldProps } from '@mui/material';

import '/node_modules/flag-icons/css/flag-icons.min.css';

type CountryAutocompleteProps<T extends FieldValues> = TextFieldProps & {
  control: Control<T>;
  name: Path<T>;
  isLoading?: boolean;
};

export const CountryAutocomplete = <T extends FieldValues>({
  control,
  name,
  isLoading,
  ...props
}: CountryAutocompleteProps<T>) => {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          disabled={props.disabled}
          onChange={(_event, country) => field.onChange(country?.label)}
          options={countries}
          getOptionLabel={(option) => option.label}
          value={countries.find((c) => field.value === c.label) ?? null}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > span': { mr: 2, flexShrink: 0 } }} {...props}>
              <span className={`fi fi-${option.code.toLowerCase()}`}></span>
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              inputRef={field.ref}
              isLoading={isLoading}
              label={`${t('country', 'Country')} *`}
              error={Boolean(error)}
              helperText={error?.message}
              InputLabelProps={{
                shrink: true,
                sx: {
                  transform: 'translate(14px, -16px)',
                },
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
                style: { padding: '0 4px' },
              }}
            />
          )}
        />
      )}
    />
  );
};
