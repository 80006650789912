import { isSubdomainPartner } from '@routesConfig/routesUtil';

export const SUPPORTED_FILTER_TYPES = Object.freeze(
  ['workspace', 'user', 'site', 'device', 'vehicle'].filter(
    (filterType) => !isSubdomainPartner() || filterType !== 'user',
  ),
);

export const extractFilterTypeAndQuery = (searchQuery) => {
  const prefixTag = searchQuery.split(':', 1)[0].toLowerCase();
  if (SUPPORTED_FILTER_TYPES.includes(prefixTag)) {
    return [prefixTag, searchQuery.substring(prefixTag.length + 1).trim()];
  }
  return [null, searchQuery.trim()];
};

export const setOrToggleQueryFilterType = (fullSearchQuery, filteredType) => {
  const [currentType, searchQuery] = extractFilterTypeAndQuery(fullSearchQuery);
  if (currentType === filteredType) {
    return searchQuery;
  }
  return `${filteredType}: ${searchQuery.trim()}`;
};
