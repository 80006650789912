import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Spinner } from '@components/atoms/Spinner';
import { ReactComponent as ArrowLeftIcon } from '@images/icons/arrow_left.svg';

const DeviceTypeOption = ({ label, description, Icon, onClick, isLoading, beta }) => {
  const { t } = useTranslation();
  return (
    <div
      className="flex w-full cursor-pointer items-center justify-center gap-x-4 overflow-hidden rounded-2xl bg-gray-100 px-4 py-3 font-poppins hover:bg-gray-200"
      onClick={onClick}
      aria-hidden
    >
      <div className="flex h-14 w-14 shrink-0 items-center justify-center">
        {isLoading ? (
          <Spinner size="14" />
        ) : (
          <div className="flex h-full w-full items-center justify-center rounded-full border-3 border-vermillion">
            <Icon />
          </div>
        )}
      </div>

      <div className="flex-grow">
        <div className="flex items-center gap-x-2">
          <span className="font-semibold">{label}</span>
          {beta && (
            <span className="rounded border border-vermillion-200 bg-vermillion-200 px-2 py-0.5 text-left text-xs uppercase text-vermillion">
              {t('beta', 'Beta')}
            </span>
          )}
        </div>
        <div className="text-sm text-gray-600">{description}</div>
      </div>

      <ArrowLeftIcon className="shrink-0 rotate-180" />
    </div>
  );
};

DeviceTypeOption.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  beta: PropTypes.bool,
};

DeviceTypeOption.defaultProps = {
  beta: false,
};

export default DeviceTypeOption;
