import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CompanyWithUser } from 'models/company';
import { PartnerCompanyCounts } from 'models/partner-company-counts';

import { Box, Skeleton, TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { ReactComponent as MapIcon } from '@images/icons/nav/Map.svg';
import { useGetPartnerCompaniesCountsQuery, useGetPartnerCompaniesQuery } from '@services/partners/endpoints';

import { PartnersWorkspacesTableRowSkeleton, PartnersWorkspacesTableSkeleton } from './PartnersWorkspacesTableSkeleton';

const useColumns = () => {
  const { t } = useTranslation();

  const columns: PartnerColumnData[] = [
    {
      width: 380,
      label: t('workspace', 'Workspace'),
      dataKey: 'name',
      sortable: true,
    },
    {
      width: 200,
      label: t('sites', 'Sites'),
      dataKey: 'siteCount',
      sortable: true,
      numeric: true,
    },
    {
      width: 200,
      label: t('devices', 'Devices'),
      dataKey: 'deviceCount',
      sortable: true,
      numeric: true,
    },
    {
      width: 200,
      label: t('members', 'Members'),
      dataKey: 'memberCount',
      sortable: true,
      numeric: true,
    },
    {
      width: 200,
      label: t('vehicles', 'Vehicles'),
      dataKey: 'carCount',
      sortable: true,
      numeric: true,
    },
    {
      width: 200,
      label: t('rfidCards', 'RFID Cards'),
      dataKey: 'rfidCardCount',
      sortable: true,
      numeric: true,
    },
  ];

  return columns;
};

type PartnerCompanyWithCounts = CompanyWithUser & Partial<PartnerCompanyCounts>;
type PartnerColumnData = ColumnData<PartnerCompanyWithCounts>;
type RenderRowsProps = {
  rows: PartnerCompanyWithCounts[];
  columns: PartnerColumnData[];
  selectedCompany?: CompanyWithUser;
  isCountsFetching?: boolean;
};
type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: PartnerCompanyWithCounts;
};

const TableRow = ({ row, columns, selectedCompany, isCountsFetching }: TableRowProps) => {
  const navigate = useNavigate();
  return (
    <StyledTableRow
      key={row.uuid}
      sx={{
        bgcolor: selectedCompany?.uuid === row.uuid ? '#F4F4F4' : 'background.paper',
      }}
    >
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'name':
            return (
              <TableCell
                key={column.dataKey}
                align="left"
                width={column.width}
                onClick={() => navigate(row.uuid)}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Box display="flex" bgcolor="#D7E1DD" p={1.5} borderRadius={3}>
                    <MapIcon width={24} height={24} color="#386957" />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="p16b">{row.name}</Typography>
                    <Typography variant="p14" color="#5E5E5E">
                      {row.userEmail}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            );
          default:
            return (
              <TableCell
                key={column.dataKey}
                align={column.numeric ? 'right' : 'left'}
                width={column.width}
                {...(column.dataKey !== 'carCount' &&
                  !isCountsFetching && {
                    onClick: () => navigate(`${row.uuid}/${column.dataKey.split('Count')[0]}s`),
                    sx: {
                      cursor: 'pointer',
                    },
                  })}
              >
                {isCountsFetching ? (
                  <Box display="flex" justifyContent="right">
                    <Skeleton variant="text" animation="wave" width="30%" />
                  </Box>
                ) : (
                  column.dataKey !== 'menu' && (
                    <Typography variant="p14">{String(row[column.dataKey] ?? '-')}</Typography>
                  )
                )}
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};

const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.uuid} row={row} {...rest} />);

export const PartnersWorkspacesMaterialTable = () => {
  const { data: partnerCompanies, isFetching: isPartnerCompaniesFetching } = useGetPartnerCompaniesQuery();
  const { data: partnerCompaniesCounts, isFetching: isCountsFetching } = useGetPartnerCompaniesCountsQuery();
  const columns = useColumns();

  const partnerCompaniesWithCounts: PartnerCompanyWithCounts[] = (partnerCompanies ?? []).map((company) => ({
    ...company,
    ...partnerCompaniesCounts?.[company.uuid],
  }));

  if (isPartnerCompaniesFetching) {
    return <PartnersWorkspacesTableSkeleton />;
  }

  return (
    <MaterialTable
      sortingStorageKey="partnerCompaniesTable"
      data={partnerCompaniesWithCounts}
      isLoading={isPartnerCompaniesFetching}
      headerProps={{
        columns,
        orderBy: 'name',
      }}
      renderRows={(rows, columns) => renderRows({ rows, columns, isCountsFetching })}
      loaders={{ TableLoader: PartnersWorkspacesTableSkeleton, RowLoader: PartnersWorkspacesTableRowSkeleton }}
    />
  );
};
