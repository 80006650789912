import { useTranslation } from 'react-i18next';

import { CompanyRole } from 'models/role.enum';

import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectUser } from '@services/auth/selectors';
import { useGetGroupsQuery } from '@services/groups/endpoints';
import { useAppSelector } from '@services/hooks';

export type WorkspaceRoleDefDetails = { label: string; choosable: boolean };
export type WorkspaceRolesDef = Record<CompanyRole, WorkspaceRoleDefDetails>;

export const useWorkspaceRolesDef = (): WorkspaceRolesDef => {
  const { t } = useTranslation();
  return {
    owner: {
      label: t('owner', 'Owner'),
      choosable: false,
    },
    admin: {
      label: t('admin', 'Admin'),
      choosable: true,
    },
    member: {
      label: t('member', 'Member'),
      choosable: true,
    },
    installer: {
      label: t('installer', 'Installer'),
      choosable: false,
    },
    public_member: {
      label: t('publicMember', 'Public member'),
      choosable: true,
    },
  };
};

type UseWorkspaceGroupsAndRolesProps = {
  refetchOnMountOrArgChange?: boolean;
  withUsers?: boolean;
  companyUuid?: string;
};

export const useWorkspaceGroupsAndRoles = ({
  refetchOnMountOrArgChange,
  withUsers,
  companyUuid,
}: UseWorkspaceGroupsAndRolesProps = {}) => {
  const user = useAppSelector(selectUser);

  const { data: groups, isLoading: groupsLoading } = useGetGroupsQuery(
    { withUsers, companyUuid },
    {
      refetchOnMountOrArgChange,
      skip: isSubdomainPartner() && !companyUuid,
    },
  );

  const isWorkspaceAdmin = user?.role !== CompanyRole.MEMBER || !!user.admin || isSubdomainPartner();
  const isGroupManager = !!groups?.length;

  return {
    isWorkspaceAdmin,
    isGroupManager,
    groups,
    groupsLoading,
  };
};
