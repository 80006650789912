import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@hooks/useTypedForm';
import { useGetCompanyDetailsQuery, useSaveCompanyDetailsMutation } from '@services/companies/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';

import { CompanyDetailsForm, companyDetailsDefaultValues, useCompanyDetailsSchema } from './useCompanyDetailsSchema';

export const useCompanyDetailsForm = () => {
  const { t } = useTranslation();
  const schema = useCompanyDetailsSchema();
  const form = useForm<CompanyDetailsForm>({
    schema,
    defaultValues: companyDetailsDefaultValues,
  });
  const companyDetailsLabel = t('companyDetails', 'Company details');

  const { handleSubmitAndResolve, reset } = form;
  const dispatch = useAppDispatch();
  const { data: companyDetails, isLoading: isCompanyDetailsRetrieveLoading } = useGetCompanyDetailsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [saveCompanyDetails, { isLoading: isCompanyDetailsSaveLoading }] = useSaveCompanyDetailsMutation();

  useEffect(() => {
    if (companyDetails) {
      reset({ ...companyDetails, vatNumber: companyDetails.vatNumber ?? '' });
    }
  }, [companyDetails]);

  const submit = async (data: CompanyDetailsForm) => {
    try {
      await saveCompanyDetails(data).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemSaved', '{{item}} saved', { item: companyDetailsLabel }),
        }),
      );
      return true;
    } catch (e) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('addingItemFailed', 'Adding {{item}} failed', {
            item: companyDetailsLabel.toLowerCase(),
          }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  const submitCompanyDetails = handleSubmitAndResolve(submit);

  return {
    form,
    isLoading: isCompanyDetailsRetrieveLoading,
    isSaving: isCompanyDetailsSaveLoading,
    submitCompanyDetails,
  };
};
