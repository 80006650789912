import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import { DeviceType } from 'models/device.enums';

import { Tooltip } from '@mui/material';

type CurrVoltRadialChartProps = {
  deviceType?: DeviceType;
  max: number;
  current?: number;
  voltage?: number;
  color: string;
  offline: boolean;
  backgroundColor: string;
  dark?: boolean;
  variant?: 'default' | 'small';
  label?: string;
};

export const CurrVoltRadialChart = ({
  deviceType = DeviceType.LMC,
  max = 0,
  current = 0,
  color = 'green',
  voltage = 0,
  offline = true,
  backgroundColor = '',
  dark = false,
  variant = 'default',
  label = '',
}: CurrVoltRadialChartProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(current);
  const [maxValue, setMaxValue] = useState(max);
  const isSmall = variant === 'small';
  const isCharger = deviceType === DeviceType.CHARGER;
  const options = {
    series: [(value / maxValue || 0) * 100],
    options: {
      chart: {
        type: 'radialBar' as const,
        sparkline: {
          enabled: true,
        },
        offsetY: -20,
      },
      plotOptions: {
        radialBar: {
          startAngle: -130,
          endAngle: 130,
          track: {
            background: 'e7e7e7',
            strokeWidth: '100%',
            margin: 5, // margin is in pixels
          },
          hollow: {
            size: isSmall ? '45' : '80',
          },
          dataLabels: {
            name: {
              offsetY: 15,
              show: true,
              color: '#282828',
              fontSize: '14px',
              fontFamily: 'Poppins',
              fontWeight: 400,
            },
            value: {
              show: false,
              offsetY: -30,
              color: '#282828',
              formatter: (val: number) => (offline ? '-' : ((val / 100) * maxValue).toFixed(2)),
              fontSize: '24px',
              fontWeight: 400,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        colors: [color],
        // gradientToColors: ['blue'],
        gradient: {
          gradientToColors: [color],
          shade: 'light',
          shadeIntensity: 0.1,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      labels: [''],
    },
  };

  useEffect(() => {
    setValue(current);
    setMaxValue(max);
  }, [current, max]);

  return (
    <div
      className={`relative flex items-center rounded-2xl font-poppins text-sm text-black ${
        isSmall ? 'h-16 justify-start gap-x-11 px-4' : 'h-full w-full justify-center '
      } ${backgroundColor} `}
    >
      <div className={`text-base font-normal ${backgroundColor} ${!isSmall ? 'absolute right-2 top-2' : ' hidden'}`}>
        {label}
      </div>
      <div
        className={`absolute bottom-8 left-1/4 md:left-3 xl:left-[20%] ${dark ? 'text-white' : 'text-gray-600'} ${
          isSmall ? 'hidden' : ''
        }`}
      >
        0
      </div>
      <ReactApexChart
        className="mt-7"
        options={options.options}
        series={options.series}
        type="radialBar"
        height="100%"
        width={isSmall ? '45px' : '100%'}
      />
      {isSmall && (
        <Tooltip
          placement="top"
          title={isCharger ? t('currentLimit', 'Current limit') : t('gridConnection', 'Grid connection')}
          className={`absolute left-8 top-6 text-sm ${dark ? 'text-white' : 'text-gray-600'}`}
        >
          <div>{maxValue}</div>
        </Tooltip>
      )}
      <div
        className={`absolute bottom-8 right-1/4 md:right-2 xl:right-[15%] ${dark ? 'text-white' : 'text-gray-600'} ${
          isSmall ? 'hidden' : ''
        }`}
      >
        {maxValue}A
      </div>
      <div
        className={`flex items-center ${
          isSmall
            ? 'w-full justify-between text-base font-semibold'
            : 'absolute top-[38%] flex-col justify-center text-2xl'
        } ${dark ? 'text-white' : 'text-gray-600'}`}
      >
        <div>
          {offline ? '- ' : Number(value).toFixed(2)}
          <span className={`font-normal ${isSmall ? 'text-xs' : 'text-sm'}`}>A</span>
        </div>
        <div>
          {offline ? '- ' : Number(voltage).toFixed(1)}
          <span className={`font-normal ${isSmall ? 'text-xs' : 'text-sm'}`}>V</span>
        </div>
        <div className={`text-base font-normal ${backgroundColor} ${!isSmall ? 'hidden' : ''}`}>{label}</div>
      </div>
    </div>
  );
};
