import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Box, CheckboxProps, Typography } from '@mui/material';

import { StyledCheckbox } from './StyledCheckbox';

type FormCheckboxProps<T extends FieldValues> = CheckboxProps & {
  control: Control<T>;
  name: Path<T>;
  isLoading?: boolean;
};

export const FormCheckbox = <T extends FieldValues>({
  control,
  name,
  disabled,
  isLoading,
  ...props
}: FormCheckboxProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <Box
          ref={ref}
          display="flex"
          gap={1}
          onClick={() => !disabled && field.onChange(!field.value)}
          sx={{
            cursor: disabled ? 'default' : 'pointer',
          }}
        >
          <StyledCheckbox {...field} checked={!!field.value} isLoading={isLoading} disabled={disabled} {...props} />
          {!isLoading && (
            <Typography
              variant="p14"
              sx={{
                ...(disabled && { color: '#C1C1C1' }),
              }}
            >
              {props.title}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};
