import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

// https://redux-toolkit.js.org/rtk-query/usage-with-typescript#inline-error-handling-example
/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

type FetchBaseQueryErrorWithMessage = FetchBaseQueryError & {
  data: { message: string };
};

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError` with a `data` object with a string `message` property
 */
export function isFetchBaseQueryErrorWithMessage(error: unknown): error is FetchBaseQueryErrorWithMessage {
  return (
    isFetchBaseQueryError(error) && typeof error.data === 'object' && error.data !== null && 'message' in error.data
  );
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error != null && 'message' in error && typeof (error as any).message === 'string';
}

export const getErrorMessage = (error: unknown) => {
  if (isFetchBaseQueryErrorWithMessage(error)) {
    return error.data.message;
  } else if (isErrorWithMessage(error)) {
    return error.message;
  }

  return 'Something went wrong. Please try again later.';
};
