import { Navigate, Route, Routes } from 'react-router-dom';

import { isSubdomainPartner } from '@routesConfig/routesUtil';

import { LoggedOutBasicLayout } from './layouts/LoggedOutBasicLayout';
import { LoggedinBasicLayout } from './layouts/LoggedinBasicLayout';
import { Route as CustomRoute, partnerRoutes, privateRoutes, publicRoutes } from './routesConfig/routesConfig';

const renderRoute = (route: CustomRoute) => (
  <Route key={route.path} path={route.path} element={<route.component />}>
    {route.children?.map(renderRoute)}
  </Route>
);

const renderRoutes = (publicR: CustomRoute[], privateR: CustomRoute[]) => (
  <Routes>
    <Route element={<LoggedOutBasicLayout />}>{publicR.map(renderRoute)}</Route>
    <Route element={<LoggedinBasicLayout />}>{privateR.map(renderRoute)}</Route>
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export const Router = () => {
  if (isSubdomainPartner()) {
    return renderRoutes(publicRoutes('/workspaces'), partnerRoutes);
  }
  return renderRoutes(publicRoutes(), privateRoutes);
};
