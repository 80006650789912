import PropTypes from 'prop-types';

const colors = {
  default: 'bg-vermillion',
  primary: 'bg-forest',
};

const ButtonSwitch = ({ options, value, onChange, disabled, color }) => {
  const backgroundColor = colors[color] || colors.default;
  return (
    <div className="mb-2 mt-1 flex items-center justify-items-stretch rounded-full bg-gray-100">
      {options.map((option) => (
        <button
          key={option.value}
          className={`flex-1 rounded-full px-4 py-2 text-center font-poppins text-sm ${
            option.value === value ? `${backgroundColor} text-white` : ''
          }`}
          type="button"
          disabled={disabled}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

ButtonSwitch.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

ButtonSwitch.defaultProps = {
  value: null,
  disabled: false,
  color: null,
};

export default ButtonSwitch;
