import { useState } from 'react';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { useComputedStatusDefs } from '@views/Chargers/useComputedStatusDefs';

export const useDeviceStatusMenu = () => {
  const computedStatusDefs = useComputedStatusDefs();
  const options: StyledMenuItemProps[] = Object.entries(computedStatusDefs)
    .filter(([, computedStatusDef]) => !computedStatusDef.hiddenInFilters)
    .map(([computedStatus, computedStatusDef]) => ({
      uuid: computedStatus,
      checked: false,
      label: computedStatusDef.statusLabel,
      Icon: () => (
        <img
          src={computedStatusDef.icon}
          alt={computedStatusDef.statusLabel}
          style={{
            borderRadius: '100%',
          }}
          className={computedStatusDef.iconClassName}
        />
      ),
      onClick: () => toggleOptionChecked(computedStatus),
    }));

  const [deviceStatusMenuOptions, setDeviceStatusMenuOptions] = useState<StyledMenuItemProps[]>(options);

  const toggleOptionChecked = (selectedOption: string) => {
    setDeviceStatusMenuOptions((prevOptions) =>
      prevOptions.map((option) => {
        if (option.uuid === selectedOption) {
          return { ...option, checked: !option.checked };
        }
        return option;
      }),
    );
  };

  return { deviceStatusMenuOptions, setDeviceStatusMenuOptions };
};
