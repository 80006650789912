import { TransactionStatus } from './transaction.enums';

export interface ReportingTransaction {
  id: number;
  transactionType: TransactionType;
  status?: TransactionStatus;
  transactionId: string;
  startTime: Date;
  endTime?: Date;
  siteUuid?: string;
  siteName?: string;
  deviceUuid?: string;
  deviceName?: string;
  deviceSerialNumber?: string;
  userUuid?: string;
  userName?: string;
  userEmail?: string;
  carUuid?: string;
  carName?: string;
  carBrand?: string;
  carModel?: string;
  rfidCardUuid?: string;
  rfidNormalizedIdentifier?: string;
  rfidName?: string;
  duration: number;
  amount: number;
  monetaryRevenue: number;
  monetaryRevenueWithVAT: number;
  monetaryProfit: number;
  monetaryCost: number;
  monetaryCostWithVAT: number;
  currency: string;
  invoiceId?: string;
  companyUuid?: string;
}

export enum TransactionType {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SMART_CHARGING = 'smartCharging',
}

export interface ReportingTransactionsSummary {
  totalConsumption: number;
  totalCost: number;
  totalSessions: number;
}
