import { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { UseFormSetError } from 'react-hook-form';

import { SiteAddressJson } from 'models/site';
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from 'use-places-autocomplete';

import { Box } from '@mui/material';

import { StyledTextField } from '@components/atoms/StyledTextField';

import { getAddressComponent, getAddressDetails } from '../../helpers/placesHelper';
import { InputProps } from '../atoms/Input';
import Map from './Map';

type FormFieldKey = 'address' | 'addressJson';

type AddressValueMap = {
  address: string;
  addressJson: SiteAddressJson;
};

type GoogleMapsAddressInputProps = Partial<InputProps> & {
  setFormValue?: <K extends FormFieldKey>(key: K, value: AddressValueMap[K]) => void;
  isError?: boolean;
  helpText?: string;
  clearError?: (key: Extract<FormFieldKey, 'address'>) => void;
  setError?: UseFormSetError<Pick<AddressValueMap, 'address'>>;
  address?: string;
  addressJson?: SiteAddressJson;
  required?: boolean;
};

export const GoogleMapsAddressInput = ({
  setFormValue,
  isError,
  helpText,
  clearError,
  setError,
  address,
  addressJson,
  required,
  ...props
}: GoogleMapsAddressInputProps) => {
  const [oldValue, setOldValue] = useState(address);
  const [localAddressObject, setLocalAddressObject] = useState(addressJson);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setLocalAddressObject(undefined);
    setOldValue(undefined);
    setValue(e.target.value);

    if (!newValue && required) {
      setError?.('address', {
        type: 'manual',
        message: 'Address is required',
      });
    } else {
      clearError?.('address');
    }
  };

  const handleSelect =
    (
      addressObject: SiteAddressJson & {
        description: string;
        place_id: string;
      },
    ) =>
    async () => {
      setFormValue?.('address', addressObject.description);

      setValue(addressObject.description, false);
      clearSuggestions();

      try {
        const geoCode = await getGeocode({ placeId: addressObject.place_id });
        const { lat, lng } = await getLatLng(geoCode[0]);

        const addressDetailsArray = await getDetails({
          placeId: addressObject.place_id,
          fields: ['address_components'],
        });

        const addressDetails = getAddressDetails(addressDetailsArray);
        const addressCountryCode = getAddressComponent(addressDetailsArray, 'country', { useShortName: true });

        const combinedAddressObject = {
          ...addressDetails,
          lat,
          lng,
          country_code: addressCountryCode,
        } as SiteAddressJson;
        setLocalAddressObject(combinedAddressObject);
        setFormValue?.('addressJson', combinedAddressObject);
      } catch (error) {
        setError?.('address', {
          type: 'manual',
          message: String(error),
        });
      }
    };

  useEffect(() => {
    setLocalAddressObject(addressJson);
  }, [addressJson]);

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <li key={placeId} className="overflow-hidden">
          <button
            type="button"
            onClick={handleSelect(suggestion)}
            onKeyDown={() => {}}
            className="w-full truncate whitespace-nowrap px-2 py-1 text-left text-sm"
          >
            <span>{mainText}</span> <span>{secondaryText}</span>
          </button>
        </li>
      );
    });

  useEffect(() => {
    if (address) {
      setOldValue(address);
    }
  }, [address]);

  return (
    <Box position="relative" display="flex" flexDirection="column" gap={1} ref={ref}>
      <StyledTextField
        name="address"
        value={oldValue || value}
        disabled={!ready}
        inputProps={{
          autoComplete: 'off',
        }}
        error={isError}
        helperText={helpText}
        {...props}
        onChange={handleInput}
      />
      <Box>
        {status === 'OK' && (
          <ul className="mh-48 absolute z-50 mt-1 w-full overflow-hidden overflow-y-scroll rounded-md border border-gray-300 bg-white shadow-md dark:bg-truegray-700">
            {renderSuggestions()}
          </ul>
        )}
      </Box>
      <Map
        location={localAddressObject}
        onChange={(pos) => setFormValue?.('addressJson', { ...localAddressObject, ...pos })}
      />
    </Box>
  );
};
