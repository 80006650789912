import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@services/hooks';
import { ToastMessage, deleteToastMessage } from '@services/toastMessages';
import { selectToastMessages } from '@services/toastMessages/selectors';

type ToastProps = {
  toastMessage: ToastMessage;
};

const Toast = ({ toastMessage }: ToastProps) => {
  const dispatch = useAppDispatch();
  const [animationClass, setAnimationClass] = useState('animate-enterAndFadeInFromTop');

  useEffect(() => {
    let leaveTimeout: ReturnType<typeof setTimeout>;
    let deleteTimeout: ReturnType<typeof setTimeout>;
    if (!toastMessage.persistent) {
      leaveTimeout = setTimeout(() => {
        setAnimationClass('animate-leaveAndFadeOutToTop');
        deleteTimeout = setTimeout(() => {
          dispatch(deleteToastMessage(toastMessage));
        }, 400);
      }, 4000);
    }
    return () => {
      clearTimeout(leaveTimeout);
      clearTimeout(deleteTimeout);
    };
  }, []);

  return (
    <div
      className={`rounded-2xl p-4 font-poppins text-sm font-normal text-white ${animationClass} ${
        toastMessage.type === 'error' ? 'bg-vermillion' : 'bg-forest'
      }`}
    >
      <div className="text-base font-semibold">{toastMessage.title}</div>
      <p>{toastMessage.message}</p>
    </div>
  );
};

export const ToastsOverlay = () => {
  const toastMessages = useAppSelector(selectToastMessages);

  return (
    <div className="fixed left-0 right-0 top-10 z-50 mx-auto max-w-[352px] space-y-5">
      {toastMessages.map((toastMessage) => (
        <Toast key={toastMessage.id} toastMessage={toastMessage} />
      ))}
    </div>
  );
};
