import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { countBy } from 'lodash';
import { DeviceStatus, OcppStatus } from 'models/device.enums';
import PropTypes from 'prop-types';

import alertIcon from '@images/icons/Alert.svg';
import { makeSelectChargersBySiteUuid } from '@services/devices/selectors';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';

export const SiteOccupancyContent = ({ site }) => {
  const { t } = useTranslation();
  const selectChargersBySiteUuid = useMemo(makeSelectChargersBySiteUuid, []);
  const siteChargers = useSelector((state) => selectChargersBySiteUuid(state, site.uuid));
  const connectorsOfSiteChargers = useMemo(
    () => siteChargers.flatMap((charger) => getUnifiedConnectors(charger)),
    [siteChargers],
  );

  const statusCount = {
    offline: 0,
    occupied: 0,
    other: 0,
    ...countBy(connectorsOfSiteChargers, (connector) => {
      if (connector.status !== DeviceStatus.ONLINE) {
        return 'offline';
      }
      if (connector.ocppStatus === OcppStatus.OCCUPIED) {
        return 'occupied';
      }
      return 'other';
    }),
  };
  const totalCount = connectorsOfSiteChargers.length;

  if (totalCount === 0) {
    return <div className="text-sm leading-6">{t('noChargers', 'No chargers')}</div>;
  }

  return (
    <>
      {/* TODO: improve spacing according to design */}
      <div className="my-2 h-1 rounded bg-gray-100">
        <div
          className={`h-1 rounded ${statusCount.occupied === totalCount ? 'bg-vermillion' : 'bg-forest'}`}
          style={{ width: `${(statusCount.occupied / totalCount) * 100}%` }}
        />
      </div>
      <div className="text-sm leading-6">
        {statusCount.occupied}/{totalCount}
        {statusCount.offline > 0 && (
          <>
            {' '}
            · <img src={alertIcon} alt="Alert" className="inline-block" /> {statusCount.offline}{' '}
            {t('offline', 'Offline').toLowerCase()}
          </>
        )}
      </div>
    </>
  );
};

SiteOccupancyContent.propTypes = {
  site: PropTypes.object.isRequired,
};

export const SiteContractContent = ({ site }) => <div>{site.contract}</div>;

SiteContractContent.propTypes = {
  site: PropTypes.object.isRequired,
};
