import { Inverter, InverterSummary, InverterSummaryQuery } from 'models/inverter';

import apiSlice from '@services/api';

export const invertersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInverterAccountLoginUrl: builder.mutation<string, { siteUuid: string }>({
      query: ({ siteUuid }) => ({
        url: '/inverterAccounts/loginUrl',
        method: 'POST',
        body: {
          siteUuid,
        },
      }),
      transformResponse: (response: { url: string }) => response?.url,
    }),
    deleteInverter: builder.mutation<{ inverterUuid: string }, string>({
      query: (uuid) => ({
        url: `/inverters/${uuid}`,
        method: 'DELETE',
      }),
    }),
    getInverter: builder.query<Inverter, string>({
      query: (uuid) => ({
        url: `/inverters/${uuid}`,
        method: 'GET',
      }),
      transformResponse: (response: { inverter: Inverter }) => response.inverter,
    }),
    getInverters: builder.query<Inverter[], { companyUuid?: string }>({
      query: ({ companyUuid }) => ({
        url: '/inverters',
        method: 'GET',
        params: { companyUuid },
      }),
      transformResponse: (response: { data: Inverter[] }) => response.data,
    }),
    getAllInverters: builder.query<Inverter[], void>({
      query: () => '/inverters/all',
      transformResponse: (response: { data: Inverter[] }) => response.data,
    }),
    getInverterSummary: builder.query<InverterSummary, InverterSummaryQuery>({
      query: ({ uuid, duration }) => ({
        url: `/inverters/${uuid}/summary${duration ? `?duration=${duration}` : ''}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: InverterSummary }) => response.data,
    }),
    updateInverter: builder.mutation<Inverter, { uuid: string; name: string }>({
      query: ({ uuid, name }) => ({
        url: `/inverters/${uuid}`,
        method: 'PUT',
        body: {
          name,
        },
      }),
      transformResponse: (response: { inverter: Inverter }) => response.inverter,
    }),
  }),
});

export const {
  useGetInverterAccountLoginUrlMutation,
  useDeleteInverterMutation,
  useGetInverterQuery,
  useGetInvertersQuery,
  useGetAllInvertersQuery,
  useGetInverterSummaryQuery,
  useUpdateInverterMutation,
} = invertersApi;
