import PropTypes from 'prop-types';

import { Tooltip, Typography } from '@mui/material';

import sortDownIcon from '@images/icons/Sort_down.svg';

export const parseSortingParam = (sortingParam) => ({
  sortingColumn: sortingParam.replace('-', ''),
  isSortingDescending: sortingParam.startsWith('-'),
});

const TableHeaderWithSorting = ({ columns, sortingParam, onSortingParamChange }) => {
  const { sortingColumn, isSortingDescending } = parseSortingParam(sortingParam);

  const getSortableColumnHeader = (column) => {
    const isSortingByThisColumn = sortingColumn === column.key;
    const handleSortByColumn = () => {
      if (column.reverseSorting) {
        onSortingParamChange(`${!isSortingByThisColumn || !isSortingDescending ? '-' : ''}${column.key}`);
      } else {
        onSortingParamChange(`${!isSortingByThisColumn || isSortingDescending ? '' : '-'}${column.key}`);
      }
    };
    return (
      <th key={column.key}>
        <Tooltip title={column.tooltip && <Typography variant="p12b">{column.tooltip}</Typography>}>
          <div
            className={`flex h-full items-end px-4 py-3 font-normal ${column.className ?? ''}`}
            {...(!column.sortingDisabled && {
              role: 'button',
              tabIndex: 0,
              onClick: handleSortByColumn,
              onKeyDown: (e) => e.key === 'Enter' && handleSortByColumn(),
            })}
          >
            <div className="flex h-full flex-col justify-end">
              {column.extraData && <span className={column.extraDataClassName ?? ''}>{column.extraData}</span>}
              <span className="flex items-center">{column.label}</span>
            </div>
            <img
              src={sortDownIcon}
              alt={isSortingDescending ? 'Descending' : 'Ascending'}
              className={`ml-1 inline-block h-4 w-3
            ${isSortingByThisColumn ? '' : 'invisible'} 
            ${isSortingDescending ? '' : 'rotate-180'}`}
            />
          </div>
        </Tooltip>
      </th>
    );
  };

  return (
    <thead>
      <tr className="select-none border-b border-gray-100 text-left text-sm text-gray-600">
        {columns.map(getSortableColumnHeader)}
      </tr>
    </thead>
  );
};

TableHeaderWithSorting.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      sortingDisabled: PropTypes.bool,
      extraData: PropTypes.string,
      extraDataClassName: PropTypes.string,
      reverseSorting: PropTypes.bool,
    }),
  ).isRequired,
  sortingParam: PropTypes.string.isRequired,
  onSortingParamChange: PropTypes.func.isRequired,
};

export default TableHeaderWithSorting;
