import { Checkbox, CheckboxProps, Skeleton, styled } from '@mui/material';

import { ReactComponent as CheckboxCheckedIcon } from '@images/icons/atoms/checkbox-checked.svg';
import { ReactComponent as CheckboxIcon } from '@images/icons/atoms/checkbox.svg';

const BaseStyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.lightGray,
  '&.MuiCheckbox-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: theme.palette.pureWhite,
      svg: {
        rect: {
          stroke: theme.palette.spanishGray,
        },
      },
    },
    '&.Mui-checked': {
      svg: {
        rect: {
          stroke: theme.palette.forestGreenCrayola,
        },
      },
      '&:hover': {
        svg: {
          rect: {
            stroke: '#1F4C3C',
            fill: '#1F4C3C',
          },
        },
      },
    },
    '&.Mui-disabled': {
      svg: {
        rect: {
          fill: theme.palette.cultured,
        },
      },
      '&.Mui-checked': {
        svg: {
          rect: {
            stroke: theme.palette.forestGreenCrayola,
            fill: theme.palette.forestGreenCrayola,
          },
        },
      },
    },
  },
}));

type StyledCheckboxProps = CheckboxProps & {
  isLoading?: boolean;
};

export const StyledCheckbox = ({ isLoading, ...props }: StyledCheckboxProps) => {
  return isLoading ? (
    <Skeleton variant="rounded" animation="wave" height={24} width={24} />
  ) : (
    <BaseStyledCheckbox icon={<CheckboxIcon />} checkedIcon={<CheckboxCheckedIcon />} {...props} />
  );
};
