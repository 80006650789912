// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';

const availableLanguages = ['et', 'en', 'ru'];
const fallbackLang = 'en';

// TODO: Whitelist doesn't seem to be supported
const options = {
  fallbackLng: fallbackLang,
  saveMissing: false,
  saveMissingTo: 'all',
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    caches: ['cookie'],
    // checkWhitelist: true,
  },
  debug: false,
  // whitelist: availableLanguages,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: '/assets/l10n/{{lng}}.json',
  },
};

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .use(HttpBackend) // load translations using http (default public/assets/locals/en/translations)
  // .use(LanguageDetector) // detect user language
  .init(options);

export { availableLanguages, fallbackLang as fallbackLanguage };
export default i18n;
