import { Fragment, forwardRef } from 'react';

import PropTypes from 'prop-types';

import chargerIcon from '@images/icons/Charger.svg';
import homeIcon from '@images/icons/Home.svg';
import carIcon from '@images/icons/nav/Car.svg';
import mapIcon from '@images/icons/nav/Map.svg';
import userIcon from '@images/icons/nav/User.svg';

import SearchResultItemBase from './SearchResultItemBase';
import SearchTypeBadge from './SearchTypeBadge';

const typeToIcon = {
  device: chargerIcon,
  site: mapIcon,
  user: userIcon,
  vehicle: carIcon,
  workspace: homeIcon,
};

// Credit: https://stackoverflow.com/a/71060118/2655932
const BoldedText = ({ text, boldedSubstring }) => {
  if (!boldedSubstring) {
    return text;
  }
  const escapedBoldedSubstring = boldedSubstring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const textArray = text.split(RegExp(escapedBoldedSubstring, 'ig'));
  const match = text.match(RegExp(escapedBoldedSubstring, 'ig'));

  return textArray.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={index}>
      {item}
      {index !== textArray.length - 1 && match && <strong className="font-semibold">{match[index]}</strong>}
    </Fragment>
  ));
};

const SearchResultItem = forwardRef(({ name, type, searchQuery, isSelected, onHover, onClick }, ref) => (
  <SearchResultItemBase
    isSelected={isSelected}
    className="flex items-center gap-2"
    onHover={onHover}
    onClick={onClick}
    ref={ref}
  >
    <img src={typeToIcon[type]} alt={type} />
    <div className="flex-1">
      <BoldedText text={name} boldedSubstring={searchQuery} />
    </div>
    <SearchTypeBadge type={type} />
  </SearchResultItemBase>
));

SearchResultItem.displayName = 'SearchResultItem';

SearchResultItem.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['device', 'site', 'user', 'vehicle', 'workspace']).isRequired,
  searchQuery: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

SearchResultItem.defaultProps = {
  searchQuery: null,
};

export default SearchResultItem;
