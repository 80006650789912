import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

const DeviceOwner = ({ siteName, companyName, email }) => {
  const { t } = useTranslation();

  const dataLabels = [
    {
      tooltip: t('siteName', 'Site name'),
      label: siteName || '-',
    },
    {
      tooltip: t('companyName', 'Company name'),
      label: companyName || '-',
    },
    {
      tooltip: t('email', 'Email'),
      label: email || '-',
    },
  ];

  return (
    <div className="flex flex-col items-start font-poppins leading-6">
      {dataLabels.map(({ tooltip, label }) => (
        <Tooltip key={tooltip} title={tooltip} placement="top">
          <span>{label}</span>
        </Tooltip>
      ))}
    </div>
  );
};

DeviceOwner.propTypes = {
  siteName: PropTypes.string,
  companyName: PropTypes.string,
  email: PropTypes.string,
};

DeviceOwner.defaultProps = {
  siteName: null,
  companyName: null,
  email: null,
};

export default DeviceOwner;
