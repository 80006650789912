import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { uniq } from 'lodash';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectUser } from '@services/auth/selectors';
import { useGetAllDevicesQuery, useGetDevicesQuery } from '@services/devices/endpoints';
import { useAppSelector } from '@services/hooks';

export const useDeviceFirmwareMenu = () => {
  const { companyUuid } = useParams();
  const self = useAppSelector(selectUser);
  const skipGetAll = isSubdomainPartner() ? !!companyUuid : !self?.admin;
  const { data, isFetching, isError } = useGetDevicesQuery({ companyUuid }, { skip: !skipGetAll });
  const {
    data: dataAdmin,
    isFetching: isFetchingAdmin,
    isError: isErrorAdmin,
  } = useGetAllDevicesQuery({ forBackoffice: true }, { skip: skipGetAll });
  const [deviceFirmwareMenuOptions, setDeviceFirmwareMenuOptions] = useState<StyledMenuItemProps[]>([]);

  useEffect(() => {
    const devices = data?.devices ?? dataAdmin?.devices;
    if (!isFetching && !isError && !isFetchingAdmin && !isErrorAdmin) {
      setDeviceFirmwareMenuOptions((prevState) =>
        uniq(devices?.flatMap((device) => (device.firmwareVersion ? [device.firmwareVersion] : [])))
          .map((firmwareVersion) => ({
            uuid: firmwareVersion,
            checked: !!prevState?.find((option) => option.uuid === firmwareVersion)?.checked,
            label: firmwareVersion,
            onClick: () => toggleOptionChecked(firmwareVersion),
          }))
          .sort(compareMenuOptions),
      );
    }
  }, [isFetching, isFetchingAdmin]);

  const toggleOptionChecked = (selectedOption?: string) => {
    setDeviceFirmwareMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOption) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { deviceFirmwareMenuOptions, setDeviceFirmwareMenuOptions };
};
