import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';

import { sumBy } from 'lodash';
import { ChargingAction, DeviceStatus } from 'models/device.enums';
import moment from 'moment';

import { Box, Typography } from '@mui/material';

import { EnergySupplied } from '@components/energySupplied/energySuppliedChart';
import { MessageBar, MessageLevel } from '@components/messages/MessageBar';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { clearLocalFailedAction } from '@services/devices/actions';
import { useAppDispatch } from '@services/hooks';
import { DeviceOutletContextType } from '@views/Devices/DeviceOutletContextType';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

import { useGetDeviceEnergySuppliedSummaryQuery } from '../../../services/devices/endpoints';
import { ChargerButton } from './ChargerButton';
import { ChargerSummary } from './ChargerSummary';
import { RadialChart } from './RadialChart';
import { useConnectorTypes } from './chargerConst';

export const ChargerOverview = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobile } = useBreakpoints();

  const {
    device: charger,
    site,
    selectedDuration,
    setSelectedDuration,
    isFetchingTransactions,
    timeFrame,
  } = useOutletContext<DeviceOutletContextType>();

  const { data: energySuppliedSummary } = useGetDeviceEnergySuppliedSummaryQuery(
    { uuid: charger.uuid, timeFrame: timeFrame! },
    { skip: !charger || !timeFrame },
  );

  const summarizedTransactions = useMemo(() => {
    return energySuppliedSummary?.data?.map((connector, _index, array) => {
      const { connectorId, ...rest } = connector;
      return {
        ...rest,
        name:
          array.length === 1
            ? `${t('energySupplied', 'Energy supplied')} kWh`
            : `${t('connector', 'Connector')} ${connectorId}`,
      };
    });
  }, [energySuppliedSummary, t]);

  const connectorTypes = useConnectorTypes();
  const connectors = getUnifiedConnectors(charger);
  const isOffline = charger?.status !== DeviceStatus.ONLINE;

  const moreThan3Connectors = connectors.length > 3;
  const activePower = sumBy(connectors, 'active_power') || charger?.active_power || 0;
  const maxPower = charger?.maxPowerKw || 22;

  const detailsData = [
    {
      label: t('brand', 'Brand'),
      value: isVoolDevice(charger) ? 'VOOL' : charger?.vendor || '-',
    },
    {
      label: t('serial', 'Serial'),
      value: charger?.serialNumber || '-',
    },
    {
      label: t('model', 'Model'),
      value: charger?.model || '-',
    },
    {
      label: t('maxPower', 'Max power'),
      value: `${maxPower} kW`,
    },
    {
      label: t('firmware', 'Firmware'),
      value: charger?.firmwareVersion || '-',
    },
    {
      label: t('connectors', 'Connectors'),
      value: connectors
        .map(({ connectorType }) => {
          const type = (connectorType && connectorTypes[connectorType]) || connectorTypes.TYPE_2;
          return type.label;
        })
        .join(', '),
    },
  ];

  const clearFailedAction = (connectorId: number) => {
    dispatch(clearLocalFailedAction({ deviceUuid: charger.uuid, connectorId }));
  };

  const failedConnector = connectors.find((c) => c.localFailedAction);

  return (
    <div className="flex flex-col">
      {failedConnector && (
        <MessageBar level={MessageLevel.WARNING} onClose={() => clearFailedAction(failedConnector.connectorId)}>
          <div className="float-right">
            {failedConnector.localFailedActionTime &&
              moment(failedConnector.localFailedActionTime).format('DD.MM.YY, HH:mm')}
          </div>
          {failedConnector.localFailedAction === ChargingAction.START
            ? t('startChargingFailed', 'Start charging failed')
            : t('stopChargingFailed', 'Stop charging failed')}
        </MessageBar>
      )}
      <div className={`mb-10 flex w-full flex-wrap gap-x-4 gap-y-2 ${!moreThan3Connectors ? 'xl:flex-nowrap' : ''}`}>
        <div className={`flex w-full gap-x-2 gap-y-2 ${moreThan3Connectors ? 'flex-wrap' : 'flex-col xl:w-auto'}`}>
          {connectors.map((connector) => (
            <ChargerButton
              connector={connector}
              site={site}
              key={connector.connectorId || connector.deviceUuid}
              className={`w-full ${moreThan3Connectors ? 'lg:max-w-[294px]' : 'h-full'}`}
            />
          ))}
        </div>
        <div className={`flex w-full flex-col md:flex-row ${moreThan3Connectors ? 'gap-2' : 'gap-4'}`}>
          <div className="min-h-[136px] w-full md:max-w-[294px]">
            <RadialChart
              label="kW"
              max={maxPower}
              color="#eb4e20"
              value={activePower}
              offline={isOffline}
              backgroundColor="bg-vermillion-200"
            />
          </div>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            bgcolor="white"
            p={2}
            gap={1}
            sx={{ borderRadius: '16px' }}
          >
            <Typography variant="p14">{t('details', 'Details')}</Typography>
            <Box
              display="grid"
              sx={(theme) => {
                return isMobile
                  ? {
                      gridTemplateColumns: '1fr',
                      '& > :nth-of-type(3n)': {
                        marginBottom: theme.spacing(2),
                      },
                    }
                  : {
                      gridTemplateColumns: '1fr 1fr',
                    };
              }}
            >
              {detailsData.map((item) => (
                <Box key={item.label}>
                  <Typography variant="p14b">{item.label}: </Typography>
                  <Typography variant="p14">{item.value}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </div>
      </div>
      <div className="flex flex-col">
        <ChargerSummary
          isPrivateSite={!site?.publicFlag}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
        />
        <EnergySupplied
          isFetching={isFetchingTransactions}
          summarizedTransactions={summarizedTransactions}
          filter={timeFrame}
        />
      </div>
      <Outlet />
    </div>
  );
};
