import { useTranslation } from 'react-i18next';

import { availableLanguages, fallbackLanguage } from '@config/i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const browserLang = i18n.language;
  const currentLang = availableLanguages.find((lng) => browserLang.includes(lng)) ?? fallbackLanguage;
  return (
    <div className="flex items-center justify-center rounded-2xl bg-gray-100">
      {availableLanguages.map((lng) => (
        <button
          key={lng}
          type="button"
          className={`hover:bg-vool-gray-100 m-4 h-10 w-10 rounded-full text-sm sm:m-3 ${
            currentLang === lng
              ? 'bg-gray-600 bg-opacity-25 font-semibold text-primary sm:bg-white'
              : 'text-white sm:text-black'
          }`}
          onClick={() => {
            i18n.changeLanguage(lng);
          }}
        >
          {lng.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default LanguageSelector;
