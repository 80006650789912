import { User } from 'models/user';

import * as Amplitude from '@amplitude/analytics-browser';

import { AmplitudeEvent } from './data';

type AmplitudeIdentifyProps = {
  userId?: User['uuid'];
  companyId?: User['activeCompanyUuid'];
};

type EventProperties = AmplitudeIdentifyProps & Record<string, unknown>;

const AMPLITUDE_CONFIG = JSON.parse(process.env.AMPLITUDE_CONFIG ?? '')[process.env.REACT_APP_CONF_FILE ?? ''];

const setAmplitudeIdentify = ({ userId, companyId }: AmplitudeIdentifyProps): void => {
  const identify = new Amplitude.Identify();
  if (!userId || !companyId) {
    identify.clearAll();
  } else {
    identify.set('userId', userId);
    identify.set('companyId', companyId);
  }
  Amplitude.setUserId(userId);
  const existingDeviceId = localStorage.getItem('amplitude_device_id');
  if (existingDeviceId) {
    Amplitude.setDeviceId(existingDeviceId);
  }
  Amplitude.identify(identify);
  const amplitudeDeviceId = Amplitude.getDeviceId();
  if (!existingDeviceId && amplitudeDeviceId) {
    localStorage.setItem('amplitude_device_id', amplitudeDeviceId);
  }
};

type CustomTrackEventHandlers = Record<string, (eventName: string, eventProperties: EventProperties) => Promise<void>>;

const customTrackEventHandlers: CustomTrackEventHandlers = {
  [AmplitudeEvent.LOGIN]: async (eventName, eventProperties) => {
    const { userId, companyId } = eventProperties;
    if (userId !== Amplitude.getUserId()) {
      setAmplitudeIdentify({ userId, companyId });
      await Amplitude.track(eventName, eventProperties).promise;
    }
  },
  [AmplitudeEvent.LOGOUT]: async (eventName, eventProperties) => {
    await Amplitude.track(eventName, eventProperties).promise;
    setAmplitudeIdentify({ userId: undefined, companyId: undefined });
    Amplitude.reset();
  },
};

export const initAmplitude = (): void => {
  if (AMPLITUDE_CONFIG) {
    Amplitude.init(AMPLITUDE_CONFIG.key);
  }
};

export const trackAmplitudeEvent = async (
  eventName: AmplitudeEvent,
  eventProperties: EventProperties,
): Promise<void> => {
  if (!AMPLITUDE_CONFIG) {
    return;
  }
  const customHandler = customTrackEventHandlers[eventName];
  if (customHandler) {
    await customHandler(eventName, eventProperties);
  } else {
    await Amplitude.track(eventName, eventProperties).promise;
  }
};
