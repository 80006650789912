import React, { ChangeEventHandler, InputHTMLAttributes, useRef } from 'react';

import classNames from 'classnames';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';

export type InputProps = {
  id?: string;
  name: string;
  label?: string;
  type?: string;
  value?: string;
  disabled?: boolean;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: boolean;
  leadingAddOn?: string;
  trailingAddOn?: string;
  leadingIcon?: React.FC<{ className: string }>;
  trailingIcon?: React.FC<{ className: string }>;
  leadingIconClassName?: string;
  trailingIconClassName?: string;
  helpText?: string;
  questionMark?: string;
  className?: string;
  autoComplete?: string;
  trailingText?: string;
  isInteger?: boolean;
  backgroundColor?: string;
  placeholder?: string;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      label,
      type = 'text',
      value,
      disabled,
      inputProps,
      onChange,
      error,
      leadingAddOn,
      trailingAddOn,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      leadingIconClassName,
      trailingIconClassName,
      helpText,
      questionMark,
      className,
      autoComplete,
      trailingText,
      isInteger,
      backgroundColor = 'bg-white',
      placeholder,
    },
    ref,
  ) => {
    const trailingRef = useRef<HTMLDivElement>(null);

    return (
      <div className={`relative font-poppins ${label ? 'pt-4' : ''}`}>
        {label && (
          <label
            htmlFor={name}
            className={`absolute left-2 top-label z-10 flex items-center px-1 text-sm font-normal ${backgroundColor} ${
              error ? 'text-vermillion' : 'text-gray-400'
            }`}
          >
            {label}
            {questionMark && (
              <Tooltip title={questionMark} placement="top">
                <div>
                  <QuestionMarkCircleIcon className="ml-1 h-4 w-4 text-gray-400" />
                </div>
              </Tooltip>
            )}
          </label>
        )}
        <div className={`flex items-center ${label ? 'mt-1' : ''}`}>
          {LeadingIcon && <LeadingIcon className={`${leadingIconClassName} absolute left-2 h-6 w-6`} />}
          <Tooltip title={disabled ? 'Disabled' : ''} placement="top-start">
            <input
              ref={ref}
              type={type}
              id={id}
              name={name}
              value={value}
              disabled={disabled}
              onChange={onChange}
              autoComplete={autoComplete}
              placeholder={placeholder}
              className={classNames(
                className,
                'block h-12 w-full border-2 text-base font-normal focus:outline-none',
                { 'rounded-md': !leadingAddOn && !trailingAddOn },
                { 'rounded-r-md': leadingAddOn && !trailingAddOn },
                { 'rounded-l-md': trailingAddOn && !leadingAddOn },
                error
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-vermillion focus:ring-vermillion'
                  : 'border-gray-200 placeholder-gray-400 focus:border-forest focus:ring-forest',
                { 'pl-10': LeadingIcon },
                { 'pr-10': TrailingIcon || error },
                disabled ? 'text-gray-400' : 'text-gray-900',
              )}
              style={{ paddingRight: trailingRef.current ? trailingRef.current.offsetWidth + 15 : 0 }}
              onKeyDown={(e) => {
                if (isInteger && ['.', ',', 'e'].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              {...inputProps}
            />
          </Tooltip>
          {(TrailingIcon || trailingText) && (
            <div ref={trailingRef} className="absolute right-3 flex items-center">
              {trailingText && (
                <div className={`font-poppins text-sm ${error ? 'text-vermillion' : 'text-gray-400'}`}>
                  {trailingText}
                </div>
              )}
              {TrailingIcon && <TrailingIcon className={`${trailingIconClassName} h-6 w-6`} />}
            </div>
          )}
        </div>
        {helpText && (
          <span className={`mt-1 block text-sm leading-6 ${error ? 'text-vermillion' : 'text-gray-500'}`}>
            {helpText}
          </span>
        )}
      </div>
    );
  },
);

Input.displayName = 'Input';
