import { forwardRef } from 'react';

import PropTypes from 'prop-types';

const SearchResultItemBase = forwardRef(({ isSelected, className, onHover, onClick, children }, ref) => (
  <button
    type="button"
    className={`${className} -mx-2 w-full rounded-sm px-2 py-2 text-left focus:outline-none active:bg-gray-200 ${
      isSelected ? 'bg-gray-100' : ''
    }`}
    onPointerOver={onHover}
    onClick={onClick}
    ref={ref}
  >
    {children}
  </button>
));

SearchResultItemBase.displayName = 'SearchResultItemBase';

SearchResultItemBase.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onHover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

SearchResultItemBase.defaultProps = {
  className: '',
};

export default SearchResultItemBase;
