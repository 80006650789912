import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { partition, sumBy } from 'lodash';

import { Box } from '@mui/material';

import MetricsCard from '@components/cards/MetricsCard';
import LineChart from '@components/charts/LineChart';
import { formatDecimals } from '@helpers/formatDecimals';
import { useActiveCurrency } from '@hooks/useActiveCurrency';
import { useBreakpoints } from '@hooks/useBreakpoints';
import apiSlice from '@services/api';
import { useAppSelector } from '@services/hooks';
import { TimeFrameKey } from '@services/transactions/chartDataParser';
import { selectEndedTransactions } from '@services/transactions/selectors';

type ChargingSessionsSummaryProps = {
  timeFrame?: TimeFrameKey;
  periodStartTime?: string;
};

export const ChargingSessionsSummary = ({ timeFrame, periodStartTime }: ChargingSessionsSummaryProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();
  const currency = useActiveCurrency();
  const endedTransactions = useAppSelector(selectEndedTransactions);

  const [publicTransactions, privateTransactions] = useMemo(
    () => partition(endedTransactions, 'publicChargingFlag'),
    [endedTransactions],
  );

  const totalCost = useMemo(() => formatDecimals(sumBy(endedTransactions, 'monetaryCost')), [endedTransactions]);
  const totalEnergyKwh = useMemo(() => formatDecimals(sumBy(endedTransactions, 'amount') / 1000), [endedTransactions]);
  const totalRevenue = useMemo(() => formatDecimals(sumBy(endedTransactions, 'monetaryRevenue')), [endedTransactions]);
  const totalProfit = useMemo(
    () => formatDecimals(sumBy(publicTransactions, 'monetaryRevenue') - sumBy(publicTransactions, 'monetaryCost')),
    [publicTransactions],
  );

  const totalData = [
    {
      label: t('publicChargingSessions', 'Public charging sessions'),
      value: publicTransactions.length || 0,
    },
    {
      label: t('privateChargingSessions', 'Private charging sessions'),
      value: privateTransactions.length || 0,
    },
    {
      label: t('totalSessions', 'Total sessions'),
      value: endedTransactions.length,
    },
    {
      label: t('totalChargingSessionsCost', 'Total charging sessions cost'),
      value: `${totalCost} ${currency.symbol}`,
    },
    {
      label: t('totalEnergyConsumption', 'Total energy consumption'),
      value: `${totalEnergyKwh} kWh`,
    },
    {
      label: t('revenue', 'Revenue'),
      value: `${totalRevenue} ${currency.symbol}`,
    },
    {
      label: t('profit', 'Profit'),
      value: `${totalProfit} ${currency.symbol}`,
    },
  ];

  const { isFetching: isFetchingTransactions } = apiSlice.useTransactionsQuery(
    {
      periodStartTime,
    },
    {
      skip: !periodStartTime,
      refetchOnMountOrArgChange: true,
    },
  );

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
      <LineChart
        noBorder
        title={t('chargingSessions', 'Charging sessions')}
        series={[
          {
            name: t('privateChargingSessions', 'Private charging sessions'),
            data: privateTransactions,
          },
          {
            name: t('publicChargingSessions', 'Public charging sessions'),
            data: publicTransactions,
          },
        ]}
        isFetching={isFetchingTransactions}
        filter={timeFrame}
      />
      <MetricsCard
        title={t('chargingSessions', 'Charging sessions')}
        metrics={totalData}
        onClick={() => navigate('/reporting')}
      />
    </Box>
  );
};
