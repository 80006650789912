import { ConnectorComputedStatus } from 'models/device.enums';

import { Box, Typography } from '@mui/material';

import { useComputedStatusDefs } from '@views/Chargers/useComputedStatusDefs';
import { UnifiedConnector } from '@views/Devices/connectorsUtil';

import { ConnectorVehicle } from './ConnectorVehicle';

export const ConnectorStatusContent = ({ connector }: { connector: UnifiedConnector }) => {
  const status = connector.computedStatus;
  const isSuspended = [ConnectorComputedStatus.SUSPENDED_EV, ConnectorComputedStatus.SUSPENDED_EVSE].includes(status);
  const computedStatusDefs = useComputedStatusDefs();

  const getDisplayedStatus = () => {
    if (status === ConnectorComputedStatus.STARTING) {
      return ConnectorComputedStatus.PLUGGED_IN;
    }
    if (status === ConnectorComputedStatus.STOPPING) {
      return ConnectorComputedStatus.CHARGING;
    }
    return status;
  };

  const statusDef = computedStatusDefs[getDisplayedStatus()];

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center">
        <img
          src={statusDef.icon}
          alt={statusDef.statusLabel}
          className={`mr-2 inline-block rounded-full ${statusDef.iconClassName}`}
        />
        <Typography variant="p14" className={statusDef.textClassName}>
          {statusDef.statusLabel} {isSuspended && statusDef.secondaryLabel}
        </Typography>
      </Box>
      {connector.lastCarUuid && connector.computedStatus !== ConnectorComputedStatus.AVAILABLE && (
        <ConnectorVehicle carUuid={connector.lastCarUuid} />
      )}
    </Box>
  );
};
