import { EventLogType } from 'models/event-log';

import { TableCell, TableCellProps, Typography } from '@mui/material';

const categoryColors: Record<EventLogType, string> = {
  info: 'bg-gray-100 text-gray-600',
  warning: 'bg-amber-600/20 text-amber-600',
  error: 'bg-vermillion/20 text-vermillion',
};

type CategoryCellProps = TableCellProps & {
  type: EventLogType;
};

export const CategoryCell = ({ type, ...props }: CategoryCellProps) => {
  return (
    <TableCell {...props}>
      <Typography
        variant="p12"
        textTransform="uppercase"
        padding={0.5}
        borderRadius={1}
        className={categoryColors[type]}
      >
        {type}
      </Typography>
    </TableCell>
  );
};
