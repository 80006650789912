import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { GroupDto } from 'models/group.dto';

import {
  useGetGroupQuery,
  useUpdateGroupResourcesMutation,
  useUpdateGroupUsersMutation,
} from '@services/groups/endpoints';
import { useAppDispatch } from '@services/hooks';
import { addToastMessage } from '@services/toastMessages';
import { useWorkspaceGroupsAndRoles } from '@views/Workspace/workspaceHooks';

import { GroupSidebarBase } from './GroupSidebarBase';

export const EditGroupSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { groupUuid, companyUuid } = useParams();
  const { data: group, isLoading } = useGetGroupQuery(groupUuid ?? skipToken);
  const { isWorkspaceAdmin } = useWorkspaceGroupsAndRoles();

  const [updateGroupResources, { isLoading: isSavingGroupResources }] = useUpdateGroupResourcesMutation();
  const [updateGroupUsers, { isLoading: isSavingGroupUsers }] = useUpdateGroupUsersMutation();

  const handleSave = async (group: GroupDto) => {
    try {
      if (!groupUuid) {
        throw new Error('Group UUID is missing');
      }
      if (isWorkspaceAdmin) {
        await updateGroupResources({ uuid: groupUuid, ...group }).unwrap();
      } else {
        await updateGroupUsers({ uuid: groupUuid, users: group.users }).unwrap();
      }
    } catch {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('updatingTheGroupFailed', 'Updating the group failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }

    dispatch(
      addToastMessage({
        type: 'success',
        title: t('groupUpdated', 'Group updated'),
        message: t('youCanSeeTheGroupInYourGroupsList', 'You can see the group in your groups list.'),
      }),
    );

    return true;
  };

  return (
    <GroupSidebarBase
      mode="edit"
      isLoading={isLoading}
      isSaving={isSavingGroupResources || isSavingGroupUsers}
      handleSave={handleSave}
      group={group}
      companyUuid={companyUuid}
    />
  );
};
