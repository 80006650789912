import { useState } from 'react';
import { Link } from 'react-router-dom';

import { SxProps, Typography } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';

export type BreadcrumbItemProps = {
  label: string;
  path: string;
  color?: string;
  options?: BreadcrumbItemProps[];
  isMobile?: boolean;
  sx?: SxProps;
};

export const BreadcrumbItem = ({ label, path, color, options, isMobile, sx }: BreadcrumbItemProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const typography = isMobile ? 'p16' : 'h3';

  return options ? (
    <>
      <Typography
        color={color}
        typography={typography}
        noWrap
        px="4px"
        sx={{
          cursor: 'pointer',
          ...sx,
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {label}
      </Typography>
      <MenuContainer horizontalOrigin="center" anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)}>
        {options.map((option) => (
          <Typography
            component={Link}
            key={option.path}
            to={option.path}
            variant="p14"
            textAlign="center"
            p="8px"
            sx={{
              '&:hover': {
                backgroundColor: '#F4F4F4',
              },
            }}
          >
            {option.label}
          </Typography>
        ))}
      </MenuContainer>
    </>
  ) : (
    <Typography
      component={Link}
      to={path}
      color={color}
      typography={typography}
      noWrap
      sx={{
        cursor: 'pointer',
        ...sx,
      }}
    >
      {label}
    </Typography>
  );
};
