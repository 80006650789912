import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { ControlChannel, LmcConnectionType } from 'models/device.enums';

import ButtonSwitch from '@components/buttons/ButtonSwitch';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { useForm } from '@hooks/useTypedForm';

import { isChargerSupportingConnectivityConf } from '../deviceConfigurationUtils';
import { ConnectivityOutletContext, useConnectivitySchema } from './ChargerConnectivityFormSidebar';
import { CellularEnabledCheckbox } from './connectivityFormParts/CellularEnabledCheckbox';
import { EthernetEnabledByDefaultCheckbox } from './connectivityFormParts/EthernetEnabledByDefaultCheckbox';
import { WifiSection } from './connectivityFormParts/WifiSection';
import { useSaveChargerConnectivity } from './saveDeviceHooks';

type ChargerConnectivityWizValues = {
  wifiEnabledWiz: boolean;
  wifiSsidWiz: string;
  passwordWiz: string;
  cellularEnabledWiz: boolean;
  controlChannelsWiz: ControlChannel[]; // in schema, but unused
};

export const ChargerConnectivityWizardSidebar = () => {
  const { t } = useTranslation();
  const {
    device,
    wifiSsid: initialWifiSsid,
    wifiPassword: initialWifiPassword,
    isCellularSupported,
    onCloseConnectivityFormSidebar,
  } = useOutletContext<ConnectivityOutletContext>();

  const deviceConfiguration = device.configuration ?? {};
  const schema = useConnectivitySchema(deviceConfiguration, 'Wiz');
  const { formState, reset, register, handleSubmitAndResolve, watch } = useForm<ChargerConnectivityWizValues>({
    schema,
  });
  const wifiEnabled = watch('wifiEnabledWiz');

  const [isConnectedToLmc, setIsConnectedToLmc] = useState<boolean>();
  const [lmcConnectionType, setLmcConnectionType] = useState<LmcConnectionType>();
  const [isDirectlyConnectedToInternet, setIsDirectlyConnectedToInternet] = useState<boolean>();

  useEffect(() => {
    reset({
      wifiSsidWiz: initialWifiSsid,
      passwordWiz: initialWifiPassword,
    });
  }, [initialWifiSsid, initialWifiPassword]);

  const [saveChargerConnectivity, { isLoading, isError }] = useSaveChargerConnectivity();

  const getControlChannels = () => {
    if (isConnectedToLmc === undefined) {
      return null;
    }
    if (!isConnectedToLmc) {
      return [ControlChannel.APP_CMD];
    }
    const appCmdOrNull = isDirectlyConnectedToInternet ? ControlChannel.APP_CMD : null;
    if (lmcConnectionType === LmcConnectionType.CAN) {
      return [appCmdOrNull ?? ControlChannel.CAN_CMD, ControlChannel.CAN_LM];
    }
    if (lmcConnectionType === LmcConnectionType.LAN) {
      return [appCmdOrNull ?? ControlChannel.LAN_CMD, ControlChannel.LAN_LM];
    }
    return null;
  };

  const controlChannels = getControlChannels();

  const saveValues = async (values: ChargerConnectivityWizValues) => {
    if (controlChannels) {
      const success = await saveChargerConnectivity(device, {
        wifiEnabled: values.wifiEnabledWiz,
        wifiSsid: values.wifiSsidWiz,
        password: values.passwordWiz,
        cellularEnabled: values.cellularEnabledWiz,
        controlChannels,
      });
      if (success) {
        onCloseConnectivityFormSidebar();
        return true;
      }
    }
    return false;
  };

  const canSaveValues =
    isConnectedToLmc !== undefined &&
    (!isConnectedToLmc || !!lmcConnectionType) &&
    (!isChargerSupportingConnectivityConf(device) ||
      (isDirectlyConnectedToInternet !== undefined && (isConnectedToLmc || isDirectlyConnectedToInternet)));

  return (
    <EditingSidebarBase
      title={t('connectivityWizard', 'Connectivity Wizard')}
      subtitle={device.name || device.serialNumber}
      anyDataChanged={isConnectedToLmc !== undefined}
      saveLabel={t('update', 'Update')}
      saveDisabled={!canSaveValues}
      discardLabel={t('back', 'Back')}
      updateLoading={isLoading}
      updateFailed={isError}
      onSaveValues={handleSubmitAndResolve(saveValues)}
      isSubSidebar
    >
      <div className="mb-10">
        <p>1. {t('isTheChargerConnectedToLmc', 'Is the charger connected to LMC?')}</p>
        <ButtonSwitch
          options={[
            { label: t('yes', 'Yes').toUpperCase(), value: true },
            { label: t('no', 'No').toUpperCase(), value: false },
          ]}
          value={isConnectedToLmc}
          onChange={setIsConnectedToLmc}
        />
      </div>
      {isConnectedToLmc && (
        <div className="mb-10">
          <p>2. {t('doesChargerCommLmcCanOrLan', 'Does the charger communicate with LMC over CAN or LAN?')}</p>
          <ButtonSwitch
            options={[
              { label: 'CAN', value: LmcConnectionType.CAN },
              { label: 'LAN', value: LmcConnectionType.LAN },
            ]}
            value={lmcConnectionType}
            onChange={setLmcConnectionType}
          />
        </div>
      )}
      {(isConnectedToLmc === false || lmcConnectionType) && isChargerSupportingConnectivityConf(device) && (
        <div className="mb-10">
          <p>
            {!isConnectedToLmc ? 2 : 3}.{' '}
            {t('isTheChargerDirectlyConnectedToInternet', 'Is the charger directly connected to Internet?')}
          </p>
          <ButtonSwitch
            options={[
              { label: t('yes', 'Yes').toUpperCase(), value: true },
              { label: t('no', 'No').toUpperCase(), value: false },
            ]}
            value={isDirectlyConnectedToInternet}
            onChange={setIsDirectlyConnectedToInternet}
          />
        </div>
      )}
      {isDirectlyConnectedToInternet && (
        <div className="mb-8 space-y-6 font-poppins">
          <EthernetEnabledByDefaultCheckbox />
          {isChargerSupportingConnectivityConf(device) && (
            <>
              <WifiSection
                enabled={wifiEnabled}
                enabledProps={register('wifiEnabledWiz')}
                ssidProps={register('wifiSsidWiz')}
                passwordProps={register('passwordWiz')}
                errors={formState.errors}
              />
              <CellularEnabledCheckbox isCellularSupported={isCellularSupported} {...register('cellularEnabledWiz')} />
            </>
          )}
        </div>
      )}
      {!isConnectedToLmc && isDirectlyConnectedToInternet === false && (
        <p>
          <strong>{t('chargerCompletelyOffline', 'Charger completely offline')}</strong>
          {` - ${t(
            'chargerCompletelyOfflineInfo',
            'this can only be configured using mobile app and connecting to charger via Bluetooth, to avoid locking yourself out.',
          )}`}
        </p>
      )}
    </EditingSidebarBase>
  );
};
