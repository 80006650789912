import { DevicesStatusLogDtos, DevicesStatusLogDtosQuery, EventLogDto } from 'models/event-log';

import apiSlice from '@services/api';
import { PagedDataQuery } from '@services/reporting/endpoints';

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    eventLogDtos: builder.query<EventLogDto[], PagedDataQuery<EventLogDto>>({
      query: (body) => ({
        url: 'reporting/event-log',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: EventLogDto[] }) => response.data,
      providesTags: ['CompanyContext'],
    }),
    devicesStatusLogDtos: builder.query<DevicesStatusLogDtos, DevicesStatusLogDtosQuery>({
      query: (body) => ({
        url: 'reporting/devices/status',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: DevicesStatusLogDtos }) => response.data,
      providesTags: ['CompanyContext'],
    }),
  }),
});

export const { useLazyEventLogDtosQuery, useDevicesStatusLogDtosQuery } = extendedApi;
export const eventLogApi = extendedApi;
