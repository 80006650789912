import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '@components/atoms/StyledButton';

type ScheduleReportButtonProps = {
  isPrimary?: boolean;
};

export const ScheduleReportButton = ({ isPrimary }: ScheduleReportButtonProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledButton
      color={isPrimary ? 'success' : 'secondary'}
      onClick={() => navigate('/reporting/scheduler/schedule')}
      fullWidth
    >
      {isPrimary ? t('createSchedule', 'Create schedule') : t('schedule', 'Schedule')}
    </StyledButton>
  );
};
