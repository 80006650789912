import { ReportingTransaction, TransactionType } from 'models/reporting-transaction';

import { Box, TableCell, TableCellProps, Typography } from '@mui/material';

import { ReactComponent as RFIDIcon } from '@images/icons/reporting/rfid.svg';
import { ReactComponent as UserIcon } from '@images/icons/reporting/user.svg';
import { ReactComponent as VehicleIcon } from '@images/icons/reporting/vehicle.svg';

type StartedByCellProps = TableCellProps & {
  row: ReportingTransaction;
};

export const StartedByCell = ({ row, ...props }: StartedByCellProps) => {
  return (
    <TableCell align="left" {...props}>
      <Box display="flex" flexDirection="column">
        {row.userUuid && row.transactionType !== TransactionType.PUBLIC && (
          <Typography variant="p14" display="flex" alignItems="center" gap={0.5} noWrap>
            <UserIcon style={{ flexShrink: 0 }} />
            {row.userName || row.userEmail}
          </Typography>
        )}
        {row.carUuid && row.transactionType !== TransactionType.PUBLIC && (
          <Typography variant="p14" display="flex" alignItems="center" gap={0.5} noWrap>
            <VehicleIcon style={{ flexShrink: 0 }} />
            {row.carBrand} {row.carName || row.carModel}
          </Typography>
        )}
        {row.rfidCardUuid && (
          <Typography variant="p14" display="flex" alignItems="center" gap={0.5} noWrap>
            <RFIDIcon style={{ flexShrink: 0 }} />
            {row.rfidNormalizedIdentifier}
          </Typography>
        )}
        {row.rfidName && !row.carUuid && !row.userUuid && (
          <Typography variant="p14" display="flex" alignItems="center" gap={0.5} noWrap>
            {row.rfidName}
          </Typography>
        )}
      </Box>
    </TableCell>
  );
};
