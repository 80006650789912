import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { ReportingTransaction, ReportingTransactionsSummary } from 'models/reporting-transaction';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import {
  FiltersBaseContextType,
  FiltersDataContextType,
  getFiltersBaseContextDefaultValues,
} from '@components/filters/context/FiltersContext';
import { useDataFiltering } from '@components/filters/hooks/useDataFiltering';
import {
  useLazyPostReportingTransactionsQuery,
  useLazyPostReportingTransactionsSummaryQuery,
} from '@services/reporting/endpoints';
import { SessionsContextType } from '@views/reporting/SessionsTotalCards';
import { useRfidMenu } from '@views/reporting/hooks/useRfidMenu';
import { useTransactionTypeMenu } from '@views/reporting/hooks/useTransactionTypeMenu';
import { useUserMenu } from '@views/reporting/hooks/useUserMenu';
import { useVehicleMenu } from '@views/reporting/hooks/useVehicleMenu';

type DeviceTransactionsFilterMenuOption = Extract<FilterMenuOption, 'vehicle' | 'user' | 'rfid' | 'type'>;

type DeviceTransactionsContextType = FiltersBaseContextType<DeviceTransactionsFilterMenuOption> &
  FiltersDataContextType<ReportingTransaction> &
  SessionsContextType;

export const DeviceTransactionsContext = createContext<DeviceTransactionsContextType>({
  ...getFiltersBaseContextDefaultValues<ReportingTransaction>(),
  filterOptionsMap: {
    vehicle: [],
    user: [],
    rfid: [],
    type: [],
  },
  summaryLoading: false,
});

export const DeviceTransactionsProvider = ({ deviceUuid, children }: { deviceUuid: string; children?: ReactNode }) => {
  const [summary, setSummary] = useState<ReportingTransactionsSummary>();
  const [fetchTransactions, { isFetching: dataLoading }] = useLazyPostReportingTransactionsQuery();
  const [fetchSummary, { isFetching: summaryLoading }] = useLazyPostReportingTransactionsSummaryQuery();

  const { vehicleMenuOptions, setVehicleMenuOptions } = useVehicleMenu();
  const { userMenuOptions, setUserMenuOptions } = useUserMenu();
  const { rfidMenuOptions, setRfidMenuOptions } = useRfidMenu();
  const { typeMenuOptions, setTypeMenuOptions } = useTransactionTypeMenu();

  const filterOptionsMap: Record<DeviceTransactionsFilterMenuOption, StyledMenuItemProps[]> = useMemo(() => {
    return {
      vehicle: vehicleMenuOptions,
      user: userMenuOptions,
      rfid: rfidMenuOptions,
      type: typeMenuOptions,
    };
  }, [vehicleMenuOptions, userMenuOptions, rfidMenuOptions, typeMenuOptions]);

  const filterMap: Record<DeviceTransactionsFilterMenuOption, Dispatch<SetStateAction<StyledMenuItemProps[]>>> = {
    vehicle: setVehicleMenuOptions,
    user: setUserMenuOptions,
    rfid: setRfidMenuOptions,
    type: setTypeMenuOptions,
  };

  const uuidFilterMap: Record<DeviceTransactionsFilterMenuOption, keyof ReportingTransaction> = {
    vehicle: 'carUuid',
    user: 'userUuid',
    rfid: 'rfidCardUuid',
    type: 'transactionType',
  };

  const dataFiltering = useDataFiltering<ReportingTransaction, DeviceTransactionsFilterMenuOption>(
    {
      filterOptionsMap,
      uuidFilterMap,
      filterMap,
      pageSize: 50,
      initialOrderBy: 'startTime',
      dataFilters: [{ column: 'deviceUuid', values: [deviceUuid], inclusive: true }],
      customDateRange: {
        startDate: dayjs().subtract(1, 'week').startOf('day'),
        endDate: dayjs().endOf('day'),
      },
    },
    fetchTransactions,
  );

  const { startDate, endDate, buildQuery, activeFiltersLength, filterInclusivityMap } = dataFiltering;

  const refetchSummary = async () => {
    try {
      const result = await fetchSummary(
        buildQuery({
          omitPageSize: true,
        }),
      ).unwrap();
      setSummary(result);
    } catch (error) {
      console.error('Error fetching transactions summary', error);
    }
  };

  useEffect(() => {
    refetchSummary();
  }, [startDate, endDate, activeFiltersLength, filterInclusivityMap]);

  const value = useMemo(
    () => ({
      ...dataFiltering,
      dataLoading,
      summary,
      summaryLoading,
      filterOptionsMap,
      deviceUuid,
    }),
    [summary, summaryLoading, filterOptionsMap, dataFiltering, deviceUuid],
  );

  return <DeviceTransactionsContext.Provider value={value}>{children}</DeviceTransactionsContext.Provider>;
};
