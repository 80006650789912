import { isNil } from 'lodash';
import PropTypes from 'prop-types';

const typeToBadgeColorScheme = {
  device: 'text-queen bg-queen/20',
  site: 'border-queen text-queen bg-queen/20',
  user: 'text-black bg-black/20',
  vehicle: 'text-forest bg-forest/20',
  workspace: 'border-black text-black bg-black/20',
};

const SearchTypeBadge = ({ type, count, className, onClick }) => {
  const Element = onClick ? 'button' : 'div';
  return (
    <Element
      type={onClick ? 'button' : undefined}
      className={`${className} ${typeToBadgeColorScheme[type]} rounded border px-2 py-0.5 text-left text-xs uppercase`}
      onClick={onClick}
    >
      {!isNil(count) && <span className="float-right inline-block">{count}</span>}
      {type}
    </Element>
  );
};

SearchTypeBadge.propTypes = {
  type: PropTypes.string.isRequired,
  count: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SearchTypeBadge.defaultProps = {
  count: null,
  className: '',
  onClick: undefined,
};

export default SearchTypeBadge;
