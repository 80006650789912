import CloseIcon from '@mui/icons-material/Close';
import { Chip, ChipProps, Typography, styled } from '@mui/material';

const StyledChip = styled(Chip)({
  color: '#282828',
  backgroundColor: '#D7E1DD',
  borderRadius: '4px',
  '& .MuiChip-deleteIcon': {
    '&:hover': {
      color: '#386957',
    },
    color: '#386957',
    marginRight: '4px',
    fontSize: '16px',
  },
  '& .MuiChip-label': {
    padding: '4px 8px',
  },
});

export const GroupMemberChip = (props: ChipProps) => (
  <StyledChip deleteIcon={<CloseIcon />} {...props} label={<Typography variant="p14">{props.label}</Typography>} />
);
