import { CreateReportScheduleDto, ReportScheduleDto } from 'models/report-schedule';
import { ReportingTransaction, ReportingTransactionsSummary } from 'models/reporting-transaction';

import apiSlice from '@services/api';

export type QueryRange = {
  startDate: string;
  endDate?: string;
};

export type Order = 'asc' | 'desc';

export type SortOrder<ContextDataType> = {
  column: keyof ContextDataType;
  order: Order;
};

export type ColumnFilter<ContextDataType> = {
  column: keyof ContextDataType;
  values: any[];
  inclusive: boolean;
};

/**
 * Represents a database cursor.
 */
export type DbCursor = {
  /** The unique identifier to start the query from. (e.g. PK) */
  after: any;

  /** The value to start the query from. */
  value: any;
};

export type PagedDataQuery<ContextDataType> = {
  queryRange: QueryRange;
  cursor?: DbCursor;
  pageSize?: number;
  columnFilters?: ColumnFilter<ContextDataType>[];
  timeZone?: string;
  sortOrder: SortOrder<ContextDataType>;
};

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postReportingTransactions: builder.query<ReportingTransaction[], PagedDataQuery<ReportingTransaction>>({
      query: (body) => ({
        method: 'POST',
        url: 'reporting/transactions',
        body,
      }),
      transformResponse: (response: { transactions: ReportingTransaction[] }) => response.transactions,
      providesTags: ['CompanyContext'],
    }),
    postReportingTransactionsSummary: builder.query<ReportingTransactionsSummary, PagedDataQuery<ReportingTransaction>>(
      {
        query: (body) => ({
          method: 'POST',
          url: 'reporting/transactions/summary',
          body,
        }),
        transformResponse: (response: { summary: ReportingTransactionsSummary }) => response.summary,
        providesTags: ['CompanyContext'],
      },
    ),
    getReportSchedules: builder.query<ReportScheduleDto[], void>({
      query: () => 'report-schedules',
      providesTags: ['CompanyContext', 'ReportSchedule'],
      transformResponse: (response: { data: ReportScheduleDto[] }) => response.data,
    }),
    getReportSchedule: builder.query<ReportScheduleDto, string>({
      query: (uuid: string) => `report-schedules/${uuid}`,
      providesTags: ['CompanyContext', 'ReportSchedule'],
      transformResponse: (response: { data: ReportScheduleDto }) => response.data,
    }),
    postReportSchedule: builder.mutation<ReportScheduleDto, CreateReportScheduleDto>({
      query: (body) => ({
        method: 'POST',
        url: 'report-schedules',
        body,
      }),
      invalidatesTags: ['ReportSchedule'],
      transformResponse: (response: { data: ReportScheduleDto }) => response.data,
    }),
    updateReportSchedule: builder.mutation<ReportScheduleDto, { uuid: string; body: CreateReportScheduleDto }>({
      query: ({ uuid, body }) => ({
        method: 'PUT',
        url: `report-schedules/${uuid}`,
        body,
      }),
      invalidatesTags: ['ReportSchedule'],
      transformResponse: (response: { data: ReportScheduleDto }) => response.data,
    }),
    deleteReportSchedule: builder.mutation<void, string>({
      query: (uuid: string) => ({
        method: 'DELETE',
        url: `report-schedules/${uuid}`,
      }),
      invalidatesTags: ['ReportSchedule'],
      onQueryStarted(uuid, { dispatch, queryFulfilled }) {
        const update = dispatch(
          extendedApi.util.updateQueryData('getReportSchedules', undefined, (draft) => {
            draft = draft?.filter((schedule) => schedule.uuid !== uuid);
            return draft;
          }),
        );
        queryFulfilled.catch(() => {
          update.undo();
        });
      },
    }),
  }),
});

export const {
  useLazyPostReportingTransactionsQuery,
  useLazyPostReportingTransactionsSummaryQuery,
  useGetReportSchedulesQuery,
  useLazyGetReportScheduleQuery,
  usePostReportScheduleMutation,
  useUpdateReportScheduleMutation,
  useDeleteReportScheduleMutation,
} = extendedApi;
export const reportingApi = extendedApi;
