import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConnectorComputedStatus, DeviceStatus } from 'models/device.enums';
import moment from 'moment';

import {
  Box,
  Skeleton,
  Tab,
  TabProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Tabs,
  Typography,
} from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledButton } from '@components/atoms/StyledButton';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';
import checkIcon from '@images/icons/check.svg';
import { useGetAllDevicesQuery } from '@services/devices/endpoints';
import { selectDevices } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetAllSitesQuery } from '@services/sites/endpoints';
import { getUnifiedConnectors } from '@views/Devices/connectorsUtil';
import { ConnectorStatusContent } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/ConnectorStatusContent';
import { UserFavoriteDeviceStar } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/UserFavoriteDeviceStar';

import { PartnerDeviceContent } from './PartnerDeviceContent';

enum DevicesDashboardTab {
  NEEDS_ATTENTION = 'needsAttention',
  LATEST_DEVICES = 'latestDevices',
  FAVORITE_DEVICES = 'favoriteDevices',
}

const PartnersDevicesRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell width={180}>
      <Box display="flex" flexDirection="column">
        <Skeleton variant="text" animation="wave" width="60%" />
        <Skeleton variant="text" animation="wave" width="80%" />
      </Box>
    </TableCell>
    <TableCell width={160}>
      <Skeleton variant="text" animation="wave" width="30%" />
    </TableCell>
  </StyledSkeletonTableRow>
);

const PartnersDevicesTableSkeleton = () => {
  return (
    <TableContainer component={CardContainer} sx={{ p: 0 }}>
      <Table>
        <TableBody>
          {Array.from(Array(6).keys()).map(() => (
            <PartnersDevicesRowSkeleton key={self.crypto.randomUUID()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PartnersDevicesDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const rawDevices = useAppSelector(selectDevices);

  const [activeTab, setActiveTab] = useState(DevicesDashboardTab.NEEDS_ATTENTION);

  const { data, isLoading } = useGetAllDevicesQuery({ forBackoffice: true }, { refetchOnFocus: true });
  useGetAllSitesQuery();

  const devices = useMemo(
    () =>
      data?.devices.map((device) => ({
        ...device,
        connectors: getUnifiedConnectors(device),
        userFavoriteFlag: rawDevices.find((rawDevice) => rawDevice.uuid === device.uuid)?.userFavoriteFlag,
      })) ?? [],
    [data, rawDevices],
  );

  const devicesNeedingAttention = useMemo(
    () =>
      devices.filter(
        (device) =>
          device.status !== DeviceStatus.ONLINE ||
          device.stateOfConnectors?.some(
            (connector) =>
              connector.computedStatus &&
              [
                ConnectorComputedStatus.FAULTED,
                ConnectorComputedStatus.NEVER_CONNECTED,
                ConnectorComputedStatus.OFFLINE,
                ConnectorComputedStatus.SUSPENDED_EV,
                ConnectorComputedStatus.SUSPENDED_EVSE,
                ConnectorComputedStatus.UNAVAILABLE,
                ConnectorComputedStatus.UNKNOWN,
              ].includes(connector.computedStatus),
          ),
      ),
    [devices],
  );

  const latestDevices = useMemo(
    () => [...devices].sort((a, b) => moment(b.addTime).diff(moment(a.addTime))).slice(0, 10),
    [devices],
  );

  const favoriteDevices = devices.filter((device) => device.userFavoriteFlag);

  const devicesToDisplay = {
    [DevicesDashboardTab.NEEDS_ATTENTION]: devicesNeedingAttention,
    [DevicesDashboardTab.LATEST_DEVICES]: latestDevices,
    [DevicesDashboardTab.FAVORITE_DEVICES]: favoriteDevices,
  }[activeTab];

  const tabs: TabProps[] = [
    {
      label: `${t('needAttention', 'Needs attention')} • ${devicesNeedingAttention.length}`,
      value: DevicesDashboardTab.NEEDS_ATTENTION,
    },
    {
      label: `${t('latest', 'Latest')} • ${latestDevices.length}`,
      value: DevicesDashboardTab.LATEST_DEVICES,
    },
    {
      label: `${t('favorites', 'Favorites')} • ${favoriteDevices.length}`,
      value: DevicesDashboardTab.FAVORITE_DEVICES,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" width={1} gap={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="p18">
          {t('devices', 'Devices')} • {devices.length}
        </Typography>
        <StyledButton color="success" onClick={() => navigate('/partner-devices')}>
          {t('viewAll', 'View all')}
        </StyledButton>
      </Box>
      <CardContainer width={1} mb={2} sx={{ p: 0 }}>
        <Tabs
          value={activeTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(e, newValue) => setActiveTab(newValue)}
          TabIndicatorProps={{
            sx: {
              backgroundColor: '#EB4E20',
            },
          }}
          sx={{
            width: 1,
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiButtonBase-root.Mui-selected': {
              color: '#EB4E20',
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={
                <Typography variant="p16" textTransform="none">
                  {tab.label}
                </Typography>
              }
              value={tab.value}
            />
          ))}
        </Tabs>
        {isLoading ? (
          <PartnersDevicesTableSkeleton />
        ) : (
          <Box display="flex" flexDirection="column" gap={1}>
            {!devicesToDisplay.length ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={1}
                height="500px"
              >
                <Box borderRadius="100%" border="3px solid #386957" p={3}>
                  <img src={checkIcon} alt="check" />
                </Box>
                <Typography variant="h3">{t('nothingToShow', 'Nothing to show')}</Typography>
                {activeTab === DevicesDashboardTab.NEEDS_ATTENTION && (
                  <Typography variant="p14">
                    {t('allDevicesAreWorkingAsExpected', 'All devices are working as expected')}
                  </Typography>
                )}
              </Box>
            ) : (
              devicesToDisplay.map((device) => (
                <Box
                  key={device.uuid}
                  display="flex"
                  alignItems="center"
                  gap={1}
                  py={1}
                  px={2}
                  sx={{
                    borderBottom: '1px solid #E0E0E0',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <PartnerDeviceContent device={device} />
                  <Box display="flex" flexDirection="column" justifyContent="center" width={0.5}>
                    {device.connectors.map((connector) => (
                      <ConnectorStatusContent key={connector.connectorId || device.uuid} connector={connector} />
                    ))}
                  </Box>
                  <UserFavoriteDeviceStar device={device} />
                </Box>
              ))
            )}
          </Box>
        )}
      </CardContainer>
    </Box>
  );
};
