import { Skeleton, Table, TableBody, TableCell, TableContainer } from '@mui/material';

import { CardContainer } from '@components/atoms/CardContainer';
import { StyledSkeletonTableRow } from '@components/atoms/StyledTableRow';

export const RfidCardTableRowSkeleton = () => (
  <StyledSkeletonTableRow>
    <TableCell width={120}>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
    <TableCell width={160}>
      <Skeleton variant="text" animation="wave" />
    </TableCell>
  </StyledSkeletonTableRow>
);

export const RfidCardTableSkeleton = () => {
  return (
    <TableContainer component={CardContainer} sx={{ p: 0 }}>
      <Table>
        <TableBody>
          {Array.from(Array(5).keys()).map(() => (
            <RfidCardTableRowSkeleton key={self.crypto.randomUUID()} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
