import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { FilterMenuOption } from '@components/filters';
import { ReactComponent as ChipIcon } from '@images/icons/Chip.svg';
import { ReactComponent as NotificationIcon } from '@images/icons/Notification.svg';
import { ReactComponent as OutlinedStarIcon } from '@images/icons/Star_Outlined.svg';
import { ReactComponent as MapIcon } from '@images/icons/nav/Map.svg';
import { ReactComponent as ChargerIcon } from '@images/icons/reporting/charger.svg';
import { ReactComponent as RFIDIcon } from '@images/icons/reporting/rfid.svg';
import { ReactComponent as SiteIcon } from '@images/icons/reporting/site.svg';
import { ReactComponent as TypeIcon } from '@images/icons/reporting/type.svg';
import { ReactComponent as UserIcon } from '@images/icons/reporting/user.svg';
import { ReactComponent as VehicleIcon } from '@images/icons/reporting/vehicle.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';

export const useFilterMenu = <ExtractedType extends FilterMenuOption>(
  filterOptionsMap: Partial<Record<ExtractedType, StyledMenuItemProps[]>>,
) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<FilterMenuOption>();

  const handleMenuClick = (option: FilterMenuOption) => {
    return () => {
      setSelectedFilter(option);
    };
  };

  const filterLabel: Record<FilterMenuOption, string> = {
    favorite: t('favorites', 'Favorites'),
    status: t('status', 'Status'),
    workspace: t('workspace', 'Workspace'),
    site: t('site', 'Site'),
    charger: t('charger', 'Charger'),
    vehicle: t('vehicle', 'Vehicle'),
    user: t('user', 'User'),
    rfid: t('rfid', 'RFID'),
    type: t('type', 'Type'),
    firmware: t('firmware', 'Firmware'),
    category: t('category', 'Category'),
  };

  const filterPluralLabels: Record<FilterMenuOption, string> = {
    favorite: t('favorites', 'Favorites'),
    status: t('statuses', 'Statuses'),
    workspace: t('workspaces', 'Workspaces'),
    site: t('sites', 'Sites'),
    charger: t('chargers', 'Chargers'),
    vehicle: t('vehicles', 'Vehicles'),
    user: t('users', 'Users'),
    rfid: t('rfidIdentifiers', 'RFID identifiers'),
    type: t('types', 'Types'),
    firmware: t('firmware', 'Firmware'),
    category: t('categories', 'Categories'),
  };

  const allFilterMenuOptions: Record<FilterMenuOption, StyledMenuItemProps> = {
    favorite: {
      uuid: 'filter',
      label: filterLabel.favorite,
      Icon: () => <OutlinedStarIcon width={16} height={16} />,
      onClick: handleMenuClick('favorite'),
    },
    status: { uuid: 'status', label: filterLabel.status, Icon: NotificationIcon, onClick: handleMenuClick('status') },
    workspace: {
      uuid: 'workspace',
      label: filterLabel.workspace,
      Icon: () => (
        <MapIcon
          height={16}
          width={16}
          style={{
            minWidth: '16px',
          }}
        />
      ),
      onClick: handleMenuClick('workspace'),
    },
    site: { uuid: 'site', label: filterLabel.site, Icon: SiteIcon, onClick: handleMenuClick('site') },
    charger: { uuid: 'charger', label: filterLabel.charger, Icon: ChargerIcon, onClick: handleMenuClick('charger') },
    vehicle: { uuid: 'vehicle', label: filterLabel.vehicle, Icon: VehicleIcon, onClick: handleMenuClick('vehicle') },
    user: { uuid: 'user', label: filterLabel.user, Icon: UserIcon, onClick: handleMenuClick('user') },
    rfid: { uuid: 'rfid', label: filterLabel.rfid, Icon: RFIDIcon, onClick: handleMenuClick('rfid') },
    type: { uuid: 'type', label: filterLabel.type, Icon: TypeIcon, onClick: handleMenuClick('type') },
    firmware: { uuid: 'firmware', label: filterLabel.firmware, Icon: ChipIcon, onClick: handleMenuClick('firmware') },
    category: { uuid: 'category', label: filterLabel.category, Icon: TypeIcon, onClick: handleMenuClick('category') },
  };

  const filterMenuOptions: StyledMenuItemProps[] = Object.keys(filterOptionsMap)
    .filter((key) => isSubdomainPartner() || key !== 'workspace')
    .map((key) => allFilterMenuOptions[key as FilterMenuOption]);

  return {
    selectedFilter,
    setSelectedFilter,
    filterLabel,
    filterPluralLabels,
    filterMenuOptions,
    allFilterMenuOptions,
  };
};
