const buildTarifEndpoints = (builder) => ({
  tarif: builder.query({
    query: (params) => ({
      url: `/graphs/hourly-prices${
        params?.startDate && params?.endDate ? `?startDate=${params.startDate}&endDate=${params.endDate}` : ''
      }`,
      method: 'GET',
    }),
  }),
});

export default buildTarifEndpoints;
