import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';

import { Card } from '@components/atoms/Card';
import ComponentError from '@components/atoms/ComponentError';
import apiSlice from '@services/api';
import { DurationKey, getFormattedStartTimeForDuration } from '@services/transactions/chartDataParser';

import { VehiclesSummary } from './VehiclesSummary';
import { VehiclesTable } from './VehiclesTable';

export const Vehicles = () => {
  const { t } = useTranslation();
  const setLoading = useOutletContext<Dispatch<SetStateAction<boolean>>>();

  const [selectedDuration, setSelectedDuration] = useState<DurationKey>();
  const [error, setError] = useState(null);

  const periodStartTime = useMemo(() => getFormattedStartTimeForDuration(selectedDuration), [selectedDuration]);

  const [getBrandLoginLink] = apiSlice.useGetLoginLinkPageVehicleMutation();

  const { isLoading: isLoadingVehicles, error: errorVehicles } = apiSlice.useVehiclesQuery(
    {
      periodStartTime,
      withOdometerSummary: true,
      withChargingSessionsSummary: true,
    },
    {
      skip: !periodStartTime,
      refetchOnMountOrArgChange: true,
    },
  );

  const { isLoading: isLoadingChargingSessions, error: errorChargingSessions } =
    apiSlice.useGetSmartChargingSessionsSummaryQuery(
      {
        periodStartTime,
      },
      {
        skip: !periodStartTime,
        refetchOnMountOrArgChange: true,
      },
    );

  const { isLoading: isLoadingVehiclesMileageSummary, error: errorVehiclesMileageSummary } =
    apiSlice.useGetVehiclesMileageSummaryQuery(
      {
        periodStartTime,
      },
      {
        skip: !periodStartTime,
        refetchOnMountOrArgChange: true,
      },
    );
  const isLoading = isLoadingVehicles || isLoadingChargingSessions || isLoadingVehiclesMileageSummary;
  const finalError = errorVehicles || errorChargingSessions || errorVehiclesMileageSummary || error;

  const handleAddVehicle = async () => {
    try {
      setLoading(true);
      const response = (await getBrandLoginLink({ brand: 'enode', provider: 'enode' }).unwrap()) as {
        data: { url: string };
      };
      window.location.href = response.data.url;
    } catch (e: any) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(isLoading);

    return () => {
      setLoading(false);
    };
  }, [isLoading]);

  if (finalError) {
    return (
      <Card title={t('vehicles', 'Vehicles')} className="w-full">
        <ComponentError error={finalError} />
      </Card>
    );
  }

  return (
    <Box position="relative">
      <VehiclesSummary selectedDuration={selectedDuration} setSelectedDuration={setSelectedDuration} />
      <VehiclesTable onAddVehicle={handleAddVehicle} />
    </Box>
  );
};
