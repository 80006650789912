import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Device, UpdateDeviceDataValues } from 'models/device';
import { DeviceStatus } from 'models/device.enums';

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Box, Tooltip, Typography } from '@mui/material';

import { FormCheckbox } from '@components/atoms/FormCheckbox';
import { FormTextField } from '@components/atoms/FormTextField';
import { RadioButton } from '@components/atoms/RadioButton';
import { FormAutocomplete } from '@components/atoms/StyledAutocomplete';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { selectUser } from '@services/auth/selectors';
import { selectLmcMultipointsBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { isVoolDevice } from '@views/Sites/SiteDevicesAndGroups/siteDevicesAndGroupsHelpers/siteDevicesUtils';

import { useChargerSettingsForm } from './useChargerSettingsForm';

type ChargerSettingsFormSidebarProps = {
  device: Device;
  isUpdateLoading: boolean;
  isUpdateError: boolean;
  onSaveValues: (props: UpdateDeviceDataValues) => Promise<boolean>;
};

export const ChargerSettingsFormSidebar = ({
  device,
  isUpdateLoading,
  isUpdateError,
  onSaveValues,
}: ChargerSettingsFormSidebarProps) => {
  const { t } = useTranslation();

  const lmcMultipoints = useAppSelector((state) => selectLmcMultipointsBySiteUuid(state, device.siteUuid));

  const isOffline = device.status === DeviceStatus.OFFLINE;

  const { form, submitChargerSettings } = useChargerSettingsForm({ device, onSaveValues });
  const { control, formState, setValue, clearErrors, watch } = form;
  const selectedLmcMultipoint = watch('lmcMultipoint');
  const selectedCommunicatesViaLmc = watch('communicatesViaLmc');
  const defaultIdTagEnabled = watch('defaultIdTagEnabled');

  const user = useAppSelector(selectUser);
  const isAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const canChangeCommunicatesViaLmc = isAdmin || isInstaller;
  const lmcMultipointOptions = useMemo(
    () => [
      { label: t('noGroup', 'No group'), value: null },
      ...lmcMultipoints.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    ],
    [lmcMultipoints],
  );

  return (
    <EditingSidebarBase
      title={t('settings', 'Settings')}
      subtitle={device.name || device.serialNumber}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      anyDataChanged={formState.isDirty}
      onSaveValues={submitChargerSettings}
      updateLoading={isUpdateLoading}
      updateFailed={isUpdateError}
      isSubSidebar
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <Box display="flex" flexDirection="column" gap={3}>
          <FormTextField
            label={`${t('deviceName', 'Device name')} (${t('optional', 'Optional').toLowerCase()})`}
            control={control}
            name="name"
          />
          <FormTextField
            label={
              <Box display="flex" flexDirection="row">
                {t('currentLimit', 'Current limit')}
                {!isVoolDevice(device) && (
                  <Tooltip
                    title={t(
                      'visualEffectRadialChartsText',
                      'Changing this setting will only have a visual effect on radial charts.',
                    )}
                    placement="top"
                  >
                    <QuestionMarkCircleIcon className="ml-1 h-4 w-4 text-gray-400" />
                  </Tooltip>
                )}
              </Box>
            }
            name="gridConnection"
            InputProps={{
              endAdornment: <Typography variant="p14">A</Typography>,
            }}
            type="number"
            disabled={isVoolDevice(device) && isOffline}
            helperText={
              formState.errors?.gridConnection?.message ??
              (!isVoolDevice(device) ? t('forVisualPurposesOnly', 'For visual purposes only.') : null)
            }
            control={control}
          />
          {isVoolDevice(device) && (
            <>
              <FormCheckbox
                control={control}
                name="chargeEnabled"
                title={t('enabled', 'Enabled')}
                disabled={isOffline}
              />
              <FormCheckbox
                control={control}
                name="autoStart"
                title={t('autoStart', 'Auto start')}
                disabled={isOffline}
              />
            </>
          )}
          <FormCheckbox control={control} name="defaultIdTagEnabled" title={t('defaultIdTag', 'Default ID tag')} />
          {defaultIdTagEnabled && (
            <FormTextField
              name="defaultIdTag"
              label={t('defaultIdTag', 'Default ID tag')}
              type="text"
              control={control}
            />
          )}
        </Box>
        {isVoolDevice(device) && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p16b">{t('loadManagement', 'Load management')}</Typography>
            <FormAutocomplete
              name="lmcMultipoint"
              control={control}
              label={t('controller/group', 'Controller/Group')}
              options={lmcMultipointOptions}
            />
          </Box>
        )}
        {canChangeCommunicatesViaLmc && !!selectedLmcMultipoint?.value && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p16b">{t('ocppSource', 'OCPP source')}</Typography>
            <RadioButton
              value="0"
              checked={!selectedCommunicatesViaLmc}
              label={t('internal', 'Internal')}
              name="communicatesViaLmc"
              helpText={t(
                'ocppSourceInternalText',
                'Charger itself is connected to the Internet, and it uses OCPP 1.6.',
              )}
              onChange={() => {
                clearErrors();
                setValue('communicatesViaLmc', 0, { shouldDirty: true });
              }}
            />
            <RadioButton
              value="1"
              checked={!!selectedCommunicatesViaLmc}
              label={t('lmc', 'LMC')}
              name="communicatesViaLmc"
              helpText={t(
                'ocppSourceLmcText',
                'Charger is not connected to Internet, LMC proxies OCPP 2.0 connection for the charger.',
              )}
              onChange={() => {
                clearErrors();
                setValue('communicatesViaLmc', 1, { shouldDirty: true });
              }}
            />
          </Box>
        )}
      </Box>
    </EditingSidebarBase>
  );
};
