import { useTranslation } from 'react-i18next';

import { WorkspaceSetupSidebarBase } from '../setup/WorkspaceSetupSidebarBase';
import { PayoutDetails } from './PayoutDetails';

export const PayoutDetailsSidebar = () => {
  const { t } = useTranslation();

  return (
    <WorkspaceSetupSidebarBase title={t('payoutDetails', 'Payout details')}>
      <PayoutDetails />
    </WorkspaceSetupSidebarBase>
  );
};
