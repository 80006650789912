export enum Permission {
  CAN_SEE_USERS = 'can_see_users',
  CAN_ADD_USERS = 'can_add_users',
  CAN_EDIT_USERS = 'can_edit_users',
  CAN_DELETE_USERS = 'can_delete_users',
  CAN_SEE_SITES = 'can_see_sites',
  CAN_ADD_SITES = 'can_add_sites',
  CAN_EDIT_SITES = 'can_edit_sites',
  CAN_EDIT_SITE_SETTINGS = 'can_edit_site_settings',
  CAN_DELETE_SITES = 'can_delete_sites',
  CAN_SEE_DEVICES = 'can_see_devices',
  CAN_SEE_DEVICE_OCPP_VARIABLES = 'can_see_device_ocpp_variables',
  CAN_ADD_DEVICES = 'can_add_devices',
  CAN_EDIT_DEVICES = 'can_edit_devices',
  CAN_EDIT_DEVICE_OCPP_VARIABLES = 'can_edit_device_ocpp_variables',
  CAN_AUTO_CONF_DEVICES = 'can_auto_conf_devices',
  CAN_DELETE_DEVICES = 'can_delete_devices',
  CAN_RESTART_DEVICES = 'can_restart_devices',
  CAN_UPDATE_DEVICE_FIRMWARE = 'can_update_device_firmware',
  CAN_SEE_COMPANY_CARS = 'can_see_company_cars',
  CAN_ADD_COMPANY_CARS = 'can_add_company_cars',
  CAN_EDIT_COMPANY_CARS = 'can_edit_company_cars',
  CAN_DELETE_COMPANY_CARS = 'can_delete_company_cars',
  CAN_SEE_RFID_CARDS = 'can_see_rfid_cards',
  CAN_ADD_RFID_CARDS = 'can_add_rfid_cards',
  CAN_EDIT_RFID_CARDS = 'can_edit_rfid_cards',
  CAN_DELETE_RFID_CARDS = 'can_delete_rfid_cards',
  CAN_EDIT_COMPANY = 'can_edit_company',
  CAN_SEE_COMPANY_REPORTS = 'can_see_company_reports',
  CAN_SEE_USER_REPORTS = 'can_see_user_reports',
  CAN_USE_CHARGING = 'can_use_charging',
}
