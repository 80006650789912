import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

const selectSlice = (state: RootState) => state.users;
export const selectUsers = createAppSelector(selectSlice, (slice) => slice.users);
export const selectBoUsers = createAppSelector(selectSlice, (slice) => slice.boUsers);

export const makeSelectUserByUuid = () => {
  const selectUserByUuid = createAppSelector(
    [(state: RootState) => state.users.users, (state: RootState, uuid: string) => uuid],
    (users, uuid) => users.find((user) => user.uuid === uuid),
  );
  return selectUserByUuid;
};
