import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SettingsSectionItem } from '@components/settings/SettingsSectionItem';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { useLazyGetLmcMultipointsQuery } from '@services/devices/endpoints';
import { selectLmcMultipointsBySiteUuid, selectLmcsBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';

import { DeviceSettingsProps } from './DeviceSettings';

export const VoolChargerSettings = ({ device, isLoading }: DeviceSettingsProps) => {
  const { t } = useTranslation();
  const lmcDevices = useAppSelector((state) => selectLmcsBySiteUuid(state, device.siteUuid));
  const lmcMultipoints = useAppSelector((state) => selectLmcMultipointsBySiteUuid(state, device.siteUuid));

  const [getLmcMultipoints] = useLazyGetLmcMultipointsQuery();

  const lmcDeviceUuids = lmcDevices.map((lmc) => lmc.uuid);
  const deviceConfiguration = device.configuration ?? {};

  useEffect(() => {
    lmcDevices.forEach((lmc) => {
      const lmcMultipoint = lmcMultipoints.find((mp) => mp.lmcDeviceUuid === lmc.uuid);
      if (!lmcMultipoint) {
        getLmcMultipoints(lmc.uuid);
      }
    });
  }, [lmcDeviceUuids.join(','), lmcDevices.length]);

  const selectedMultipoint = useMemo(
    () => lmcMultipoints.find((mp) => mp.uuid === device.lmcMultipointUuid),
    [lmcMultipoints, device.lmcMultipointUuid],
  );

  return (
    <>
      <SettingsSectionItem
        label={t('deviceEnabled', 'Device enabled')}
        component={() => {
          const deviceEnabled = deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.CHARGER_ENABLED.key];
          if (isLoading || !deviceEnabled) {
            return undefined;
          }
          return deviceEnabled?.value ? t('on', 'On') : t('off', 'Off');
        }}
      />
      <SettingsSectionItem
        label={t('autoStart', 'Auto start')}
        component={() => {
          const autoStart = deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.AUTOMATICALLY_START_CHARGING.key];
          if (isLoading || !autoStart) {
            return undefined;
          }
          return autoStart?.value ? t('on', 'On') : t('off', 'Off');
        }}
      />
      <SettingsSectionItem
        label={t('controller/group', 'Controller/Group')}
        component={() => selectedMultipoint?.name || t('noGroup', 'No group')}
        hidden={!lmcMultipoints.length}
      />
    </>
  );
};
