import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import arrowLeftIcon from '@images/icons/arrow_left.svg';

import SearchResultItem from './SearchResultItem';
import SearchResultItemBase from './SearchResultItemBase';
import SearchTypeBadge from './SearchTypeBadge';
import {
  SUPPORTED_FILTER_TYPES,
  setOrToggleQueryFilterType as setOrToggleQueryFilterTypeUtil,
} from './searchQueryUtils';
import useGroupedSearchResults from './useGroupedSearchResults';

const SearchResults = ({
  searchQuery: fullSearchQuery,
  setSearchQuery,
  selectedResultUuid,
  setSelectedResultUuid,
  closeModal,
  keyboardTargetElem,
}) => {
  const [filteredType, searchQuery, groupedResults] = useGroupedSearchResults({ fullSearchQuery });
  const navigatableItemsRef = useRef([]);
  const selectedResult = useMemo(
    () => selectedResultUuid && navigatableItemsRef.current.find((result) => result.uuid === selectedResultUuid),
    [groupedResults],
  );
  const selectedResultRef = useRef(null);
  selectedResultRef.current = selectedResult;
  const [selectedResultElem, setSelectedResultElem] = useState(null);
  const scrollableContainerRef = useRef(null);
  const lastKeyboardNavAtRef = useRef(null);

  const setOrToggleQueryFilterType = (type) => setSearchQuery(setOrToggleQueryFilterTypeUtil(fullSearchQuery, type));

  useEffect(() => {
    if (!selectedResult && navigatableItemsRef.current.length) {
      setSelectedResultUuid(navigatableItemsRef.current[0].uuid);
    }
  }, [selectedResult, groupedResults]);

  const selectPreviousItem = useCallback(() => {
    if (navigatableItemsRef.current.length) {
      let newIndex = selectedResultRef.current ? selectedResultRef.current.index - 1 : 0;
      if (newIndex < 0) {
        newIndex += navigatableItemsRef.current.length;
      }
      setSelectedResultUuid(navigatableItemsRef.current[newIndex]?.uuid);
      lastKeyboardNavAtRef.current = Date.now();
    }
  }, []);

  const selectNextItem = useCallback(() => {
    if (navigatableItemsRef.current.length) {
      const newIndex = selectedResultRef.current
        ? (selectedResultRef.current.index + 1) % navigatableItemsRef.current.length
        : 0;
      setSelectedResultUuid(navigatableItemsRef.current[newIndex]?.uuid);
      lastKeyboardNavAtRef.current = Date.now();
    }
  }, []);

  const handleItemHoverByUuid = (uuid) => {
    const lastKeyboardNavAt = lastKeyboardNavAtRef.current;
    if (!lastKeyboardNavAt || Date.now() - lastKeyboardNavAt > 250) {
      setSelectedResultUuid(uuid);
    }
  };

  const goToSelectedItem = useCallback(() => {
    const { current: selResult } = selectedResultRef;
    if (!selResult) {
      return;
    }
    if (selResult.filterTypeForAll) {
      setOrToggleQueryFilterType(selResult.filterTypeForAll);
    } else {
      selResult.group.onItemClick(selResult);
      closeModal();
    }
  }, []);

  const getUuidForAllItem = (group) => `all-of-${group.type}`;

  useEffect(() => {
    navigatableItemsRef.current = groupedResults
      .flatMap((group) =>
        group.results
          .map((result) => ({ group, ...result }))
          .concat(
            group.totalCount > group.results.length
              ? [{ uuid: getUuidForAllItem(group), filterTypeForAll: group.type }]
              : [],
          ),
      )
      .map((item, index) => ({ ...item, index }));
  }, [groupedResults]);

  useEffect(() => {
    if (!keyboardTargetElem) {
      return () => {};
    }

    const handleNavigationKeys = (event) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault();
        selectPreviousItem();
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        selectNextItem();
      } else if (event.key === 'Enter') {
        goToSelectedItem();
      }
    };

    keyboardTargetElem.addEventListener('keydown', handleNavigationKeys);
    return () => {
      keyboardTargetElem.removeEventListener('keydown', handleNavigationKeys);
    };
  }, [keyboardTargetElem]);

  useEffect(() => {
    if (!selectedResultElem || !scrollableContainerRef.current) {
      return;
    }
    const itemRect = selectedResultElem.getBoundingClientRect();
    const containerRect = scrollableContainerRef.current.getBoundingClientRect();
    if (itemRect.bottom > containerRect.bottom || itemRect.top < containerRect.top) {
      selectedResultElem.scrollIntoView({
        block: itemRect.top < containerRect.top ? 'start' : 'end',
        inline: 'nearest',
      });
    }
  }, [selectedResultElem]);

  const getTotalCountByType = (type) => {
    const countByType = groupedResults.find((group) => group.type === type)?.totalCount;
    if (countByType) {
      return countByType;
    }
    if (!countByType && type === filteredType) {
      return 0;
    }
    return null;
  };

  const isNoResults = !!searchQuery.trim() && !groupedResults.some((group) => group.results.length);

  return (
    <>
      {isNoResults ? (
        <div className="px-4">
          There are no results for <em>“{searchQuery}”</em>
        </div>
      ) : (
        <div className="mb-6 flex gap-2 px-4">
          {SUPPORTED_FILTER_TYPES.map((type) => (
            <SearchTypeBadge
              key={type}
              type={type}
              count={getTotalCountByType(type)}
              className={`flex-1 ${filteredType && filteredType !== type ? 'opacity-50' : ''}`}
              onClick={() => setOrToggleQueryFilterType(type)}
            />
          ))}
        </div>
      )}
      {/* FIXME: get rid of this calc() trickery */}
      <div className="max-h-[calc(100vh-370px)] overflow-auto pl-4" ref={scrollableContainerRef}>
        {groupedResults
          .filter((group) => group.results.length)
          .map((group) => (
            <div key={group.type} className="mt-6 first:mt-0">
              {group.results.map((result) => (
                <SearchResultItem
                  key={result.uuid}
                  type={group.type}
                  name={result.name}
                  searchQuery={searchQuery}
                  isSelected={selectedResultUuid === result.uuid}
                  {...(selectedResultUuid === result.uuid && { ref: setSelectedResultElem })}
                  onHover={() => handleItemHoverByUuid(result.uuid)}
                  onClick={() => {
                    group.onItemClick(result);
                    closeModal();
                  }}
                />
              ))}
              {group.totalCount > group.results.length && (
                <SearchResultItemBase
                  isSelected={selectedResultUuid === getUuidForAllItem(group)}
                  className="w-full py-2 pl-10 pr-2 text-left text-gray-600"
                  {...(selectedResultUuid === getUuidForAllItem(group) && { ref: setSelectedResultElem })}
                  onHover={() => handleItemHoverByUuid(getUuidForAllItem(group))}
                  onClick={() => setOrToggleQueryFilterType(group.type)}
                >
                  <img src={arrowLeftIcon} alt="" className="float-right -scale-x-100" />
                  See all {group.totalCount} {group.type}s
                </SearchResultItemBase>
              )}
            </div>
          ))}
      </div>
    </>
  );
};

SearchResults.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  selectedResultUuid: PropTypes.string,
  setSelectedResultUuid: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  keyboardTargetElem: PropTypes.object,
};

SearchResults.defaultProps = {
  selectedResultUuid: null,
  keyboardTargetElem: null,
};

export default SearchResults;
