import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Permission } from 'models/permission.enum';
import { RFIDCard } from 'models/rfid-card';

import { TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import apiSlice from '@services/api';
import { selectPermissionByName } from '@services/auth/selectors';
import { selectActiveCompanyUuid } from '@services/companies/selectors';
import { useAppDispatch, useAppSelector } from '@services/hooks';
import { useDeleteRfidCardMutation, useGetRfidCardsQuery } from '@services/rfidCards/endpoints';
import { selectRfidCards } from '@services/rfidCards/selectors';
import { addToastMessage } from '@services/toastMessages';
import { selectUsers } from '@services/users/selectors';
import { selectPrivateVehiclesOrByCompanyUuid } from '@services/vehicles/selectors';
import { Menu } from '@views/reporting/table/Menu';

import { RfidCardTableRowSkeleton, RfidCardTableSkeleton } from './RfidCardTableSkeleton';
import { useGetRfidCardAssigneeData } from './useGetRfidCardAssigneeData';

type RfidCardsWithAssignee = RFIDCard & {
  assigneeLabel: JSX.Element | string;
  AssigneeComponent: () => JSX.Element | string;
};

type RfidColumnData = ColumnData<RfidCardsWithAssignee>;

type RenderRowsProps = {
  rows: RfidCardsWithAssignee[];
  columns: RfidColumnData[];
};

type TableRowProps = Omit<RenderRowsProps, 'rows'> & {
  row: RfidCardsWithAssignee;
};

const useColumns = (): RfidColumnData[] => {
  const { t } = useTranslation();
  const columns: RfidColumnData[] = [
    {
      dataKey: 'assigneeLabel',
      label: t('name/assignee', 'Name/Assignee'),
    },
    {
      dataKey: 'normalizedIdentifier',
      label: t('identifier', 'Identifier'),
      sortable: true,
    },
    {
      dataKey: 'menu',
      label: '',
    },
  ];

  return columns;
};

const renderRows = ({ rows, ...rest }: RenderRowsProps) =>
  rows.map((row) => <TableRow key={row.uuid} row={row} {...rest} />);

const TableRow = ({ row, columns }: TableRowProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const canEditRfidCards = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_RFID_CARDS));
  const canDeleteRfidCards = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_DELETE_RFID_CARDS));

  const [deleteRfidCard] = useDeleteRfidCardMutation();

  const rfidCardLabel = t('rfidCard', 'RFID card');

  const handleDeleteRfidCard = async (rfidCardUuid: string) => {
    try {
      await deleteRfidCard(rfidCardUuid).unwrap();
      dispatch(
        addToastMessage({ type: 'success', title: t('itemDeleted', '{{item}} deleted', { item: rfidCardLabel }) }),
      );
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletingTheItemFailed', 'Deleting the {{item}} failed', { item: rfidCardLabel }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  const getRfidMenuOptions = (rfidCard: RFIDCard): StyledMenuItemProps[] => [
    {
      title: t('editItem', 'Edit {{item}}', { item: rfidCardLabel }),
      key: 'editRfidCard',
      hidden: !canEditRfidCards,
      onClick: () => navigate(rfidCard.uuid),
    },
    {
      title: t('removeItem', 'Remove {{item}}', { item: rfidCardLabel }),
      className: 'text-vermillion',
      key: 'removeRfidCard',
      hidden: !canDeleteRfidCards,
      onClick: () => handleDeleteRfidCard(rfidCard.uuid),
    },
  ];

  return (
    <StyledTableRow key={row.uuid}>
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'assigneeLabel':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Link to={row.uuid} style={{ textDecoration: 'none', width: '100%' }} className="flex w-full">
                  <row.AssigneeComponent />
                </Link>
              </TableCell>
            );
          case 'normalizedIdentifier':
            return (
              <TableCell key={column.dataKey} align={column.numeric ? 'right' : 'left'} width={column.width}>
                <Link to={row.uuid} style={{ textDecoration: 'none', width: '100%' }} className="flex w-full">
                  <Typography variant="p14">{String(row[column.dataKey])}</Typography>
                </Link>
              </TableCell>
            );
          case 'menu':
            return (
              <TableCell key={column.dataKey} align="right" width={column.width}>
                <Menu menuOptions={getRfidMenuOptions(row)} />
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};

export const RfidCardsMaterialTable = () => {
  const columns = useColumns();
  const { companyUuid } = useParams();
  const activeCompanyUuid = useAppSelector(selectActiveCompanyUuid);
  const users = useAppSelector(selectUsers);
  const vehicles = useAppSelector((state) =>
    selectPrivateVehiclesOrByCompanyUuid(state, companyUuid ?? activeCompanyUuid),
  );
  const rfidCards = useAppSelector(selectRfidCards);

  const { isFetching: isFetchingVehicles } = apiSlice.useVehiclesQuery(companyUuid ? { companyUuid } : undefined);
  const { isFetching: isFetchingRfidCars } = useGetRfidCardsQuery(companyUuid ? { companyUuid } : undefined);
  const getRfidCardAssigneeData = useGetRfidCardAssigneeData();

  const rfidCardsWithAssignee = useMemo<RfidCardsWithAssignee[]>(
    () =>
      rfidCards.map((rfidCard) => {
        const assignedTo = getRfidCardAssigneeData({ rfidCard, users, vehicles });
        return {
          ...rfidCard,
          assigneeLabel: assignedTo.label,
          AssigneeComponent: () => assignedTo.content,
        };
      }),
    [rfidCards, users, vehicles],
  );

  return (
    <MaterialTable
      data={rfidCardsWithAssignee}
      isLoading={isFetchingRfidCars || isFetchingVehicles}
      headerProps={{
        columns,
        orderBy: 'normalizedIdentifier',
      }}
      renderRows={(rows, columns) => renderRows({ rows, columns })}
      loaders={{ TableLoader: RfidCardTableSkeleton, RowLoader: RfidCardTableRowSkeleton }}
    />
  );
};
