import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import { Typography } from '@mui/material';

import { StyledTextField, StyledTextFieldProps } from './StyledTextField';

type FormTextFieldProps<T extends FieldValues> = StyledTextFieldProps & {
  control: Control<T>;
  name: Path<T>;
};

export const FormTextField = <T extends FieldValues>({ control, name, isLoading, ...props }: FormTextFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <StyledTextField
          inputRef={ref}
          isLoading={isLoading}
          {...field}
          {...props}
          value={field.value ?? ''}
          error={Boolean(error)}
          helperText={error?.message || props.helperText}
          label={<Typography variant="p14">{props.label}</Typography>}
          inputProps={{
            ...props.inputProps,
            style: { padding: '8px 12px' },
          }}
        />
      )}
    />
  );
};
