import React from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment-duration-format';
import PropTypes from 'prop-types';

import { SummaryCard } from '@components/cards/SummaryCard';
import { TimePeriodSelectorHeader } from '@components/filterComponents/TimePeriodSelectorHeader';
import { useActiveCurrency } from '@hooks/useActiveCurrency';

const VehicleSummary = ({ vehicleSummary, selectedDuration, setSelectedDuration }) => {
  const { t } = useTranslation();
  const currency = useActiveCurrency();
  const getDurationContent = (minutes) => {
    const duration = moment.duration(minutes, 'minutes');
    if (duration) {
      return (
        <div className="flex items-center justify-start space-x-2">
          <div>
            {Math.floor(duration.asHours())}{' '}
            <span className="-ml-1 text-sm text-gray-400">{t('hoursAbbreviation', 'h')}</span>
          </div>
          <div>
            {duration.minutes()} <span className="-ml-1 text-sm text-gray-400">{t('minutesAbbreviation', 'm')}</span>
          </div>
          <div>
            {duration.seconds()} <span className="-ml-1 text-sm text-gray-400">{t('secondsAbbreviation', 's')}</span>
          </div>
        </div>
      );
    }
    return '-';
  };
  const data = {
    cost: {
      label: t('cost', 'Cost'),
      unit: currency.symbol,
      value: vehicleSummary?.totalMonetaryCost?.toFixed(2) ?? '-',
      tooltip: t('vatNotIncluded', 'VAT not included'),
    },
    consumption: {
      label: t('consumption', 'Consumption'),
      unit: 'kWh',
      value: vehicleSummary?.totalKwhAmount?.toFixed(2) ?? '-',
    },
    duration: {
      label: t('chargingDuration', 'Charging Duration'),
      unit: '',
      value: getDurationContent(vehicleSummary?.totalMinutes),
    },
    totalSessions: {
      label: t('sessions', 'Sessions'),
      unit: '',
      value: vehicleSummary?.totalSessions ?? 0,
      valueDecimalPlaces: 0,
    },
  };
  return (
    <div className="relative">
      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        labelPrefix={t('summaryFor', 'Summary for')}
        storageKey="vehiclesSummaryDuration"
        withoutAll
      />
      <div className="mb-4 flex flex-wrap items-stretch gap-4">
        {Object.entries(data).map(([key, value]) => (
          <SummaryCard
            key={key}
            label={value.label}
            unit={value.unit}
            value={value.value}
            valueDecimalPlaces={value?.valueDecimalPlaces ?? 2}
            tooltip={value.tooltip}
          />
        ))}
      </div>
    </div>
  );
};

VehicleSummary.propTypes = {
  vehicleSummary: PropTypes.shape({
    totalMonetaryCost: PropTypes.number,
    totalKwhAmount: PropTypes.number,
    totalMinutes: PropTypes.number,
    totalSessions: PropTypes.number,
  }),
  selectedDuration: PropTypes.string,
  setSelectedDuration: PropTypes.func,
};

VehicleSummary.defaultProps = {
  vehicleSummary: null,
  selectedDuration: null,
  setSelectedDuration: null,
};

export default VehicleSummary;
