import { ExclamationIcon } from '@heroicons/react/outline';
import { Box, Typography } from '@mui/material';

import { Modal, ModalProps } from './Modal';

export const ErrorModal = ({ children, ...props }: ModalProps) => (
  <Modal {...props}>
    <Box display="flex" gap={3}>
      <Typography color="#EB4E20">
        <ExclamationIcon height={24} width={24} />
      </Typography>
      <Box display="flex" flexDirection="column" gap={0.5}>
        {children}
      </Box>
    </Box>
  </Modal>
);
