import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Permission } from 'models/permission.enum';

import { IconButton, Tooltip } from '@mui/material';

import { MenuContainer } from '@components/atoms/MenuContainer';
import { StyledMenuItem, StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { ReactComponent as FileIcon } from '@images/icons/File.svg';
import { ReactComponent as ChargerIcon } from '@images/icons/reporting/charger.svg';
import { ReactComponent as SettingsIcon } from '@images/icons/reporting/settings.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { selectPermissionByName } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

export const WorkspaceSettingsButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const canEditCompany = useAppSelector((state) => selectPermissionByName(state, Permission.CAN_EDIT_COMPANY));
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const menuItems: StyledMenuItemProps[] = [
    {
      label: t('settings', 'Settings'),
      Icon: () => <SettingsIcon height={24} width={24} />,
      onClick: () => navigate('/workspace/settings'),
    },
    {
      label: t('invoices', 'Invoices'),
      Icon: () => <FileIcon height={24} width={24} />,
      onClick: () => navigate('/workspace/invoices'),
    },
    {
      label: t('subscriptions', 'Subscriptions'),
      Icon: () => <ChargerIcon height={24} width={24} />,
      onClick: () => navigate('/workspace/subscriptions'),
    },
  ];

  return (
    !isSubdomainPartner() &&
    canEditCompany && (
      <>
        <Tooltip placement="bottom" title={t('settings', 'Settings')}>
          <IconButton
            sx={{
              padding: 1.5,
              '&:hover': {
                backgroundColor: '#D6D6D6',
              },
            }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <SettingsIcon color="#282828" />
          </IconButton>
        </Tooltip>
        <MenuContainer
          horizontalOrigin="right"
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(undefined)}
          paperProps={{
            sx: {
              p: 1,
            },
          }}
        >
          {menuItems.map((item, index) => (
            <StyledMenuItem
              key={index}
              label={item.label}
              width="288px"
              height="48px"
              Icon={item.Icon}
              onClick={(e) => {
                setAnchorEl(undefined);
                item.onClick?.(e);
              }}
            />
          ))}
        </MenuContainer>
      </>
    )
  );
};
