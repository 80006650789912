import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PaymentMethod } from 'models/paymentMethod';

import { Box, Collapse, Typography, useTheme } from '@mui/material';

import { Spinner } from '@components/atoms/Spinner';
import { ReactComponent as ArrowDownIcon } from '@images/icons/ArrowDown.svg';
import { useGetCompanyPaymentMethodsQuery } from '@services/billing/endpoints';

import { AddPaymentMethodForm } from './AddPaymentMethodForm';
import { PaymentMethodItem } from './PaymentMethodItem';

type PaymentMethodSelectorProps = {
  paymentMethodId?: string;
  savePaymentMethodId: (paymentMethodId: string) => void;
  isLoading: boolean;
};

export const PaymentMethodSelector = ({
  paymentMethodId,
  savePaymentMethodId,
  isLoading,
}: PaymentMethodSelectorProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showAddForm, setShowAddForm] = useState(false);
  const [cardListOpen, setCardListOpen] = useState(false);
  const { isSuccess, isError, isFetching, data, refetch } = useGetCompanyPaymentMethodsQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const paymentMethods = (data as { paymentMethods?: PaymentMethod[] })?.paymentMethods ?? [];
  const selectedPaymentMethod = paymentMethods.find((method) => method.id === paymentMethodId);
  const hasPaymentMethods = isSuccess && !isFetching && paymentMethods.length > 0;
  const noPaymentMethods = isSuccess && !isFetching && paymentMethods.length === 0;

  if (showAddForm) {
    return (
      <AddPaymentMethodForm
        onSuccess={(setupIntent) => {
          setShowAddForm(false);
          refetch();
          if (typeof setupIntent.payment_method === 'string') {
            savePaymentMethodId(setupIntent.payment_method);
          }
        }}
        onCancel={() => setShowAddForm(false)}
      />
    );
  }

  return (
    <Box flex={1}>
      <Box
        className={`flex h-20 rounded-2xl transition-all duration-500 ${cardListOpen ? 'bg-gray-200' : 'bg-gray-100'}`}
        onClick={() => hasPaymentMethods && setCardListOpen(!cardListOpen)}
      >
        {isFetching && (
          <Box display="flex" flex={1} alignItems="center" justifyContent="center">
            <Spinner size={14} />
          </Box>
        )}
        {selectedPaymentMethod && !isFetching && <PaymentMethodItem paymentMethod={selectedPaymentMethod} isSelected />}
        {noPaymentMethods && (
          <Typography
            variant="p14"
            color={theme.palette.darkGray}
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
          >
            {t('noPaymentMethod', 'No payment method added')}
          </Typography>
        )}
        {hasPaymentMethods && !selectedPaymentMethod && (
          <Box display="flex" flex={1} alignItems="center" justifyContent="space-between" p={2} component="button">
            <Typography variant="p16b" color={theme.palette.darkGray}>
              {t('selectExistingPaymentMethod', 'Select an existing payment method')}
            </Typography>
            <ArrowDownIcon />
          </Box>
        )}
        {isError && (
          <Typography
            variant="p14"
            color={theme.palette.darkGray}
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="center"
          >
            {t('errorLoadingPaymentMethods', 'Error loading payment methods')}
          </Typography>
        )}
      </Box>
      {!isFetching && (
        <Box display="flex" flexDirection="column" gap={1}>
          <Collapse in={cardListOpen}>
            <Box className="mt-2 w-full rounded-2xl bg-white shadow">
              {paymentMethods.map((paymentMethod) => (
                <PaymentMethodItem
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  preventActions={isLoading}
                  checkIcon={paymentMethod.id === paymentMethodId}
                  onClick={() => !isLoading && savePaymentMethodId(paymentMethod.id)}
                  onPaymentMethodDeleted={refetch}
                />
              ))}
            </Box>
          </Collapse>
          <Typography
            variant="p16"
            component="button"
            alignSelf="flex-start"
            color={theme.palette.mediumVermilion}
            onClick={() => setShowAddForm(true)}
          >
            {t('addPaymentMethod', 'Add payment method')}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
