import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';

import { Dialog, Transition } from '@headlessui/react';

import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import apiSlice from '@services/api';

const LogTable = ({ logsArray }) => {
  const { t } = useTranslation();
  if (isEmpty(logsArray)) {
    return (
      <div className="text-md py-4 text-center text-gray-500 dark:text-warmgray-400">
        {t('noLogsToShow', 'No logs to show')}
      </div>
    );
  }

  return (
    <div className="overflow-scroll" style={{ maxHeight: 500 }}>
      <table className="mx-4 w-full text-sm">
        <thead className="border-b font-bold text-gray-500 dark:text-warmgray-400">
          <tr>
            <td>{t('description', 'Description')}</td>
            <td>{t('time', 'Time')}</td>
            <td>{t('type', 'Type')}</td>
          </tr>
        </thead>
        <tbody>
          {logsArray.map((log) => (
            <tr key={log.eventTime}>
              <td className="pt-1">{log.eventDescription || '-'}</td>
              <td className="pt-1">{moment(log.eventTime).format('YYYY/MM/DD H:mm') || '-'}</td>
              <td className="pt-1">{log.eventType || '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const VehicleChangelogModal = ({ vehicle, closeModal, modalOpen }) => {
  const { data, isLoading } = apiSlice.useGetChargingSessionsLogQuery({ carUuid: vehicle?.uuid }, { skip: !modalOpen });
  const logsArray = data?.data;

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={closeModal}>
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/50 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="relative inline-block transform overflow-hidden rounded-lg bg-white pb-2 text-left align-bottom shadow-xl transition-all dark:bg-truegray-800 sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
              style={{ maxHeight: '90vh' }}
            >
              <Dialog.Title>
                <div className="bg-white px-4 pb-4 pt-4 dark:bg-truegray-800 sm:p-4">
                  {`${vehicle?.name || vehicle?.model} Changelog: Last 7 days`}
                </div>
                {isLoading ? (
                  <div className="relative my-3" style={{ minHeight: 80 }}>
                    <OverflowSpinner />
                  </div>
                ) : (
                  <LogTable logsArray={logsArray || []} />
                )}
              </Dialog.Title>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

LogTable.propTypes = {
  logsArray: PropTypes.array,
};

LogTable.defaultProps = {
  logsArray: [],
};

VehicleChangelogModal.propTypes = {
  vehicle: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
};

export default VehicleChangelogModal;
