import { Box } from '@mui/material';

import { FiltersCard } from '@components/filters/FiltersCard';

import { InvoicesContext, InvoicesProvider } from '../context/InvoicesProvider';
import { InvoicesMaterialTable } from './InvoicesMaterialTable';

export const WorkspaceInvoicesView = () => {
  return (
    <InvoicesProvider>
      <Box display="flex" flexDirection="column" gap={2} pb={8}>
        <FiltersCard context={InvoicesContext} />
        <InvoicesMaterialTable />
      </Box>
    </InvoicesProvider>
  );
};
