import { useNavigate } from 'react-router-dom';

import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { useAppSelector } from '@services/hooks';
import { selectActiveCompanySites } from '@services/sites/selectors';

export const useNavigateToSites = () => {
  const navigate = useNavigate();
  const sites = useAppSelector(selectActiveCompanySites);
  const isSingleSiteAvailable = !isSubdomainPartner() && sites.length === 1;
  const sitesPath = isSingleSiteAvailable ? `/sites/${sites[0].uuid}` : '/sites';

  return {
    isSingleSiteAvailable,
    navigateToSites: () => navigate(sitesPath),
    sitesPath,
  };
};
