import { useEffect, useState } from 'react';

import { StyledMenuItemProps } from '@components/atoms/StyledMenuItem';
import { compareMenuOptions } from '@components/filters';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { useGetPartnerCompaniesQuery } from '@services/partners/endpoints';

export const useWorkspaceMenu = () => {
  const {
    data: partnerCompanies,
    isFetching,
    isError,
  } = useGetPartnerCompaniesQuery(undefined, { skip: !isSubdomainPartner() });

  const [workspaceMenuOptions, setWorkspaceMenuOptions] = useState<StyledMenuItemProps[]>([]);

  useEffect(() => {
    if (isSubdomainPartner() && !isFetching && !isError) {
      setWorkspaceMenuOptions(
        (prevState) =>
          partnerCompanies
            ?.map((company) => ({
              uuid: company.uuid,
              checked: prevState.find((option) => option.uuid === company.uuid)?.checked ?? false,
              label: company.name,
              subtitle: company.userName ?? company.userEmail,
              onClick: () => toggleOptionChecked(company.uuid),
            }))
            .sort(compareMenuOptions) ?? [],
      );
    }
  }, [isFetching]);

  const toggleOptionChecked = (selectedOptionUuid: string) => {
    setWorkspaceMenuOptions((prevOptions) =>
      prevOptions
        ?.map((option) => {
          if (option.uuid === selectedOptionUuid) {
            return { ...option, checked: !option.checked };
          }
          return option;
        })
        .sort(compareMenuOptions),
    );
  };

  return { workspaceMenuOptions, setWorkspaceMenuOptions };
};
