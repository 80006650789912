import { createSlice } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { User } from 'models/user';

import { usersApi } from './endpoints';

export interface UsersState {
  users: User[];
  boUsers: User[];
}

const initialState: UsersState = {
  users: [],
  boUsers: [],
};

const setUsers = (state: UsersState, { payload }: { payload: User[] }) => {
  state.users = payload;
};

const setBoUsers = (state: UsersState, { payload }: { payload: User[] }) => {
  state.boUsers = payload;
};

const updateUser = (state: UsersState, { payload }: { payload: User }) => {
  const user = state.users.find(({ uuid }) => uuid === payload.uuid);
  if (user) {
    Object.assign(user, payload);
  }
};

const updateUserFeatures = (state: UsersState, { payload }: { payload: User }) => {
  const user = state.boUsers.find(({ uuid }) => uuid === payload.uuid);
  if (user) {
    Object.assign(user, payload);
  }
};

const removeUser = (state: UsersState, { payload }: { payload: { userUuid: string } }) => {
  state.users = state.users.filter(({ uuid }) => uuid !== payload.userUuid);
};

const clearUsersIfForbidden = (state: UsersState, { payload }: { payload?: FetchBaseQueryError | string }) => {
  if (typeof payload === 'object' && payload?.status === 403) {
    state.users = [];
  }
};

const clearBoUsersIfForbidden = (state: UsersState, { payload }: { payload?: FetchBaseQueryError | string }) => {
  if (typeof payload === 'object' && payload?.status === 403) {
    state.boUsers = [];
  }
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.getUsers.matchFulfilled, setUsers);
    builder.addMatcher(usersApi.endpoints.getUsers.matchRejected, clearUsersIfForbidden);
    builder.addMatcher(usersApi.endpoints.getAllUsers.matchFulfilled, setBoUsers);
    builder.addMatcher(usersApi.endpoints.getAllUsers.matchRejected, clearBoUsersIfForbidden);
    builder.addMatcher(usersApi.endpoints.inviteUsersToWorkspace.matchFulfilled, setUsers);
    builder.addMatcher(usersApi.endpoints.updateUserRole.matchFulfilled, updateUser);
    builder.addMatcher(usersApi.endpoints.updateUserFeatures.matchFulfilled, updateUserFeatures);
    builder.addMatcher(usersApi.endpoints.removeUserFromWorkspace.matchFulfilled, removeUser);
  },
});

export default usersSlice.reducer;
