import { useTranslation } from 'react-i18next';

import { GetDeviceVariableResponseItem, SetVariableAttributeStatus } from 'models/device';

import { Box, TableCell, TextareaAutosize, Typography, styled } from '@mui/material';

import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';
import { ReactComponent as RebootRequiredIcon } from '@images/icons/RebootRequired.svg';
import checkIcon from '@images/icons/check.svg';

import { OcppVariableColumnData } from './OcppVariablesSidebar.common';

const VariableValueTextareaAutosize = styled(TextareaAutosize)({
  '&.changed:enabled': {
    borderColor: '#D6D6D6',
  },
  '&:enabled:hover, &:enabled:focus': {
    borderColor: '#999898',
  },
  backgroundColor: 'transparent',
  borderWidth: '2px',
  borderRadius: '8px',
  borderColor: 'transparent',
  textAlign: 'right',
  wordBreak: 'break-all',
  width: '100%',
  resize: 'none',
  boxShadow: 'none !important',
});

const FailedIcon = ({ title }: { title?: string }) => <CloseIcon className="[&_path]:fill-vermillion" title={title} />;

type OcppVariablesTableRowProps = {
  row: GetDeviceVariableResponseItem;
  columns: OcppVariableColumnData[];
  variableValue: string;
  onValueChange: (value: string) => void;
  variableStatus?: SetVariableAttributeStatus;
};

export const OcppVariablesTableRow = ({
  row,
  columns,
  variableValue,
  onValueChange,
  variableStatus,
}: OcppVariablesTableRowProps) => {
  const { t } = useTranslation();

  const getStatusIcon = () => {
    if (!variableStatus) {
      return null;
    }
    if (variableStatus === SetVariableAttributeStatus.ACCEPTED) {
      return <img src={checkIcon} width="24" alt="Accepted" title="Accepted" />;
    }
    if (variableStatus === SetVariableAttributeStatus.REBOOT_REQUIRED) {
      return (
        <Box sx={{ color: '#EB4E20' }}>
          <RebootRequiredIcon title="Reboot required" />
        </Box>
      );
    }
    return <FailedIcon title={variableStatus} />;
  };

  const needsAttention = variableStatus && variableStatus !== SetVariableAttributeStatus.ACCEPTED;
  const getVariableColor = () => {
    if (needsAttention) {
      return '#EB4E20';
    }
    if (row.readonly) {
      return '#999898';
    }
    return '#282828';
  };
  const variableColor = getVariableColor();

  const getStatusLabel = () => {
    if (!needsAttention) {
      return null;
    }
    if (variableStatus === SetVariableAttributeStatus.REBOOT_REQUIRED) {
      return t('rebootRequired', 'Reboot required');
    }
    return t('failed', 'Failed');
  };

  return (
    <StyledTableRow key={row.variable}>
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'variable':
            return (
              <TableCell key={column.dataKey} sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <Typography color={variableColor} sx={{ wordBreak: 'break-word' }}>
                  {row.variable}
                </Typography>
                {needsAttention && <Typography color="#999898">{getStatusLabel()}</Typography>}
              </TableCell>
            );
          case 'attributeValue':
            return (
              <TableCell key={column.dataKey} align="right" sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                <VariableValueTextareaAutosize
                  className={(row.attributeValue ?? '') !== (variableValue ?? '') ? 'changed' : ''}
                  value={variableValue ?? ''}
                  sx={{ color: variableColor, marginBottom: '-5px', marginRight: '-14px' }}
                  disabled={!!row.readonly}
                  onChange={(e) => onValueChange(e.target.value.replaceAll('\n', ''))}
                />
              </TableCell>
            );
          case 'menu':
            return (
              <TableCell key={column.dataKey} width="60px" sx={{ paddingTop: '8px', paddingBottom: '8px' }}>
                {getStatusIcon()}
              </TableCell>
            );
        }
      })}
    </StyledTableRow>
  );
};
