import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import moment from 'moment';

import { Spinner } from '@components/atoms/Spinner';
import { MenuComponent } from '@components/dropdown/MenuComponent';
import SortableTable from '@components/tables/SortableTable';
import kebabIcon from '@images/icons/Kebab.svg';
import { useGetAllUsersQuery, useUpdateUserFeaturesMutation } from '@services/users/endpoints';
import { selectBoUsers } from '@services/users/selectors';

import UserFilters from './UserFilters';

const BackofficeUsersView = () => {
  const { t } = useTranslation();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [togglingUserUuid, setTogglingUserUuid] = useState();

  const boUsers = useSelector(selectBoUsers);

  const { isLoading } = useGetAllUsersQuery();
  const [updateUserFeatures, { isLoading: isToggling }] = useUpdateUserFeaturesMutation();

  const toggleInstaller = (user) => {
    setTogglingUserUuid(user.uuid);
    updateUserFeatures({
      uuid: user.uuid,
      features: {
        isInstaller: !user.features?.isInstaller,
      },
    });
  };

  const getInstallerState = (user) =>
    user?.features?.isInstaller ? (
      <div className="rounder-corners flex h-6 w-6 items-center justify-center rounded-full bg-forest px-1 text-white">
        ✓
      </div>
    ) : (
      '-'
    );

  const getUserMenuOptions = (user) => [
    {
      title: t('enableInstaller', 'Enable installer mode'),
      key: 'enableInstaller',
      onClick: () => toggleInstaller(user),
      hidden: user.features?.isInstaller,
    },
    {
      title: t('disableInstaller', 'Disable installer mode'),
      key: 'disableInstaller',
      onClick: () => toggleInstaller(user),
      hidden: !user.features?.isInstaller,
    },
  ];

  const usersDataColumns = [
    {
      key: 'email',
      label: t('email', 'Email'),
      tdClassName: 'py-2 px-4',
      value: (user) => user?.email,
    },
    {
      key: 'name',
      label: t('name', 'Name'),
      tdClassName: 'py-2 px-4',
      value: (user) => user?.name,
    },
    {
      key: 'installer',
      label: t('Invite hook error', 'Installer'),
      tdClassName: 'py-2 px-4',
      value: (user) =>
        user.uuid === togglingUserUuid && isToggling ? (
          <div className="w-6">
            <Spinner size="6" />
          </div>
        ) : (
          getInstallerState(user)
        ),
    },
    {
      key: 'addTime',
      label: t('dateAdded', 'Date added'),
      tdClassName: 'py-2 px-4',
      value: (user) => {
        const date = moment.utc(user.addTime).local();

        return (
          <div className="text-sm leading-6">
            <div>{date.format('HH:mm')}</div>
            <div>{date.format('DD MMM YYYY')}</div>
          </div>
        );
      },
    },
    {
      key: 'menu',
      label: '',
      sortingDisabled: true,
      tdClassName: 'w-10 pr-1.5',
      value: (user) => <MenuComponent menuItems={getUserMenuOptions(user)} buttonIcon={kebabIcon} />,
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col gap-y-5">
      <UserFilters
        users={boUsers}
        onFilteredUsersChange={setFilteredUsers}
        onActiveFilterChange={setActiveFilters}
        onSearchQuery={setSearchQuery}
      />
      {activeFilters.length || searchQuery.length > 1 ? (
        <SortableTable columns={usersDataColumns} data={filteredUsers} keyAttr="uuid" sortingStorageKey="usersTable" />
      ) : (
        <div className="py-8 text-center">
          See all the installers by activating the filter or search users by email or name
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default BackofficeUsersView;
