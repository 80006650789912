import { GetDeviceVariableResponseItem } from 'models/device';

import { ColumnData } from '@components/atoms/MaterialTable';
import { ReactComponent as CloseIcon } from '@images/icons/Close.svg';

export type OcppVariableColumnData = ColumnData<GetDeviceVariableResponseItem>;

export const FailedIcon = ({ title }: { title?: string }) => (
  <CloseIcon className="[&_path]:fill-vermillion" title={title} />
);
