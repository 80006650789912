import Cookies from 'js-cookie';
import { Permission } from 'models/permission.enum';

import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

export const selectSlice = (state: RootState) => state.auth;

export const selectUser = createAppSelector(selectSlice, (slice) => slice.user);
export const selectToken = createAppSelector(
  selectSlice,
  (slice) => slice.token || Cookies.get('ad_token') || Cookies.get('ad_refreshToken') || null,
);
export const selectPermissions = createAppSelector(selectSlice, (slice) => slice.permissions);
export const selectPermissionByName = createAppSelector(
  selectPermissions,
  selectUser,
  (state: RootState, name: Permission) => name,
  (permissions, user, name) => permissions.includes(name) || !!user?.admin,
);
