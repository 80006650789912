import { createAppSelector } from '@services/hooks';
import { RootState } from '@services/store';

export const selectSlice = (state: RootState) => state.inverters;

export const selectInverters = createAppSelector(selectSlice, (slice) => slice.list);
export const selectInverterByUuid = createAppSelector(
  selectInverters,
  (state: RootState, uuid: string) => uuid,
  (inverters, uuid) => inverters.find((i) => i.uuid === uuid),
);
export const selectInvertersBy = createAppSelector(
  selectInverters,
  (state: RootState, siteUuid?: string) => siteUuid,
  (state: RootState, siteUuid?: string, companyUuid?: string) => companyUuid,
  (inverters, siteUuid, companyUuid) =>
    inverters.filter((i) => (!siteUuid || i.siteUuid === siteUuid) && (!companyUuid || i.companyUuid === companyUuid)),
);
export const selectInverterSummary = createAppSelector(selectSlice, (slice) => slice.inverterSummary);
