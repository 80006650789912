import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { RevenuePricingModel } from 'models/site';
import { FeatureId } from 'models/subscriptions';

import { makeChargerOption } from '@components/atoms/ChargerAutocomplete';
import { useForm } from '@hooks/useTypedForm';
import { getErrorMessage } from '@services/api/api-errors';
import { selectChargersBySiteUuid } from '@services/devices/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetSiteQuery, useUpdateSiteMutation, useUpdateSiteSettingsMutation } from '@services/sites/endpoints';
import { useGetFeaturesQuery } from '@services/subscriptions/endpoints';

import {
  SiteAvailabilityForm,
  siteAvailabilityFormDefaultValues,
  useSiteAvailabilitySchema,
} from './useSiteAvailabilitySchema';

export const useSiteAvailabilityForm = () => {
  const { siteUuid } = useParams();
  const schema = useSiteAvailabilitySchema();
  const form = useForm<SiteAvailabilityForm>({
    schema,
    defaultValues: siteAvailabilityFormDefaultValues,
  });

  const [updateFailed, setUpdateFailed] = useState(false);
  const [updateFailedMessage, setUpdateFailedMessage] = useState<string>();
  const [returnPath, setReturnPath] = useState<string>();
  const { handleSubmitAndResolve, reset } = form;

  const { data: site, isLoading: isSiteLoadings } = useGetSiteQuery(siteUuid ?? skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [updateSite, { isLoading: isUpdateSiteLoading }] = useUpdateSiteMutation();
  const [updateSiteSettings, { isLoading: isUpdateSettingsLoading }] = useUpdateSiteSettingsMutation();

  const { data: features } = useGetFeaturesQuery();

  const devices = useAppSelector((state) => selectChargersBySiteUuid(state, site?.uuid));
  // property v exists in backend but it is not allowed to be sent to backend
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { v, ...revPriceModel } = (site?.revenuePricingModel ?? {}) as RevenuePricingModel & {
    v: number;
  };

  useEffect(() => {
    if (site) {
      const reservationSettings = site?.settings?.chargerReservation ?? {};
      const parkingSettings = site?.settings?.parking ?? {};
      const isFixedInModel = revPriceModel?.setting !== 'market';
      const priceInModel = isFixedInModel ? revPriceModel?.fixed : revPriceModel?.margin;

      const publicChargers = devices.filter((device) => device.publicFlag).map(makeChargerOption);
      const chargerOptions = devices.map(makeChargerOption);

      reset({
        isPublic: !!site.publicFlag,
        isFixed: isFixedInModel,
        priceInput: priceInModel ? (priceInModel * 100).toString() : '',
        isReservationEnabled: !!reservationSettings.isEnabled,
        freeReservationMinutes: reservationSettings.freeMinutes?.toString() ?? '',
        reservationCentsPerMinute: reservationSettings.priceCentsPerMinute
          ? reservationSettings.priceCentsPerMinute.toString()
          : '',
        isParkingEnabled: !!parkingSettings.isEnabled,
        freeParkingMinutes: parkingSettings.freeMinutes?.toString() ?? '',
        parkingCentsPerMinute: parkingSettings.priceCentsPerMinute
          ? parkingSettings.priceCentsPerMinute.toString()
          : '',
        publicChargers: site.publicFlag ? publicChargers : chargerOptions,
      });
    }
  }, [site]);

  const submit = async (data: SiteAvailabilityForm) => {
    const {
      isPublic,
      isFixed,
      priceInput,
      isReservationEnabled,
      freeReservationMinutes,
      reservationCentsPerMinute,
      isParkingEnabled,
      freeParkingMinutes,
      parkingCentsPerMinute,
      publicChargers,
    } = data;
    const princeInputInEuros = Number(priceInput) / 100;
    const newRevPriceModel: RevenuePricingModel = {
      ...revPriceModel,
      setting: isFixed ? 'fixed' : 'market',
      ...(isFixed ? { fixed: princeInputInEuros } : { margin: princeInputInEuros }),
    };
    const needsSubscription =
      !!isPublic &&
      !features?.some(
        (feature) => feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING && feature.subscription?.isActive,
      );

    if (!site) {
      return false;
    }
    try {
      await updateSite({
        uuid: site.uuid,
        revenuePricingModel: newRevPriceModel,
        publicFlag: isPublic && !needsSubscription ? 1 : 0,
        publicChargerUuids: isPublic && !needsSubscription ? publicChargers.map((option) => option.uuid) : null,
      }).unwrap();
      await updateSiteSettings({
        uuid: site.uuid,
        settings: {
          chargerReservation: {
            isEnabled: isReservationEnabled,
            ...(isReservationEnabled && {
              freeMinutes: Number(freeReservationMinutes),
              priceCentsPerMinute: Number(reservationCentsPerMinute),
            }),
          },
          parking: {
            isEnabled: isParkingEnabled,
            ...(isParkingEnabled && {
              freeMinutes: Number(freeParkingMinutes),
              priceCentsPerMinute: Number(parkingCentsPerMinute),
            }),
          },
        },
      }).unwrap();
    } catch (error) {
      setUpdateFailed(true);
      setUpdateFailedMessage(getErrorMessage(error));
      return false;
    }

    setReturnPath(needsSubscription ? `../subscribe` : undefined);
    return true;
  };

  const submitSiteAvailability = () => handleSubmitAndResolve(submit)();
  return {
    site,
    form,
    isLoading: isSiteLoadings,
    isSaving: isUpdateSiteLoading || isUpdateSettingsLoading,
    submitSiteAvailability,
    returnPath,
    updateFailed,
    updateFailedMessage,
  };
};
