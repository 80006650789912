import { FormHelperText, styled } from '@mui/material';

export const StyledFormHelperText = styled(FormHelperText)({
  '& .MuiFormHelperText-root': {
    color: '#EB4E20',
  },
  '&.Mui-error': {
    color: '#EB4E20',
  },
});
