import { useCallback, useEffect, useState } from 'react';

import { IconButton, Tooltip } from '@mui/material';

import { ReactComponent as SearchIcon } from '@images/icons/Search.svg';

import SearchModal from './SearchModal';

const SearchOpenButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleKeyPress = useCallback((event) => {
    if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
      setIsModalOpen(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <>
      <Tooltip placement="bottom" title="Search (Ctrl+K or ⌘+K)">
        <IconButton
          onClick={() => setIsModalOpen(true)}
          sx={{
            padding: 1.5,
            '&:hover': {
              backgroundColor: '#D6D6D6',
            },
          }}
        >
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <SearchModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} />
    </>
  );
};

export default SearchOpenButton;
