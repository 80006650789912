import { useTranslation } from 'react-i18next';

import { LoginBox } from './LoginBox';
import { useInviteTokenFromQueryParam } from './inviteTokenHook';

export const Login = () => {
  const { t } = useTranslation();
  const { inviteData, outcomeComponent: OutcomeComponent, inviteHeader: InviteHeader } = useInviteTokenFromQueryParam();
  if (OutcomeComponent) {
    return <OutcomeComponent />;
  }
  return (
    <div className="w-11/12 max-w-md space-y-6 rounded-lg bg-white p-9 md:w-full">
      {InviteHeader ? (
        <InviteHeader />
      ) : (
        <div className="w-full">
          <div className="font-everett text-3xl">{t('welcomeBack', 'Welcome Back')} 👋</div>
          <span className="font-poppins">{t('youKnowWhatToDo', 'You know what to do')}</span>
        </div>
      )}
      <LoginBox inviteData={inviteData} />
    </div>
  );
};
