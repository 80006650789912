import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as EditIcon } from '@images/icons/Edit.svg';

type EditableDetailCardBaseProps = {
  title: string;
  children: ReactNode;
  editingPath: string;
  disabled?: boolean;
};

export const EditableDetailCardBase = ({ title, children, editingPath, disabled }: EditableDetailCardBaseProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // TODO: need to account for pathname ending with slash?
  const isEditingSidebarOpen = pathname.endsWith(editingPath);

  return (
    <button
      type="button"
      className={`relative flex-1 rounded-lg text-left
        ${isEditingSidebarOpen ? 'bg-gray-100' : 'hover:bg-gray-100 active:bg-gray-200'} disabled:bg-transparent`}
      onClick={() => navigate(editingPath)}
      disabled={disabled}
    >
      <span className="block h-full p-2">
        {!disabled && <EditIcon className="float-right" />}
        <h3 className="mb-1 text-sm leading-6 text-gray-600">{title}</h3>
        {children}
      </span>
    </button>
  );
};
