import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type ToastMessage = {
  id?: number;
  type: 'success' | 'error';
  title: string;
  message?: string;
  persistent?: boolean;
};

type ToastMessagesState = {
  toastMessages: ToastMessage[];
};

const initialState: ToastMessagesState = {
  toastMessages: [],
};

export const toastMessagesSlice = createSlice({
  name: 'toastMessages',
  initialState,
  reducers: {
    addToastMessage: {
      prepare: (toastMessage: ToastMessage) => ({ payload: { ...toastMessage, id: Date.now() } }),
      reducer: (state, { payload }: PayloadAction<ToastMessage>) => {
        state.toastMessages.push(payload);
      },
    },
    deleteToastMessage: (state, { payload }) => {
      state.toastMessages = state.toastMessages.filter((message) => message.id !== payload.id);
    },
  },
});

export const { addToastMessage, deleteToastMessage } = toastMessagesSlice.actions;

export default toastMessagesSlice.reducer;
