import { useTranslation } from 'react-i18next';

import { Checkbox } from '@components/atoms/Checkbox';

export const EthernetEnabledByDefaultCheckbox = () => {
  const { t } = useTranslation();
  return (
    <>
      <Checkbox
        label={t('ethernetEnabled', 'Ethernet enabled')}
        name="ethernetEnbled"
        checked
        disabled
        className="!text-base"
        onChange={() => {}}
      />
      <div className="text-gray-600">
        {t('ethernetConnectionIsEnabledByDefault', 'Ethernet cable connection is enabled by default.')}
      </div>
    </>
  );
};
