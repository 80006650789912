import { useTranslation } from 'react-i18next';

import { ReportScheduleRecurrence } from 'models/report-schedule';

export const useRecurrence = () => {
  const { t } = useTranslation();

  const recurrenceMap: Record<ReportScheduleRecurrence, string> = {
    daily: t('daily', 'Daily'),
    weekly: t('weekly', 'Weekly'),
    monthly: t('monthly', 'Monthly'),
  };

  const recurrenceDescriptionMap: Record<ReportScheduleRecurrence, string> = {
    daily: t('everyDay', 'Every day'),
    weekly: t('everyMonday', 'Every Monday'),
    monthly: t('firstOfEveryMonth', '1st of every month'),
  };

  return { recurrenceMap, recurrenceDescriptionMap };
};
