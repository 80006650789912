import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Box } from '@mui/material';

import { AppleAuth } from '@components/apple/AppleAuth';
import Button from '@components/atoms/Button';
import { Checkbox } from '@components/atoms/Checkbox';
import { Input } from '@components/atoms/Input';
import { PasswordInput } from '@components/atoms/PasswordInput';
import GoogleAuth from '@components/google/GoogleAuth';
import { OverflowSpinner } from '@components/spinner/OverflowSpinner';
import { useForm } from '@hooks/useForm';
import { DEFAULT_PATH } from '@routesConfig/routesConst';
import apiSlice from '@services/api';

const useSchema = () => {
  const { t } = useTranslation();
  return yup
    .object()
    .shape({
      name: yup.string().required(t('required*', 'Required*')),
      email: yup
        .string()
        .email(t('invalidEmail', 'Invalid email'))
        .required(t('required*', 'Required*'))
        .matches(/^\S+@\S+$/, { message: t('pleaseProvideValidEmail', 'Please provide a valid email address') }),
      password: yup.string().required(t('required*', 'Required*')),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password'), null], t('passwordsMustMatch', 'Passwords must match')),
      termsAndConditions: yup
        .boolean()
        .oneOf([true], t('pleaseAcceptTermsAndConditions', 'Please accept the terms and conditions')),
    })
    .required();
};

const RegisterBox = ({ inviteData }) => {
  const { t } = useTranslation();
  const schema = useSchema();
  const navigate = useNavigate();
  const { register, handleSubmit, formState, reset } = useForm({ schema });
  const [formError, setFormError] = useState(undefined);
  const [registerCustomer, { isLoading, error }] = apiSlice.useRegisterMutation();

  const onSubmit = async ({ name, email, password }) => {
    const response = await registerCustomer({
      name,
      ...(inviteData ? { inviteToken: inviteData.inviteToken } : { email }),
      password,
    });
    if (!inviteData && !response?.error) {
      navigate(DEFAULT_PATH);
    }
  };

  useEffect(() => {
    if (error?.data) {
      const message = error?.data?.message;
      setFormError(message === 'Email already in use' ? message : t('somethingWentWrong', 'Something went wrong'));
    } else {
      setFormError(undefined);
    }
  }, [error]);

  useEffect(() => {
    if (inviteData) {
      reset({ email: inviteData.email });
    }
  }, [inviteData]);

  return (
    <div className="bg-white sm:rounded-lg">
      <form onSubmit={handleSubmit(onSubmit)} className="relative w-full space-y-1">
        {formError && <div className="text-sm text-vermillion">{formError}</div>}

        {inviteData && (
          <div className="font-poppins">
            {t(
              'registerBoxInviteDataText',
              'As your invite was sent to {{email}}, we’ll use that as your email address.',
              {
                email: inviteData.email,
              },
            )}
          </div>
        )}
        <Input
          label={t('name', 'Name')}
          name="name"
          error={Boolean(formState.errors?.name)}
          helpText={formState.errors?.name?.message}
          {...register('name')}
        />
        {!inviteData && (
          <Input
            label={t('emailAddress', 'Email address')}
            name="email"
            error={Boolean(formState.errors?.email)}
            helpText={formState.errors?.email?.message}
            {...register('email')}
          />
        )}

        <PasswordInput
          label={t('password', 'Password')}
          name="password"
          error={Boolean(formState.errors?.password)}
          helpText={formState.errors?.password?.message}
          {...register('password')}
        />
        <PasswordInput
          label={`${t('confirm')} ${t('password', 'Password').toLowerCase()}`}
          name="passwordConfirmation"
          error={Boolean(formState.errors?.passwordConfirmation)}
          helpText={formState.errors?.passwordConfirmation?.message}
          {...register('passwordConfirmation')}
        />
        <Checkbox
          name="termsAndConditions"
          className="w-5/6 pt-3"
          error={Boolean(formState.errors?.termsAndConditions)}
          helpText={formState.errors?.termsAndConditions?.message}
          {...register('termsAndConditions')}
        >
          <div className="text-sm leading-6">
            <Trans
              i18nKey="termsAndConditions"
              defaults="I agree to the <a1>Terms of Service</a1> and <a2>Privacy Policy</a2>."
              components={{
                a1: (
                  <a
                    href="https://www.vool.com/general-terms/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-vermillion"
                  />
                ),
                a2: (
                  <a
                    href="https://www.vool.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-vermillion"
                  />
                ),
              }}
            />
          </div>
        </Checkbox>
        <div className="w-full space-y-1 pt-3 text-center">
          <Button type="submit" variant="primary" className="h-14 w-full font-semibold">
            {t('register', 'Register')}
          </Button>
          {inviteData ? (
            <>
              <div className="py-4 text-center font-poppins text-base text-black">or</div>
              <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
                <GoogleAuth inviteData={inviteData} />
                <AppleAuth inviteData={inviteData} />
              </Box>
            </>
          ) : (
            <Button variant="link" bigger onClick={() => navigate('/login')}>
              {t('haveAnAccountText', 'I’m not new here. Let me SIGN IN.')}
            </Button>
          )}
        </div>
        {isLoading && <OverflowSpinner />}
      </form>
    </div>
  );
};

RegisterBox.propTypes = {
  inviteData: PropTypes.shape({
    email: PropTypes.string,
    inviteToken: PropTypes.string,
  }),
};

RegisterBox.defaultProps = {
  inviteData: null,
};

export default RegisterBox;
