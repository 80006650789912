import { useTranslation } from 'react-i18next';

import { FeatureDto } from 'models/subscriptions';

import { Box } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { useBreakpoints } from '@hooks/useBreakpoints';
import { useCancelFeatureSubscriptionMutation } from '@services/subscriptions/endpoints';

type CancelSubscriptionSidebarFooterProps = {
  feature: FeatureDto;
  onGoBack: () => void;
};

export const CancelSubscriptionSidebarFooter = ({ feature, onGoBack }: CancelSubscriptionSidebarFooterProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();
  const [cancelSubscription, { isLoading }] = useCancelFeatureSubscriptionMutation();

  const handleClick = async () => {
    try {
      await cancelSubscription({ subscriptionUuid: feature.subscription!.uuid }).unwrap();
      onGoBack();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="end" gap={2}>
      <StyledButton color="secondary" onClick={onGoBack} large={!isMobile}>
        {t('goBack', 'Go back')}
      </StyledButton>
      <StyledButton onClick={handleClick} disabled={isLoading} large={!isMobile}>
        {t('yesCancelSubscription', 'Yes, cancel subscription')}
      </StyledButton>
    </Box>
  );
};
