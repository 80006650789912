import { useTranslation } from 'react-i18next';

import moment from 'moment';
import PropTypes from 'prop-types';

import { Tooltip } from '@mui/material';

import ManualSessionEndedIcon from '@images/icons/sessionStatus/manual_ended.svg';
import ManualSessionFailedIcon from '@images/icons/sessionStatus/manual_failed.svg';
import ManualSessionOngoingIcon from '@images/icons/sessionStatus/manual_ongoing.svg';
import PublicSessionEndedIcon from '@images/icons/sessionStatus/public_ended.svg';
import PublicSessionFailedIcon from '@images/icons/sessionStatus/public_failed.svg';
import PublicSessionOngoingIcon from '@images/icons/sessionStatus/public_ongoing.svg';
import SmartSessionEndedIcon from '@images/icons/sessionStatus/smart_ended.svg';
import SmartSessionFailedIcon from '@images/icons/sessionStatus/smart_failed.svg';
import SmartSessionOngoingIcon from '@images/icons/sessionStatus/smart_ongoing.svg';
import SolarInverterSessionIcon from '@images/icons/sessionStatus/solar_inverter_session.svg';

const useSessionTypes = () => {
  const { t } = useTranslation();
  return {
    smartCharging: t('smartCharging', 'Smart charging'),
    publicCharging: t('publicCharging', 'Public charging'),
    manualCharging: t('manualCharging', 'Manual charging'),
    solarInverterSession: t('solarInverterSession', 'Solar inverter session'),
  };
};

const statusMapping = {
  // normal transaction statuses
  Updated: 'ongoing',
  Started: 'ongoing',
  Ended: 'ended',
  CanceledByUser: 'failed',
  // vehicle transaction statuses
  entered: 'ongoing',
  validated: 'ongoing',
  started: 'ongoing',
  stopped: 'ended',
  failed: 'failed',
};

const sessionTypeStatusIcons = {
  smartCharging: {
    ended: SmartSessionEndedIcon,
    ongoing: SmartSessionOngoingIcon,
    failed: SmartSessionFailedIcon,
  },
  publicCharging: {
    ended: PublicSessionEndedIcon,
    ongoing: PublicSessionOngoingIcon,
    failed: PublicSessionFailedIcon,
  },
  manualCharging: {
    ended: ManualSessionEndedIcon,
    ongoing: ManualSessionOngoingIcon,
    failed: ManualSessionFailedIcon,
  },
  solarInverterSession: {
    default: SolarInverterSessionIcon,
  },
};

const SessionStatus = ({
  className,
  startTime,
  publicChargingFlag,
  smartFlag,
  solarInverterSessionFlag,
  status,
  includeTime,
}) => {
  const { t } = useTranslation();
  const sessionTypes = useSessionTypes();
  const date = moment(startTime);
  const isBeforeCurrentYear = date.isBefore(moment().startOf('year'));

  const getSessionType = () => {
    if (solarInverterSessionFlag) return sessionTypes.solarInverterSession;
    if (publicChargingFlag) return sessionTypes.publicCharging;
    if (smartFlag) return sessionTypes.smartCharging;
    return sessionTypes.manualCharging;
  };

  const getSessionIcon = () => {
    if (solarInverterSessionFlag) {
      return sessionTypeStatusIcons.solarInverterSession.default;
    }
    if (publicChargingFlag) {
      return (
        sessionTypeStatusIcons.publicCharging[statusMapping[status]] || sessionTypeStatusIcons.publicCharging.failed
      );
    }
    if (smartFlag) {
      return sessionTypeStatusIcons.smartCharging[statusMapping[status]] || sessionTypeStatusIcons.smartCharging.failed;
    }
    return sessionTypeStatusIcons.manualCharging[statusMapping[status]] || sessionTypeStatusIcons.manualCharging.failed;
  };

  const getSessionTooltip = () => {
    const sessionType = getSessionType();
    if (solarInverterSessionFlag) return sessionType;
    const sessionStatus = statusMapping[status] || statusMapping.failed;
    const sessionStatusTranslated = t(sessionStatus, sessionStatus);
    return `${sessionType} - ${sessionStatusTranslated}`;
  };

  return (
    <div className={`flex ${className}`}>
      <Tooltip title={getSessionTooltip()} placement="top">
        <div className="flex h-12 w-12 min-w-[48px] items-center rounded-xl">
          <img src={getSessionIcon()} alt="session type" />
        </div>
      </Tooltip>
      <div className="flex flex-col justify-center pl-4 font-poppins">
        {includeTime && <span className="text-base">{date.format('HH:mm')}</span>}
        <span className="text-sm">{date.format(`D MMMM ${isBeforeCurrentYear ? 'YYYY' : ''}`)}</span>
      </div>
    </div>
  );
};

SessionStatus.propTypes = {
  className: PropTypes.string,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  publicChargingFlag: PropTypes.bool,
  solarInverterSessionFlag: PropTypes.bool,
  status: PropTypes.string,
  smartFlag: PropTypes.bool,
  includeTime: PropTypes.bool,
};

SessionStatus.defaultProps = {
  className: '',
  startTime: new Date(),
  publicChargingFlag: false,
  smartFlag: false,
  solarInverterSessionFlag: false,
  status: '',
  includeTime: false,
};

export default SessionStatus;
