import { useOutletContext } from 'react-router-dom';

import { DeviceType } from 'models/device.enums';

import { ChargerOverview } from '@views/Chargers/ChargerOverview';
import { LmcOverview } from '@views/LMC/LmcOverview';

import { DeviceOutletContextType } from './DeviceOutletContextType';

export const DeviceOverview = () => {
  const { device } = useOutletContext<DeviceOutletContextType>();

  if (device.type === DeviceType.CHARGER) {
    return <ChargerOverview />;
  }

  if (device.type === DeviceType.LMC) {
    return <LmcOverview />;
  }

  return null;
};
