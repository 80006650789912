import { useTranslation } from 'react-i18next';

import { skipToken } from '@reduxjs/toolkit/query';
import { DeviceStatus } from 'models/device.enums';

import { useGetLmcCommunicationsQuery } from '@services/devices/endpoints';
import { isLmcSupportingChargerCommStatusesRequest } from '@views/Devices/DeviceDetailsSidebar/deviceConfigurationUtils';

import { ChargerWithStats, LmcMultipointWithStats } from '../devicesAndGroupsTypes';

type UseLmcChargerCommunicationsProps = {
  charger?: ChargerWithStats;
  lmcMultipoint?: LmcMultipointWithStats;
};

export const useLmcChargerCommunications = ({ charger, lmcMultipoint }: UseLmcChargerCommunicationsProps) => {
  const { t } = useTranslation();
  const lmcDeviceUuid = lmcMultipoint?.lmcDeviceUuid;
  const isLmcOffline = !lmcMultipoint?.status || lmcMultipoint.status === DeviceStatus.OFFLINE;

  const {
    data: lmcCommunications,
    isLoading: isLoadingGetLmcCommunications,
    isError: isErrorGetLmcCommunications,
  } = useGetLmcCommunicationsQuery(lmcDeviceUuid ?? skipToken, {
    skip: isLmcOffline || !isLmcSupportingChargerCommStatusesRequest(lmcMultipoint),
    refetchOnMountOrArgChange: true,
  });

  const lmcCommunication = lmcCommunications?.find(
    (lmcCommunication) =>
      lmcCommunication.deviceSerialNumber.toLocaleUpperCase() === charger?.serialNumber.toLocaleUpperCase(),
  );

  const getTooltipText = () => {
    if (isLmcOffline) return t('lmcOffline', 'LMC offline');
    if (isErrorGetLmcCommunications) return t('errorFetchingLmcCommunications', 'Error fetching LMC communications');
    if (!lmcCommunication) return t('chargerMissingFromLmcConfiguration', 'Charger missing from LMC configuration');

    const lmcConnectionMessages = {
      functional: t('lmcCommunicationFunctionalViaConnType', 'LMC communication functional via {{lmcConnectionType}}', {
        lmcConnectionType: lmcCommunication?.interface,
      }),
      missing: t('lmcCommunicationMissingViaConnType', 'LMC communication missing via {{lmcConnectionType}}', {
        lmcConnectionType: lmcCommunication?.interface,
      }),
      chargerIsNotCommunicating: t('chargerIsNotCommunicating', 'Charger is not communicating'),
    };

    const lmcConnectionMessage = lmcCommunication?.isCommunicationOk
      ? lmcConnectionMessages.functional
      : lmcConnectionMessages.missing;

    return lmcCommunication?.interface ? lmcConnectionMessage : lmcConnectionMessages.chargerIsNotCommunicating;
  };

  const getDotBgColor = () => {
    if (isLmcOffline) return '#D6D6D6';
    if (isErrorGetLmcCommunications) return '#FF0000';
    return lmcCommunication?.isCommunicationOk ? '#386957' : '#EB4E20';
  };

  return {
    lmcCommunications,
    isLmcOffline,
    isLoadingGetLmcCommunications,
    isErrorGetLmcCommunications,
    text: getTooltipText(),
    bgColor: getDotBgColor(),
  };
};
