import { useTranslation } from 'react-i18next';

import { DeviceStatus, SolarInverterType } from 'models/device.enums';

import { SettingsSectionItem } from '@components/settings/SettingsSectionItem';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';

import { DeviceSettingsProps } from './DeviceSettings';

export const VoolLmcSettings = ({ device, isLoading }: DeviceSettingsProps) => {
  const { t } = useTranslation();
  const deviceConfiguration = device.configuration ?? {};

  const getExternalAntennaValue = () => {
    if (isLoading) {
      return null;
    }
    if (device.status === DeviceStatus.OFFLINE) {
      return '-';
    }
    const value = deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.EXTERNAL_ANTENNA_ENABLED.key]?.value;
    return value ? t('on', 'On') : t('off', 'Off');
  };

  return (
    <>
      <SettingsSectionItem
        label={t('loadManagement', 'Load management')}
        component={() => deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_SHARING_METHOD.key]?.value}
      />
      <SettingsSectionItem
        label={t('powerMeter', 'Power meter')}
        component={() => deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE.key]?.value}
      />
      <SettingsSectionItem
        label={t('solarInverter', 'Solar inverter')}
        component={() => {
          const solarInverterType = deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE.key];
          if (isLoading || !solarInverterType || solarInverterType?.value === SolarInverterType.NONE) {
            return undefined;
          }
          return solarInverterType?.value;
        }}
      />
      <SettingsSectionItem
        label={t('externalAntenna', 'External antenna')}
        component={() => getExternalAntennaValue()}
      />
    </>
  );
};
