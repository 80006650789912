import { useTranslation } from 'react-i18next';

import { WorkspaceSetupSidebarBase } from '../setup/WorkspaceSetupSidebarBase';
import { CompanyDetails } from './CompanyDetails';

export const CompanyDetailsSidebarBase = () => {
  const { t } = useTranslation();

  return (
    <WorkspaceSetupSidebarBase title={t('companyDetails', 'Company details')}>
      <CompanyDetails />
    </WorkspaceSetupSidebarBase>
  );
};
