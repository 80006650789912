import { useTranslation } from 'react-i18next';

import { Device, UpdateDeviceDataValues } from 'models/device';
import {
  DeviceStatus,
  MainsMeteringType,
  PowerMeterType,
  PowerSharingMethod,
  SolarInverterType,
} from 'models/device.enums';

import { Box, Typography } from '@mui/material';

import { FormCheckbox } from '@components/atoms/FormCheckbox';
import { FormTextField } from '@components/atoms/FormTextField';
import { RadioButton } from '@components/atoms/RadioButton';
import { FormAutocomplete } from '@components/atoms/StyledAutocomplete';
import { EditingSidebarBase } from '@components/sidebars/EditingSidebarBase';
import { DEVICE_CONFIGURATION_PARAMETERS } from '@handlers/device/deviceConst';
import { selectUser } from '@services/auth/selectors';
import { useAppSelector } from '@services/hooks';

import { useLmcSettingsForm } from './useLmcSettingsForm';

type LmcSettingsFormSidebarProps = {
  device: Device;
  isUpdateLoading: boolean;
  isUpdateError: boolean;
  onSaveValues: (props: UpdateDeviceDataValues) => Promise<boolean>;
};

export const LmcSettingsFormSidebar = ({
  device,
  isUpdateLoading,
  isUpdateError,
  onSaveValues,
}: LmcSettingsFormSidebarProps) => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);

  const isAdmin = !!user?.admin;
  const isInstaller = !!user?.features?.isInstaller;
  const deviceConfiguration = device.configuration ?? {};
  const isOffline = device.status === DeviceStatus.OFFLINE;

  const { form, submitLmcSettings } = useLmcSettingsForm({ device, onSaveValues });
  const { control, formState, clearErrors, watch, setValue } = form;
  const selectedPowerMeterType = watch('powerMeterType');
  const selectedSolarInverterType = watch('solarInverterType');
  const selectedPowerSharingMethod = watch('powerSharingMethod');
  const selectedMainsMeteringMode = watch('mainsMeteringMode');

  const getOptions = (array: string[]) =>
    array.map((item) => ({
      label: item,
      value: item,
    }));

  const powerMeterOptions = getOptions(
    deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.POWER_METER_TYPE_OPTIONS.key]?.value ?? [],
  );
  const solarInverterOptions = getOptions(
    deviceConfiguration[DEVICE_CONFIGURATION_PARAMETERS.SOLAR_INVERTER_TYPE_OPTIONS.key]?.value ?? [],
  );

  const applyIpMask = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const segments = value.split('.');
    const output = segments.map((segment) => segment.slice(0, 3)).join('.');
    event.target.value = output;
  };

  return (
    <EditingSidebarBase
      title={t('settings', 'Settings')}
      subtitle={device.name || device.serialNumber}
      saveLabel={t('update', 'Update')}
      discardLabel={t('cancel', 'Cancel')}
      containerClassName="flex flex-col"
      containerBottomPadding="pb-36"
      anyDataChanged={formState.isDirty}
      onSaveValues={submitLmcSettings}
      updateLoading={isUpdateLoading}
      updateFailed={isUpdateError}
      isSubSidebar
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <Box display="flex" flexDirection="column" gap={3}>
          <FormTextField
            control={control}
            name="deviceName"
            label={`${t('deviceName', 'Device name')} (${t('optional', 'Optional').toLowerCase()})`}
          />
          <FormTextField
            control={control}
            name="gridConnection"
            label={t('gridConnection', 'Grid connection')}
            type="number"
            disabled={isOffline}
            isInteger
            InputProps={{
              endAdornment: <Typography variant="p14">A</Typography>,
            }}
          />
        </Box>
        {isAdmin && (
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="p16b">{t('ocppHost', 'OCPP host')}</Typography>
            <Box display="flex" flexDirection="column" gap={3}>
              <FormTextField
                control={control}
                name="ocppHostAddress"
                label={t('ocppHostAddress', 'OCPP host address')}
                disabled={isOffline}
              />
              <FormTextField
                control={control}
                name="ocppServerReconnectTime"
                label={t('ocppServerReconnectTime', 'OCPP server reconnect time')}
                disabled={isOffline}
                type="number"
              />
            </Box>
          </Box>
        )}
        {(isAdmin || isInstaller) && (
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p16b">{t('mainsMeteringMode', 'Mains metering mode')}</Typography>
            <RadioButton
              value={MainsMeteringType.HOUSE_AND_CHARGER_CONSUMPTION}
              checked={selectedMainsMeteringMode === MainsMeteringType.HOUSE_AND_CHARGER_CONSUMPTION}
              label={t('buildingAndCharger', 'Building and charger')}
              name="buildingAndCharger"
              helpTextClassName="text-sm !text-gray-600"
              helpText={t(
                'buildingAndChargerMainsMeteringTypeText',
                'The LMC meters both the building and charger current.',
              )}
              disabled={isOffline}
              onChange={(event) => {
                clearErrors();
                setValue('mainsMeteringMode', event.target.value, { shouldDirty: true });
              }}
            />
            <RadioButton
              value={MainsMeteringType.HOUSE_CONSUMPTION}
              checked={selectedMainsMeteringMode === MainsMeteringType.HOUSE_CONSUMPTION}
              label={t('buildingOnly', 'Building only')}
              name="buildingOnly"
              helpTextClassName="text-sm !text-gray-600"
              helpText={t('buildingOnlyMainsMeteringTypeText', 'The LMC meters only the building current.')}
              disabled={isOffline}
              onChange={(event) => {
                clearErrors();
                setValue('mainsMeteringMode', event.target.value, { shouldDirty: true });
              }}
            />
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography variant="p16b">{t('loadManagementMode', 'Load management mode')}</Typography>
          <RadioButton
            value={PowerSharingMethod.EQUAL}
            checked={selectedPowerSharingMethod === PowerSharingMethod.EQUAL}
            label={t('equal', 'Equal')}
            name="equal"
            helpTextClassName="text-sm !text-gray-600"
            helpText={t(
              'equalLoadManagementModeText',
              'Share available power equally between all active devices connected through this LMC.',
            )}
            disabled={isOffline}
            onChange={(event) => {
              clearErrors();
              setValue('powerSharingMethod', event.target.value, { shouldDirty: true });
            }}
          />
          <RadioButton
            value={PowerSharingMethod.PRIORITIZED}
            checked={selectedPowerSharingMethod === PowerSharingMethod.PRIORITIZED}
            label={t('prioritized', 'Prioritized')}
            name="prioritized"
            helpTextClassName="text-sm !text-gray-600"
            helpText={t(
              'prioritizedLoadManagementModeText',
              'The first vehicles to start charging will be given higher priority.',
            )}
            disabled={isOffline}
            onChange={(event) => {
              clearErrors();
              setValue('powerSharingMethod', event.target.value, { shouldDirty: true });
            }}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="p16b">{t('powerMetering', 'Power metering')}</Typography>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p14" color="#5E5E5E">
              {t(
                'powerMeteringText',
                'Currently the only supported model is the Siemens PAC3220, but other Modbus compliant models may work.',
              )}
            </Typography>
            <FormAutocomplete
              control={control}
              name="powerMeterType"
              label={t('powerMeter', 'Power meter')}
              value={selectedPowerMeterType}
              options={powerMeterOptions}
              disabled={isOffline}
            />
            <FormTextField
              control={control}
              name="powerMeterIpAddress"
              label={t('powerMeterIpAddress', 'Power meter IP address')}
              disabled={selectedPowerMeterType?.value === PowerMeterType.NONE || isOffline}
              InputProps={{
                onInput: applyIpMask,
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="p16b">{t('solarInverter', 'Solar inverter')}</Typography>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography variant="p14" color="#5E5E5E">
              {t(
                'solarInverterText',
                'Currently the only supported model is the Huawei Smart Logger 3000, but other Modbus compliant models may work.',
              )}
            </Typography>
            <FormAutocomplete
              control={control}
              name="solarInverterType"
              label={t('solarInverter', 'Solar inverter')}
              value={selectedSolarInverterType}
              options={solarInverterOptions}
              disabled={isOffline}
            />
            <FormTextField
              control={control}
              name="solarInverterIpAddress"
              label={t('solarInverterIpAddress', 'Solar inverter IP address')}
              disabled={selectedSolarInverterType?.value === SolarInverterType.NONE || isOffline}
              inputProps={{
                onInput: applyIpMask,
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <FormCheckbox
            control={control}
            name="externalAntennaEnabled"
            title={t('externalAntennaEnabled', 'External antenna enabled')}
            disabled={isOffline}
          />
          <Typography variant="p14" color="#5E5E5E">
            {t(
              'externalAntennaEnabledText',
              'Before enabling, verify that external external antenna is present and correctly connected.',
            )}
            <br />
            <strong>{t('restartLmcToApplyChanges', 'Restart LMC to apply changes to this setting.')}</strong>
          </Typography>
        </Box>
      </Box>
    </EditingSidebarBase>
  );
};
