import { useTranslation } from 'react-i18next';

import { isNil } from 'lodash';
import { AddSiteDto, PricingModel } from 'models/site';
import * as yup from 'yup';

export const emptyDefaultPricingModel: PricingModel = {
  isAutomatic: true,
  isSinglePrice: true,
  isSingleTransmission: true,
};

export const defaultSiteDetailsValues: Partial<AddSiteDto> = {
  name: undefined,
  address: undefined,
  addressJson: undefined,
  pricingModel: emptyDefaultPricingModel,
};

export const useDualPriceSchema = ({ isTransmissionCost }: { isTransmissionCost: boolean }) => {
  const { t } = useTranslation();
  const testValue = isTransmissionCost
    ? yup.string().test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => Number(v) >= 0)
    : yup.string().test('positive', t('mustBePositive', 'Must be positive'), (value) => Number(value) > 0);
  return yup.object().shape({
    price: yup.object().shape({
      night: yup.string().required(t('required*', 'Required*')).concat(testValue),
      day: yup.string().required(t('required*', 'Required*')).concat(testValue),
    }),
    dayHours: yup.object().shape({
      start: yup.string().required(t('required*', 'Required*')),
      end: yup.string().required(t('required*', 'Required*')),
    }),
  });
};

export const useSiteDetailsSchema = () => {
  const { t } = useTranslation();

  const transmissionDualPriceSchema = useDualPriceSchema({ isTransmissionCost: true });
  const dualPriceSchema = useDualPriceSchema({ isTransmissionCost: false });

  return yup
    .object()
    .shape({
      name: yup
        .string()
        .required(t('required*', 'Required*'))
        .test('nameIsRequired', t('nameIsRequired', 'Name is required'), (value) => value?.trim?.() !== ''),
      address: yup.string().required(t('required*', 'Required*')),
      addressJson: yup.object().required(t('required*', 'Required*')),
      pricingModel: yup.object().shape({
        isAutomatic: yup.boolean(),
        region: yup.string().when('isAutomatic', {
          is: true,
          then: yup.string().required(t('required*', 'Required*')),
        }),
        isSingleTransmission: yup.boolean(),
        transmissionSinglePrice: yup.string().when('isSingleTransmission', {
          is: true,
          then: yup
            .string()
            .required(t('required*', 'Required*'))
            .test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => Number(v) >= 0),
        }),
        transmissionDualPrice: yup.object().when('isSingleTransmission', {
          is: false,
          then: transmissionDualPriceSchema,
        }),
        isSinglePrice: yup.boolean(),
        singlePrice: yup.lazy((value, { parent }) => {
          if (!parent.isAutomatic && parent.isSinglePrice) {
            return yup
              .string()
              .required(t('required*', 'Required*'))
              .test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => Number(v) >= 0);
          }
          return yup.string();
        }),
        dualPrice: yup.lazy((value, { parent }) => {
          if (!parent.isAutomatic && !parent.isSinglePrice) {
            return dualPriceSchema;
          }
          return yup.object();
        }),
        extraFees: yup.lazy((value) => {
          if (isNil(value) || value?.trim?.() === '') {
            return yup.string().nullable();
          }
          return yup
            .number()
            .typeError(t('mustBeNumber', 'Must be a number'))
            .test('notNegative', t('valueCannotBeNegative', 'Value cannot be negative'), (v) => Number(v) >= 0);
        }),
      }),
    })
    .required();
};
