import { Outlet, useLocation } from 'react-router-dom';

import { Tab, TabsWithMenu } from '@components/tabs/TabsWithMenu';
import { isSubdomainPartner } from '@routesConfig/routesUtil';

import { ExportReportButton } from './ExportReportButton';
import { ScheduleReportButton } from './ScheduleReportButton';
import { ReportScheduleProvider } from './context/ReportScheduleProvider';
import { ReportingTransactionsContext, ReportingTransactionsProvider } from './context/ReportingTransactionsProvider';

export const ReportingView = () => {
  const { pathname } = useLocation();

  const tabs: Tab[] = [
    {
      label: 'Sessions',
      path: '/reporting/sessions',
      actionButton: <ExportReportButton context={ReportingTransactionsContext} fullWidth />,
    },
    ...(!isSubdomainPartner()
      ? [
          {
            label: 'Report scheduler',
            path: '/reporting/scheduler',
          },
        ]
      : []),
  ];

  const [sessionsTab] = tabs;
  const activeTab = tabs.find((tab) => pathname.startsWith(tab.path)) ?? sessionsTab;

  return (
    <ReportingTransactionsProvider>
      <ReportScheduleProvider>
        <TabsWithMenu
          activeTab={activeTab}
          tabs={tabs}
          {...(!isSubdomainPartner() && {
            globalActionButton: <ScheduleReportButton isPrimary={activeTab !== sessionsTab} />,
          })}
        />
        <Outlet />
      </ReportScheduleProvider>
    </ReportingTransactionsProvider>
  );
};
