import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RFIDCardDto } from 'models/rfid-card';

import { useAppDispatch } from '@services/hooks';
import { useAddRfidCardMutation } from '@services/rfidCards/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { RfidCardDetailsSidebarBase } from './RfidCardDetailsSidebarBase';

export const AddRfidCardSidebar = () => {
  const { t } = useTranslation();
  const { companyUuid } = useParams();
  const dispatch = useAppDispatch();

  const [addRfidCard, { isLoading }] = useAddRfidCardMutation();

  const rfidCardLabel = t('rfidCard', 'RFID card');

  const saveValues = async (attrs: RFIDCardDto) => {
    try {
      await addRfidCard({ ...attrs, companyUuid }).unwrap();
      dispatch(
        addToastMessage({
          type: 'success',
          title: t('itemAdded', '{{item}} added', { item: rfidCardLabel }),
          message: t('youCanSeeTheCardInTheList', 'You can see the card in your RFID cards list.'),
        }),
      );
      return true;
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('addingTheItemFailed', 'Adding The {{item}} failed', { item: rfidCardLabel }),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
      return false;
    }
  };

  return (
    <RfidCardDetailsSidebarBase
      title={t('addAnItem', 'Add an {{item}}', { item: rfidCardLabel })}
      saveLabel={t('addItem', 'Add {{item}}', { item: rfidCardLabel })}
      discardLabel={t('cancel', 'Cancel')}
      isSaveLoading={isLoading}
      onSaveValues={saveValues}
    />
  );
};
