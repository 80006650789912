import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { XIcon } from '@heroicons/react/solid';

import circleAlertIcon from '@images/icons/Circle_alert.svg';
import warningIcon from '@images/icons/Warning.svg';
import checkIcon from '@images/icons/check.svg';

export enum MessageLevel {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

type MessageBarProps = {
  level: MessageLevel;
  onClose?: () => void;
  children: ReactNode;
};

export const MessageBar = ({ level, onClose, children }: MessageBarProps) => {
  const { t } = useTranslation();
  type StyleAttrs = {
    icon: string;
    className: string;
  };
  const levelToStyle: Record<MessageLevel, StyleAttrs> = {
    [MessageLevel.INFO]: {
      icon: circleAlertIcon,
      className: 'bg-queen/10 text-queen',
    },
    [MessageLevel.SUCCESS]: {
      icon: checkIcon,
      className: 'bg-forest-200 text-forest',
    },
    [MessageLevel.WARNING]: {
      icon: warningIcon,
      className: 'bg-vermillion-200 text-vermillion',
    },
  };
  const style = levelToStyle[level];
  return (
    <div
      className={`mb-6 flex items-center justify-start gap-2 rounded-2xl p-4 font-poppins text-sm leading-6 ${style.className}`}
    >
      <img src={style.icon} className="h-6 w-6" alt="alert" />
      <div className="flex-1">{children}</div>
      {onClose && (
        <button type="button" className="flex rounded-md" onClick={onClose}>
          <span className="sr-only">{t('dismiss', 'Dismiss')}</span>
          <XIcon className={`h-6 w-6`} aria-hidden="true" />
        </button>
      )}
    </div>
  );
};
