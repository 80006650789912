import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';

import { ReportScheduleDto } from 'models/report-schedule';

import { Box, TableCell, Typography } from '@mui/material';

import { ColumnData, MaterialTable } from '@components/atoms/MaterialTable';
import { StyledTableRow } from '@components/atoms/StyledTableRow';
import { DeleteModal } from '@components/modals/DeleteModal';
import { ReactComponent as DeleteIcon } from '@images/icons/Trash.svg';
import { ReactComponent as TimeIcon } from '@images/icons/reporting/time.svg';
import { isSubdomainPartner } from '@routesConfig/routesUtil';
import { useAppDispatch } from '@services/hooks';
import { useDeleteReportScheduleMutation, useGetReportSchedulesQuery } from '@services/reporting/endpoints';
import { addToastMessage } from '@services/toastMessages';

import { ReportScheduleContext } from './context/ReportScheduleProvider';
import { useRecurrence } from './hooks/useRecurrence';
import { ReportScheduleTableRowSkeleton, ReportScheduleTableSkeleton } from './table/ReportScheduleTableSkeleton';

type ReportScheduleColumnData = ColumnData<ReportScheduleDto>;

const useColumns = (): ReportScheduleColumnData[] => {
  const columns: ReportScheduleColumnData[] = [
    {
      label: 'Recurrence',
      dataKey: 'recurrence',
    },
    {
      label: 'Created by',
      dataKey: 'createdByEmail',
    },
    {
      label: 'Sent to',
      dataKey: 'recipients',
    },
    {
      label: '',
      dataKey: 'menu',
    },
  ];

  return columns;
};

const TableHeaderContent = ({ column }: { column: ReportScheduleColumnData }) => {
  return (
    <Typography variant="p14" color="#5E5E5E">
      {column.label}
    </Typography>
  );
};

const renderRows = (rows: ReportScheduleDto[], columns: ReportScheduleColumnData[]) => {
  return rows.map((row) => <TableRow key={row.uuid} row={row} columns={columns} />);
};

type TableRowProps = {
  row: ReportScheduleDto;
  columns: ReportScheduleColumnData[];
};

const TableRow = ({ row, columns }: TableRowProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [deleteReportSchedule] = useDeleteReportScheduleMutation();
  const { recurrenceMap, recurrenceDescriptionMap } = useRecurrence();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteReportSchedule(row.uuid).unwrap();

      dispatch(
        addToastMessage({
          type: 'success',
          title: t('scheduleDeleted', 'Schedule deleted'),
        }),
      );
    } catch (error) {
      dispatch(
        addToastMessage({
          type: 'error',
          title: t('deletingScheduleFailed', 'Deleting schedule failed'),
          message: t('pleaseTryAgain', 'Please try again.'),
        }),
      );
    }
  };

  return (
    <StyledTableRow
      onClick={() => !isSubdomainPartner() && navigate(`/reporting/scheduler/schedule/${row.uuid}`)}
      sx={{ cursor: !isSubdomainPartner() ? 'pointer' : 'default' }}
    >
      {columns.map((column) => {
        switch (column.dataKey) {
          case 'recurrence':
            return (
              <TableCell key={column.dataKey} align="left">
                <Box display="flex" alignItems="center" gap={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexShrink={0}
                    width={48}
                    height={48}
                    borderRadius={3}
                    sx={{ backgroundColor: '#D7E1DD' }}
                  >
                    <TimeIcon color="#386957" />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="p16b">{row.name || recurrenceMap[row.recurrence]}</Typography>
                    <Typography variant="p14" color="#5E5E5E">
                      {recurrenceDescriptionMap[row.recurrence]}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            );
          case 'createdByEmail':
            return (
              <TableCell key={column.dataKey} align="left">
                <Box display="flex" flexDirection="column">
                  <Typography variant="p14b">{row.createdByName}</Typography>
                  <Typography variant="p14">{row.createdByEmail}</Typography>
                </Box>
              </TableCell>
            );
          case 'recipients':
            return (
              <TableCell key={column.dataKey} align="left" width="60%">
                <Typography variant="p14">{row.recipients.map((r) => r.email).join(', ')}</Typography>
              </TableCell>
            );
          case 'menu':
            return (
              <TableCell
                key={column.dataKey}
                align="right"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isSubdomainPartner()) {
                    setDeleteModalOpen(true);
                  }
                }}
              >
                {!isSubdomainPartner() && (
                  <>
                    <DeleteIcon />
                    <DeleteModal
                      title={t('deleteSchedule', 'Delete schedule')}
                      confirmText={t('yesDeleteTheSchedule', 'Yes, delete the schedule')}
                      isOpen={deleteModalOpen}
                      onDelete={(e) => {
                        e.stopPropagation();
                        setDeleteModalOpen(false);
                        handleDelete();
                      }}
                      onClose={(e) => {
                        e.stopPropagation();
                        setDeleteModalOpen(false);
                      }}
                    >
                      <Typography variant="p16">
                        {t('deleteScheduleConfirmation', 'Are you sure you want to delete the schedule')}
                        {row.name && <strong> {row.name}</strong>}?
                      </Typography>
                    </DeleteModal>
                  </>
                )}
              </TableCell>
            );
          default:
            return null;
        }
      })}
    </StyledTableRow>
  );
};

export const ReportSchedulerView = () => {
  const { data, isFetching: dataLoading } = useGetReportSchedulesQuery();

  return (
    <Box display="flex" flexDirection="column" gap={2} pb={8}>
      <MaterialTable
        context={ReportScheduleContext}
        data={data}
        isLoading={dataLoading}
        headerProps={{
          columns: useColumns(),
          TableHeaderContent,
          orderBy: 'addTime',
        }}
        renderRows={renderRows}
        loaders={{ TableLoader: ReportScheduleTableSkeleton, RowLoader: ReportScheduleTableRowSkeleton }}
      />
      <Outlet />
    </Box>
  );
};
