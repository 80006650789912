import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet, useOutletContext } from 'react-router-dom';

import { EnergySupplied } from '@components/energySupplied/energySuppliedChart';
import { selectSiteByUuid } from '@services/sites/selectors';
import { durationToTimeFrame } from '@services/transactions/chartDataParser';
import { selectSmartChargingSessions } from '@services/vehicles/selectors';
import VehicleBattery from '@views/Vehicles/VehicleView/VehicleBattery';
import VehicleSummary from '@views/Vehicles/VehicleView/VehicleSummary';

const VehicleOverview = () => {
  const { t } = useTranslation();
  const { vehicle, selectedDuration, setSelectedDuration } = useOutletContext();
  const smartChargingSessions = useSelector(selectSmartChargingSessions);
  const vehicleSite = useSelector((state) => selectSiteByUuid(state, vehicle?.siteUuid));

  const vehicleTransactions = useMemo(
    () => [
      {
        name: t('vehicleTransaction', 'Vehicle Transactions'),
        data:
          smartChargingSessions
            ?.filter((st) => st.carUuid === vehicle.uuid && st.scheduledStartTime)
            .map((session) => {
              const summary = session.slotsSummary;
              return {
                startTime: session.scheduledStartTime,
                endTime: session.scheduledEndTime,
                amount: summary?.actualKwhAmount,
                monetaryCost: summary?.actualMonetaryCost,
              };
            }) || [],
      },
    ],
    [smartChargingSessions, vehicle?.uuid],
  );

  const detailsData = [
    {
      label: t('name', 'Name'),
      value: vehicle?.name || ' - ',
    },
    {
      label: t('brand', 'Brand'),
      value: vehicle?.brand || ' - ',
    },
    {
      label: t('model', 'Model'),
      value: vehicle?.model || ' - ',
    },
    {
      label: t('vehicleIdAbbreviation', 'VIN'),
      value: vehicle?.status?.vin ?? ' - ',
    },
    {
      label: t('registrationNumberAbbreviation', 'Reg. no'),
      value: vehicle?.status?.registrationNumber ?? ' - ',
    },
    {
      label: t('site', 'Site'),
      value: vehicleSite?.name ?? ' - ',
    },
  ];

  const timeFrame = durationToTimeFrame[selectedDuration] || durationToTimeFrame['1-months'];

  return (
    <>
      <div className="flex flex-col gap-y-10">
        <div className="flex w-full flex-wrap gap-x-4 gap-y-2 md:flex-nowrap">
          <VehicleBattery vehicle={vehicle} />
          <div className="flex flex-1 flex-col gap-y-2 rounded-2xl bg-white p-4 xl:w-auto">
            <span>{t('details', 'Details')}</span>
            <div className="grid grid-cols-1 gap-x-4 gap-y-1 md:grid-cols-2">
              {detailsData.map((item) => (
                <div key={item.label} className="flex flex-1 gap-x-1 font-semibold">
                  {item.label}: <span className="font-normal">{item.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <VehicleSummary
          vehicleSummary={vehicle?.chargingSessionsSummary}
          selectedDuration={selectedDuration}
          setSelectedDuration={setSelectedDuration}
        />
        <EnergySupplied categorizedTransactions={vehicleTransactions} filter={timeFrame} />
      </div>
      <Outlet context={{ vehicle }} />
    </>
  );
};

export default VehicleOverview;
