import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportingTransaction } from 'models/reporting-transaction';

import { ContentPaste } from '@mui/icons-material';
import { Box, Collapse, IconButton, Skeleton, TableCell, Tooltip, Typography } from '@mui/material';

import { StyledTableRow } from '@components/atoms/StyledTableRow';
import TableRowGraph from '@components/atoms/TableRowGraph';
import copyContent from '@helpers/copyContent';
import { ReactComponent as ChartIcon } from '@images/icons/nav/Chart.svg';
import apiSlice from '@services/api';

type TransactionDetailsRowProps = {
  transaction: ReportingTransaction;
  colSpan: number;
  open: boolean;
};

export const TransactionDetailsRow = ({ transaction, colSpan, open }: TransactionDetailsRowProps) => {
  const { t } = useTranslation();
  const { data: transactionGraphData, isLoading } = apiSlice.useTransactionDataQuery(transaction.transactionId, {
    skip: !open,
  }) as { data: any; isLoading: boolean };
  const defaultCopyState = t('copyTransactionId', 'Copy transaction ID');
  const [copyState, setCopyState] = useState(defaultCopyState);

  const hasConsumption = transactionGraphData?.consumption?.some((c: any) => c.value > 0);

  return (
    <StyledTableRow
      sx={{
        '& > *': {
          borderBottom: '0 !important',
        },
      }}
    >
      <TableCell
        colSpan={colSpan}
        sx={{
          paddingBottom: 0,
          paddingTop: 0,
          backgroundColor: '#f4f4f480',
          borderBottom: open ? '1px solid #F4F4F4 !important' : undefined,
        }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box minHeight="200px" display="flex" flexDirection="column" justifyContent="center">
            <Box>
              <Typography variant="p14">{`${t('id', 'ID')}: ${transaction.transactionId}`}</Typography>
              <Tooltip
                title={copyState}
                TransitionProps={{
                  onExited: () => {
                    setCopyState(defaultCopyState);
                  },
                }}
              >
                <IconButton
                  size="small"
                  disableRipple
                  onClick={() =>
                    copyContent({
                      text: transaction.transactionId,
                      afterCopy: () => setCopyState(t('copied', 'Copied')),
                    })
                  }
                  sx={{ color: '#5E5E5E' }}
                >
                  <ContentPaste />
                </IconButton>
              </Tooltip>
            </Box>
            {isLoading && (
              <Box display="flex" height="200px" alignItems="end" gap={4}>
                <Skeleton variant="rectangular" width="25%" height="40%" />
                <Skeleton variant="rectangular" width="25%" height="90%" />
                <Skeleton variant="rectangular" width="25%" height="20%" />
                <Skeleton variant="rectangular" width="25%" height="60%" />
              </Box>
            )}
            {!hasConsumption && !isLoading && (
              <Box display="flex" gap={1} justifyContent="center" alignItems="center" flex={1}>
                <ChartIcon className="h-5 w-5 [&_path]:fill-gray-400" />
                <Typography variant="p14" color="#999998">
                  {t('notEnoughDataAvailable', 'Not enough data available')}
                </Typography>
              </Box>
            )}
            {hasConsumption && (
              <TableRowGraph options={transactionGraphData?.consumption} prices={transactionGraphData?.prices} />
            )}
          </Box>
        </Collapse>
      </TableCell>
    </StyledTableRow>
  );
};
