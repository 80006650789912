import { useTranslation } from 'react-i18next';
import { Outlet, useOutletContext } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { FiltersCard } from '@components/filters/FiltersCard';
import { DeviceOutletContextType } from '@views/Devices/DeviceOutletContextType';
import { ExportReportButton } from '@views/reporting/ExportReportButton';
import { SessionsTotalCards } from '@views/reporting/SessionsTotalCards';
import { ReportingMaterialTable } from '@views/reporting/table/ReportingMaterialTable';

import { ReportingTransaction } from '../../../models/reporting-transaction';
import { DeviceTransactionsContext, DeviceTransactionsProvider } from './context/DeviceTransactionsProvider';

export const ChargerHistory = () => {
  const { t } = useTranslation();
  const { device, site } = useOutletContext<DeviceOutletContextType>();

  const hiddenColumns = [
    'siteName',
    ...(!site.publicFlag ? ['monetaryRevenue', 'monetaryProfit'] : []),
  ] as (keyof ReportingTransaction)[];

  return (
    <DeviceTransactionsProvider deviceUuid={device.uuid}>
      <Box display="flex" flexDirection="column" gap={2} pb={8}>
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="p18" flex={1}>
            {t('sessions', 'Sessions')}
          </Typography>
          <ExportReportButton context={DeviceTransactionsContext} />
        </Box>
        <FiltersCard context={DeviceTransactionsContext} />
        <SessionsTotalCards context={DeviceTransactionsContext} />
        <ReportingMaterialTable context={DeviceTransactionsContext} hiddenColumns={hiddenColumns} />
      </Box>
      <Outlet />
    </DeviceTransactionsProvider>
  );
};
