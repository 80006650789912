/* eslint-disable consistent-return */
import PusherDevicesDataHandler from '../pusherDevicesData';
import PusherInvertersDataHandler from '../pusherInvertersData';
import PusherSmartChargingSessionsDataHandler from '../pusherSmartChargingSessions';
import PusherTransactionsDataHandler from '../pusherTransactionsData';
import PusherVehiclesDataHandler from '../pusherVehiclesData';

const HANDLER_TYPES = {
  device: PusherDevicesDataHandler,
  inverter: PusherInvertersDataHandler,
  transaction: PusherTransactionsDataHandler,
  car: PusherVehiclesDataHandler,
  chargingSession: PusherSmartChargingSessionsDataHandler,
};

export default class PusherDataHandler {
  data;

  meta;

  object;

  constructor({ data, meta }) {
    this.data = data;
    this.meta = meta;
    this.object = meta.object;
  }

  getActions() {
    const Handler = HANDLER_TYPES[this.object];
    if (Handler) {
      const handlerInstance = new Handler(this);
      return handlerInstance.getActions();
    }
  }
}
