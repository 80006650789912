import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PayoutType } from 'models/payment.enums';
import { StripePaymentMethodType } from 'models/stripe.enums';

import { Box } from '@mui/material';

import ButtonSwitch from '@components/buttons/ButtonSwitch';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import { PaymentLink, StripePaymentLinkProps } from './PaymentLink';

export const AddPaymentMethodForm = (props: StripePaymentLinkProps) => {
  const { t } = useTranslation();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [paymentMethodType, setPaymentMethodType] = useState<PayoutType>(PayoutType.CARD);

  useEffect(() => {
    const initStripe = async () => {
      if (process.env.REACT_APP_STRIPE_CONFIG && process.env.REACT_APP_CONF_FILE) {
        const loadStripeResult = await loadStripe(
          JSON.parse(process.env.REACT_APP_STRIPE_CONFIG)[process.env.REACT_APP_CONF_FILE].key,
        );
        setStripe(loadStripeResult);
      }
    };

    initStripe().catch(console.error);
  }, []);

  return (
    <>
      <Box>
        <ButtonSwitch
          options={[
            { label: t('card', 'Card'), value: PayoutType.CARD },
            { label: t('bankAccount', 'Bank account'), value: PayoutType.BANK },
          ]}
          value={paymentMethodType}
          onChange={(value) => {
            setPaymentMethodType(value);
          }}
        />
      </Box>
      <Elements
        stripe={stripe}
        options={{
          mode: 'setup',
          currency: 'eur',
          setup_future_usage: 'off_session',
          payment_method_types: [
            paymentMethodType === PayoutType.BANK ? StripePaymentMethodType.SEPA_DEBIT : StripePaymentMethodType.CARD,
          ],
        }}
      >
        <PaymentLink {...props} />
      </Elements>
    </>
  );
};
