import '@headlessui/react';
import { Drawer, DrawerProps } from '@mui/material';

import { useBreakpoints } from '@hooks/useBreakpoints';

type SidebarBaseProps = Omit<DrawerProps, 'open'> & {
  isOpen?: boolean;
  isWide?: boolean;
  isSubSidebar?: boolean;
};

export const SidebarBase = ({ isOpen, isWide, isSubSidebar, ...props }: SidebarBaseProps) => {
  const { isMobile } = useBreakpoints();
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      {...props}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: isWide && !isMobile ? '640px' : '400px',
          overflowY: 'hidden',
        },
        ...props.PaperProps,
      }}
      slotProps={{
        backdrop: {
          sx: {
            bgcolor: isSubSidebar ? 'transparent' : 'rgba(40, 40, 40, 0.5)',
          },
        },
        ...props.slotProps,
      }}
      sx={{
        zIndex: 40,
        ...props.sx,
      }}
    />
  );
};
