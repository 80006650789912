import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { skipToken } from '@reduxjs/toolkit/query';
import { Device } from 'models/device';
import { FeatureId } from 'models/subscriptions';

import { Box } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { SummaryCard } from '@components/cards/SummaryCard';
import DeviceOcppSetupInfoModal from '@components/devices/DeviceOcppSetupInfoModal';
import { MenuComponent } from '@components/dropdown/MenuComponent';
import { TimePeriodSelectorHeader } from '@components/filterComponents/TimePeriodSelectorHeader';
import { useSubscribeToInactiveCompanyPusherChannel } from '@hooks/pusherChannelHooks';
import { useActiveCurrency } from '@hooks/useActiveCurrency';
import { useNavigateToSites } from '@hooks/useNavigateToSites';
import kebabIcon from '@images/icons/Kebab.svg';
import apiSlice from '@services/api';
import { selectSiteDashboard } from '@services/dashboard/selectors';
import { useAppSelector } from '@services/hooks';
import { useGetSiteQuery } from '@services/sites/endpoints';
import { selectSiteByUuid } from '@services/sites/selectors';
import { useGetFeaturesQuery } from '@services/subscriptions/endpoints';
import { DurationKey } from '@services/transactions/chartDataParser';

import { DeleteSiteModal } from './DeleteSiteModal';
import { DevicesAndGroups } from './SiteDevicesAndGroups/DevicesAndGroups';
import { SiteAvailabilityCard } from './cards/SiteAvailabilityCard';
import { SiteDetailsCard } from './cards/SiteDetailsCard';
import { SitePricingModelWarning } from './cards/SitePricingModelWarning';
import { useGetSiteMenuItems } from './useGetSiteMenuItems';

export const SingleSiteView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { siteUuid } = useParams();
  const currency = useActiveCurrency();
  const { isSingleSiteAvailable } = useNavigateToSites();

  const site = useAppSelector((state) => selectSiteByUuid(state, siteUuid));
  const siteDashboard = useAppSelector(selectSiteDashboard);
  const { data: features } = useGetFeaturesQuery();
  const publicChargingFeature = features?.find((feature) => feature.featureId === FeatureId.DEVICE_PUBLIC_CHARGING);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ocppSetupModalVisible, setOcppSetupModalVisible] = useState(false);
  const [addedDevice, setAddedDevice] = useState<Device>();
  const showOcppConfigInfoModalForDevice = (device: Device) => {
    setAddedDevice(device);
    setOcppSetupModalVisible(true);
  };

  const [selectedDuration, setSelectedDuration] = useState<DurationKey>();

  const { isFetching: isFetchingGetSite } = useGetSiteQuery(siteUuid ?? skipToken, { refetchOnMountOrArgChange: true });
  apiSlice.useGetSiteDashboardQuery(
    { uuid: siteUuid, duration: selectedDuration },
    {
      skip: !selectedDuration,
      refetchOnMountOrArgChange: true,
    },
  );

  useSubscribeToInactiveCompanyPusherChannel(site?.companyUuid);

  const getSiteMenuItems = useGetSiteMenuItems({
    onOpenDeleteSiteModal: () => setIsDeleteModalOpen(true),
    isSiteView: true,
  });
  const siteMenuItems = site ? getSiteMenuItems(site) : [];

  return (
    <>
      {/*
        TODO: make the border span entire screen width
        TODO: make the header have fixed position
      */}

      {!isFetchingGetSite && !site?.pricingModel && <SitePricingModelWarning />}
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <h2 className="font-poppins text-lg font-semibold leading-8">{t('overview', 'Overview')}</h2>
        <Box display="flex" alignItems="center">
          {isSingleSiteAvailable && (
            <StyledButton color="success" onClick={() => navigate('addSite')}>
              {t('createNewSite', 'Create new site')}
            </StyledButton>
          )}
          <MenuComponent menuItems={siteMenuItems} buttonIcon={kebabIcon} />
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="stretch"
        mb="40px"
        gap="8px"
        borderRadius="16px"
        bgcolor="white"
        p="8px"
        sx={(theme) => ({
          flexDirection: 'row',
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        })}
      >
        <SiteDetailsCard site={site} />
        <SiteAvailabilityCard site={site} />
      </Box>

      <TimePeriodSelectorHeader
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
        storageKey="dashboardDuration"
      />
      <Box display="flex" flexWrap="wrap" mb="40px" gap="16px">
        {!!site?.publicFlag && (
          <>
            <SummaryCard
              label={t('revenue', 'Revenue')}
              unit={currency.symbol}
              value={siteDashboard?.totalEnergyRevenue}
              valueDecimalPlaces={2}
              trend={siteDashboard?.totalEnergyRevenueTrend}
              tooltip={t('vatNotIncluded', 'VAT not included')}
            />
            <SummaryCard
              label={siteDashboard?.totalEnergyProfit < 0 ? t('loss', 'Loss') : t('profit', 'Profit')}
              unit={currency.symbol}
              value={siteDashboard?.totalEnergyProfit}
              valueDecimalPlaces={2}
              trend={siteDashboard?.totalEnergyProfitTrend}
              tooltip={t('vatNotIncluded', 'VAT not included')}
            />
          </>
        )}
        <SummaryCard
          label={t('sessions', 'Sessions')}
          value={siteDashboard?.totalTransactions}
          trend={siteDashboard?.totalTransactionsTrend}
        />
        <SummaryCard
          label={t('consumption', 'Consumption')}
          unit="kWh"
          value={siteDashboard?.totalEnergyKwh}
          valueDecimalPlaces={2}
          trend={siteDashboard?.totalEnergyKwhTrend}
        />
      </Box>
      <DevicesAndGroups selectedDuration={selectedDuration} />
      {site && (
        <DeleteSiteModal site={site} isOpen={isDeleteModalOpen} closeModal={() => setIsDeleteModalOpen(false)} />
      )}
      <DeviceOcppSetupInfoModal
        open={ocppSetupModalVisible}
        closeModal={() => setOcppSetupModalVisible(false)}
        device={addedDevice || {}}
        isDeviceAdded
      />
      <Outlet context={{ site, showOcppConfigInfoModalForDevice, feature: publicChargingFeature }} />
    </>
  );
};
