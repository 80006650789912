import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import loginBackgroundImage from '@images/welcome/login-background.jpg';
import welcomeBackgroundImage from '@images/welcome/welcome-background.jpg';

enum LoggedOutPathname {
  welcome = 'welcome',
  login = 'login',
  register = 'register',
  forgotPassword = 'forgot-password',
  resetPassword = 'reset-password',
}

type LoggedOutBasicLayoutProps = {
  isLogin?: boolean;
  children?: React.ReactNode;
};

export const LoggedOutBasicLayout = ({ isLogin, children }: LoggedOutBasicLayoutProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname.split('/')[1] as LoggedOutPathname;
  const backgroundImage =
    isLogin || [LoggedOutPathname.login, LoggedOutPathname.register].includes(pathname)
      ? loginBackgroundImage
      : welcomeBackgroundImage;

  const pageTitles: Record<LoggedOutPathname, string> = {
    [LoggedOutPathname.welcome]: t('welcome', 'Welcome'),
    [LoggedOutPathname.login]: t('logIn', 'Log in'),
    [LoggedOutPathname.register]: t('register', 'Register'),
    [LoggedOutPathname.forgotPassword]: t('forgotPassword', 'Forgot password'),
    [LoggedOutPathname.resetPassword]: t('resetPassword', 'Reset password'),
  };
  const pageTitle = pageTitles[pathname] ?? pageTitles[LoggedOutPathname.welcome];
  const [backgroundAnimateClass, setBackgroundAnimateClass] = useState('');

  useEffect(() => {
    setBackgroundAnimateClass('animate-fadeIn');

    const animationClearTimeout = setTimeout(() => {
      setBackgroundAnimateClass('');
    }, 500);

    return () => {
      clearTimeout(animationClearTimeout);
    };
  }, [pathname]);

  return (
    <div className="flex h-screen w-screen bg-white">
      <Helmet>
        <title>{pageTitle} - VOOL</title>
      </Helmet>
      <div className="relative flex h-full w-full flex-col items-center justify-center">{children || <Outlet />}</div>

      <div
        className={`relative hidden h-full w-full bg-cover bg-right-bottom md:block ${backgroundAnimateClass}`}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      />
    </div>
  );
};
