import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { StyledButton } from '@components/atoms/StyledButton';
import { CreateWorkspaceModal } from '@views/Workspace/CreateWorkspaceModal';

import { PartnersWorkspacesMaterialTable } from './PartnersWorkspacesMaterialTable';

export const PartnerWorkspacesView = () => {
  const { t } = useTranslation();
  const [isCreateWorkspaceModalOpen, setIsCreateWorkspaceModalOpen] = useState(false);
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="p18">{t('allWorkspaces', 'All Workspaces')}</Typography>
        <StyledButton color="success" sx={{ height: 40 }} onClick={() => setIsCreateWorkspaceModalOpen(true)}>
          {t('createNewWorkspace', 'Create new workspace')}
        </StyledButton>
      </Box>
      <PartnersWorkspacesMaterialTable />
      <CreateWorkspaceModal isOpen={isCreateWorkspaceModalOpen} onClose={() => setIsCreateWorkspaceModalOpen(false)} />
    </Box>
  );
};
