import { useSearchParams } from 'react-router-dom';

import { EventLogType } from 'models/event-log';

import { Box } from '@mui/material';

import { FiltersCard } from '@components/filters/FiltersCard';

import { EventLogMaterialTable } from './EventLogMaterialTable';
import { EventLogContext, EventLogProvider } from './context/EventLogProvider';

export const LogsView = () => {
  const [searchParams] = useSearchParams();
  const initialDeviceUuid = searchParams.get('deviceUuid') as string | undefined;
  const initialCategories = searchParams.get('categories')?.split(',') as EventLogType[] | undefined;

  return (
    <EventLogProvider deviceUuid={initialDeviceUuid} categories={initialCategories}>
      <Box display="flex" flexDirection="column" gap={2} pb={8}>
        <FiltersCard context={EventLogContext} />
        <EventLogMaterialTable />
      </Box>
    </EventLogProvider>
  );
};
