import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import i18n from '@config/i18next';
import App from '@core';
import { initAmplitude } from '@handlers/amplitude';

import './index.css';

initAmplitude();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Suspense fallback="...is loading">
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Suspense>,
);
